import axios from "axios";
import { encodeQueryParams } from "./client";
import { baseUrl } from "./config";

export const getStandardListByClub: any = (params:any) => {
    const path = `${baseUrl}/api/standards/clubs/${params.clubId}${encodeQueryParams({ criterionId: params.criterionId })}`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data).catch((err) => console.error('Error in getStandardListByClub:', err));
  };
  
export const unassignAction: any = (params:any) => {
  const path = `${baseUrl}/api/standards/${params.standardId}/actions/${params.actionId}`;

  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};