import { createSlice } from "@reduxjs/toolkit";
import logo from '../../assets/icons/JLogoLight.svg'
import { upcoming } from "../../assets/staticData";

let initialData: any = {
    adminPageSearchString: '',
    globalFilters: [{filterBy: 'Club name', operator: 'contains', value: '', condition: 'and'}],
    globalFiltersCount: 0,
    clubNameFilter: [],
    leagues: [],
    leagueFilter: [],
    starRatingFilter: [],
    targetStarRatingFilter: [],
    myClubs: [],
    displayMyClubs: [],
    pinnedClubs: [],
    upcoming: upcoming,
    displayUpcoming: [],
    projects: [],
    displayProjects: [],
    inputRef: null,
    isAdmin: false,
    isClubMaker: false,
    isClubChecker: false,
    token: {},
    currentRoute: '',
    user: {},
    commentBoxTabs: ['COMMENTS', 'CHAT'],
    selectedCommentBoxTab: 'COMMENTS',
    clubUsers: [],
    userRoles: [],
};

export const common = createSlice({
    name: 'common',
    initialState: initialData,
    reducers: {
        setIsAdmin: (state:any, action:any) => {
            state.isAdmin = action.payload;
            return state;
        },
        setIsClubMaker: (state:any, action:any) => {
            state.isClubMaker = action.payload;
            return state;
        },
        setIsClubChecker: (state:any, action:any) => {
            state.isClubChecker = action.payload;
            return state;
        },
        setToken: (state:any, action:any) => {
            state.token = action.payload;
            return state;
        },
        setAdminPageSearchString: (state:any, action:any) => {
            state.adminPageSearchString = action.payload;
            return state;
        },
        setGlobalFiltersCount: (state: any, action: any) => {
            state.globalFiltersCount = action.payload;
            return state;
        },
        setLeagues: (state: any, action: any) => {
            state.leagues = action.payload;
            return state;
        },
        setClubNameFilter: (state: any, action: any) => {
            state.clubNameFilter = action.payload;
            return state;
        },
        resetClubNameFilter: (state: any) => {
            state.clubNameFilter = initialData.clubNameFilter;
            return state;
        },
        setLeagueFilter: (state: any, action: any) => {
            state.leagueFilter = action.payload;
            return state;
        },
        resetLeagueFilter: (state: any) => {
            state.leagueFilter = initialData.leagueFilter;
            return state;
        },
        setStarRatingFilter: (state: any, action: any) => {
            state.starRatingFilter = action.payload;
            return state;
        },
        resetStarRatingFilter: (state: any) => {
            state.starRatingFilter = initialData.starRatingFilter;
            return state;
        },
        setTargetStarRatingFilter: (state: any, action: any) => {
            state.targetStarRatingFilter = action.payload;
            return state;
        },
        resetTargetStarRatingFilter: (state: any) => {
            state.targetStarRatingFilter = initialData.targetStarRatingFilter;
            return state;
        },
        setGlobalFilters: (state: any, action: any) => {
            state.globalFilters = action.payload;
            return state;
        },
        addNewGlobalFilters: (state: any) => {
            state.globalFilters =  [...state.globalFilters, {filterBy: 'Club name', operator: 'contains', value: '', condition: 'and'}];
            return state;
        },
        setInitialGlobalFilters: (state: any) => {
            state.globalFilters = initialData.globalFilters;
            return state;
        },
        setMyClubs: (state: any, action:any) => {
            state.myClubs = action.payload;
            return state;
        },
        setDisplayMyClubs: (state: any, action:any) => {
            state.displayMyClubs = action.payload;
            return state;
        },
        setPinnedClubs: (state: any, action:any) => {
            state.pinnedClubs = action.payload;
            return state;
        },
        setUpcoming: (state: any, action:any) => {
            state.upcoming = action.payload;
            return state;
        },
        setDisplayUpcoming: (state: any, action:any) => {
            state.displayUpcoming = action.payload;
            return state;
        },
        setProjects: (state: any, action:any) => {
            state.projects = action.payload;
            return state;
        },
        setDisplayProjects: (state: any, action:any) => {
            state.displayProjects = action.payload;
            return state;
        },
        setInputRef: (state: any, action:any) => {
            state.inputRef = action.payload;
            return state;
        },
        setCurrentRoute: (state:any, action:any) => {
            state.currentRoute = action.payload;
            return state;
        },
        setUserDetails: (state:any, action:any) => {
            state.user = action.payload;
            return state;
        },
        setSelectedCommentBoxTab: (state:any, action:any) => {
            state.selectedCommentBoxTab = action.payload;
            return state;
        },
        setClubUsers: (state:any, action:any) => {
            state.clubUsers = action.payload;
            return state;
        },
        setUserRoles: (state:any, action:any) => {
            state.userRoles = action.payload;
            return state;
        },
    }
})

export const {
    setIsAdmin,
    setIsClubChecker,
    setIsClubMaker,
    setToken,
    setAdminPageSearchString, 
    setGlobalFilters, 
    setInitialGlobalFilters, 
    addNewGlobalFilters,
    setMyClubs,
    setDisplayMyClubs,
    setPinnedClubs,
    setUpcoming,
    setDisplayUpcoming,
    setProjects,
    setDisplayProjects,
    setGlobalFiltersCount,
    setInputRef,
    setCurrentRoute,
    setClubNameFilter,
    resetClubNameFilter,
    setLeagueFilter,
    resetLeagueFilter,
    setStarRatingFilter,
    resetStarRatingFilter,
    setTargetStarRatingFilter,
    resetTargetStarRatingFilter,
    setLeagues,
    setUserDetails,
    setSelectedCommentBoxTab,
    setClubUsers,
    setUserRoles,
    } = common.actions;
export default common.reducer;