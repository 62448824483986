import { TextField, InputAdornment } from "@mui/material";

const YenTextField = (props: any) => {
    const { v } = props;
    return(
        <TextField
        InputProps={{
            endAdornment: (
                <InputAdornment position="start">
                    ¥
                </InputAdornment>
            )
        }}
        variant='standard' value={v}/>
    )
}

export default YenTextField;