import React, { useState } from "react";
import TableSingleSelectBox from "../SearchSelectBox/TableSingleSelectBox";
import { useSelector } from "react-redux";

const InterviewTeamSelectBox = (props:any) => {
    const { value, manageSelect } = props;
    const interviewTeams = useSelector((state:any) => state.evaluation.interviewTeams);
    const [selectedOption, setSelectedOption] = React.useState(value || null);
    const [inputValue, setInputValue] = React.useState(value ? (value?.displayName || '') : '');
    const [optionList, setOptionList] = useState(interviewTeams || []);
  
    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object; 
      
      if (newValue == null || newValue == undefined) {
        tempObj = null;
      } else if(newValue && newValue?.displayName && newValue.displayName.startsWith('Create "')) {
        tempObj = {...selectedOption, displayName: newValue.displayName.slice(8, -1), userId: newValue.id};
      } else {
        tempObj = {...selectedOption, displayName: newValue.displayName, userId: newValue.id};
      }

      setSelectedOption(tempObj);
      manageSelect(tempObj);
    };

    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
  
      const filteredOptions = interviewTeams.filter((option: any) => !option.displayName?.startsWith('Create'));
  
      if (newInputValue && !filteredOptions.some((option: any) => option.displayName === newInputValue || option.name === newInputValue)) {
        setOptionList([
          ...filteredOptions,
          { displayName: `Create "${newInputValue}"` }
        ]);
      } else {
        setOptionList(filteredOptions);
      }
    };

    return (
        <TableSingleSelectBox
        options={optionList}
        keyLabel={'displayName'}
        handleChange={manageHandleChange}
        variant={'standard'}
        selectedValue={selectedOption}
        handleInputChange={manageHandleInputChange}
        inputValue={inputValue}
        />
    )
}

export default InterviewTeamSelectBox