import { standards } from "../../assets/staticData";
import { createSlice } from "@reduxjs/toolkit";
import logo from '../../assets/icons/JLogoLight.svg'

let initialClub: any = {id: 0, name: 'J League', color: '#ED1A22', logo: logo};

export const clubDetails:any = createSlice({
    name: 'clubDetails',
    initialState: initialClub,
    reducers: {
        setCurrentClub: (state: any, action: any) => {
            state =  action.payload;
            return state;
        },
        setInitialClub: (state: any) => {
            state = initialClub
        }
    }
})

export const { setCurrentClub, setInitialClub } = clubDetails.actions;
export default clubDetails.reducer;