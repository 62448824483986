import React from 'react';
import './styles.css';
import footerLogo from '../../assets/icons/JFooterDark.svg'

const Footer = () => {
    return(
     <React.Fragment>
        <div className='footerContainer'>
            <img src={footerLogo} alt="Project DNA Logo" />
            <span>「本サイトで使用している文章・画像等の無断での複製・転載を禁止します。 ©JAPAN PROFESSIONAL FOOTBALL LEAGUE ALL RIGHTS RESERVED.」</span>
        </div>
     </React.Fragment>   
    )
}

export default Footer;