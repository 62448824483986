import styled, { css } from 'styled-components';

interface SpacerProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  horizontal?: boolean;
  border?: boolean;
}
export const Spacer = styled.div<SpacerProps>`
  @media (min-width: 0px) {
    ${({ xs, horizontal }) =>
      xs &&
      css`
        height: ${xs}px;
        width: ${horizontal ? `${xs}px` : 'unset'};
        min-width: ${horizontal ? `${xs}px` : 'unset'};
      `};
  }

  @media (min-width: 600px) {
    ${({ sm, horizontal }) =>
      sm &&
      css`
        height: ${sm}px;
        width: ${horizontal ? `${sm}px` : 'unset'};
        min-width: ${horizontal ? `${sm}px` : 'unset'};
      `};
  }

  @media (min-width: 900px) {
    ${({ md, horizontal }) =>
      md &&
      css`
        height: ${md}px;
        width: ${horizontal ? `${md}px` : 'unset'};
        min-width: ${horizontal ? `${md}px` : 'unset'};
      `};
  }

  @media (min-width: 1200px) {
    ${({ lg, horizontal }) =>
      lg &&
      css`
        height: ${lg}px;
        width: ${horizontal ? `${lg}px` : 'unset'};
        min-width: ${horizontal ? `${lg}px` : 'unset'};
      `};
  }

  @media (min-width: 1536px) {
    ${({ xl, horizontal }) =>
      xl &&
      css`
        height: ${xl}px;
        width: ${horizontal ? `${xl}px` : 'unset'};
        min-width: ${horizontal ? `${xl}px` : 'unset'};
      `};
  }

  ${({ border }) =>
    border &&
    css`
      border: 1px solid red;
    `};
`;
