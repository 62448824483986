import React from 'react';
//internal and external componentscontainers
import { Typography, Avatar, Box} from '@mui/material';

export const AvatarAndName = (props: any) => {
    const { avatarName, avatarImage } = props;
    return(
        <React.Fragment>
            <Box sx={{ display: 'flex', minWidth: 100 }}>
                <Avatar className='mr10' alt={avatarName} src={avatarImage} sx={{ width: 20, height: 20 }} />
                <Typography className='fs13' sx={{ mt: '0px' }}>{avatarName}</Typography>
            </Box>
        </React.Fragment>
    )
}