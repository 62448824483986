import React from 'react';
import './styles.css';
//internal and external components import
import { Select, InputLabel, MenuItem, Box, createTheme, ThemeProvider, Avatar } from "@mui/material";
import { colors } from '../../common/pallette/colors';
import { AvatarAndName } from '../AvatarAndName';
import { genUID } from '../../common/StaticExports';
import { useSelector } from 'react-redux';

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 0,
                }
            }
        },
    }
})

const SelectBox = (props: { label?: any; options: any; type?: string, border: string, selected?: string, manageChange?: any, width?: string, isDisabled?: boolean}) => {
    const { label, options, type, border, selected, manageChange, width, isDisabled } = props;
    const [value, setValue] = React.useState('');
    const handleChange = (event: { target: { value: string } }) => {
        setValue(event.target.value);
        manageChange(event.target.value);
    };
    const selectedStandardsChat = useSelector((state:any) => state.evaluation.selectedStandardsChat);
    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box className={'selectBoxWrapper'}>
                    {(label && label !== '')  && <InputLabel className='selectBoxLabel'>{label}</InputLabel>}
                    <Select
                    className='selectBoxSelect uppercase'
                    disabled={isDisabled}
                    sx={{ border: border !== 'none' ? `2px ${border}`+colors.grey.dark : '', width: width ? width : 'auto'}}
                    value={selected}
                    onChange={handleChange}
                    >
                        {
                            options.map((option: string) => {
                                return <MenuItem className='selectBoxmenuItem' value={option}>
                                            {
                                                type === 'avatar' ? 
                                                <AvatarAndName avatarName={option}/>:
                                                option
                                            }
                                        </MenuItem>
                            })
                        }
                    </Select>
                </Box>
                {
                    label == 'Chats Phase' &&
                    <Box
                    sx={{
                        backgroundColor: colors.blue.navy,
                        fontSize: '10px',
                        color: colors.white.bright,
                        p: '2px 8px',
                        borderRadius: '16px',
                        position: 'absolute',
                        top: '27px',
                        right: '14px',
                    }}>
                        {selectedStandardsChat?.uid ? genUID(selectedStandardsChat.uid) : 'N/A'}
                    </Box>
                }
            </ThemeProvider>
        </React.Fragment>
    )
}

export default SelectBox;