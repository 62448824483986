import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Typography, Box} from "@mui/material";
import { EvidenceWrapper } from "../../components/EvidenceWrapper";
import { getLicenceStandardsEvidences } from "../../api/licence";
import EvidenceList from "../../components/EvidenceList";
import { setLicenceStandardsEvidences } from "../../redux/reducers/licence";
import EvidencesTable from "../../components/EvidencesTable";

const EvidenceContents = (props: any) => {

    const { standardId } = props;
    const currentClub = useSelector((state: any) => state.clubDetails);
    const standardsEvidences = useSelector((state: any) => state.licence.standardsEvidences);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchEvidences = async () => {
            try {
                setIsLoading(true);
                const response = await getLicenceStandardsEvidences({standardId: standardId, clubId: currentClub.id});
                dispatch(setLicenceStandardsEvidences(response));
                setIsLoading(false);
            } catch {

            }
        }
        (standardId !== undefined && standardId) && fetchEvidences();
    }, [standardId])
    return(
        <EvidencesTable data={standardsEvidences} isLoading={isLoading} bg={'#FAFAFA'}/>
    )
}

export default EvidenceContents;