import React, { useRef, useState } from "react";
import { useDrag } from "../../hooks/useDrag";
import './styles.css';
import { Box, TextareaAutosize, Typography } from "@mui/material";
import { faPenToSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textarea } from "@mui/joy";
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

const DraggableNotes = () => {
    const draggableRef:any = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notes, setNotes] = useState('Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    const { position, handleMouseDown } = useDrag({
    ref: draggableRef
  });
    return(
        <div
            className="draggable"
            ref={draggableRef}
            style={{
            top: position.y,
            left: position.x
            }}
        >
            {
                isOpen ?
                <Box className={'draggable-content'}>
                    <FontAwesomeIcon className="icon xMarkIcon" icon={faXmark} onClick={() => {setIsOpen(false)}} />
                        {/* <JoyCssVarsProvider> */}
                            <TextareaAutosize
                                // sx={{ borderRadius: '6px', mt: 1, fontSize: 13 }}
                                onChange={(e:any) => {setNotes(e.target.value)}}
                                placeholder='' 
                                minRows={10}
                                value={notes}/>
                        {/* </JoyCssVarsProvider> */}
                    {/* <Typography>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                    </Typography> */}
                </Box> :
                <Box className="draggable-panel" onMouseDown={handleMouseDown}>
                    <Typography className="draggable-panel-typography">Notes</Typography>
                    <FontAwesomeIcon className="draggable-panel-icon" onClick={() => {setIsOpen(true)}} icon={faPenToSquare} />
                </Box>
            }
        </div>
    )
}

export default DraggableNotes;