import React, { useEffect, useState } from "react";
import './styles.css';
//internal and external componentscontainers
import { Typography, Paper, Box, Fade, Popper, Button, ThemeProvider, createTheme, ClickAwayListener, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { CommentsOverviewBox } from "../CommentsOverviewBox";
import { executiveSummary, standards } from "../../assets/staticData";
import { ReportComment } from "../ReportComment";
import SelectBox from "../../components/SelectBox";
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import Textarea from '@mui/joy/Textarea';
import ProgressBar from "../../components/ProgressBar";
import SelectBoxPrimary from "../../components/SelectBoxPrimary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateSelector from "../../components/DateSelector";
import ActionsStandardTable from "../../components/ActionsStandardsTable";
import { getAction, updateAction } from "../../api/action";
import AutoComplete from "../../components/AutoComplete";
import { getStaff } from "../../api/user";
import { useSelector } from "react-redux";
import StaffSelectBox from "../../components/SearchSelectBox/StaffSelectBox";
import { getClubDepartments, getProjectsByClub } from "../../api/club";
import SearchSelectBox from "../../components/SearchSelectBox";
import { getStandardListByClub } from "../../api/standards";
import { getSelfAssessmentCriteria } from "../../api/evaluation";
import LicenceActionsStandardsTable from "../../components/LicenceActionsStandardsTable";
import { Description } from "@mui/icons-material";
import { formatISO } from "date-fns";

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 0
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '13px !important',
                    padding: '10px !important',
                }
            }
        }
    }
})

const ActionDetailPopper = (props:any) => {
    const { isOpen, handleIsOpen, data } = props;
    const [comment, setComment] = React.useState('');
    const [addEvalStandards, setAddEvalStandards] = React.useState(false);
    const [addLicenceStandards, setAddLicenceStandards] = React.useState(false);
    const currentClub = useSelector((state:any) => state.clubDetails);
    const saCriteria = useSelector((state:any) => state.evaluation.selectedSaCriteria);
    const [staffList, setStaffList] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [projects, setProjects] = useState([]);
    const isAdmin = useSelector((state:any) => state.common.isAdmin);
    const [actionData, setActionData]:any = useState({
        id: null,
        name: '',
        description: '',
        standards: [],
        licenceStandards: [],
        tasks: [],
        startDate: null,
        endDate: null,
        status: 0,
        ownerName: null,
        ownerUserId: null,
        pastStatus: null,
        pastStatusDate: null,
        projectId: null,
        projectName: null,
        departmentId: null,
        departmentName: null,
    });
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
        // handleIsOpen()
    }


    const tableHead = [
        {
            key: 'standards', displayName: 'standard',
        },
        {
            key: 'criteria', displayName: 'criteria',
        },
        {
            key: '', displayName: 'required for',
        },
        {
            key: '', displayName: ''
        }
    ]

    let initialTask: any = {checked: false, task: '', id: null};
    const manageTaskDelete = (index: number) => {
        let temp = actionData.tasks;
        temp = temp.filter((d:any, i:number) => i!==index);
        setActionData({...actionData, tasks: temp});
    }

    useEffect(() => {
        const fetchAction = async () => {
            const response = await getAction({id: data.id, pastStatusPeriod: 0});
            return response;
        }

        const fetchStaffs = async () => {
            const response = await getStaff({clubId: currentClub.id});
            return response;
        }

        const fetchDepartments = async () => {
            const response = await getClubDepartments({clubId: currentClub.id});
            return response;
        }

        const fetchProjects = async () => {
            const response = await getProjectsByClub({clubId: currentClub.id});
            return response;
        }

        data?.id &&
        fetchAction().then((res:any) => {
            setActionData(res);
        });

        currentClub?.id &&
        fetchStaffs().then((res:any) => {
            setStaffList(res);
        })

        currentClub?.id &&
        fetchDepartments().then((res:any) => {
            setDepartments(res);
        })

        currentClub?.id &&
        fetchProjects().then((res:any) => {
            setProjects(res);
        })
    }, [data]);

    const calcTaskPercentage = (taskData:any) => {
        if (taskData.length === 0) return 0;
      
        const completedCount = taskData.filter((task:any) => task.checked).length;
        const percentage = (completedCount / taskData.length) * 100;
      
        return percentage;
    }

    const manageTask = (taskLabel:any, t:any, index:any) => {
        const tempObj = {...t,
            task: (taskLabel != '-') ? taskLabel : t.task,
            checked: (taskLabel == '-') ? !t.checked : t.checked}
        let tempArr = actionData.tasks.map((d:any, i:any) => {
            if(index==i) {
                return tempObj;
            } return d;
        });
        setActionData({...actionData, tasks: tempArr});
    }

    const manageClickAway = () => {
        if (!isAdmin) {
            updateAction({actionId: actionData.id, action: actionData});
        } handleIsOpen();
    }

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popper className='popperStyle' open={isOpen} transition>
                    {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => {manageClickAway()}}>
            <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ p: 2, width: 'fit-content', mt: '70px', maxHeight: 600, overflowY: 'auto' }} onClick={() => handleClickInsidePopper}>
                            <Box sx={{ mt: 2, mb: 2, height: 'auto', width: 580 }}>
                                <Box className={'mb20'}>
                                    <Typography className="fw700 uppercase fs13 fs13">Action Details</Typography>
                                    <Box className={'adDetailsWrapper'}>
                                        <Box className={'adDetails1'}>
                                            <TextField fullWidth 
                                            placeholder="Enter action Name" 
                                            onChange={(e:any) => {setActionData({...actionData, name: e.target.value})}}
                                            value={actionData.name}/>
                                            <JoyCssVarsProvider>
                                                <Textarea sx={{ borderRadius: '6px', mt: 1, fontSize: 13 }}
                                                 onChange={(e:any) => {setActionData({...actionData, description: e.target.value})}}
                                                 placeholder='Action description' 
                                                 minRows={6} maxRows={10} 
                                                 value={actionData.description}/>
                                            </JoyCssVarsProvider>
                                        </Box>
                                        <Box className={'adDetails2'}>
                                            <StaffSelectBox 
                                            options={staffList}
                                            selectedValue={actionData.ownerUserId}
                                            manageSelect={(e:any) => {setActionData({...actionData, ownerName: e.displayName, ownerUserId: e.id})}}
                                            label={'Owner Name'}
                                            />
                                            <SearchSelectBox 
                                            options={departments}
                                            selectedValue={actionData.departmentId}
                                            manageSelect={(e:any) => {setActionData({...actionData, departmentName: e.name, departmentId: e.id})}}
                                            label={'Department'}
                                            key={'name'}
                                            />
                                            <StaffSelectBox 
                                            options={projects}
                                            selectedValue={actionData.projectId}
                                            manageSelect={(e:any) => {setActionData({...actionData, projectName: e.displayName, projectId: e.id})}}
                                            label={'Project Name'}
                                            key={'displayName'}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={'adTaksContainer mb20'}>
                                    <Typography className="fw700 uppercase fs13">Tasks</Typography>
                                    <ProgressBar percent={calcTaskPercentage(actionData.tasks)} width="100%"/>
                                    <Box className={'adTaskToDos'}>
                                        <Box>
                                            {
                                                actionData.tasks.length > 0 && 
                                                actionData.tasks.map((d: any, i: number) => {
                                                    return(
                                                        <Box>
                                                            <Checkbox checked={d.checked} onClick={() => {manageTask('-', d, i)}}/>
                                                            <TextField 
                                                            value={d.task} 
                                                            onChange={(e) => {manageTask(e.target.value, d, i)}} 
                                                            sx={d.checked ? { textDecoration:  'line-through', cursor: "not-allowed"} : { }}/>
                                                            <FontAwesomeIcon className="icon ml10" icon={faTrash} onClick={() => {manageTaskDelete(i)}}/>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                        <Box>
                                            <Button className='smallButton' 
                                            sx={{ float: 'right', zIndex: 9 }} 
                                            disableElevation 
                                            onClick={() => {setActionData({...actionData, tasks: [...actionData.tasks, initialTask]})}} 
                                            variant='contained' 
                                            size='small' 
                                            startIcon={<FontAwesomeIcon icon={faPlus} />} 
                                            disabled={false}>
                                                {'Add Task'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={'mb20'}>
                                    <Typography className="fw700 uppercase fs13">Status</Typography>
                                    <Box className={'adStatusContainer'}>
                                        <Box className={'adStatusCommentWrapper'}>
                                            <JoyCssVarsProvider>
                                                <Textarea 
                                                sx={{ borderRadius: '6px', mt: 1, fontSize: 13 }} 
                                                onChange={(e:any) => {setActionData({...actionData, statusComment: e.target.value})}} 
                                                placeholder='Status comment' 
                                                minRows={8} 
                                                value={actionData.statusComment}/>
                                            </JoyCssVarsProvider>
                                        </Box>
                                        <Box className={'adStatusDate'}>
                                            <SelectBoxPrimary 
                                            label={'Status'} 
                                            options={[0, 1, 2, 3, 4]} 
                                            isStatus={true} 
                                            selected={actionData.status} 
                                            manageChange={(d:any) => {setActionData({...actionData, status: d})}}
                                            report={'rabg'}/>
                                            <DateSelector 
                                            manageChange={(e:any) => {setActionData({...actionData, startDate: e ? formatISO(new Date(e)) : null})}}
                                            dateValue={actionData.startDate}
                                            label={'Start Date'}/>
                                            <DateSelector 
                                            manageChange={(e:any) => {setActionData({...actionData, dueDate: e ? formatISO(new Date(e)) : null})}}
                                            dateValue={actionData.dueDate}
                                            label={'Due Date'}/>
                                            {/* <TextField></TextField>
                                            <Button sx={{ borderRadius: 20, padding: '5px', width: 20, mx: 'auto' }} variant="contained" disableElevation>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button> */}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={'mt10 mb20'}>
                                    <Typography className="fw700 uppercase fs13">Evaluation Standards</Typography>
                                    <Button className='smallButton' sx={{ float: 'right', zIndex: 9 }} disableElevation onClick={() => {setAddEvalStandards(true)}} variant='contained' size='small' startIcon={<FontAwesomeIcon icon={faPlus} />} disabled={addEvalStandards}>
                                        {'Add Standards'}
                                    </Button>
                                    <ActionsStandardTable 
                                    head={tableHead} 
                                    data={actionData.standards} 
                                    addStandards={addEvalStandards} 
                                    handleClick={() => setAddEvalStandards(false)}
                                    manageDone={(d:any) => {setActionData({...actionData, standards: [...actionData.standards, d]}); setAddEvalStandards(false);}}
                                    />
                                </Box>
                                <Box className={'mt10 mb20'}>
                                    <Typography className="fw700 uppercase fs13">Licence Standards</Typography>
                                    <Button className='smallButton' sx={{ float: 'right', zIndex: 9 }} disableElevation onClick={() => setAddLicenceStandards(true)} variant='contained' size='small' startIcon={<FontAwesomeIcon icon={faPlus} />} disabled={addLicenceStandards}>
                                        {'Add Standards'}
                                    </Button>
                                    <LicenceActionsStandardsTable 
                                    head={tableHead} 
                                    data={actionData.licenceStandards} 
                                    addStandards={addLicenceStandards} 
                                    handleClick={() => setAddLicenceStandards(false)}
                                    manageDone={(d:any) => {setActionData({...actionData, licenceStandards: [...actionData.licenceStandards, d]}); setAddLicenceStandards(false);}}
                                    />
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                    </ClickAwayListener>
                    )}
                </Popper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default ActionDetailPopper;