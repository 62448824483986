import { combineReducers } from "redux";
import evaluation from "./evaluation";
import clubDetails from "./clubDetails";
import common from "./common";
import library from "./library";
import clubData from "./clubData";
import licence from './licence';
import dataCaptures from "./dataCaptures";

const rootReducer = combineReducers({
    evaluation,
    licence,
    clubDetails,
    common,
    library,
    clubData,
    dataCaptures
});

export default rootReducer;