import React, { useRef, useState } from 'react'
import './styles.css'
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
} from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Box } from '@mui/material'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

const Calendar = (props: any) => {

    const { data } = props;

    const [currentEvents, setCurrentEvents] = useState({})    

    const events = [
        {
            title: "this is a sample upcoming event",
            date: "2023-03-16"
        },
        {
            title: "this is a sample upcoming event",
            date: "2023-03-16"
        },
        {
            title: "Kyot ground visit",
            date: "2023-01-10"
        },
        {
            title: "meeting coaching team",
            date: "2023-06-07"
        },
        {
            title: "Kyot ground visit",
            date: "2023-08-20"
        },
        {
            title: "meeting coaching team",
            date: "2023-09-02"
        },
        {
            title: "Kyot ground visit",
            date: "2023-11-10"
        },
        {
            title: "meeting coaching team",
            date: "2023-07-16"
        }
    ]

    
    return(
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            dayMaxEvents={true}
            weekends={true}
            height={'100%'}
            initialEvents={data || []}
            // eventContent={renderEventContent}
            // eventsSet={handleEvents}
        />
    )
}

export default Calendar;




// import React from 'react'
// import {
//   EventApi,
//   DateSelectArg,
//   EventClickArg,
//   EventContentArg,
//   formatDate,
// } from '@fullcalendar/core'
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import timeGridPlugin from '@fullcalendar/timegrid'
// import interactionPlugin from '@fullcalendar/interaction'

// interface DemoAppState {
//   weekendsVisible: boolean
//   currentEvents: EventApi[]
// }

// export default class DemoApp extends React.Component<{}, DemoAppState> {

//   state: DemoAppState = {
//     weekendsVisible: true,
//     currentEvents: []
//   }

//   render() {
//     return (
//       <div className='demo-app'>
//         {/* {this.renderSidebar()} */}
//         <div className='demo-app-main'>
//           <FullCalendar
//             plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
//             initialView='dayGridMonth'
//             selectable={true}
//             dayMaxEvents={true}
//             weekends={this.state.weekendsVisible}
//             initialEvents={[
//                         { title: 'engagement', date: '2023-11-19' },
//                         { title: 'Marriage', date: '2023-11-25' },
//                     ]}
//             eventContent={renderEventContent}
//             eventsSet={this.handleEvents}
//           />
//         </div>
//       </div>
//     )
//   }

//   handleWeekendsToggle = () => {
//     this.setState({
//       weekendsVisible: !this.state.weekendsVisible
//     })
//   }

//   handleEvents = (events: EventApi[]) => {
//     this.setState({
//       currentEvents: events
//     })
//   }

// }

// function renderEventContent(eventContent: EventContentArg) {
//   return (
//     <>
//       <b>{eventContent.timeText}</b>
//       <i>{eventContent.event.title}</i>
//     </>
//   )
// }