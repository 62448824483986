import React, { useEffect, useState } from 'react';
import './styles.css';
import { useSelector, useDispatch } from "react-redux";
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Popper, Fade, Paper, Typography } from '@mui/material/';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import CommentsDocsActionsIconWrapper from '../CommentsDocsActionsIconWrapper';
import SelectBoxPrimary from '../SelectBoxPrimary';
import StandardsDetail from '../../containers/StandardsDetail/SAStandardsDetail';
import { colors } from '../../common/pallette/colors';
import { licenceStandards } from '../../assets/staticData';
import LicenceStandardsDetail05 from '../../containers/LicenceStandardsDetail05';
import LicenceStandardsDetail02 from '../../containers/LicenceStandardsDetail02';
import LicenceStandardsDetail08 from '../../containers/LicenceStandardsDetail08';
import LicenceStandardsDetail03 from '../../containers/LicenceStandardsDetail03';
import LicenceStandardsDetail04 from '../../containers/LicenceStandardsDetail04';
import LicenceStandardsDetail01 from '../../containers/LicenceStandardsDetail01';
import { getLicenceCriteriaStandards } from '../../api/licence';
import { setLicenceCriteriaStandards } from '../../redux/reducers/licence';
import TableSkeleton from '../TableSkeleton';
import { genUID } from '../../common/StaticExports';
import LicenceStandardsDetail from '../../containers/LicenceStandardsDetail';

const LicenceStandardsTable = () => {
    const dispatch = useDispatch();

    // const licenceCriteria = useSelector((state: any) => state.licenceCriteria);
    const currentClub = useSelector((state: any) => state.clubDetails);
    const selectedLicenceCriteria = useSelector((state: any) => state.licence.selectedLicenceCriteria);
    const [standards, setStandards] = useState(useSelector((state: any) => state.licence.licenceCriteriaStandards));

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [detail, setDetail] = React.useState(Object);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // let visibleRows = React.useMemo(
  //   () =>
  //     stableSort(standards, getComparator(order, orderBy)),
  //   [order, orderBy],
  // );

  const head = [
    {
      key: 'uid', displayName: 'id',
    },
    {
      key: 'name', displayName: 'licence standards',
    },
    {
      key: 'status', displayName: 'evaluation status',
    },
    {
      key: 'isComplete', displayName: 'club status',
    },
    {
      key: '', displayName: '',
    },
  ]


  React.useEffect(() => {
    const fetchLicenceCriteriaStandards = async () => {
      try{
        const response = await getLicenceCriteriaStandards({clubId: currentClub.id, criteriaId: selectedLicenceCriteria.id});
        dispatch(setLicenceCriteriaStandards(response));
        setStandards(response);
      } catch {

      }
    }
    fetchLicenceCriteriaStandards();
  }, []);



  //table contents
    return(
        <React.Fragment>
            <Box sx={{ position: 'relative' }}>
              {/* <LicenceStandardsDetail05 isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/> */}
              {/* <LicenceStandardsDetail02 isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/> */}
              <LicenceStandardsDetail isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/>
              {/* <LicenceStandardsDetail08 isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/> */}
              {/* <LicenceStandardsDetail03 isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/> */}
              {/* <LicenceStandardsDetail04 isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/> */}
              {/* <LicenceStandardsDetail01 isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/> */}
              <TableContainer sx={{ height: '40vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                    {
                      standards.length ?
                      standards.map((d: any, i: number) => (
                          <TableRow
                          className='hoverBG'
                          sx={{ cursor: 'pointer' }}
                          key={`standards-table-row-${i}`}
                          >
                          <TableCell className='fs12'>{genUID(d.uid)}</TableCell>
                          <TableCell className='fs12'
                          onClick={() => {
                            setDetail(d);
                            console.log(d);
                            setIsOpen(!isOpen);
                          }} 
                          >{d.name}</TableCell>
                          <TableCell className='fs12'>
                              <SelectBoxPrimary label={''} options={[1, 3]} isStatus={true} selected={d.status} report={'siteReview'} />
                          </TableCell>
                          <TableCell className='fs12'>
                              {d.isComplete ? 'Complete' : 'Not Complete' }
                              {d.isComplete && <FontAwesomeIcon icon={faCircleCheck} className='ml10' color={colors.green.bright} />}
                          </TableCell>
                          <TableCell className='fs12'><CommentsDocsActionsIconWrapper actions={d.noOfActions} comments={d.noOfComments} docs={d.noOfEvidences} /></TableCell>
                          </TableRow>
                      ))
                      :
                      <TableSkeleton rowCount={6} cellCount={5} />
                    }
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
        </React.Fragment>
    )
}

export default LicenceStandardsTable;