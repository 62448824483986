import React, { useState } from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import { AvatarAndName } from '../AvatarAndName';
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import logo from '../../assets/icons/JLogo.svg';
import ThreeDotMenu from '../ThreeDotMenu';
import SelectBoxPrimary from '../SelectBoxPrimary';
import SelectBox from '../SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faLinkSlash, faEyeSlash, faBicycle, faTrashCan, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import DateSelector from '../DateSelector';
import TableSkeleton from '../TableSkeleton';
import { SwitchButton } from '../SwitchButton';
import { format } from 'date-fns';
import { DATE_FORMAT, formatDate, genUID } from '../../common/StaticExports';
import { useDispatch, useSelector } from 'react-redux';
import ActionSelectBox from '../SearchSelectBox/ActionSelectBox';
import StaffSelectBox from '../SearchSelectBox/StaffSelectBox';
import { Description } from '@mui/icons-material';
import { deleteAction, updateActions } from '../../api/action';
import { setActionsList, setSaActions } from '../../redux/reducers/evaluation';
import NestedThreeDotMenu from '../NestedThreeDotMenu';
import { icon } from '@fortawesome/fontawesome-svg-core';
import AutoComplete from '../AutoComplete';
import { unassignAction } from '../../api/standards';
import { baseUrl } from '../../api/config';
import { updateActionsLicence } from '../../api/licence';
import { setLicenceStandardsActions } from '../../redux/reducers/licence';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 16px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const AdminActionsTable = (props: any) => {
    const { data, head, addAction, handleClick, isLoading, isAddLoading, currentStandard, bg, module } = props;

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [openActionDetail, setOpenActionDetail] = React.useState(false);
  const actionList = useSelector((state:any) => state.evaluation.actionsList);
  const staffList = useSelector((state:any) => state.evaluation.staffList);
  const isAdmin = useSelector((state:any) => state.common.isAdmin);
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const licenceStandardsActions = useSelector((state: any) => state.licence.standardsActions);
  const initialAction = {
    name: '',
    description: '',
    isCreatedByJL: true,
    isArchived: false,
    isAlreadyComplete: false,
    licenceJLActionStatus: false,
  };
  const [newAction, setNewAction]:any = useState(initialAction);
  const [newlyAddedAction, setNewlyAddedAction]:any = useState(initialAction);
  // const [selectedStandards]
  const dispatch = useDispatch();
  const threeDotMenuOptions = (issued:any) => {
    return (
      issued ? 
      [
        {label: 'UNISSUE_ADMIN_ACTION', icon: faEyeSlash, isDisabled: false},
        {label: 'DELETE_ADMIN_ACTION', icon: faTrash, isDisabled: true},
      ] :
      [
        {label: 'ISSUE_ADMIN_ACTION', icon: faEye, isDisabled: false},
        {label: 'DELETE_ADMIN_ACTION', icon: faTrash, isDisabled: false},
      ]
    )
  };
  const [actionData, setActionData] = useState([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows:any = React.useMemo(
    () => 
      data?.length > 0 &&
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const manageCreateClick = () => {

    if(newAction.name != '' && newAction.name != undefined && clubDetails?.id && currentStandard?.id) {
      var actionArr:any = [];
      var parameters:any = {};
      const tempNewAction = {
        ...newAction,
        isCreatedByJL: true,
        licenceJLActionStatus: false
      }
      actionArr = [...licenceStandardsActions, tempNewAction];
      parameters = {
        clubId: clubDetails.id,
        actions: actionArr,
        standardId: currentStandard.id,
      }
      const response = updateActionsLicence(parameters);
      dispatch(setLicenceStandardsActions(response));
      handleClick();
    }
  }

  const manageSwitchChange = (row:any) => {
    let tempArr = licenceStandardsActions.map((d:any) => {
      if(d.id == row.id) {
        return {...d, isAlreadyComplete: !row.isAlreadyComplete};
      } return d;
    });
    dispatch(setLicenceStandardsActions(tempArr));
  }

  const manageLicenceActionDelete = (action:any) => {
    const index = licenceStandardsActions.findIndex((obj:any) => (obj.id == action.id || !("id" in action)));
    let tempArr:any = licenceStandardsActions;
    if (index !== -1) {
      tempArr = licenceStandardsActions.filter((d:any, i:any) => i != index);
    }
    const parameters = {
      clubId: clubDetails.id,
      actions: tempArr,
      standardId: currentStandard.id,
    }
    const response = updateActionsLicence(parameters);
    dispatch(setLicenceStandardsActions(response));
  }

  const manageIssueUnIssueAction =  (action:any) => {
    let tempArr:any = licenceStandardsActions.map((obj:any) => {
      if(obj.id == action.id || !("id" in action)) {
        return {...obj, licenceJLActionStatus: !obj.licenceJLActionStatus};
      } return obj;
    });
    const parameters = {
      clubId: clubDetails.id,
      actions: tempArr,
      standardId: currentStandard.id,
    }
    const response = updateActionsLicence(parameters);
    dispatch(setLicenceStandardsActions(response));
  }

  const handleThreedotMenuClick = (selctedAction:any, action:any) => {
    switch(selctedAction) {
      case 'DELETE_ADMIN_ACTION':
        manageLicenceActionDelete(action);
        handleClick();
        break;
      case 'UNISSUE_ADMIN_ACTION': 
        manageIssueUnIssueAction(action);
        // handleClick();
        break;
      case 'ISSUE_ADMIN_ACTION': 
        manageIssueUnIssueAction(action);
        // handleClick();
        break;
    }
  }
  
  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              {/* <ActionDetailPopper isOpen={openActionDetail} handleIsOpen={() => {setOpenActionDetail(false)}} data={actionData} /> */}
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    bg={bg}
                    />
                    <TableBody>
                      {
                        !isAddLoading && addAction ? 
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`actions-table-row-create`}
                        >
                            <TableCell className='fs12'>
                                {
                                    isAdmin && <img className='smallLogoForTables' style={{ marginTop: '0px' }} src={logo} alt="JL Logo" />
                                }
                            </TableCell>
                            <TableCell className='fs12'>
                              <StatusIndicatorSmall reportType={'liecenceAdminAction'} status={newAction?.licenceJLActionStatus ? 1 : 0} />
                                {/* <SelectBoxPrimary label={''} options={[0, 1]} isStatus={true} selected={newAction.licenceJLActionStatus ? 1 : 0} report={'liecenceAdminAction'} /> */}
                            </TableCell>
                            <TableCell className='fs12'>
                              <ActionSelectBox optionList={actionList} variant={'standard'} selected={newAction.name} manageActionSelect={(data:any) => {
                                if (data && Object.keys(data).includes('id')) {
                                  setNewAction({...data, standardHistory: []});
                                } else {
                                  setNewAction({...newAction, ...data, standardHistory: []});
                                }
                                }}/>
                            </TableCell>
                            <TableCell className='fs12'>
                              <TextField variant='standard' value={newAction.description} onChange={(e) => {setNewAction({...newAction, description: e.target.value, standardHistory: []})}} />
                            </TableCell>
                            <TableCell className='fs12'>
                                <SwitchButton label={''} isOn={newAction.isAlreadyComplete}
                                 handleOnClick={() => {setNewAction({...newAction, isAlreadyComplete: !newAction.isAlreadyComplete, standardHistory: []})}} />
                            </TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Delete' arrow placement='top'><FontAwesomeIcon onClick={() => {setNewAction(initialAction); handleClick();}} className={'icon mr10'} icon={faXmark}/></Tooltip>
                                <Tooltip title='Create' arrow placement='top'><FontAwesomeIcon onClick={() => {manageCreateClick()}} className={'icon mr10'} icon={faCheck} /></Tooltip>
                              </Box>
                            </TableCell>
                        </TableRow>:
                        (
                          isAddLoading &&
                          <TableSkeleton rowCount={1} cellCount={7} />
                        )
                      }
                    {
                      !isLoading && visibleRows?.length ?
                      visibleRows.map((d: any, i: number) => 
                        (
                          <TableRow
                          sx={{ cursor: 'pointer' }}
                          key={`actions-table-row-${i}`}
                          >
                              <TableCell className='fs12'>
                                  {
                                      d.isCreatedByJL && <img className='smallLogoForTables' style={{ marginTop: '0px' }} src={logo} alt="JL Logo" />
                                  }
                              </TableCell>
                              <TableCell className='fs12'>
                                  <StatusIndicatorSmall reportType={'liecenceAdminAction'} status={d?.licenceJLActionStatus ? 1 : 0} />
                              </TableCell>
                              <TableCell className='fs12' onClick={() => {setActionData(d); setOpenActionDetail(true);}}>{d.name?.length > 40 ? d.name.slice(0, 40) + '...' : d.name}</TableCell>
                              <TableCell className='fs12'>
                                {
                                  d.description?.length > 60 ? d.description.slice(0, 60) + '...' : 
                                  (d.description != undefined && d.description != null) ? d.description : ''
                                }
                              </TableCell>
                              <TableCell className='fs12'>
                                  <SwitchButton label={''} isOn={d.isAlreadyComplete} handleOnClick={() => {manageSwitchChange(d)}} />
                              </TableCell>
                              <TableCell sx={{ width: '1px' }}>
                                <NestedThreeDotMenu options={threeDotMenuOptions(d.licenceJLActionStatus)} manageClick={(e:any) => {handleThreedotMenuClick(e, d)}} />
                              </TableCell>
                          </TableRow>
                      )) :
                      (
                        !isLoading && (visibleRows?.length == 0 || visibleRows == undefined) ?
                        <TableRow>
                          <TableCell colSpan={6} className='fs12'>No Action Found</TableCell>
                        </TableRow>:
                        <TableSkeleton rowCount={3} cellCount={6} />
                      )
                  }
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default AdminActionsTable;