import axios from "axios";
import { encodeBody, encodeQueryParams } from "./client";
import { baseUrl } from "./config";

export const getStaff: any = (params:any) => {
  const path = `${baseUrl}/api/users/staff${encodeQueryParams({ clubId: params.clubId })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in getStaff:', err));
};

export const getUser: any = () => {
  const path = `${baseUrl}/api/users/account`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};