import axios from "axios";
import { encodeBody } from "./client";
import { baseUrl } from "./config";

export const makeLogin = (parameters:any) => {
    const path = `${baseUrl}/api/auth/login`;
    return axios({
      method: 'POST',
      url: path,
      data: encodeBody(parameters),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(res => res.data.data). catch(err => {
        console.error('error while Login', err)
    })
  };

  export const requestPasswordReset = (parameters:any) => {
    const path = `${baseUrl}/api/auth/password/forgot`;
    return axios({
      method: 'POST',
      url: path,
      data: encodeBody(parameters),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };

  export const resetPassword = (parameters:any) => {
    const path = '/api/auth/password/reset';
    return axios({
      method: 'POST',
      url: path,
      data: encodeBody(parameters),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };