import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { QueryCache, useMutation, useQuery } from 'react-query';
import { checkFileExist, getEvidenceLibrary, uploadFile } from '../../api/evidence';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import MessageModal from '../../components/MessageModal/index';
import { toast } from 'react-toastify';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import CurrentHeader from './EvidenceTable/comps/CurrentHeader';
import CurrentTable from './CurrentTable/index';
import ArchiveTable from './ArchiveTable/index';
import FolderStructure from '../../components/FolderStructure/index';
// import store from 'src/redux/store';
import { useSelector, useDispatch, Provider } from 'react-redux';
import { setCurrentData, setArchiveData, setSelectedFolder, setDisplayData, setDisplayArchiveData, setSelectedArchiveFolder, setExistingEvidenceId } from '../../redux/reducers/library';
import { getClub, getClubsDesirePlan } from '../../api/club';
import { setClubDesirePlan, setClubLicence } from '../../redux/reducers/clubData';
import { getClubLicence } from '../../api/licence';
import { setInputRef } from '../../redux/reducers/common';

const Div = styled.div`
  .filter-container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 3fr 3fr 3fr 2fr;
    margin: 0 50px;

    &__item {
      /* border: 1px solid red; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;
    }

    .flex-end {
      justify-content: flex-end;
    }
  }

  .head {
    display: flex;
    align-items: center;
  }

  #hidden-input {
    display: none;
  }

  height: 80vh;
`;

const FilterLabel = styled.span`
  margin-right: 10px;
  white-space: nowrap;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: 'calc(100% - 100px)' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: '100%' }}>
          <Typography sx={{ height: '100%' }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const EvidenceLibraryPage: React.FC = () => {
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const [t] = useTranslation();
  const [criteriaFilterId, setCriteriaFilterId] = useState<number | string | undefined>(undefined);
  const [criteriaFilterIdLicence, setCriteriaFilterIdLicence] = useState<number | string | undefined>(undefined);
  const [standardFilterId, setStandardFilterId] = useState<number | string | undefined>(undefined);
  const [standardFilterIdLicence, setStandardFilterIdLicence] = useState<number | string | undefined>(undefined);
  const [resurceExistError, setResurceExistError] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [debouncedSearchString] = useDebounce(searchString, 1000);
  const dispatch = useDispatch();
  const selectedFolder = useSelector((state:any) => state.library.selectedFolder);
  const selectedArchiveFolder = useSelector((state:any) => state.library.selectedArchiveFolder);
  const currentData = useSelector((state: any) => state.library.currentData);
  const archiveData = useSelector((state: any) => state.library.archiveData);
  const displayData = useSelector((state: any) => state.library.displayData);
  const displayArchiveData = useSelector((state: any) => state.library.displayArchiveData);
  const folderStructure = useSelector((state: any) => state.library.folderStructure);
  const archiveFolderStructure = useSelector((state: any) => state.library.archiveFolderStructure);
  const currentFolderList = useSelector((state: any) => state.library.currentFolderList);
  const archiveFolderList = useSelector((state: any) => state.library.archiveFolderList);
  const toastId = useRef<React.ReactText>('');
  const [value, setValue] = React.useState(1);
  const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);
  const currentRootFolder = currentFolderList.find((x:any) => x.parentFolderId == null);
  const archiveRootFolder = archiveFolderList.find((x:any) => x.parentFolderId == null);
  const clubDesirePlan = useSelector((state: any) => state.clubData.clubDesirePlan);
  const clubLicence = useSelector((state: any) => state.clubData.clubLicence);
  const existingEvidenceId = useSelector((state: any) => state.library.existingEvidenceId);
  const inputRef = useSelector((state: any) => state.common.inputRef);

  // useEffect(() => {
  //   const fetchDesirePlan = () => {
  //     try {
  //       const response = getClubsDesirePlan('desirePlan', {clubId: clubDetails?.id})
  //       return response;
  //     } catch (error) {
  //       console.error('Error while fetching getClubsDesirePlan');
  //     }
  //   }
  //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //   clubDetails?.id && 
  //   fetchDesirePlan()?.then((res:any) => {
  //     dispatch(setClubDesirePlan(res));
  //   });
  // }, [clubDetails]);
  
  // useEffect(() => {
  //   const fetchClubLicence = () => {
  //     try {
  //       const response = getClubLicence({clubId: clubDetails?.id});
  //       return response;
  //     } catch (error) {
  //       console.error('Error while fetching fetchClubLicence');
  //     }
  //   }
  //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //   clubDetails?.id && fetchClubLicence()?.then((res:any) => {
  //     dispatch(setClubLicence(res));
  //   });
  // }, [clubDetails]);
  
  useEffect(() => {
    const fetchCurrentLibrary = () => {
      try {
        setIsLoadingCurrent(true);
        const response = getEvidenceLibrary(
          'evidence_library',
          {
            clubId: clubDetails?.id as number,
            isArchived: false as boolean,
          });
        return response;
      } catch (error) {
        console.error('Error while fetching fetchCurrentLibrary');
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    clubDetails && clubDetails?.id &&
    fetchCurrentLibrary()?.then((res:any) => {
      dispatch(setCurrentData(res));
      setIsLoadingCurrent(false);
    });
  }, [clubDetails]);
  
  useEffect(() => {
    const fetchArchivedLibrary = () => {
      try {
        setIsLoadingArchive(true);
        const response = getEvidenceLibrary(
          'evidence_library',
          {
            clubId: clubDetails?.id as number,
            isArchived: true as boolean
          });
        return response;
      } catch (error) {
        console.error('Error while fetching fetchArchivedLibrary');
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    clubDetails && clubDetails?.id &&
    fetchArchivedLibrary()?.then((res:any) => {
      dispatch(setArchiveData(res));
      setIsLoadingArchive(false);
    });
  }, [clubDetails]);

  useEffect(() => {
    if(selectedFolder == 0) {
      dispatch(setSelectedFolder(currentRootFolder?.id));
    }
    if(selectedArchiveFolder == 0) {
      dispatch(setSelectedArchiveFolder(archiveRootFolder?.id))
    }
  }, [selectedFolder, selectedArchiveFolder])

  // useEffect(() => {
  //   const fetchClubDesire = () => {
  //     const response = getClubsDesirePlan('desire plan', {clubId: clubDetails?.id});
  //     return response;
  //   }
  //   fetchClubDesire().then((res:any) => {
  //     dispatch(setClubDesirePlan(res));
  //   })
  // }, [])

  useEffect(() => {
    if((currentData.length != 0 && displayData.length == 0)) {
      const current:any = currentData;
      // dispatch(setCurrentData(current));
      dispatch(setDisplayData(current));
      // setCurrentClub(setCurrentClub);
    }
  },[currentData]);
  
  useEffect(() => {
    if((archiveData.length != 0 && displayArchiveData.length == 0)) {
      const archive:any = archiveData;
      // dispatch(setArchiveData(archive));
      dispatch(setDisplayArchiveData(archive));
      // setCurrentClub(setCurrentClub);
    }
  },[archiveData])

  useEffect(() => {
    setIsLoadingCurrent(true);
    let tempData = currentData.filter((d: any) => {
      return (d?.evidenceFolderId == selectedFolder || currentRootFolder?.id == selectedFolder);
    });
    dispatch(setDisplayData(tempData));
    setIsLoadingCurrent(false);
  }, [currentData])
  
  useEffect(() => {
    setIsLoadingArchive(true);
    let tempData = archiveData.filter((d: any) => {
      return (d?.archivedEvidenceFolderId == selectedArchiveFolder || archiveRootFolder?.id == selectedArchiveFolder);
    });
    dispatch(setDisplayArchiveData(tempData));
    setIsLoadingArchive(false);
  }, [archiveData])

  const crumbs = useMemo(() => [{ name: t('EVIDENCE_LIBRARY') }], [t]);

  // const [exist] = useMutation(checkFileExist, {
  //   onError: (e: any) => {
  //     if (e.UserMessage === 'EVIDENCE_WITH_THE_SAME_NAME_ALREADY_EXISTS') {
  //       setResurceExistError(true);
  //       return;
  //     }
  //     toast.error(e.UserMessage);
  //   },
  // });

  // const [upload] = useMutation(uploadFile, {
  //   onError: (e: any) => {
  //     if (e.UserMessage === 'EVIDENCE_WITH_THE_SAME_NAME_ALREADY_EXISTS') {
  //       setResurceExistError(true);
  //       return;
  //     }
  //     toast.error(e.UserMessage);
  //   },
  //   onSuccess: (e) => {
  //     queryCache.invalidateQueries(['evidence_library', { clubId: clubDetails?.id }]);
  //     const newEvidence:any = {
  //       ...e,
  //       title: e.name,
  //       licenceStandards: [],
  //       evaluationStandards: [],
  //     }
  //     const tempArr:any = [newEvidence, ...currentData];
  //     dispatch(setCurrentData(tempArr));
  //   },
  // });

  const isFileExist = (name:any) => (currentData || []).some((el:any) => el?.title === name);
  const uploadedFile: string[] = [];

  const onUpload = useCallback(
    async (e:any) => {
      const { files } = e.target;
      let tempInputRef:any = {};
      
      if (inputRef.current) {
        if (files && files[0] && clubDetails?.id) {
          if (
            true
            // await exist({
            //   clubId: clubDetails?.id,
            //   fileName: files[0].name,
            // }).then((res:any) => res)
          )
            {
            setResurceExistError(true);
            tempInputRef.current.value = '';
            dispatch(setInputRef(tempInputRef));
            return;
          }
          if (true
            // isFileExist(files && files[0].name)
          ) {
            setResurceExistError(true);
            tempInputRef.current.value = '';
            dispatch(setInputRef(tempInputRef));
            return;
          }
          if (uploadedFile.length !== 0) {
            uploadedFile.forEach(el => {
              if (el === e.target?.files[0].name) {
                setResurceExistError(true);
                // @ts-ignore
                tempInputRef.current.value = '';
                dispatch(setInputRef(tempInputRef));
                return;
              }
            });
          }
          uploadedFile.push(files && files[0].name);
          // upload({
          //   clubId: clubDetails?.id,
          //   file: files[0],
          //   existingEvidenceId,
          //   currentFolderId: selectedFolder,
          //   onUploadProgress: (progressEvent:any) => {
          //     const progress = progressEvent.loaded / progressEvent.total;
          //     if (!toastId.current) {
          //       toastId.current = toast(t('UPLOAD_IN_PROGRESS'), {
          //         progress,
          //         hideProgressBar: false,
          //       });
          //     } else {
          //       toast.update(toastId.current, {
          //         progress,
          //         hideProgressBar: false,
          //       });
          //     }
          //   },
          // }).then(() => {
          //   toast.update(toastId.current, {
          //     type: 'success',
          //     render: t('UPLOAD_SUCCESS'),
          //   });
          //   setTimeout(() => {
          //     toast.dismiss(toastId.current);
          //     toastId.current = '';
          //   }, 1000);
          // });
        }
        tempInputRef.current.value = '';
        dispatch(setInputRef(tempInputRef));
        dispatch(setExistingEvidenceId(undefined));
      }
    },
    [clubDetails, existingEvidenceId, selectedFolder],
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let params:any = {};
    if(newValue) { 
      setIsLoadingCurrent(true);
      params = 
      {
        clubId: clubDetails?.id as number,
        isArchived: false as boolean
      };
    } else {
      setIsLoadingArchive(true);
      params = 
      {
        clubId: clubDetails?.id as number,
        isArchived: true as boolean
      };
    }
    getEvidenceLibrary('evidence_library', params).then((data:any) => {
      if(newValue) {
        dispatch(setCurrentData(data));
        dispatch(setDisplayData(data));
        setIsLoadingCurrent(false);
      } else {
        dispatch(setArchiveData(data));
        dispatch(setDisplayArchiveData(data));
        setIsLoadingArchive(false);
      }
    });
  }

  const manageCurrentSearch = (searchString:string) => {
    let tempData = currentData.filter((d: any) => {
      return (d.title.toLowerCase().includes(searchString.toLowerCase()) && (d.evidenceFolderId == selectedFolder || selectedFolder == currentRootFolder?.id));
    });
    dispatch(setDisplayData(tempData));
  }

  const manageArchiveSearch = (searchString:string, evalFilter:string, licecneFilter:string) => {    
    let tempData = archiveData.filter((d: any) => {
      return (d.title.toLowerCase().includes(searchString.toLowerCase()) &&
       (d.archivedEvidenceFolderId == selectedArchiveFolder || selectedArchiveFolder == archiveRootFolder?.id) &&
       (d.evaluationCycleLabel == evalFilter || evalFilter == t('SHOW_ALL')) &&
       (d.licenceCycleYear == licecneFilter || licecneFilter == t('SHOW_ALL'))
       );
    });
    dispatch(setDisplayArchiveData(tempData));
  }

  return (
      <Div>
        <MessageModal
          isOpen={resurceExistError}
          message={t('EVIDENCE_WITH_THE_SAME_NAME_ALREADY_EXISTS')}
          onClose={() => setResurceExistError(false)}
        />
        <Box sx={{ m: '0px 40px', height: '100%' }}>
          <h3>{t('EVIDENCE_LIBRARY')}</h3>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab sx={{p: '10px 30px'}} label={t('ARCHIVE')} />
                <Tab sx={{p: '10px 30px'}} label={t('CURRENT')} />
              </Tabs>
            </Box>
          </Box>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ height: '100%' }}>
                <CurrentHeader currentTab={'archive'} manageArchiveChange={(search:string, evalu:string, lice:string) => {manageArchiveSearch(search, evalu, lice)}} />
                <Box sx={{ display: 'flex', width: '100%', mt: 2, height: '100%' }}>
                  <Box sx={{ width: '20%', mr: '2%' }}>
                    <FolderStructure section={'archive'} />
                  </Box>
                  <Box sx={{ width: '78%', height: 'calc(100% - 28px)' }}>
                    <ArchiveTable data={displayArchiveData} isLoading={isLoadingArchive} />
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box sx={{ height: '100%' }}>
                <CurrentHeader currentTab={'current'} handleChange={manageCurrentSearch} />
                <Box sx={{ display: 'flex', width: '100%', mt: 2, height: '100%' }}>
                  <Box sx={{ width: '20%', mr: '2%' }}>
                    <FolderStructure section={'current'} />
                  </Box>
                  <Box sx={{ width: '78%', height: 'calc(100% - 28px)' }}>
                    <CurrentTable data={displayData} isLoading={isLoadingCurrent}/>
                    <input id="hidden-input" ref={inputRef} type="file" onChange={onUpload} />
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
        </Box>
      </Div>
  );
};

const EvidenceLibraryPageWithState = () => {
  return (
    <EvidenceLibraryPage />
  );
};

export default EvidenceLibraryPageWithState;
