import React, { useEffect, useState, useTransition } from 'react';
//internal and external components import
import { Avatar, Box, Typography, CardMedia, Card, CardContent, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCopy, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { colors } from '../../common/pallette/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setCommentContent, setSelectedCommentPhase } from '../../redux/reducers/evaluation';
import { formatDateTime, genUID } from '../../common/StaticExports';
import { useTranslation } from 'react-i18next';
import { baseUrl } from '../../api/config';

const CardContentCustom = styled(CardContent)(`
&:last-child {
    padding-bottom: 10px;
  }
padding: 10px;
height: 80%;
//flex-direction: row;
// border: 2px solid ${colors.grey.dark};
// border-radius: 4px;
`)

const TextCard = (props: any) => {
    const { data, handleClick, from } = props;
    const [isOpen, setIsOpen] = useState(false);
    const commentContent = useSelector((state:any) => state.evaluation.commentContent);
    const selectedOnSiteSession = useSelector((state:any) => state.evaluation.selectedOnSiteSession);
    const onSiteCriteria = useSelector((state:any) => state.evaluation.onSiteCriteria);
    const dispatch = useDispatch();
    const [t] = useTranslation();
    //uncomment this below snippet to enable multiple phase comment edit if required in future 
    // const manageOnClick = () => {
    //     dispatch(setSelectedCommentPhase(from));
    //     handleClick(data);
    // }

    const firstName = from == t('ON_SITE') ?
    (data?.updatedUserFirstName ? data.updatedUserFirstName : '') :
    (data.firstName ? data.firstName : '');

    const lastName = from == t('ON_SITE') ?
    (data?.updatedUserLastName ? data.updatedUserLastName : '') :
    data.lastName ? data.lastName : '';

    const avatarPath = from == t('ON_SITE') ?
    (data?.userAvatarPath ? data.userAvatarPath : '') :
    (data?.avatarPath ? data.avatarPath : '');

    const fetchSessionName = () => {
        const sessionData = onSiteCriteria.find((item:any) => item.id == data.sessionId);
        if (sessionData) {
            return sessionData?.name;
        } else {
            return '-'
        }
    }

    return(
        <React.Fragment>
            <Box sx={{ display: "flex", mb: '5px' }}>
                <Avatar sx={{width: 20, height: 20, fontSize: 8}} src={avatarPath ? `${baseUrl}/Content${avatarPath}` : ''} />
                <Typography className='fs12 fw700 mtbAuto ml5 mr5'>{(firstName != '' && lastName != '') ? `${firstName} ${lastName}` : 'Unknown'}</Typography>
                <Typography className='fs12 mtbAuto'>{data.updatedDate ? formatDateTime(data.updatedDate) : '-'}</Typography>
                <Box
                sx={{
                    mr: '10px',
                    ml: 'auto',
                    display: 'flex',
                }}>
                    <Tooltip title={t('COPY_COMMENT')} arrow placement='top'>
                        <FontAwesomeIcon className='icon mtbAuto mr10' style={{ width: '20px', height: '20px'}} icon={faCopy} onClick={() => {navigator.clipboard.writeText(data.comment)}}/>
                    </Tooltip>
                    {
                        data.standardUid &&
                        <Tooltip title={`${genUID(data.standardUid)} ${data.standardName}`} arrow placement='top'>
                            <Box
                            sx={{
                                backgroundColor: colors.blue.navy,
                                fontSize: '10px',
                                color: colors.white.bright,
                                p: '2px 8px',
                                borderRadius: '16px',
                            }}>
                                {genUID(data.standardUid)}
                            </Box>
                        </Tooltip>
                    }
                </Box>
            </Box>
            <Card sx={(from == t('ON_SITE') && commentContent && commentContent?.sessionId != undefined && commentContent?.sessionId == selectedOnSiteSession?.id) ?
            { width: '98%', marginBottom: '10px', border: `2px solid ${colors.grey.dark}`, borderRadius: '4px' } :
            { width: '98%', marginBottom: '10px' }} 
            elevation={0} 
            // onClick={() => {manageOnClick()}}
            >
                <CardContentCustom>
                <Box sx={{ maxHeight: 140, overflow: 'auto' }}>
                    {
                        from == t('ON_SITE') &&
                        <Typography sx={{ fontSize: 12, textAlign: 'left', fontWeight: 700}} onClick={() => setIsOpen(!isOpen)}>
                            {fetchSessionName()}
                        </Typography>
                    }
                    <Typography sx={{ fontSize: 12, textAlign: 'left'}} onClick={() => setIsOpen(!isOpen)}>
                        {data && data.comment ? data.comment : t('NO_COMMENT')}
                    </Typography>
                </Box>
                </CardContentCustom>
                                {/* <FontAwesomeIcon className='icon' style={{ width: '24px', height: '24px', marginTop: 'auto' }} icon={faCopy} onClick={() => {navigator.clipboard.writeText(data.comment)}}/>
                                <FontAwesomeIcon className='icon' style={{ width: '24px', height: '24px', marginTop: 'auto' }} icon={faClipboard}  onClick={() => handleClick(data.comment)}/> */}
            </Card>
        </React.Fragment>
    )
}

export default TextCard;