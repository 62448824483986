import React, { useEffect } from 'react';
import Modal from '../Modal';
import styled from 'styled-components';
// import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';

// export const StyledButton = styled(Button)`
//   width: 100%;
// `;

const StyledModal:any = styled(Modal)`
  max-width: 800px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;

  #title {
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }

  .title1 {
    font-size: 18px;
    font-weight: 500;
  }
  
  .pStyle {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888; /* For Firefox */
    
    &::-webkit-scrollbar {
      height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 0.5em;
    }
  }
`;

const InformationPopup= (props:any) => {
    const { isOpen, closeModal, makeAction, message, rightButtonLabel, retainedList, actionList, retainMessage, listMessage, singleButtonLabel } = props;
    const [t] = useTranslation();
    const retainFiles = retainedList ? retainedList.map((d:any) => decodeURIComponent(d.title)).join(', ') : '';
    const actionFiles = actionList ? actionList.map((d:any) => decodeURIComponent(d.title)).join(', ') : '';

    return (
        <StyledModal onRequestClose={() => {}} isOpen={isOpen}>
            {((retainedList == undefined || retainedList?.length == 0) || (message != '' || message != undefined)) &&
             <span id="title">{t(message)}</span>}
            <div css="margin-top: 20px;" />
            {
                retainedList?.length > 0 &&
                <Box sx={{ width: '100%', mb: '20px'}}>
                    <Typography className='title1'>
                        {t(retainMessage)} :
                    </Typography>
                    <Box sx={{ width: '100%', padding: '10px', backgroundColor: '#F3F6FB', borderRadius: '5px' }}>
                        <Typography className='pStyle' sx={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>{retainFiles}</Typography>
                    </Box>
                </Box>
            }
            {
                actionList?.length > 0 &&
                <Box sx={{ width: '100%', mb: '20px'}}>
                    <Typography className='title1'>
                        {t(listMessage)} :
                    </Typography>
                    <Box sx={{ width: '100%', padding: '10px', backgroundColor: '#F3F6FB', overflowX: 'auto', borderRadius: '5px' }}>
                        <Typography className='pStyle' sx={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>{actionFiles}</Typography>
                    </Box>
                </Box>
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'space-around' }}>
                {
                    makeAction == undefined ?
                        <Button onClick={() => {closeModal()}}>{singleButtonLabel ? t(singleButtonLabel) : t('BACK')}</Button>
                    :
                    <>
                        <Button onClick={() => {closeModal()}}>{t('BACK')}</Button>
                        <Button onClick={() => {makeAction()}}>{rightButtonLabel ? t(rightButtonLabel) : t('OK')}</Button>
                    </>
                }
            </Box>
        </StyledModal>
    );
};

export default InformationPopup;
