import React from "react";
//internal and external componentscontainers
import { Typography, Paper, Box, Fade, Popper, Button, ThemeProvider, createTheme, ClickAwayListener } from '@mui/material';
import SearchDropBox from "../../components/SearchDropBox";

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 70
                },
            }
        },
    }
})

const ChangeClubPopup = (props: { isOpen: boolean, handleIsOpen: any }) => {
    const { isOpen, handleIsOpen } = props;
    const [comment, setComment] = React.useState('');
    const [club, setClub] = React.useState({});
    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popper className='popperStyle' open={isOpen} transition>
                    {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleIsOpen}>
                    <Fade {...TransitionProps} timeout={350}>
                                <Paper sx={{ p: 2, width: 'fit-content', display: 'flex' }}>
                                    <SearchDropBox placeholder={'Search the club name...'} selectedValue={handleIsOpen}/>
                                    {/* <Button sx={{ ml: 2 }} variant="contained" disableElevation disableRipple>Select</Button> */}
                                </Paper>
                            </Fade>
                            </ClickAwayListener>
                    )}
                </Popper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default ChangeClubPopup;