import { createSlice } from "@reduxjs/toolkit";

let initialData: any = {
    companyDetails: {},
    contactDetails: {},
    staffDetails: [],
    facilitiesDetails: [],
    teamDetails: [],
    profitAndLossDetails: [],
    supportFundDetails: [],
    playerDetails: [],
};


export const dataCaptures = createSlice({
    name: 'dataCaptures',
    initialState: initialData,
    reducers: {
        setCompanyDetails: (state: any, action: any) => {
            state.companyDetails =  action.payload;
            return state;
        },
        setContactDetails: (state: any, action: any) => {
            state.contactDetails =  action.payload;
            return state;
        },
        setStaffDetails: (state: any, action: any) => {
            state.staffDetails =  action.payload;
            return state;
        },
        setFacilitiesDetails: (state: any, action: any) => {
            state.facilitiesDetails =  action.payload;
            return state;
        },
        setTeamDetails: (state: any, action: any) => {
            state.teamDetails =  action.payload;
            return state;
        },
        setProfitAndLossDetails: (state: any, action: any) => {
            state.profitAndLossDetails =  action.payload;
            return state;
        },
        setSupportFundDetails: (state: any, action: any) => {
            state.supportFundDetails =  action.payload;
            return state;
        },
        setPlayerDetails: (state: any, action: any) => {
            state.playerDetails =  action.payload;
            return state;
        },
    }
});

export const {
    setCompanyDetails,
    setContactDetails,
    setStaffDetails,
    setFacilitiesDetails,
    setTeamDetails,
    setProfitAndLossDetails,
    setSupportFundDetails,
    setPlayerDetails,
} = dataCaptures.actions;
export default dataCaptures.reducer;