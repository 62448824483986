import { Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import SearchBox from "../../../../components/SearchBox";
import './currentHeaderStyles.css';
import EvaluationCycleFilter from "../../EvaluationCycleFilter";
import LicenceYearFilter from "../../LicenceYearFilter";
import { useSelector, useDispatch } from "react-redux";
import { updateArchiveFolderList, updateArchiveFolderStructure, updateCurrentFolderList, updateFolderStructure } from "../../../../redux/reducers/library";
import { updateEvidenceFolders } from "../../../../api/evidence";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from "react-i18next";
import InformationPopup from "../../../../components/InformationPopup";

const CurrentHeader = (props:any) => {
    
    const [t] = useTranslation();
    const { currentTab, handleChange, manageArchiveChange } = props;
    const dispatch = useDispatch();
    // const folders = currentTab == 'archive' ? useSelector((state: any) => state.library.archiveFolderStructure) : useSelector((state: any) => state.library.folderStructure);
    const folderList = useSelector((state: any) => {
        return currentTab === 'archive' ? state.library.archiveFolderList : state.library.currentFolderList;
      });
    const selectedFolder = useSelector((state:any) => state.library.selectedFolder);
    const selectedArchiveFolder = useSelector((state:any) => state.library.selectedArchiveFolder);
    const selected = currentTab == 'archive' ? selectedArchiveFolder : selectedFolder;
    const archiveData = useSelector((state: any) => state.library.archiveData);
    const [evalFilter, setEvalFilter] = useState(t('SHOW_ALL'));
    const [licecneFilter, setLicenceFilter] = useState(t('SHOW_ALL'));
    const [evaluationOptions, setEvaluationOptions] = useState([t('SHOW_ALL')]);
    const [licenceOptions, setLicenceOptions] = useState([t('SHOW_ALL')]);
    const [isAddingFolder, setIsAddingFolder] = useState(false);
    const [addFolderWarning, setAddFolderWarning] = useState(false);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const inputRef = useSelector((state: any) => state.common.inputRef);
    const isAdmin = useSelector((state: any) => state.common.isAdmin);

    const manageAddFolder = () => {

        setIsAddingFolder(true);

        const maxDepth = getMaxDepth(folderList, selected);

        if(maxDepth < 5) {
            let newFolderObj = {
                name: 'untitled',
                id: 0,
                parentFolderId: selected
            };
        
            // Check if a folder with the same name already exists
            let newName = newFolderObj.name;
            let counter = 1;
            while (folderList.some((folder:any) => folder.name === newName)) {
                newFolderObj.name = newName = `untitled (${counter})`;
                newFolderObj.id = 0;
                counter++;
            }
    
            const addFolder = async () => {
                const response = await updateEvidenceFolders({
                    clubId: clubDetails?.id,
                    parentFolderId: selected,
                    folderName: newFolderObj.name,
                    isArchived: currentTab == 'archive',
                    evidenceFolderId: ""
                })
            }
            addFolder().then(() => {
    
                // Add the new folder to childrenFolders array
                // childrenFolders.push(newFolderObj);
                let tempArr:any = [];
                if(currentTab == 'archive') {
                    // dispatch(updateArchiveFolderStructure({...folders, children: [... folders.children, newFolderObj]}));
                    tempArr = [...folderList, newFolderObj]
                    dispatch(updateArchiveFolderList(tempArr));
                } else {
                    // dispatch(updateFolderStructure({...folders, children: [... folders.children, newFolderObj]}));
                    tempArr = [...folderList, newFolderObj]
                    dispatch(updateCurrentFolderList(tempArr));
                }
                setIsAddingFolder(false);
            });
        } else {
            setAddFolderWarning(true);
        }
    }

    function getMaxDepth(array:any, selectedId:number) {
        
        // Find the selected folder
        const selectedFolder = array.find((folder:any) => folder.id === selectedId);

        // If the selected folder is not found, return 0
        if (!selectedFolder) {
            return 0;
        }

        // If the selected folder's parentFolderId is null, return 1
        if (selectedFolder.parentFolderId === null) {
            return 0;
        }

        let depth = 0;
        let currentId = selectedFolder.parentFolderId;
      
        // Iterate through the array to find the selected folder
        while (currentId !== null) {
          const folder = array.find((item:any) => item.id === currentId);
          if (!folder) {
            break; // If folder with currentId is not found, exit loop
          }
          depth++;
          currentId = folder.parentFolderId; // Move to the parent folder
        }

        // Return the maximum depth between parent and child depth
        return depth;
    }


    useEffect(() => {
        let tempEvaluationCycles:any = [];
        let tempLicenceCycles:any = [];
        archiveData.map((d:any) => {
            if (!evaluationOptions.includes(d?.evaluationCycleLabel) &&
                !tempEvaluationCycles.includes(d?.evaluationCycleLabel) && d?.evaluationCycleLabel != '' && d?.evaluationCycleLabel != null) {
                tempEvaluationCycles.push(d?.evaluationCycleLabel);
            }
            if (!licenceOptions.includes(d?.licenceCycleYear) &&
                !tempLicenceCycles.includes(d?.licenceCycleYear) && d?.licenceCycleYear != null) {
                tempLicenceCycles.push(d?.licenceCycleYear);
            }
            setEvaluationOptions([...evaluationOptions, ...tempEvaluationCycles]);
            setLicenceOptions([...licenceOptions, ...tempLicenceCycles]);
        })
    }, [archiveData])

    return (
        <>
            <InformationPopup isOpen={addFolderWarning} closeModal={() => {
                setAddFolderWarning(false);
                setIsAddingFolder(false);
            }}
            message={'REACHED_MAX_FOLDER_DEPTH_MESSAGE'} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {
                    !isAdmin &&
                    <Box>
                        <LoadingButton variant='contained' 
                        className='mtbAuto ELbuttons' 
                        onClick={manageAddFolder} 
                        startIcon={<FontAwesomeIcon icon={faFolderPlus} width={14} />} 
                        sx={{ height: 28 }}
                        loading={isAddingFolder}
                        disableElevation>
                            {t('ADD_FOLDER')}
                        </LoadingButton>
                        {
                            currentTab == 'current' &&
                            <Button variant='contained' className='mtbAuto ELbuttons'
                            onClick={(e:any) => {
                              e.preventDefault();
                              inputRef.current?.click();
                            }} startIcon={<FontAwesomeIcon icon={faUpload} width={14} />} sx={{ height: 28, ml: 1 }} disableElevation>{t('ADD_FILE')}</Button>
                        }
                    </Box>
                }
                {
                    currentTab == 'archive' &&
                    <Box sx={{ display: 'flex', width: '50%', m: 'auto'}}>
                        <EvaluationCycleFilter data={evaluationOptions} selected={evalFilter} onChange={(value:any) => {
                            setEvalFilter(value);
                            manageArchiveChange('', value, licecneFilter)
                            }} />
                        <Box sx={{ ml:1 }}>
                            <LicenceYearFilter data={licenceOptions} selected={licecneFilter} onChange={(value:any) => {
                                setLicenceFilter(value);
                                manageArchiveChange('', evalFilter, value);
                                }}/>
                        </Box>
                    </Box>
                }
                <Box sx={{ width: '30%', marginLeft: "auto" }}>
                    <SearchBox handleChange={(value:any) => currentTab == 'current' ? handleChange(value) : manageArchiveChange(value, evalFilter, licecneFilter)}/>
                </Box>
            </Box>
        </>
    )
}

export default CurrentHeader;