import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import './styles.css'
//internal and external components
import { Skeleton } from '@mui/material';
import { Box } from '@mui/joy';

const ClubCardsSkeleton = () => {

    return(
        <React.Fragment>
            <Box className={'myClubCard'}>
                <Box className={'myClubCardTopSection'}>
                    <Skeleton variant='circular' width={60} height={60} className='mtbAuto'/>
                    <Box width={'67%'} className={'ml10'}>
                        <Box>
                            <Skeleton />
                            <Skeleton variant='rectangular' height={40}/>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default ClubCardsSkeleton;