import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { EvidenceWrapper } from "../EvidenceWrapper";

const EvidenceList = (props:any) => {
    const { isLoading, data } = props;

    return(
        <Box>
            {
                !isLoading && data.length ?
                data.map((d: any) => {
                    return <EvidenceWrapper name={d.name}/>
                }):
                (
                    data.length == 0 && !isLoading ?
                    <Typography className='fs13'>No Evidence Found</Typography>:
                    <Box>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Box>

                )
            }
        </Box>
    )
}

export default EvidenceList;