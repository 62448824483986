import React from 'react';
import './styles.css';
//internal and external components import
import { Select, InputLabel, MenuItem, Box, createTheme, ThemeProvider } from "@mui/material";
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import { colors } from '../../common/pallette/colors';

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 0,
                }
            }
        },
    }
})

const SelectBoxPrimary = (props:any) => {
    const { label, options, isStatus, selected, manageChange, report } = props;
    const [value, setValue] = React.useState(selected ? selected : options[0]);
    const handleChange = (event: { target: { value: string } }) => {
        setValue(event.target.value);
        manageChange(event.target.value);
    };
    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box className={'selectBoxPrimaryWrapper'} sx={{ top: (label === undefined || label !== '') ? '-6px' : '0px' }}>
                    <InputLabel className='selectBoxPrimaryLabel' sx={{display: (label == undefined || label !== '') ? 'block' : 'none', fontSize: 12, float: 'left'}}>{label}</InputLabel>
                    <Select
                    className='selectBoxPrimarySelect'
                    sx={{ backgroundColor: colors.grey.light }}
                    value={selected}
                    onChange={handleChange}
                    >
                        {
                            options.map((option: string) => {
                                return <MenuItem className='selectBoxPrimarymenuItem' value={option}>
                                    {
                                        isStatus ? 
                                        <StatusIndicatorSmall reportType={report ? report : ''} status={option} /> :
                                        option
                                    }
                                    </MenuItem>
                            })
                        }
                    </Select>
                </Box>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default SelectBoxPrimary;