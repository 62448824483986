import { Box, Skeleton } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

const FolderStructureSkeleton = (props:any) => {
    const { childFolders } = props;
return(
    <>
    <Box sx={{ display: "flex" }}>
        <FontAwesomeIcon className="directoryIcons" icon={faFolder} />
        <Skeleton sx={{ width: '100%' }} animation="wave" />
    </Box>
    {
        Array.from({length: childFolders}, () => {
            return (
            <Box sx={{ display: "flex", ml:6 }}>
                <FontAwesomeIcon className="directoryIcons" icon={faFolder} />
                <Skeleton sx={{ width: '100%' }} animation="wave" />
            </Box>)
        })
    }
    </>
);
}

export default FolderStructureSkeleton;