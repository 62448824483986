import { createBrowserRouter } from "react-router-dom";
import JLHome from "./containers/JLHome";
import LicenceHome from "./containers/LicenceHome";
import ClubsHomePage from "./pages/ClubsHomePage";
import EvidenceLibraryPageWithState from "./pages/EvidenceLibraryPage";
import FilePreviewPage from "./common/FilePreviewPage";

export const routes:any = {
  '/adminhome': {
    component: JLHome,
    clubRequired: false,
  },
  '/selfassessment': {
    component: ClubsHomePage,
    clubRequired: true,
  },
  "/selfassessment/:criteriaId(\\d+)": {
    component: ClubsHomePage,
    clubRequired: true,
  },
  "/onsite": {
    component: ClubsHomePage,
    clubRequired: true,
  },
  "/reports": {
    component: ClubsHomePage,
    clubRequired: true,
  },
  "/clublicence": {
    component: LicenceHome,
    clubRequired: true,
  },
  "/clublicence/:criteriaId(\\d+)": {
    component: LicenceHome,
    clubRequired: true,
  },
  "/evidencelibrary": {
    component: EvidenceLibraryPageWithState,
    clubRequired: false,
  },
  "/preview": {
    component: FilePreviewPage,
    clubRequired: true,
  },
}