import React from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography,InputAdornment  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import { teams } from '../../assets/staticData';
import YenTextField from '../YenTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import DateSelector from '../DateSelector';
import MultiSelectPrimary from '../MultiSelectPrimary';
import CustomTimePicker from '../CustomTimePicker';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const TrainingScheduleTable = () => {

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [data, setData]: any = React.useState(teams);
  let types:any = [];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      data?.length > 0 &&
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  const head = [
    {key: '', displayName: 'Day'},
    {key: '', displayName: 'Start'},
    {key: '', displayName: 'End'},
    {key: '', displayName: 'Ground'},
    {key: '', displayName: 'Remarks'},
  ]


  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                    {
                        visibleRows && visibleRows?.length &&
                        visibleRows?.map((d: any, i: number) => (
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`history-table-row-${i}`}
                        >
                            <TableCell className='fs12'>
                              {days[i]}
                            </TableCell>
                            <TableCell className='fs12'>
                                <CustomTimePicker />
                            </TableCell>
                            <TableCell className='fs12'>
                              <CustomTimePicker />
                            </TableCell>
                            <TableCell className='fs12'>
                              <MultiSelectPrimary variant={'standard'}/>
                            </TableCell>
                            <TableCell className='fs12'>
                              <TextField variant='standard' sx={{ width: '100%' }} value={''}/>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default TrainingScheduleTable;