import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle, Box, IconButton, Slide } from "@mui/material";
import React, { useEffect } from "react";

const MessageModalPopup = (props:any) => {
    const { open, message, closeModal } = props;

    setTimeout(() => {
        closeModal();
    }, 2000); 

    return (
        <Slide direction="down" in={open}>
            <Box sx={{ position: "absolute", top: '20px', right: '10px', zIndex: 99, boxShadow: '2px 4px 8px #000' }}>
                    <Alert severity="info" 
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </IconButton>
                    }
                    sx={{ mb: 0 }}>
                        {/* <AlertTitle>Info</AlertTitle> */}
                        {message}
                    </Alert>
            </Box>
        </Slide>
    );
}

export default MessageModalPopup;