import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPenToSquare, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Box, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { colors } from "../../common/pallette/colors";
import { useDispatch, useSelector } from "react-redux";
import { getClubsDesirePlan, saveClubsDesirePlan } from "../../api/club";
import { StarRating } from "../../api/types";
import { setClubDesirePlan } from "../../redux/reducers/clubData";
import { format, isAfter } from "date-fns";

const TargetAcheivedStars = (props: {label: string, acheived?: any}) => {
    const { label, acheived } = props;
    // const [currentStar, setCurrentStar] = useState(acheived);
    const [isEdit, setIsEdit] = useState(false);
    // const [isConfirm, setIsConfirm] = useState(false);
    const [hoveredStar, setHoveredStar] = useState(undefined);
    const [selectedStar, setSelectedStar]:any = useState(undefined);
    const clubDesirePlan = useSelector((state: any) => state.clubData.clubDesirePlan);
    const clubDetails = useSelector((State:any) => State.clubDetails);
    const isAdmin = useSelector((state: any) => state.common.isAdmin);
    const prevTargetStar = clubDesirePlan.previousTargetStarRating;
    const dispatch = useDispatch();
    
    const onMouseEnter = useCallback((star:any) => setHoveredStar(star), []);
    const onMouseLeave = useCallback(() => setHoveredStar(undefined), []);

    const changeTargetRating = async () => {
        const response = saveClubsDesirePlan({starRating: selectedStar});
        return response;
    }

    const fetchClubDesirePlan = async () => {
        const response = await getClubsDesirePlan('desirePlan', {clubId: clubDetails?.id});
        return response;
    }

    const checkIsConfirmed = (inputDate:any) => {
    return (
        format(new Date(), 'yyyy.MM.dd') != format(new Date(inputDate), 'yyyy.MM.dd') &&
        isAfter(new Date(), new Date(inputDate))
    );
    };

    const isConfirmed = !!clubDesirePlan?.starRating && clubDesirePlan?.targetDate && checkIsConfirmed(clubDesirePlan?.targetDate);

    const manageDoneClick = () => {
        setIsEdit(false);
        if(label == 'target' && selectedStar != acheived) {
            changeTargetRating()
            .then(() => {
                console.log('Target changed!');
                fetchClubDesirePlan()
                .then((res:any) => dispatch(setClubDesirePlan(res)));
            })
        }
    }

    const onStarClick = useCallback((star:any) => {setSelectedStar(star)}, []);
    const calcStarColor = useCallback((star:any) => {
        if (typeof acheived !== 'undefined' && star <= acheived && selectedStar == undefined) {
            return colors.yellow.bright;
        }
        if (star <= (prevTargetStar || 0)) {
            return colors.yellow.bright;
        }
        if (selectedStar && star <= selectedStar) {
            return colors.yellow.bright;
        }
        if (hoveredStar && star <= hoveredStar) {
            return colors.yellow.bright;
        }
        return colors.grey.bright;
    }, [acheived, prevTargetStar, selectedStar, hoveredStar]);   

    let i=0;
    return(
        <React.Fragment>
            <ClickAwayListener onClickAway={() => {setIsEdit(false); setSelectedStar(acheived);}}>
                <Box sx={{ display: "flex", marginRight: 1, padding: '10px'}} className={isEdit ? 'elevate' : ''}>
                    <Typography className="mr10 capitalize fs13 fw800 mtbAuto">{label}</Typography>
                    <Box sx={{ display: "flex" }}>
                        {[1, 2, 3, 4].map(star => (
                        <FontAwesomeIcon 
                            className={isEdit ? `mtbAuto cursorPointer` : "mtbAuto"} 
                            icon={faStar} 
                            onMouseEnter={() => {!isAdmin && !isConfirmed && isEdit && onMouseEnter(star)}}
                            onMouseLeave={() => {!isAdmin && !isConfirmed && isEdit && onMouseLeave()}}
                            onClick={() => {!isAdmin && !isConfirmed && isEdit && onStarClick(star)}}
                            // style={{color: ((acheived == undefined || i <= acheived))
                            //     ? colors.yellow.bright : colors.grey.bright}} 
                            style={{ color: calcStarColor(star) }}
                        />
                        ))}
                    </Box>
                    {
                        label == 'target' &&
                        <Box sx={{ m:'auto', ml: '10px' }}>
                            {
                                !isAdmin && !isConfirmed && isEdit ?
                                <Tooltip title={'Done'} arrow placement="right">
                                    <FontAwesomeIcon className="icon" icon={faCheck} onClick={() => manageDoneClick()} />
                                </Tooltip>
                                :
                                !isAdmin && !isConfirmed &&
                                <Tooltip title={'Edit'} arrow placement="right">
                                    <FontAwesomeIcon className="icon" icon={faPenToSquare} onClick={() => setIsEdit(true)} />
                                </Tooltip>
                            }
                        </Box>
                    }
                </Box>
            </ClickAwayListener>
        </React.Fragment>
    )
}

export default TargetAcheivedStars;