import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import '../styles.css';
//internal and external components import
import { Table, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import StatusIndicatorSmall from '../../StatusIndicatorSmall';
import ProgressBar from '../../ProgressBar';
import { stableSort, descendingComparator, getComparator, sortData } from '../../../common/SortSupport';
import { EnhancedTableHead } from '../../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMaximize } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../CustomTooltip';
import { colors } from '../../../common/pallette/colors';
import TableSkeleton from '../../TableSkeleton';
import { resetSaStandards, resetSelectedSaCriteria, setSelectedSaCriteria } from '../../../redux/reducers/evaluation';
import { genUID } from '../../../common/StaticExports';
import CommentsDocsActionsIconWrapper from '../../CommentsDocsActionsIconWrapper';
import NewMarker from '../../NewMarker';
import { useTranslation } from 'react-i18next';


export default function CriteriaTable(props: any) {

  const { data, criteria, selectedCriteria, isLoading } = props;

  const dispatch = useDispatch();
  const currentSection = useSelector((state:any) => state.evaluation.currentSection);
  const criteriaGroups = useSelector((state:any) => state.evaluation.criteriaGroups);
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const [t] = useTranslation();

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('uid');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    
  };

  const visibleRows:any = React.useMemo(
    () =>
      data?.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = [
    {
      key: 'uid', displayName: 'id',
    },
    {
      key: 'name', displayName: 'criteria',
    },
    {
      key: '', displayName: '',
    },
    // {
    //   key: 'criteriaGroupId', displayName: 'group',
    // },
    {
      key: 'offSite', displayName: 'off-site',
    },
    {
      key: 'onSite', displayName: 'on-site',
    },
    {
      key: 'interimReprot', displayName: 'interim report',
    },
    {
      key: 'finalReport', displayName: 'final report',
    },
    {
      key: 'progress', displayName: 'progress',
    },
  ]

  const manageTableExpand = () => {
    dispatch(resetSelectedSaCriteria());
    dispatch(resetSaStandards());
  }

  const manageTableShrink = (d: any) => {
    dispatch(setSelectedSaCriteria(d));
    // dispatch(setSelectedStandards(standards[d.criteria]))
  }

  const fetchGroupName = (groupId:any) => {
    const group = criteriaGroups.find((x:any)=> x.id == groupId);
    return group.name;
  }

  //table contents
    
  return (
    <React.Fragment>
      <Box sx={{ height: '100%', position: 'relative' }}>
        {!criteria && <FontAwesomeIcon className='icon tableExpandIcon' icon={faMaximize} onClick={() => manageTableExpand()} />}
        <TableContainer sx={{height: '100%', width: !criteria ? 'fit-content' : 'auto', overflow: criteria ? 'auto' : 'hidden'}}>
          <Table stickyHeader sx={{ width: criteria ? '100%' : 'auto', boxShadow: 'none' }} size="small" aria-label="a dense table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              bg={'#fff'}
              tableHeads={criteria ? head : [head[0]]}
            />
            <TableBody>
              {
                (!isLoading && visibleRows?.length && currentSection == t('EVALUATION')) ?
                visibleRows.map((d: any, i: number) => (
                  <TableRow
                    className='hoverBG'
                    key={`dynamic-table-row-${i}`}
                    onClick={() => manageTableShrink(d)}
                  >
                    <CustomTooltip title={d.name} className={!criteria ? 'capitalize' : 'displayNone'} arrow followCursor placement='top-start'>
                      <TableCell className={(!criteria && selectedCriteria.id === d.id) ? 'fw900 ' : ''}>{genUID(d.uid)}</TableCell>
                    </CustomTooltip>
                    {
                      criteria && <>
                      <TableCell className='capitalize' sx={{cursor: 'pointer', width: '500px'}}>
                        {/* <FontAwesomeIcon className='icon mr5' icon={faChevronRight} /> */}
                        {d.name}
                        {/* enable while implementing the new marker logic with eval report */}
                        {/* <NewMarker /> */}
                      </TableCell>
                      <TableCell className='fs12'><CommentsDocsActionsIconWrapper actions={d.noOfActions} comments={d.noOfComments} docs={d.noOfEvidences} /></TableCell>
                      {/* <TableCell>{fetchGroupName(d.criteriaGroupId)}</TableCell> */}
                      <TableCell><StatusIndicatorSmall reportType={'siteReview'} status={d.offSiteStatus} /></TableCell>
                      <TableCell>
                        {
                          !(clubDetails.currentEvaluationPhase == "ON_SITE" || clubDetails.currentEvaluationPhase == "INTERIM_REPORT" ||
                            clubDetails.currentEvaluationPhase == "FINAL_REPORT") ? '-' :
                          <StatusIndicatorSmall reportType={'siteReview'} status={d.onSiteStatus} />
                        }
                      </TableCell>
                      <TableCell>
                        {
                          !(clubDetails.currentEvaluationPhase == "INTERIM_REPORT" || clubDetails.currentEvaluationPhase == "FINAL_REPORT") ? '-' :
                          <StatusIndicatorSmall reportType={'siteReview'} status={d.interimReportStatus} />
                        }
                      </TableCell>
                      <TableCell>
                        {
                          (clubDetails.currentEvaluationPhase == "FINAL_REPORT") ?
                          <StatusIndicatorSmall reportType={'siteReview'} status={d.finalReportStatus} /> : '-'
                        }
                      </TableCell>
                      <TableCell>
                        <ProgressBar percent={d.progress}/>
                      </TableCell></>
                    } 
                  </TableRow>
                ))
                :
                <TableSkeleton rowCount={13} cellCount={8} />
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}