import { createSlice } from "@reduxjs/toolkit";

let initialData: any = {
    licenceCriteria: [],
    selectedLicenceCriteria: {},
    licenceCriteriaStandards: [],
    criteriaActions: [],
    criteriaEvidences: [],
    standardsActions: [],
    // standardsActionsFiltered: [],
    // standardsAdminActions: [],
    standardsEvidences: [],
};


export const licence = createSlice({
    name: 'licence',
    initialState: initialData,
    reducers: {
        setLicenceCriteria: (state: any, action: any) => {
            state.licenceCriteria =  action.payload;
            return state;
        },
        setInitialLicenceCriteria: (state: any) => {
            state.licenceCriteria = initialData.licenceCriteria;
            return state;
        },
        setSelectedLicenceCriteria: (state: any, action: any) => {
            state.selectedLicenceCriteria =  action.payload;
            return state;
        },
        setInitialSelectedLicenceCriteria: (state: any) => {
            state.selectedLicenceCriteria = initialData.selectedLicenceCriteria;
            return state;
        },
        setLicenceCriteriaStandards: (state: any, action: any) => {
            state.licenceCriteriaStandards =  action.payload;
            return state;
        },
        setInitialLicenceCriteriaStandards: (state: any) => {
            state.licenceCriteriaStandards = initialData.licenceCriteriaStandards;
            return state;
        },
        setLicenceCriteriaActions: (state:any, action:any) => {
            state.criteriaActions = action.payload;
            return state;
        },
        setLicenceCriteriaEvidences: (state:any, action:any) => {
            state.criteriaEvidences = action.payload;
            return state;
        },
        setLicenceStandardsActions: (state:any, action:any) => {
            state.standardsActions = action.payload;
            return state;
        },
        // setLicenceStandardsActionsFiltered: (state:any, action:any) => {
        //     state.standardsActionsFiltered = action.payload;
        //     return state;
        // },
        // setLicenceStandardsAdminActions: (state:any, action:any) => {
        //     state.standardsAdminActions = action.payload;
        //     return state;
        // },
        setLicenceStandardsEvidences: (state:any, action:any) => {
            state.standardsEvidences = action.payload;
            return state;
        },
    }
});

export const {
    setLicenceCriteria,
    setInitialLicenceCriteria,
    setSelectedLicenceCriteria,
    setInitialSelectedLicenceCriteria,
    setLicenceCriteriaStandards,
    setInitialLicenceCriteriaStandards,
    setLicenceCriteriaActions,
    setLicenceCriteriaEvidences,
    setLicenceStandardsActions,
    // setLicenceStandardsActionsFiltered,
    // setLicenceStandardsAdminActions,
    setLicenceStandardsEvidences,

} = licence.actions;
export default licence.reducer;