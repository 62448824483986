export let clubDetails = [
    {
        id: 1,
        clubName: 'Nara Club',
        "color": "#8e7cc3",
        clubLogo: "https://jamp.football/Content/clubLogos/af3481d1-f308-4730-b32d-65d6e9d15cb3_Nara%20Club.png",
        target: 4,
        
        stage:{
            stageName: 'Self Assessment',
            statusProgress: 25
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: false
    },
    {
        id: 2,
        clubName: 'Hokkaido Consadole Sapparo',
        "color": "#db005c",
        clubLogo: "https://jamp.football/Content/clubLogos/14e67362-3267-438e-baf9-9ef447f96d39_hokk%20cons%20sapp.png",
        target: 2,
        
        stage:{
            stageName: 'Off Site',
            statusProgress: 1
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: true
    },
    {
        id: 3,
        clubName: 'Fukushima United Football Club',
        "color": "#014093",
        clubLogo: "https://jamp.football/Content/clubLogos/e502f2dc-aa9e-494a-b33d-81a85df6873b_fuku%20united.png",
        target: 2,
        
        stage:{
            stageName: 'Interim Report',
            statusProgress: 2
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: true
    },
    {
        id: 4,
        clubName: 'Sagantous',
        "color": "#006dbf",
        clubLogo: "https://jamp.football/Content/clubLogos/17c8caa6-a2c8-4772-9309-a48229611e09_sagan%20tosu.png",
        target: 1,
        
        stage:{
            stageName: 'Final Report',
            statusProgress: 3
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: false
    },
    {
        id: 5,
        clubName: 'AC Nagano Parcerio',
        "color": "#01205b",
        clubLogo: "https://jamp.football/Content/clubLogos/c966b268-ef99-42fb-8b1e-cff414905570_ac%20nag%20parc.png",
        target: 1,
        
        stage:{
            stageName: 'Final Report',
            statusProgress: 3
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: false
    },
    {
        id: 6,
        clubName: 'Albeirex Niigata',
        "color": "#ea5405",
        clubLogo: "https://jamp.football/Content/clubLogos/ac26bff1-c146-4b09-b431-148228c40f60_albi%20niig.png",
        target: 2,
        
        stage:{
            stageName: 'Final Report',
            statusProgress: 3
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: false
    },
    {
        id: 7,
        clubName: 'Avispa Fukuoka',
        "color": "#fe6101",
        clubLogo: "https://jamp.football/Content/clubLogos/e61c4b31-2cde-4545-8dbc-820ca1ace537_avis%20fuku.png",
        target: 3,
        
        stage:{
            stageName: 'Final Report',
            statusProgress: 3
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: false
    },
    {
        id: 8,
        clubName: 'Azul claro Numzau',
        "color": "#e3d7b2",
        clubLogo: "https://jamp.football/Content/clubLogos/7ad76219-ef53-45f8-a653-0a5e69ec5b9d_azul%20claro.png",
        target: 4,
        
        stage:{
            stageName: 'Final Report',
            statusProgress: 3
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: false
    },
    {
        id: 9,
        clubName: 'F.C. Tokyo',
        "color": "#e6fb92",
        clubLogo: "",
        target: 5,
        
        stage:{
            stageName: 'Final Report',
            statusProgress: 3
        },
        noOfComments: 4,
        noOfDocuments: 5,
        noOfActions: 3,
        isFavourite: true
    },
    
]

export const mentions = [
    {
        clubName: 'AC Nagano Parcerio',
        comment: '2.2.1 statement of duties 2 star It is a long established fact that a reader @galler will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using , making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)',
        date: '23.01.14',
        commentBy: 'Finch Lend',
        commentByImg: '',
        clubLogo: 'https://jamp.football/Content/clubLogos/c966b268-ef99-42fb-8b1e-cff414905570_ac%20nag%20parc.png'
    },
    {
        clubName: 'Kyot Sanga',
        comment: '2.2.1 statement of duties 2 star It is a long established fact that a reader @galler will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using , making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)',
        date: '23.01.14',
        commentBy: 'Finch Lend',
        commentByImg: '',
        clubLogo: 'https://jamp.football/Content/clubLogos/e502f2dc-aa9e-494a-b33d-81a85df6873b_fuku%20united.png'
    },
    {
        clubName: 'Kyot Sanga',
        comment: '2.2.1 statement of duties 2 star It is a long established fact that a reader @galler will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using , making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)',
        date: '23.01.14',
        commentBy: 'Finch Lend',
        commentByImg: '',
        clubLogo: 'https://jamp.football/Content/clubLogos/e61c4b31-2cde-4545-8dbc-820ca1ace537_avis%20fuku.png'
    },
    {
        clubName: 'Kyot Sanga',
        comment: '2.2.1 statement of duties 2 star It is a long established fact that a reader @galler will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using , making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)',
        date: '23.01.14',
        commentBy: 'Finch Lend',
        commentByImg: '',
        clubLogo: 'https://jamp.football/Content/clubLogos/7ad76219-ef53-45f8-a653-0a5e69ec5b9d_azul%20claro.png'
    },
    {
        clubName: 'F.C. Tokyo',
        comment: '2.2.1 statement of duties 2 star It is a long established fact that a reader @galler will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using , making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)',
        date: '23.01.14',
        commentBy: 'Finch Lend',
        commentByImg: '',
        clubLogo: ''
    }
]

export const upcoming = [
    {
        clubName: 'AC Nagano Parcerio',
        upcomingDateTime: '2023-03-16T17:46',
        upcomingEvent: 'this is a sample upcoming event',
        clubLogo: 'https://jamp.football/Content/clubLogos/c966b268-ef99-42fb-8b1e-cff414905570_ac%20nag%20parc.png'
    },
    {
        clubName: 'Sagantous',
        upcomingDateTime: '2023-01-10T22:00',
        upcomingEvent: 'Kyot ground visit',
        clubLogo: 'https://jamp.football/Content/clubLogos/17c8caa6-a2c8-4772-9309-a48229611e09_sagan%20tosu.png'
    },
    {
        clubName: 'Albeirex Niigata',
        upcomingDateTime: '2023-06-07T13:16',
        upcomingEvent: 'meeting coaching team',
        clubLogo: "https://jamp.football/Content/clubLogos/ac26bff1-c146-4b09-b431-148228c40f60_albi%20niig.png",
    },
    {
        clubName: 'Nara Club',
        upcomingDateTime: '2023-08-20T03:22',
        upcomingEvent: 'Kyot ground visit',
        clubLogo: 'https://jamp.football/Content/clubLogos/af3481d1-f308-4730-b32d-65d6e9d15cb3_Nara%20Club.png'
    },
    {
        clubName: 'Albeirex Niigata',
        upcomingDateTime: '2023-09-02T20:30',
        upcomingEvent: 'meeting coaching team',
        clubLogo: "https://jamp.football/Content/clubLogos/ac26bff1-c146-4b09-b431-148228c40f60_albi%20niig.png"
    },
    {
        clubName: 'Nara Club',
        upcomingDateTime: '2023-11-10T13:46',
        upcomingEvent: 'Kyot ground visit',
        clubLogo: 'https://jamp.football/Content/clubLogos/af3481d1-f308-4730-b32d-65d6e9d15cb3_Nara%20Club.png'
    },
    {
        clubName: 'Nara Club',
        upcomingDateTime: '2023-07-16T15:45',
        upcomingEvent: 'meeting coaching team',
        clubLogo: 'https://jamp.football/Content/clubLogos/af3481d1-f308-4730-b32d-65d6e9d15cb3_Nara%20Club.png'
    },
]

export const projects = [
    {
      "id": 3896,
      "name": "Consideration of how to collect & utilise video and data related to developmental competitions ⇒Frozen as only U14 competitions will be hosted after 2023.",
      "dueDate": null,
      "ownerName": "Morita Koji",
      "departmentId": 3156,
      "departmentName": "game",
      "ragb": 4,
      "progress": 0,
      "arrowDirection": 1,
      "uid": "JP74",
      "isArchived": false
    },
    {
      "id": 6075,
      "name": "2024 Observation of university football matches",
      "dueDate": null,
      "ownerName": "Koike Naofumi",
      "departmentId": 3154,
      "departmentName": "support",
      "ragb": 4,
      "progress": 0,
      "arrowDirection": 1,
      "uid": "JP95",
      "isArchived": false
    },
    {
      "id": 6081,
      "name": "2024 JAMP APP Advancement",
      "dueDate": null,
      "ownerName": "Koike Naofumi",
      "departmentId": 3154,
      "departmentName": "support",
      "ragb": 4,
      "progress": 0,
      "arrowDirection": 1,
      "uid": "JP101",
      "isArchived": false
    },
    {
      "id": 6216,
      "name": "New AD Exchange",
      "dueDate": null,
      "ownerName": "深野 悦子",
      "departmentId": null,
      "departmentName": null,
      "ragb": 4,
      "progress": 0,
      "arrowDirection": 1,
      "uid": "JP122",
      "isArchived": false
    },
    {
      "id": 6217,
      "name": "2024 Safeguarding workshops held.",
      "dueDate": null,
      "ownerName": "深野 悦子",
      "departmentId": 3158,
      "departmentName": "Safeguarding",
      "ragb": 4,
      "progress": 0,
      "arrowDirection": 1,
      "uid": "JP123",
      "isArchived": false
    },
    {
      "id": 3390,
      "name": "Career Pathways Program (2021)",
      "dueDate": "2021-12-31T00:00:00",
      "ownerName": "Matsuzawa Midori",
      "departmentId": 3157,
      "departmentName": "education",
      "ragb": 0,
      "progress": 25,
      "arrowDirection": 1,
      "uid": null,
      "isArchived": false
    },
    {
      "id": 3756,
      "name": "Corresponding media conference (January 2022).",
      "dueDate": "2022-01-07T00:00:00",
      "ownerName": "Ueda Takeharu",
      "departmentId": 3152,
      "departmentName": "Mid-term Planning",
      "ragb": 3,
      "progress": 100,
      "arrowDirection": 1,
      "uid": "JP18",
      "isArchived": false
    },
    {
      "id": 3392,
      "name": "2021 Safeguarding",
      "dueDate": "2022-02-28T00:00:00",
      "ownerName": "Matsuzawa Midori",
      "departmentId": 3157,
      "departmentName": "education",
      "ragb": 0,
      "progress": 33,
      "arrowDirection": 1,
      "uid": null,
      "isArchived": false
    },
    {
      "id": 3608,
      "name": "2022J Elite] 2022 Games outline prepared/. Design for 2023 and beyond developed → Decision made to end the competition in 2022.",
      "dueDate": "2022-02-28T00:00:00",
      "ownerName": "Morita Koji",
      "departmentId": 3156,
      "departmentName": "game",
      "ragb": 3,
      "progress": 100,
      "arrowDirection": 1,
      "uid": null,
      "isArchived": false
    },
    // {
    //   "id": 3679,
    //   "name": "2022J Youth] Preparation of the 2022 Games outline/. Design for 2023 and beyond developed ⇒ Decision made to end the tournament in 2022.",
    //   "dueDate": "2022-03-19T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3769,
    //   "name": "Management of NGM2022",
    //   "dueDate": "2022-03-31T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP30",
    //   "isArchived": false
    // },
    // {
    //   "id": 3872,
    //   "name": "Preparations for the start of the 2022 J Elite season",
    //   "dueDate": "2022-03-31T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP68",
    //   "isArchived": false
    // },
    // {
    //   "id": 3873,
    //   "name": "Preparation for the start of the 2022 J-Youth season",
    //   "dueDate": "2022-03-31T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP69",
    //   "isArchived": false
    // },
    // {
    //   "id": 3874,
    //   "name": "[2022U-14] Opening Preparation",
    //   "dueDate": "2022-04-11T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP70",
    //   "isArchived": false
    // },
    // {
    //   "id": 3789,
    //   "name": "Distribute the J.League's Safeguarding Guidelines (tentative name)",
    //   "dueDate": "2022-04-26T00:00:00",
    //   "ownerName": "Ueda Takeharu",
    //   "departmentId": 3152,
    //   "departmentName": "Mid-term Planning",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP49",
    //   "isArchived": false
    // },
    // {
    //   "id": 5961,
    //   "name": "2022 Observation of top team matches",
    //   "dueDate": "2022-05-15T00:00:00",
    //   "ownerName": null,
    //   "departmentId": null,
    //   "departmentName": null,
    //   "ragb": 0,
    //   "progress": 67,
    //   "arrowDirection": 1,
    //   "uid": "JP76",
    //   "isArchived": false
    // },
    // {
    //   "id": 5999,
    //   "name": "Pitch Session",
    //   "dueDate": "2022-08-31T00:00:00",
    //   "ownerName": null,
    //   "departmentId": null,
    //   "departmentName": null,
    //   "ragb": 0,
    //   "progress": 33,
    //   "arrowDirection": 1,
    //   "uid": "JP79",
    //   "isArchived": false
    // },
    // {
    //   "id": 3863,
    //   "name": "Player education content (2022)",
    //   "dueDate": "2022-09-30T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP60",
    //   "isArchived": false
    // },
    // {
    //   "id": 3681,
    //   "name": "2022U-14】Preparation of the 2022 conference outline / Design development for 2023 and beyond",
    //   "dueDate": "2022-10-31T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 0,
    //   "progress": 80,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3760,
    //   "name": "Report of the Executive Committee.",
    //   "dueDate": "2022-12-13T00:00:00",
    //   "ownerName": "Ueda Takeharu",
    //   "departmentId": 3152,
    //   "departmentName": "Mid-term Planning",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP21",
    //   "isArchived": false
    // },
    // {
    //   "id": 3391,
    //   "name": "2022 Newcomer Training",
    //   "dueDate": "2022-12-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3592,
    //   "name": "Ideal games programme / Communication with the federations",
    //   "dueDate": "2022-12-31T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 0,
    //   "progress": 25,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3766,
    //   "name": "Manage your project progress with Project DNA",
    //   "dueDate": "2022-12-31T00:00:00",
    //   "ownerName": "Ueda Takeharu",
    //   "departmentId": 3152,
    //   "departmentName": "Mid-term Planning",
    //   "ragb": 0,
    //   "progress": 86,
    //   "arrowDirection": 1,
    //   "uid": "JP27",
    //   "isArchived": false
    // },
    // {
    //   "id": 3787,
    //   "name": "Promote Project DNA's public relations activities",
    //   "dueDate": "2022-12-31T00:00:00",
    //   "ownerName": "Ueda Takeharu",
    //   "departmentId": 3152,
    //   "departmentName": "Mid-term Planning",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP47",
    //   "isArchived": false
    // },
    // {
    //   "id": 3862,
    //   "name": "Career Pathways Programme (2022)",
    //   "dueDate": "2022-12-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP59",
    //   "isArchived": false
    // },
    // {
    //   "id": 3895,
    //   "name": "Strengthen information dissemination of J-League organised development tournaments ⇒ Reduced as only U14 tournaments will be organised from 2023 onwards.",
    //   "dueDate": "2022-12-31T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 0,
    //   "progress": 33,
    //   "arrowDirection": 1,
    //   "uid": "JP73",
    //   "isArchived": false
    // },
    // {
    //   "id": 3594,
    //   "name": "2022J Elite] planning, management and improving the value of the event.",
    //   "dueDate": "2023-01-10T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 0,
    //   "progress": 17,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3595,
    //   "name": "2022J Youth] planning, management and tournament value enhancement.",
    //   "dueDate": "2023-01-10T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 0,
    //   "progress": 17,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3596,
    //   "name": "[2022U-14] Planning, organising and improving the value of the conference.",
    //   "dueDate": "2023-01-10T00:00:00",
    //   "ownerName": "Morita Koji",
    //   "departmentId": 3156,
    //   "departmentName": "game",
    //   "ragb": 0,
    //   "progress": 20,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3711,
    //   "name": "JHoE (Phase 2)",
    //   "dueDate": "2023-01-25T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 6038,
    //   "name": "Temporary Gunma One star awarded.",
    //   "dueDate": "2023-01-31T00:00:00",
    //   "ownerName": null,
    //   "departmentId": null,
    //   "departmentName": null,
    //   "ragb": 0,
    //   "progress": 33,
    //   "arrowDirection": 1,
    //   "uid": "JP83",
    //   "isArchived": false
    // },
    // {
    //   "id": 3770,
    //   "name": "2022 Safeguarding",
    //   "dueDate": "2023-03-29T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 67,
    //   "arrowDirection": 1,
    //   "uid": "JP31",
    //   "isArchived": false
    // },
    // {
    //   "id": 3780,
    //   "name": "Social Sciences (2022)",
    //   "dueDate": "2023-03-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 75,
    //   "arrowDirection": 1,
    //   "uid": "JP40",
    //   "isArchived": false
    // },
    // {
    //   "id": 3860,
    //   "name": "Career support (2022)",
    //   "dueDate": "2023-03-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 17,
    //   "arrowDirection": 1,
    //   "uid": "JP57",
    //   "isArchived": false
    // },
    // {
    //   "id": 3861,
    //   "name": "Pre-professional training (2022)",
    //   "dueDate": "2023-03-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 86,
    //   "arrowDirection": 1,
    //   "uid": "JP58",
    //   "isArchived": false
    // },
    // {
    //   "id": 6068,
    //   "name": "Department of Yononaka (2023).",
    //   "dueDate": "2023-03-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP88",
    //   "isArchived": false
    // },
    // {
    //   "id": 6069,
    //   "name": "Pre-professional training (2023)",
    //   "dueDate": "2023-03-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP89",
    //   "isArchived": false
    // },
    // {
    //   "id": 6070,
    //   "name": "2023 Safeguarding.",
    //   "dueDate": "2023-03-31T00:00:00",
    //   "ownerName": "Matsuzawa Midori",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP90",
    //   "isArchived": false
    // },
    // {
    //   "id": 3759,
    //   "name": "Organise a JLiF",
    //   "dueDate": "2023-12-31T00:00:00",
    //   "ownerName": "Ueda Takeharu",
    //   "departmentId": 3152,
    //   "departmentName": "Mid-term Planning",
    //   "ragb": 0,
    //   "progress": 65,
    //   "arrowDirection": 1,
    //   "uid": "JP20",
    //   "isArchived": false
    // },
    // {
    //   "id": 6059,
    //   "name": "2023 Newcomer Training",
    //   "dueDate": "2023-12-31T00:00:00",
    //   "ownerName": "Arai Takuya",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 94,
    //   "arrowDirection": 1,
    //   "uid": "JP85",
    //   "isArchived": false
    // },
    // {
    //   "id": 6163,
    //   "name": "Player education platform operation and management (2023)",
    //   "dueDate": "2023-12-31T00:00:00",
    //   "ownerName": "Arai Takuya",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 0,
    //   "progress": 57,
    //   "arrowDirection": 1,
    //   "uid": "JP113",
    //   "isArchived": false
    // },
    // {
    //   "id": 6067,
    //   "name": "JHoE (3rd phase).",
    //   "dueDate": "2024-01-30T00:00:00",
    //   "ownerName": "Arai Takuya",
    //   "departmentId": 3157,
    //   "departmentName": "education",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP87",
    //   "isArchived": false
    // },
    // {
    //   "id": 3470,
    //   "name": "Evaluation_Completion & enhancement of the JAMP system",
    //   "dueDate": "2024-01-31T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": 3155,
    //   "departmentName": "Club License",
    //   "ragb": 0,
    //   "progress": 46,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3719,
    //   "name": "Confirmation of Homegrown_Academy registered teams",
    //   "dueDate": "2024-01-31T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": 3155,
    //   "departmentName": "Club License",
    //   "ragb": 0,
    //   "progress": 50,
    //   "arrowDirection": 1,
    //   "uid": null,
    //   "isArchived": false
    // },
    // {
    //   "id": 3762,
    //   "name": "Future Action",
    //   "dueDate": "2024-01-31T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": 3155,
    //   "departmentName": "Club License",
    //   "ragb": 0,
    //   "progress": 20,
    //   "arrowDirection": 1,
    //   "uid": "JP23",
    //   "isArchived": false
    // },
    // {
    //   "id": 6207,
    //   "name": "2024 Club Licensing Academy Application.",
    //   "dueDate": "2024-01-31T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": 3155,
    //   "departmentName": "Club License",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP115",
    //   "isArchived": false
    // },
    // {
    //   "id": 3788,
    //   "name": "Project DNA - to the FUTURE vol.2 is published",
    //   "dueDate": "2024-02-01T00:00:00",
    //   "ownerName": "Ueda Takeharu",
    //   "departmentId": 3152,
    //   "departmentName": "Mid-term Planning",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP48",
    //   "isArchived": false
    // },
    // {
    //   "id": 6215,
    //   "name": "New AD Exchange",
    //   "dueDate": "2024-02-07T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": null,
    //   "departmentName": null,
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP121",
    //   "isArchived": false
    // },
    // {
    //   "id": 6206,
    //   "name": "JAQS as a whole and in the future",
    //   "dueDate": "2024-02-29T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": 3155,
    //   "departmentName": "Club License",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP114",
    //   "isArchived": false
    // },
    // {
    //   "id": 6218,
    //   "name": "2024 New AD Exchange",
    //   "dueDate": "2024-02-29T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": 3159,
    //   "departmentName": "General",
    //   "ragb": 3,
    //   "progress": 100,
    //   "arrowDirection": 1,
    //   "uid": "JP124",
    //   "isArchived": false
    // },
    // {
    //   "id": 6219,
    //   "name": "2024AD Conference.",
    //   "dueDate": "2024-02-29T00:00:00",
    //   "ownerName": "深野 悦子",
    //   "departmentId": null,
    //   "departmentName": null,
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP125",
    //   "isArchived": false
    // },
    // {
    //   "id": 6221,
    //   "name": "2024 J-League Football Conference",
    //   "dueDate": "2024-02-29T00:00:00",
    //   "ownerName": "Kikawada Kenji",
    //   "departmentId": 3153,
    //   "departmentName": "Workforce",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP127",
    //   "isArchived": false
    // },
    // {
    //   "id": 6222,
    //   "name": "JFA leadership training licence",
    //   "dueDate": "2024-02-29T00:00:00",
    //   "ownerName": "Kikawada Kenji",
    //   "departmentId": 3153,
    //   "departmentName": "Workforce",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP128",
    //   "isArchived": false
    // },
    // {
    //   "id": 6210,
    //   "name": "2024 Visits to all 60 clubs",
    //   "dueDate": "2024-09-30T00:00:00",
    //   "ownerName": "Koike Naofumi",
    //   "departmentId": 3154,
    //   "departmentName": "support",
    //   "ragb": 0,
    //   "progress": 8,
    //   "arrowDirection": 1,
    //   "uid": "JP116",
    //   "isArchived": false
    // },
    // {
    //   "id": 6220,
    //   "name": "2024 CPD events",
    //   "dueDate": "2024-11-19T00:00:00",
    //   "ownerName": "Kikawada Kenji",
    //   "departmentId": 3153,
    //   "departmentName": "Workforce",
    //   "ragb": 0,
    //   "progress": 27,
    //   "arrowDirection": 1,
    //   "uid": "JP126",
    //   "isArchived": false
    // },
    // {
    //   "id": 6078,
    //   "name": "2024 Working with JFA",
    //   "dueDate": "2024-12-15T00:00:00",
    //   "ownerName": "Koike Naofumi",
    //   "departmentId": 3154,
    //   "departmentName": "support",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP98",
    //   "isArchived": false
    // },
    // {
    //   "id": 6213,
    //   "name": "2024 Top team match inspection",
    //   "dueDate": "2024-12-15T00:00:00",
    //   "ownerName": "Koike Naofumi",
    //   "departmentId": 3154,
    //   "departmentName": "support",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP119",
    //   "isArchived": false
    // },
    // {
    //   "id": 6211,
    //   "name": "2024 Update on the visit to the representative activities of each age group",
    //   "dueDate": "2024-12-25T00:00:00",
    //   "ownerName": "Koike Naofumi",
    //   "departmentId": 3154,
    //   "departmentName": "support",
    //   "ragb": 1,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP117",
    //   "isArchived": false
    // },
    // {
    //   "id": 6212,
    //   "name": "2024 Observe national championship-level matches in each age group",
    //   "dueDate": "2024-12-28T09:00:00",
    //   "ownerName": "Koike Naofumi",
    //   "departmentId": 3154,
    //   "departmentName": "support",
    //   "ragb": 0,
    //   "progress": 0,
    //   "arrowDirection": 1,
    //   "uid": "JP118",
    //   "isArchived": false
    // }
  ]

export const clubCriteria = [
    {
        id: 1, criteria: 'safeguarding', interimReprot: 2, finalReport: 0, progress: 10
    },
    {
        id: 2, criteria: 'organizational structure', interimReprot: 1, finalReport: 0, progress: 0
    },
    {
        id: 3, criteria: 'academy performance plan', interimReprot: 0, finalReport: 0, progress: 20
    },
    {
        id: 4, criteria: 'club\'s football philosopy', interimReprot: 2, finalReport: 0, progress: 0
    },
    {
        id: 5, criteria: 'academy\'s playing phiosophy', interimReprot: 2, finalReport: 0, progress: 30
    },
    {
        id: 6, criteria: 'vision and strategy', interimReprot: 0, finalReport: 0, progress: 0
    },
    {
        id: 7, criteria: 'academy financial accounting', interimReprot: 2, finalReport: 0, progress: 0
    },
    {
        id: 8, criteria: 'administration of the academy', interimReprot: 1, finalReport: 0, progress: 50
    },
    {
        id: 9, criteria: 'ladership development programme', interimReprot: 0, finalReport: 0, progress: 0
    },
    {
        id: 10, criteria: 'dissemination activities targeting young people', interimReprot: 2, finalReport: 0, progress: 90
    },
    {
        id: 11, criteria: 'individual development plan for players', interimReprot: 2, finalReport: 0, progress: 0
    },
    {
        id: 12, criteria: 'individual development plan for staff', interimReprot: 0, finalReport: 0, progress: 0
    },
    {
        id: 13, criteria: 'factors required of a player', interimReprot: 2, finalReport: 0, progress: 0
    },
    {
        id: 14, criteria: 'staff qualifications and compentencies', interimReprot: 2, finalReport: 0, progress: 17
    },
    {
        id: 15, criteria: 'coaching curriculum', interimReprot: 0, finalReport: 0, progress: 0
    },
    {
        id: 16, criteria: 'coaching curriculum for GK', interimReprot: 2, finalReport: 0, progress: 55
    },
    {
        id: 17, criteria: 'the academy\'s coaching philosophies', interimReprot: 1, finalReport: 0, progress: 0
    },
    {
        id: 18, criteria: 'game program', interimReprot: 0, finalReport: 0, progress: 0
    },
    {
        id: 19, criteria: 'talent search program', interimReprot: 2, finalReport: 0, progress: 60
    },
    {
        id: 20, criteria: 'building a promotion pathway & plan', interimReprot: 2, finalReport: 0, progress: 0
    },
    {
        id: 21, criteria: 'technical committee', interimReprot: 0, finalReport: 0, progress: 80
    },
    {
        id: 22, criteria: 'player care & education', interimReprot: 2, finalReport: 0, progress: 90
    },
    {
        id: 23, criteria: 'analysis departmant', interimReprot: 0, finalReport: 0, progress: 0
    }
]


export const standards = {
    'safeguarding': [
        {
            "attachedAfterInterimReport": false,
            "id": 3241,
            "name": "Annual safeguarding briefing",
            "starRating": 1,
            "status": 3,
            "uid": "S1.1.3",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'

                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3247,
            "name": "Safety is a key consideration in our staff recruitment policy",
            "starRating": 1,
            "status": 0,
            "uid": "S1.1.9",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3246,
            "name": "Safeguarding related policies/rules",
            "starRating": 1,
            "status": 0,
            "uid": "S1.1.8",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3245,
            "name": "Staff self-assessment",
            "starRating": 1,
            "status": 0,
            "uid": "S1.1.7",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3244,
            "name": "Notation of relevant procedures/procedures/contact points for safeguarding",
            "starRating": 1,
            "status": 0,
            "uid": "S1.1.6",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3243,
            "name": "Safeguarding point of contact/reporting route",
            "starRating": 1,
            "status": 0,
            "uid": "S1.1.5",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3242,
            "name": "Code of conduct for staff, players and parents",
            "starRating": 1,
            "status": 0,
            "uid": "S1.1.4",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3240,
            "name": "Head of Safeguarding attends League workshop",
            "starRating": 1,
            "status": 2,
            "uid": "S1.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        },
        {
            "attachedAfterInterimReport": false,
            "id": 3077,
            "name": "Assign a person responsible for safeguarding",
            "starRating": 1,
            "status": 3,
            "uid": "S1.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        }
    ],
    'organizational structure': [
        {
          "id": 3337,
          "name": "organizational structure",
          "description": "Please provide details of the organisational structure (organisation chart) and staff involved in the academy.",
          "starRating": 1,
          "progress": 100,
          "ragb": 0,
          "status": 3,
          "uid": "S2.1.3",
          noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
          "restrictions": [
            {
              "userId": 2274,
              "isRestricted": false
            },
            {
              "userId": 2275,
              "isRestricted": false
            },
            {
              "userId": 2277,
              "isRestricted": false
            },
            {
              "userId": 2278,
              "isRestricted": false
            },
            {
              "userId": 2279,
              "isRestricted": false
            },
            {
              "userId": 2588,
              "isRestricted": false
            },
            {
              "userId": 2787,
              "isRestricted": false
            },
            {
              "userId": 2859,
              "isRestricted": false
            }
          ]
        },
        {
          "id": 3338,
          "name": "Schedule of Duties",
          "description": "There is a detailed job description for the Academy Director and Head of Coaching",
          "starRating": 1,
          "progress": 100,
          "ragb": 0,
          "status": 3,
          "uid": "S2.1.1",
          noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
          "restrictions": [
            {
              "userId": 2274,
              "isRestricted": false
            },
            {
              "userId": 2275,
              "isRestricted": false
            },
            {
              "userId": 2277,
              "isRestricted": false
            },
            {
              "userId": 2278,
              "isRestricted": false
            },
            {
              "userId": 2279,
              "isRestricted": false
            },
            {
              "userId": 2588,
              "isRestricted": false
            },
            {
              "userId": 2787,
              "isRestricted": false
            },
            {
              "userId": 2859,
              "isRestricted": false
            }
          ]
        },
        {
          "id": 3339,
          "name": "qualifications",
          "description": "1. the Academy Director or Head of Coaching has the relevant qualifications required to fulfil the position within the organisation as Head of Development (J Club Licence is the JFA Class A Coach General Licence or equivalent. However, a J3 Club License should hold a JFA Class B Coach License or equivalent).\r\n2. the Head of Medical has a valid medical related qualification\r\n3. a minimum of four coaches hold a JFAB class coaching licence or equivalent",
          "starRating": 1,
          "progress": 100,
          "ragb": 0,
          "status": 3,
          "uid": "S2.1.2",
          noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
          "restrictions": [
            {
              "userId": 2274,
              "isRestricted": false
            },
            {
              "userId": 2275,
              "isRestricted": false
            },
            {
              "userId": 2277,
              "isRestricted": false
            },
            {
              "userId": 2278,
              "isRestricted": false
            },
            {
              "userId": 2279,
              "isRestricted": false
            },
            {
              "userId": 2588,
              "isRestricted": false
            },
            {
              "userId": 2787,
              "isRestricted": false
            },
            {
              "userId": 2859,
              "isRestricted": false
            }
          ]
        }
    ],
    'academy performance plan': [
        {
            "attachedAfterInterimReport": false,
            "id": 3248,
            "name": "Detailed APP",
            "starRating": 1,
            "status": 0,
            "uid": "S3.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ]
        }
    ],
    'club\'s football philosopy': [
        {
            "id": 3258,
            "name": "The club's football philosophy: promoting community and social action",
            "description": "The Club's Football Philosophy emphasises the importance of community and social action and the importance of providing quality entertainment and exemplary role models for the Club's fan base. Inclusivity, collaboration and a positive ethos are clearly evident in the club's football philosophy, which also promotes respect and a responsible environment, and works to continually raise awareness and support for the club's core values within the community.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S4.1.3",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3257,
            "name": "The club's football philosophy: history, culture, values and expected behaviour",
            "description": "The club's football philosophy is upheld by the directors. It includes the club's history and clearly sets out the club's culture, values and expected behaviours, laying the foundations for the future growth and development of the club.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S4.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3256,
            "name": "Academy staff understanding of the club's football philosophy",
            "description": "All academy staff are made aware of and grasp the club's football philosophy through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S4.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'academy\'s playing phiosophy': [
        {
            "id": 3267,
            "name": "Formulation of the Academy's key playing principles and approach within the Academy's Playing Philosophy",
            "description": "The Academy's Playing Philosophy is tied to the Club's Playing Philosophy.\r\nThe Academy Playing Philosophy sets out the guiding principles, values, playing style and tactical concepts for all teams within the Academy.\r\nThe Academy can explain how and to what extent the Academy Playing Philosophy is linked to the Club's Playing Philosophy, which is derived from the Club's vision and culture.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S5.1.5",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3266,
            "name": "The academy's playing philosophy: visible in training and on the field",
            "description": "The Academy's playing philosophy is embedded in training and on the field of play, and can be visibly seen.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S5.1.4",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3265,
            "name": "AD and HoC to develop and approve the Academy's Playing Philosophy",
            "description": "The Academy's Playing Philosophy has been developed and approved by the Academy Director and Head of Coaching.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S5.1.3",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3264,
            "name": "Academy's playing philosophy embraced by APP",
            "description": "The Academy's Playing Philosophy has been incorporated into the Academy Performance Plan.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S5.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3263,
            "name": "Academy staff understand the playing philosophy of the Academy",
            "description": "All staff are aware of and grasp the playing philosophy of the Academy through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S5.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'vision and strategy': [
        {
            "id": 3216,
            "name": "Academy Management Team's recognition of the Academy Vision",
            "description": "Through the Club's Vision and Mission Statements and the Academy's Goals and Objectives, the Academy Management Team is fully aware of the vision that the Club's Board members have for the Academy.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S6.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'academy financial accounting': [
        {
            "id": 3224,
            "name": "There's money in the club budget for the academy.",
            "description": "There is a budget for the Academy clearly set out in the club budget with a detailed breakdown of all income and expenditure for the Academy. The Academy's budget is reflected in the APP and it is clear where the budget is allocated in the Academy's strategy and operations.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S7.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3223,
            "name": "AD and club officers set the Academy's budget and financial goals",
            "description": "The Academy Director and the Club's Board Member in charge develop an annual budget plan for the Academy (i.e. the Academy Budget) and set financial management targets for the Academy.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S7.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'administration of the academy': [
        {
            "id": 3233,
            "name": "Annual update of contact information",
            "description": "Academies submit documentation to the J-League on an annual basis which includes the following basic information Name of the club, address, telephone number, email address&URL of the website, name, telephone number, mobile number and email address of the executive committee member, position & role at the club, name, address, telephone number and mobile number of the Academy Director&Email address, address and telephone number of the training facility number&E-mail address of the Academy, telephone number&E-mail address of the Academy, telephone number&E-mail address of the School, telephone number& Directory of all staff (full time & part time) at the Academy including relevant qualifications held",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S8.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3234,
            "name": "Compliance with Rules & Regulations",
            "description": "Appropriate monitoring is carried out to ensure that all academy staff operate and conduct activities in accordance with the rules for academies or developmental age groups stipulated by the representative bodies that govern the Japanese football world (JFA, J-League, etc.).",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S8.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'ladership development programme': [
        {
            "id": 3307,
            "name": "Annual Continuing Education Program",
            "description": "The club has an annual plan for a continuous learning (CPD) programme linked to the club's football philosophy. An Individual Development Plan (IDP) initiative is recommended as a central part of the leadership development programme.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S9.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false,
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3308,
            "name": "Review and agree the content of the annual development programme",
            "description": "The content of the leadership development programme is reviewed annually by the Head of Coaching and Academy Director (or in the absence of either of the two, the person responsible for leadership development) and the content is structured by consensus.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S9.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'dissemination activities targeting young people': [
        {
            "id": 3323,
            "name": "Building ties with local communities through extension activities",
            "description": "It will be implemented in conjunction with the Academy's scouting/talent identification programme and as part of the dissemination activities, it is expected that strong links will be established with local communities, town clubs and schools to develop and expand the scale and geography of dissemination activities such as schools and travelling coaching in the future.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S10.1.3",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3322,
            "name": "Understanding of the playing philosophy of the first team and academy by the extension staff",
            "description": "All staff (full time & part time) understand and master the playing philosophy of the first team and academy through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S10.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3321,
            "name": "Understanding of the club's football philosophy by extension staff",
            "description": "All staff (full time & part time) are aware of and grasp the club's football philosophy through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S10.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'individual development plan for players': [
        {
            "id": 3332,
            "name": "Staff understanding of the concept of IDPs for players",
            "description": "All Academy staff are aware of and grasp the concept of Individual Development Plans (IDPs) for players through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S11.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'individual development plan for staff': [
        {
            "id": 3281,
            "name": "Encourage staff and others to take ownership of IDP efforts",
            "description": "All Academy staff are encouraged to understand the mechanics of IDPs and to take ownership of their own IDPs.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S12.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3280,
            "name": "Staff understanding of the concept of IDPs for staff",
            "description": "All academy staff are aware of and grasp the concept of Individual Development Plans (IDPs) for their staff through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S12.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'factors required of a player': [
        {
            "id": 3292,
            "name": "Staff's conceptual understanding of the elements required of a player",
            "description": "Through new staff training and leadership development programs, all academy staff are aware of and grasp the concept of the elements required of a player.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S13.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'staff qualifications and compentencies': [
        {
            "id": 3110,
            "name": "Staff understanding of an overview of the qualifications and competencies required of staff",
            "description": "Through new staff training and leadership development programs, all Academy staff are aware of and grasp the concept of the quality competencies required of staff.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S14.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'coaching curriculum': [
        {
            "id": 3116,
            "name": "Coaching curriculum: linking the academy's philosophy to the club's football philosophy",
            "description": "There is a coaching curriculum for each age group, which reflects the playing and coaching philosophies of the academy and the football philosophy of the club, which can be used to improve the quality of the coaching.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S15.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3115,
            "name": "Overview understanding of the coaching curriculum for all academy staff",
            "description": "All academy staff are aware of and grasp the concept of the coaching curriculum through new staff training and coaching development programmes, and understand and acquire that the coaching curriculum enhances the playing and coaching philosophies of the academy and is incorporated into training and match The coaching curriculum is designed to enhance the playing and coaching philosophies of the Academy and is implemented in training and matches.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S15.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3117,
            "name": "Coaching curriculum: taking into account the content of the 4 corners model",
            "description": "The coaching curriculum incorporates the technical, tactical, physical, and psychosocial elements (the four-corner model) necessary for player development.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S15.1.3",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'coaching curriculum for GK': [
        {
            "id": 3109,
            "name": "Coaching Curriculum for Goalkeepers: Adapted from the 4 Corners Model",
            "description": "The coaching curriculum for goalkeepers incorporates the technical, tactical, physical, and psychosocial elements (the four-corner model) necessary for player development.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S16.1.3",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3138,
            "name": "Coaching curriculum for goalkeepers: linking the philosophy of the academy to the football philosophy of the club",
            "description": "There is an age-specific coaching curriculum for goalkeepers, which reflects the playing and coaching philosophy of the academy and the football philosophy of the club, which can be used to improve their quality.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S16.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3137,
            "name": "Overview understanding of the coaching curriculum for goalkeepers for all academy staff",
            "description": "All academy staff are aware of the concept of the goalkeeping coaching curriculum through new staff training and leadership development programs. The academy staff is aware of the concept of the GK coaching curriculum and understands that the GK coaching curriculum is designed to enhance the Academy's playing and coaching philosophies and is incorporated in training and matches.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S16.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'the academy\'s coaching philosophies': [
        {
            "id": 3096,
            "name": "Staff are aware of the behaviours and culture expected of them when working at the club and the need for personal development",
            "description": "All academy staff recognise the need to engage in elite behaviour, culture and continuous learning (CPD) tied to the club's football philosophy when working at the club as staff.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S17.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3095,
            "name": "Staff understanding of the Academy Coaching Philosophy overview",
            "description": "All academy staff are aware of and grasp the concept of the academy's coaching philosophy through new staff training and leadership development programmes.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S17.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'game program': [
        {
            "id": 3139,
            "name": "Staff's perception of the purpose of the game program",
            "description": "Through new staff training and leadership development programmes, all academy staff are aware that the games programme encompasses the academy's playing philosophy, coaching philosophy and coaching curriculum.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S18.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        },
        {
            "id": 3141,
            "name": "Academy Game Program",
            "description": "An academy games programme is in place for all age categories in the academy to build and provide a promotion pathway & plan (pathway) for players to develop through the academy.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S18.1.2",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'talent search program': [
        {
            "id": 3183,
            "name": "Staff's overview understanding of the talent search process",
            "description": "Through new staff training and leadership development programs, all Academy staff are aware of the existence of the Talent Search Program and an overview of its activities.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S19.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'building a promotion pathway & plan': [
        {
            "id": 3198,
            "name": "Staff recognition of promotion pathways & plans (pathways) for elite players",
            "description": "Through new staff training and leadership development programmes, all academy staff are aware that the promotion pathways & plans (pathways) for elite players are unique to each club/academy and vary from club/academy to club/academy.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S20.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'technical committee': [
        {
            "id": 3146,
            "name": "ADs and club officials understand the target values for the Academy",
            "description": "The Academy Director and the Club's Officer in Charge are aware of and know the targets set for the Academy each year.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S21.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'player care & education': [
        {
            "id": 3154,
            "name": "Implementation of education programs and understanding of education-related programs offered by the J-League",
            "description": "The club and academy have an ongoing education programme in place and have a good understanding of all the education-related programmes offered by the J.League.",
            "starRating": 1,
            "progress": 100,
            "ragb": 0,
            "status": 3,
            "uid": "S22.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ],
    'analysis departmant': [
        {
            "id": 3164,
            "name": "Staff understanding of the role of analysis in player development",
            "description": "Through new staff training and leadership development programmes, all academy staff are aware of and grasp the methods of analysis in player development across all age categories.",
            "starRating": 1,
            "progress": 100,
            "ragb": 3,
            "status": 3,
            "uid": "S23.1.1",
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
            standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
            actions: [
                {
                    isJlUser: true,
                    status: 0,
                    actionComment: 'Create code of conduct document for stakeholders',
                    dueDate: '23.01.20',
                    owner: 'Mr. Asahi',
                    isComplete: false
                }
            ],
            evidence: [
                {
                    evidenceName: 'Budget analysis.xlsx',
                }
            ],
            comments: [
                {
                    commentBy: 'Mr. Hana',
                    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                }
            ],
            "restrictions": [
                {
                    "userId": 2274,
                    "isRestricted": false
                },
                {
                    "userId": 2275,
                    "isRestricted": false
                },
                {
                    "userId": 2277,
                    "isRestricted": false
                },
                {
                    "userId": 2278,
                    "isRestricted": false
                },
                {
                    "userId": 2279,
                    "isRestricted": false
                },
                {
                    "userId": 2588,
                    "isRestricted": false
                },
                {
                    "userId": 2787,
                    "isRestricted": false
                },
                {
                    "userId": 2859,
                    "isRestricted": false
                }
            ]
        }
    ]
}

// export const standards = [
//     {
//         id: '7.1.1',
//         standardsName: 'AD and club officers set the Academy\'s budget and financial goals',
//         star: 1,
//         rating: 1,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//         standardsOverview: 'There is a budget for the academy clear set out in the club budget with a detailed breakdown of all income and expenditure for the academy. The academy\'s budget is rflected in the app and is clear where the budget is allocated in the academy\'s strategy and operations',
//         actions: [
//             {
//                 isJlUser: true,
//                 status: 0,
//                 actionComment: 'Create code of conduct document for stakeholders',
//                 dueDate: '23.01.20',
//                 owner: 'Mr. Asahi',
//                 isComplete: false
//             }
//         ],
//         evidence: [
//             {
//                 evidenceName: 'Budget analysis.xlsx',
//             }
//         ],
//         comments: [
//             {
//                 commentBy: 'Mr. Hana',
//                 comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
//             }
//         ]
//     },
//     {
//         id: '7.1.2',
//         standardsName: 'There\'s money in the club budget for the academy',
//         star: 1,
//         rating: 2,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.2.1',
//         standardsName: 'Consistency in the use of J.League allcoations by clubs',
//         star: 2,
//         rating: 3,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.2.2',
//         standardsName: 'Budget for leadership development and research and development',
//         star: 2,
//         rating: 2,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.3.1',
//         standardsName: 'Sustainability of the Acamedy\'s budget',
//         star: 3,
//         rating: 3,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.3.2',
//         standardsName: 'Deploymet of sales staff in charge of academies responsible for obtaining commercial benefits',
//         star: 3,
//         rating: 1,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.3.3',
//         standardsName: 'Review and approval of academy finances',
//         star: 3,
//         rating: 2,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.3.4',
//         standardsName: 'Two-year financial plan',
//         star: 3,
//         rating: 2,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.4.1',
//         standardsName: 'Foue-year financial plan',
//         star: 4,
//         rating: 1,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
//     {
//         id: '7.4.2',
//         standardsName: 'Productivity & ROI',
//         star: 4,
//         rating: 3,
//         noOfComments: 2,
//         noOfDocuments: 3,
//         noOfActions: 6,
//     },
// ]

export const actions = [
    {
        standardsId: '7.1.1',
        isJlUser: true,
        status: 0,
        actionComment: 'Create code of conduct document for stakeholders',
        dueDate: '23.01.20',
        owner: 'Mr. Asahi',
        isComplete: false,
        department: 'Management',
        project: 'Project Alpha',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    },
    {
        standardsId: '7.1.1',
        isJlUser: true,
        status: 0,
        actionComment: 'Create code of conduct document for stakeholders',
        dueDate: '23.01.20',
        owner: 'Mr. Asahi',
        isComplete: false,
        department: 'Management',
        project: 'Project Alpha',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    },
    {
        standardsId: '7.1.1',
        isJlUser: true,
        status: 0,
        actionComment: 'Create code of conduct document for stakeholders',
        dueDate: '23.01.20',
        owner: 'Mr. Asahi',
        isComplete: false,
        department: 'Management',
        project: 'Project Alpha',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    },
    {
        standardsId: '7.1.1',
        isJlUser: true,
        status: 0,
        actionComment: 'Create code of conduct document for stakeholders',
        dueDate: '23.01.20',
        owner: 'Mr. Asahi',
        isComplete: false,
        department: 'Management',
        project: 'Project Alpha',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    },
    {
        standardsId: '7.1.1',
        isJlUser: true,
        status: 0,
        actionComment: 'Create code of conduct document for stakeholders',
        dueDate: '23.01.20',
        owner: 'Mr. Asahi',
        isComplete: false,
        department: 'Management',
        project: 'Project Alpha',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
    }
]

export const comments = [
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'off-site'
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'off-site'
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    }
]

export const executiveSummary = [
    {
        criteriaId: 1,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 2,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 3,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 4,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 5,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 6,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 7,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 8,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 9,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 10,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 11,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 12,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 13,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 14,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 15,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 16,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 17,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 18,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 19,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 20,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 21,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 22,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
    {
        criteriaId: 23,
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        commentDate: '23.01.14',
        commentBy: 'Falcon Louis',
        category: 'on-site'
    },
]

// licence module

export const licence = [
    {
        "id": 1,
        "name": "Operational management of academies",
        "progress": 12,
        "evaluationProgress": 100,
        "status": 2,
        "uid": "C01"
    },
    {
        "id": 2,
        "name": "Academy's football philosophy",
        "progress": 100,
        "evaluationProgress": 75,
        "status": 0,
        "uid": "C02"
    },
    {
        "id": 3,
        "name": "Academy's education programme",
        "progress": 100,
        "evaluationProgress": 100,
        "status": 2,
        "uid": "C03"
    },
    {
        "id": 4,
        "name": "Academy's medical programme",
        "progress": 0,
        "evaluationProgress": 100,
        "status": 1,
        "uid": "C04"
    },
]

export const licenceStandards = {
    'Operational management of academies': [
        {
            "id": 1,
            "name": "Organisational information and organisation chart",
            "status": 3,
            "uid": "S1.01",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 2,
            "name": "Academy Staff",
            "status": 1,
            "uid": "S1.02",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 3,
            "name": "Academy facilities",
            "status": 1,
            "uid": "S1.03",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 4,
            "name": "Academy Team",
            "status": 1,
            "uid": "S1.04",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 5,
            "name": "academy budget",
            "status": 1,
            "uid": "S1.05",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 6,
            "name": "Management of academy targets.",
            "status": 1,
            "uid": "S1.06",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 7,
            "name": "Management of academy players.",
            "status": 1,
            "uid": "S1.07",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 8,
            "name": "Management of players from academies.",
            "status": 1,
            "uid": "S1.08",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        }
    ],
    'Academy\'s football philosophy': [
        {
            "id": 9,
            "name": "Vision of the Academy",
            "status": 3,
            "uid": "S2.01",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 10,
            "name": "Academy's playing philosophy.",
            "status": 3,
            "uid": "S2.02",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 11,
            "name": "Academy's coaching philosophy.",
            "status": 0,
            "uid": "S2.03",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 12,
            "name": "Academy coaching curriculum.",
            "status": 3,
            "uid": "S2.04",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        }
    ],
    'Academy\'s education programme': [
        {
            "id": 13,
            "name": "Annual education plan and implementation report.",
            "status": 1,
            "uid": "S3.01",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 14,
            "name": "Guarantee of compulsory education",
            "status": 3,
            "uid": "S3.03",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 15,
            "name": "Racial equality in practice",
            "status": 3,
            "uid": "S3.02",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 16,
            "name": "Guarantee of education outside football.",
            "status": 3,
            "uid": "S3.04",
            "isComplete": true,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        }
    ],
    'Academy\'s medical programme': [
        {
            "id": 17,
            "name": "Providing medical support",
            "status": 1,
            "uid": "S4.01",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        },
        {
            "id": 18,
            "name": "Medical care of athletes.",
            "status": 1,
            "uid": "S4.02",
            "isComplete": false,
            noOfComments: 2,
            noOfDocuments: 3,
            noOfActions: 6,
        }
    ]
}

export const accountingProfitLoss = [
    {
        "id": 1,
        "mandatory": true,
        "year": "2023",
        "type": "Total",
        "subType": "I",
        "name": "Income",
        "otherExpenseName": "",
        "currentYear": 30000000,
        "nextYear": 3400000
    },
    {
        "id": 22,
        "mandatory": true,
        "year": "2023",
        "type": "Total",
        "subType": "E",
        "name": "Expense",
        "otherExpenseName": "",
        "currentYear": 214748364700,
        "nextYear": 250000000
    },
    {
        "id": 21,
        "mandatory": true,
        "year": "2023",
        "type": "Income",
        "subType": "M",
        "name": "Membership fee income",
        "otherExpenseName": "",
        "currentYear": 5000000,
        "nextYear": 6000000
    },
    {
        "id": 20,
        "mandatory": true,
        "year": "2023",
        "type": "Income",
        "subType": "J",
        "name": "J League Support Fund",
        "otherExpenseName": "",
        "currentYear": 8000000,
        "nextYear": 8000000
    },
    {
        "id": 19,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "L",
        "name": "Labour Cost",
        "otherExpenseName": "U18s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 18,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "L",
        "name": "Labour Cost",
        "otherExpenseName": "U15s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 17,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "L",
        "name": "Labour Cost",
        "otherExpenseName": "U12/Development Centre",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 16,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "L",
        "name": "Labour Cost",
        "otherExpenseName": "Soccer School",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 15,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "E",
        "name": "Equipment/Fixtures",
        "otherExpenseName": "U18s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 14,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "E",
        "name": "Equipment/Fixtures",
        "otherExpenseName": "U15s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 13,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "E",
        "name": "Equipment/Fixtures",
        "otherExpenseName": "U12/Development Centre",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 12,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "E",
        "name": "Equipment/Fixtures",
        "otherExpenseName": "Soccer School",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 11,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "F",
        "name": "Facilities",
        "otherExpenseName": "U18s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 10,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "F",
        "name": "Facilities",
        "otherExpenseName": "U15s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 9,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "F",
        "name": "Facilities",
        "otherExpenseName": "U12/Development Centre",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 8,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "F",
        "name": "Facilities",
        "otherExpenseName": "Soccer School",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 7,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "T",
        "name": "Travel",
        "otherExpenseName": "U18s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 6,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "T",
        "name": "Travel",
        "otherExpenseName": "U15s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 5,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "T",
        "name": "Travel",
        "otherExpenseName": "U12/Development Centre",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 4,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "T",
        "name": "Travel",
        "otherExpenseName": "Soccer School",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 3,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "O",
        "name": "Other",
        "otherExpenseName": "U18s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 2,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "O",
        "name": "Other",
        "otherExpenseName": "U15s",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 23,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "O",
        "name": "Other",
        "otherExpenseName": "U12/Development Centre",
        "currentYear": 1234000,
        "nextYear": 1000000
    },
    {
        "id": 24,
        "mandatory": true,
        "year": "2023",
        "type": "Expense",
        "subType": "O",
        "name": "Other",
        "otherExpenseName": "Soccer School",
        "currentYear": 2147483648,
        "nextYear": 1000000
    }
]

export const accountingSupportFund = [
    {
        "id": 1,
        "description": "多摩ピッチAおよび多摩ピッチBの使用料（年額）",
        "amount": 9000000,
        "supportFundContribution": true
    },
    {
        "id": 128,
        "description": "多摩ピッチAおよび多摩ピッチBの使用料（年額）多摩ピッチAおよび多摩ピッチBの使用料（年額）",
        "amount": 2147483647000,
        "supportFundContribution": true
    },
    {
        "id": 194,
        "description": "あ",
        "amount": 1,
        "supportFundContribution": false
    }
]

export const staffList = [
    {
        "id": 1,
        "kanjiName": "お茶の水　太郎",
        "katakanaName": "オチャノミズ　タロウ",
        "englishName": "Taro OCHANOMIZU",
        "dateOfBirth": "1989-12-31T15:00:00",
        "jfaId": "JFA123456",
        "instructorRegistrationNumber": "INS1234567",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3142"
        ],
        "licenceStart": "2022-11-01T00:00:00",
        "licenceEnd": "2023-07-30T23:00:00",
        "registeredPosition": [
            "RP_ACADEMIC_TEAM_DIRECTOR",
            "RP_ACADEMY_DIRECTOR"
        ],
        "jobTitle": "U18監督",
        "leadershipRole": [
            "LR_HEAD_OF_DEV_DEP",
            "LR_U12_COACH"
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION1",
            "DC_Q_QUALIFICATION2",
            "DC_Q_QUALIFICATION3",
            "DC_Q_QUALIFICATION4"
        ],
        "mainDuties": "スクール～U12アシスタントコーチ～U15アシスタントコーチ～U15監督～U18監督",
        "competitionCareers": [
            {
                "id": 1,
                "club": "Chelsea",
                "yearFrom": 2006,
                "yearTo": 2014
            },
            {
                "id": 2,
                "club": "Liverpool FC",
                "yearFrom": 1999,
                "yearTo": 2005
            }
        ],
        "coachingCareers": [
            {
                "id": 1,
                "club": "FA",
                "yearFrom": 2014,
                "yearTo": 2015
            },
            {
                "id": 16,
                "club": "RavenFC",
                "yearFrom": 2016,
                "yearTo": 2023
            },
            {
                "id": 17,
                "club": "4Star",
                "yearFrom": 2022,
                "yearTo": 2023
            }
        ]
    },
    {
        "id": 2,
        "kanjiName": "本郷　次郎",
        "katakanaName": "ホンゴウ　ジロウ",
        "englishName": "Jiro HONGO",
        "dateOfBirth": "1980-01-31T15:00:00",
        "jfaId": "JFA2222222",
        "instructorRegistrationNumber": "87654321",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3142"
        ],
        "licenceStart": "2022-09-30T15:00:00",
        "licenceEnd": "2023-09-29T15:00:00",
        "registeredPosition": [
            "RP_ACADEMIC_TEAM_DIRECTOR"
        ],
        "jobTitle": "U15監督",
        "leadershipRole": [
            "LR_U12_COACH",
            "LR_U18_COACH"
        ],
        "annualSalary": 4000000,
        "qualifications": [
            "DC_Q_QUALIFICATION1",
            "DC_Q_QUALIFICATION5",
            "DC_Q_QUALIFICATION6",
            "DC_Q_QUALIFICATION7",
            "DC_Q_QUALIFICATION8"
        ],
        "mainDuties": "U15監督（現職）",
        "competitionCareers": [
            {
                "id": 3,
                "club": "本郷FC",
                "yearFrom": 1992,
                "yearTo": 2000
            },
            {
                "id": 4,
                "club": "本郷大学",
                "yearFrom": 2001,
                "yearTo": 2004
            }
        ],
        "coachingCareers": [
            {
                "id": 4,
                "club": "本郷大学",
                "yearFrom": 2004,
                "yearTo": 2022
            }
        ]
    },
    {
        "id": 3,
        "kanjiName": "東京　花子",
        "katakanaName": "トウキョウ　ハナコ",
        "englishName": "Hanako TOKYO",
        "dateOfBirth": "1985-02-28T15:00:00",
        "jfaId": "JFA11111111",
        "instructorRegistrationNumber": "987987987",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3162"
        ],
        "licenceStart": "2022-11-30T15:00:00",
        "licenceEnd": "2023-11-30T15:00:00",
        "registeredPosition": [
            "RP_ACADEMY_DIRECTOR"
        ],
        "jobTitle": "育成部長",
        "leadershipRole": [
            "",
            "LR_U15_COACH",
            "LR_U12_COACH"
        ],
        "annualSalary": 0,
        "qualifications": [
            "DC_Q_QUALIFICATION2",
            "DC_Q_QUALIFICATION4",
            "DC_Q_QUALIFICATION10",
            "DC_Q_QUALIFICATION11",
            "DC_Q_QUALIFICATION12"
        ],
        "mainDuties": "U18監督～トップチームアシスタントコーチ～U18コーチ",
        "competitionCareers": [
            {
                "id": 5,
                "club": "神奈川ユナイテッドFC",
                "yearFrom": 1995,
                "yearTo": 2015
            }
        ],
        "coachingCareers": [
            {
                "id": 7,
                "club": "横浜サッカークラブ",
                "yearFrom": 2016,
                "yearTo": 2018
            },
            {
                "id": 8,
                "club": "Raven FC",
                "yearFrom": 2019,
                "yearTo": 2022
            }
        ]
    },
    {
        "id": 4,
        "kanjiName": "山田　太郎",
        "katakanaName": "ヤマダ　タロウ",
        "englishName": "Taro YAMADA",
        "dateOfBirth": "1990-04-01T15:00:00",
        "jfaId": "JFA222000",
        "instructorRegistrationNumber": "888777666",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3142"
        ],
        "licenceStart": "2022-12-31T15:00:00",
        "licenceEnd": "2023-12-30T15:00:00",
        "registeredPosition": [
            "RP_ACADEMIC_TEAM_DIRECTOR",
            "RP_SCHOOL_COACH"
        ],
        "jobTitle": "U12監督",
        "leadershipRole": [
            "",
            "LR_U12_COACH"
        ],
        "annualSalary": 0,
        "qualifications": [
            "DC_Q_QUALIFICATION29"
        ],
        "mainDuties": "トップチームアシスタントコーチ兼GKアシスタントコーチ～トップチームGKコーチ～U18GKコーチ～トップチームコーチ～U15コーチ～U12監督（現職）",
        "competitionCareers": [
            {
                "id": 6,
                "club": "SV千葉",
                "yearFrom": 1996,
                "yearTo": 2010
            }
        ],
        "coachingCareers": [
            {
                "id": 10,
                "club": "Raven FC",
                "yearFrom": 2011,
                "yearTo": 2023
            }
        ]
    },
    {
        "id": 5,
        "kanjiName": "山田　花子",
        "katakanaName": "ヤマダ　ハナコ",
        "englishName": "Hanako　YAMADA",
        "dateOfBirth": "1985-05-31T15:00:00",
        "jfaId": "JFA333444",
        "instructorRegistrationNumber": "567456345",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3142",
            "3168"
        ],
        "licenceStart": "2022-09-30T15:00:00",
        "licenceEnd": "2023-09-29T15:00:00",
        "registeredPosition": [
            "RP_ACADEMIC_TEAM_COACH",
            "RP_SCHOOL_COACH"
        ],
        "jobTitle": "アカデミーサポートコーチ",
        "leadershipRole": [
            "",
            "LR_U12_COACH"
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION27",
            "DC_Q_QUALIFICATION29"
        ],
        "mainDuties": "スクールコーチ～アカデミーサポートコーチ（現職）",
        "competitionCareers": [
            {
                "id": 7,
                "club": "Raven FC Ladies",
                "yearFrom": 1998,
                "yearTo": 2003
            }
        ],
        "coachingCareers": [
            {
                "id": 12,
                "club": "Raven FC Ladies",
                "yearFrom": 2004,
                "yearTo": 2014
            },
            {
                "id": 13,
                "club": "Raven FC",
                "yearFrom": 2015,
                "yearTo": 2023
            }
        ]
    },
    {
        "id": 103,
        "kanjiName": "太郎",
        "katakanaName": "あ",
        "englishName": "",
        "dateOfBirth": "2023-04-04T15:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_FULL_TIME",
        "department": [
            "3162"
        ],
        "licenceStart": null,
        "licenceEnd": null,
        "registeredPosition": [
            ""
        ],
        "jobTitle": "あ",
        "leadershipRole": [
            ""
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION1"
        ],
        "mainDuties": "あ",
        "competitionCareers": [],
        "coachingCareers": []
    },
    {
        "id": 145,
        "kanjiName": "ロベルト本郷",
        "katakanaName": "ロベルトホンゴウ",
        "englishName": "Robert Hongo",
        "dateOfBirth": "1960-03-31T15:00:00",
        "jfaId": "JFA1234567890",
        "instructorRegistrationNumber": "C000001001",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3142"
        ],
        "licenceStart": "2023-03-31T15:00:00",
        "licenceEnd": "2024-03-30T15:00:00",
        "registeredPosition": [
            "RP_ACADEMIC_TEAM_COACH"
        ],
        "jobTitle": "テクニカルアドバイザー",
        "leadershipRole": [
            ""
        ],
        "annualSalary": 10000000,
        "qualifications": [
            "DC_Q_QUALIFICATION28"
        ],
        "mainDuties": "指導者へのアドバイス",
        "competitionCareers": [],
        "coachingCareers": []
    },
    {
        "id": 146,
        "kanjiName": "順天堂　三郎",
        "katakanaName": "ジュンテンドウ　サブロウ",
        "englishName": "Juntendo　Saburo",
        "dateOfBirth": "1993-05-14T15:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_CONSULTANT",
        "department": [
            "3167"
        ],
        "licenceStart": null,
        "licenceEnd": null,
        "registeredPosition": [
            "RP_OTHER"
        ],
        "jobTitle": "メディカルアドバイザー",
        "leadershipRole": [
            "",
            "LR_ACADEMY_TC"
        ],
        "annualSalary": 1000000,
        "qualifications": [
            "DC_Q_QUALIFICATION29"
        ],
        "mainDuties": "U12-18の傷害予防とリハビリテーションのアドバイス",
        "competitionCareers": [],
        "coachingCareers": []
    },
    {
        "id": 474,
        "kanjiName": "あ",
        "katakanaName": "あ",
        "englishName": "",
        "dateOfBirth": "2023-04-10T15:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_FULL_TIME",
        "department": [
            "3165"
        ],
        "licenceStart": null,
        "licenceEnd": null,
        "registeredPosition": [
            ""
        ],
        "jobTitle": "あ",
        "leadershipRole": [
            "",
            "LR_ACADEMY_TC"
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION2"
        ],
        "mainDuties": "あ",
        "competitionCareers": [],
        "coachingCareers": []
    },
    {
        "id": 885,
        "kanjiName": "test",
        "katakanaName": "test",
        "englishName": "",
        "dateOfBirth": "2023-03-01T06:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_CONSULTANT",
        "department": [
            "3142"
        ],
        "licenceStart": "2023-04-01T06:00:00",
        "licenceEnd": "2023-04-01T06:00:00",
        "registeredPosition": [
            ""
        ],
        "jobTitle": "test",
        "leadershipRole": [
            "",
            "LR_ACADEMY_TC"
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION1"
        ],
        "mainDuties": "test",
        "competitionCareers": [
            {
                "id": 1871,
                "club": "test",
                "yearFrom": 2014,
                "yearTo": 2023
            }
        ],
        "coachingCareers": []
    },
    {
        "id": 953,
        "kanjiName": "sample",
        "katakanaName": "sample",
        "englishName": "",
        "dateOfBirth": "2004-12-31T15:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3162"
        ],
        "licenceStart": "2023-03-31T15:00:00",
        "licenceEnd": "2023-04-30T15:00:00",
        "registeredPosition": [
            ""
        ],
        "jobTitle": "sample",
        "leadershipRole": [
            "",
            "LR_ACADEMY_TC"
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION2"
        ],
        "mainDuties": "sample",
        "competitionCareers": [],
        "coachingCareers": []
    },
    {
        "id": 955,
        "kanjiName": "test",
        "katakanaName": "test",
        "englishName": "test",
        "dateOfBirth": "2006-04-02T00:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_PART_TIME",
        "department": [
            "3163"
        ],
        "licenceStart": "2023-04-01T00:00:00",
        "licenceEnd": "2023-05-01T00:00:00",
        "registeredPosition": [
            ""
        ],
        "jobTitle": "test",
        "leadershipRole": [
            "",
            "LR_ACADEMY_TC"
        ],
        "annualSalary": null,
        "qualifications": [
            "DC_Q_QUALIFICATION4"
        ],
        "mainDuties": "test",
        "competitionCareers": [],
        "coachingCareers": []
    },
    {
        "id": 997,
        "kanjiName": "sam",
        "katakanaName": "sam",
        "englishName": "",
        "dateOfBirth": "2023-04-01T00:00:00",
        "jfaId": "",
        "instructorRegistrationNumber": "",
        "employmentStatus": "ES_FULL_TIME",
        "department": [
            "3163",
            "3165"
        ],
        "licenceStart": "2023-04-01T00:00:00",
        "licenceEnd": "2023-05-01T00:00:00",
        "registeredPosition": [
            ""
        ],
        "jobTitle": "sam",
        "leadershipRole": [
            ""
        ],
        "annualSalary": 30000000000,
        "qualifications": [
            "DC_Q_QUALIFICATION2",
            "DC_Q_QUALIFICATION4"
        ],
        "mainDuties": "sam",
        "competitionCareers": [],
        "coachingCareers": []
    }
]

export const players = [
    {
        "id": 1,
        "name": "Ian Gill",
        "dateOfBirth": "2003-12-31T15:00:00",
        "playerId": 0,
        "academyPeriods": [
            {
                "id": 1,
                "dateFrom": "2006-02-01T14:00:00",
                "dateTo": "2023-03-01T14:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1,
                "country": "England",
                "club": "Liverpool",
                "dateFrom": "2018-04-03T23:00:00",
                "dateTo": "2019-05-19T14:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 2,
        "name": "本郷　一郎",
        "dateOfBirth": "2000-03-08T15:00:00",
        "playerId": 2,
        "academyPeriods": [
            {
                "id": 2,
                "dateFrom": "2010-03-31T15:00:00",
                "dateTo": "2019-01-30T15:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 2,
                "country": "日本",
                "club": "Raven FC",
                "dateFrom": "2019-02-05T21:00:00",
                "dateTo": "2020-06-08T20:00:00"
            },
            {
                "id": 4,
                "country": "シンガポール",
                "club": "シンガポールユナイテッドFC",
                "dateFrom": "2021-05-31T20:00:00",
                "dateTo": "2023-01-02T21:00:00"
            }
        ],
        "internationalCareers": [
            {
                "id": 2,
                "ageGroup": "AG_U11",
                "country": "イングランド",
                "club": null,
                "dateFrom": "2011-02-01T06:00:00",
                "dateTo": "2012-02-01T06:00:00"
            }
        ]
    },
    {
        "id": 335,
        "name": "a",
        "dateOfBirth": "2022-12-31T15:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 334,
                "dateFrom": "2022-12-31T15:00:00",
                "dateTo": "2023-01-31T15:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1057,
                "country": "a",
                "club": "a",
                "dateFrom": "2023-01-01T06:00:00",
                "dateTo": "2023-02-01T06:00:00"
            },
            {
                "id": 1105,
                "country": "b",
                "club": "b",
                "dateFrom": "2022-12-01T06:00:00",
                "dateTo": "2023-01-01T06:00:00"
            },
            {
                "id": 1127,
                "country": "c",
                "club": "c",
                "dateFrom": "2022-10-31T15:00:00",
                "dateTo": "2022-11-30T15:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 336,
        "name": "test",
        "dateOfBirth": "2023-03-03T00:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 335,
                "dateFrom": "2023-01-01T00:00:00",
                "dateTo": "2023-02-02T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1058,
                "country": "UK",
                "club": "Raven",
                "dateFrom": "2023-03-03T00:00:00",
                "dateTo": "2023-04-03T23:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 346,
        "name": "wer",
        "dateOfBirth": "2023-01-01T06:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 345,
                "dateFrom": "2023-01-01T06:00:00",
                "dateTo": "2023-02-01T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1100,
                "country": "wer",
                "club": "wer",
                "dateFrom": "2023-03-01T05:00:00",
                "dateTo": "2023-04-01T04:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 511,
        "name": "do",
        "dateOfBirth": "2023-01-01T06:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 511,
                "dateFrom": "2023-01-01T06:00:00",
                "dateTo": "2023-02-01T06:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1620,
                "country": "do",
                "club": "dooo",
                "dateFrom": "2023-03-01T06:00:00",
                "dateTo": "2023-04-01T06:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 512,
        "name": "lon",
        "dateOfBirth": "2023-01-01T00:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 512,
                "dateFrom": "2023-01-01T00:00:00",
                "dateTo": "2023-02-01T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1621,
                "country": "lo",
                "club": "lon",
                "dateFrom": "2023-03-01T00:00:00",
                "dateTo": "2023-03-31T23:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 548,
        "name": "joe",
        "dateOfBirth": "2023-01-01T00:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 548,
                "dateFrom": "2023-02-01T00:00:00",
                "dateTo": "2023-03-01T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1709,
                "country": "raven",
                "club": "test",
                "dateFrom": "2023-03-31T23:00:00",
                "dateTo": "2023-04-30T23:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 563,
        "name": "test",
        "dateOfBirth": "2023-01-01T00:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 563,
                "dateFrom": "2023-02-01T00:00:00",
                "dateTo": "2023-03-01T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1742,
                "country": "test",
                "club": "test",
                "dateFrom": "2023-04-01T00:00:00",
                "dateTo": "2023-05-01T00:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 609,
        "name": "aaa",
        "dateOfBirth": "2023-04-01T00:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 609,
                "dateFrom": "2023-03-31T00:00:00",
                "dateTo": "2023-03-30T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1842,
                "country": "aaa",
                "club": "aaa",
                "dateFrom": "2023-03-29T00:00:00",
                "dateTo": "2023-03-29T00:00:00"
            }
        ],
        "internationalCareers": []
    },
    {
        "id": 658,
        "name": "sam",
        "dateOfBirth": "2023-01-01T00:00:00",
        "playerId": null,
        "academyPeriods": [
            {
                "id": 661,
                "dateFrom": "2023-02-01T00:00:00",
                "dateTo": "2023-03-01T00:00:00"
            }
        ],
        "clubCareers": [
            {
                "id": 1961,
                "country": "sam",
                "club": "sam",
                "dateFrom": "2023-04-01T15:00:00",
                "dateTo": "2023-05-01T00:00:00"
            }
        ],
        "internationalCareers": []
    }
]

export const facilities = [
    {
        "id": 1,
        "facilityName": "Raven FC トレーニングセンター",
        "shortName": "RTC",
        "address": "文京区本郷３丁目",
        "owner": "株式会社Raven FC",
        "facilityStatus": "FS_OWNED",
        "capabilities": [
            "CAP_CLUB_HOUSE",
            "CAP_MEDICAL_ROOM",
            "CAP_COLD_SHOWERS",
            "CAP_HOT_SHOWERS",
            "CAP_SOCCER_GOAL_POSSESSION_JUNIOR",
            "CAP_SOCCER_GOAL_POSSESSION_ADULT"
        ],
        "changingRooms": 3,
        "dormitoryCapacity": 12,
        "dormitoryRemarks": "",
        "facilityGrounds": [
            {
                "id": 1,
                "name": "ピッチA",
                "width": 70,
                "depth": 100,
                "spectators": 200,
                "turf": [
                    "TURF_1"
                ],
                "illumination": [
                    "600"
                ]
            },
            {
                "id": 2,
                "name": "ピッチB",
                "width": 20000,
                "depth": 30000,
                "spectators": 100,
                "turf": [
                    "TURF_4",
                    "TURF_3"
                ],
                "illumination": [
                    "1200",
                    "1000"
                ]
            }
        ]
    },
    {
        "id": 2,
        "facilityName": "Raven FC　多摩トレーニングセンター",
        "shortName": "RTC多摩",
        "address": "東京都多摩市",
        "owner": "多摩市",
        "facilityStatus": "FS_PRIORITY",
        "capabilities": [
            "CAP_CLUB_HOUSE",
            "CAP_COLD_SHOWERS",
            "CAP_SOCCER_GOAL_POSSESSION_ADULT",
            "CAP_SOCCER_GOAL_POSSESSION_JUNIOR"
        ],
        "changingRooms": 2,
        "dormitoryCapacity": 10,
        "dormitoryRemarks": "ユース寮（プロ選手は不在）",
        "facilityGrounds": [
            {
                "id": 3,
                "name": "ピッチA",
                "width": 80,
                "depth": 110,
                "spectators": 100,
                "turf": [
                    "TURF_1"
                ],
                "illumination": [
                    "500"
                ]
            }
        ]
    },
    {
        "id": 30,
        "facilityName": "金沢大学",
        "shortName": "金沢大学",
        "address": "金沢氏",
        "owner": "金沢大学",
        "facilityStatus": "FS_PRIORITY",
        "capabilities": [
            "CAP_CLUB_HOUSE",
            "CAP_MEDICAL_ROOM",
            "CAP_HOT_SHOWERS",
            "CAP_SOCCER_GOAL_POSSESSION_ADULT",
            "CAP_CHANGING_ROOMS"
        ],
        "changingRooms": 2,
        "dormitoryCapacity": 0,
        "dormitoryRemarks": "",
        "facilityGrounds": [
            {
                "id": 37,
                "name": "金沢大学サッカー場",
                "width": 105,
                "depth": 68,
                "spectators": 2000,
                "turf": [
                    "TURF_4"
                ],
                "illumination": [
                    "300"
                ]
            }
        ]
    },
    {
        "id": 97,
        "facilityName": "gam",
        "shortName": "2147483",
        "address": "0",
        "owner": "0",
        "facilityStatus": "FS_OWNED",
        "capabilities": [
            "CAP_CLUB_HOUSE"
        ],
        "changingRooms": 5,
        "dormitoryCapacity": 5,
        "dormitoryRemarks": "5",
        "facilityGrounds": [
            {
                "id": 123,
                "name": "8",
                "width": 8,
                "depth": 8,
                "spectators": 8,
                "turf": [
                    "TURF_1"
                ],
                "illumination": [
                    "1100"
                ]
            }
        ]
    }
]

export const teams = [
    {
        "id": 1,
        "teamDetails": {
            "id": 1,
            "teamName": "U18",
            "established": 2014,
            "ageGroup": "AG_U18",
            "staffSupervisor": 1,
            "staffCoaches": [
                -1,
                5
            ],
            "staffGkCoaches": [
                -1,
                1
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                -1
            ]
        },
        "playerAges": {
            "id": 1,
            "u18": 20,
            "u17": 2,
            "u16": 1,
            "u15": 0,
            "u14": 0,
            "u13": 0,
            "u12": 0,
            "u11": 0,
            "u10": 0,
            "u9": 0
        },
        "trainingSchedule": {
            "id": 1,
            "mon": {
                "id": 1,
                "start": "2023-03-22T22:00:00",
                "end": "2023-03-23T10:00:00",
                "grounds": [
                    1
                ],
                "remarks": ""
            },
            "tue": {
                "id": 2,
                "start": "2023-03-23T06:00:00",
                "end": "2023-03-23T19:30:00",
                "grounds": [
                    1
                ],
                "remarks": ""
            },
            "wed": {
                "id": 3,
                "start": "2023-03-23T07:00:00",
                "end": "2023-03-23T19:00:00",
                "grounds": [
                    -1
                ],
                "remarks": ""
            },
            "thur": {
                "id": 4,
                "start": "2023-03-23T10:00:00",
                "end": "2023-03-23T17:30:00",
                "grounds": [
                    2
                ],
                "remarks": ""
            },
            "fri": {
                "id": 5,
                "start": "2023-03-23T15:00:00",
                "end": "2023-03-23T17:30:00",
                "grounds": [
                    2
                ],
                "remarks": ""
            },
            "sat": {
                "id": 6,
                "start": "2023-03-23T06:00:00",
                "end": "2023-03-23T17:00:00",
                "grounds": [
                    1
                ],
                "remarks": ""
            },
            "sun": {
                "id": 7,
                "start": "2023-03-20T00:00:00",
                "end": "2023-03-22T13:30:00",
                "grounds": [
                    1
                ],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 1,
            "admissionFees": 10000,
            "playerMonthlyExpense": 10000,
            "travelMonthlyExpense": 50000,
            "remarks": "海外遠征費用を別途積立。2年で20万円。"
        },
        "titles": [
            {
                "id": 1,
                "competition": "None",
                "yearAchieved": 0
            }
        ],
        "competitions": [
            {
                "id": 1,
                "competition": "Youth Cup",
                "position": "PA_LAST_16"
            },
            {
                "id": 2,
                "competition": "Youth Cup South",
                "position": "PA_SEMI_FINAL"
            }
        ]
    },
    {
        "id": 2,
        "teamDetails": {
            "id": 2,
            "teamName": "U15",
            "established": 1990,
            "ageGroup": "AG_U15",
            "staffSupervisor": 2,
            "staffCoaches": [
                5
            ],
            "staffGkCoaches": [
                2
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                3
            ]
        },
        "playerAges": {
            "id": 2,
            "u18": 0,
            "u17": 0,
            "u16": 1,
            "u15": 14,
            "u14": 5,
            "u13": 0,
            "u12": 0,
            "u11": 0,
            "u10": 0,
            "u9": 0
        },
        "trainingSchedule": {
            "id": 2,
            "mon": {
                "id": 8,
                "start": "2023-03-22T17:00:00",
                "end": "2023-03-23T04:00:00",
                "grounds": [
                    3
                ],
                "remarks": ""
            },
            "tue": {
                "id": 9,
                "start": "2023-04-12T18:00:00",
                "end": "2023-04-14T06:00:00",
                "grounds": [
                    -1
                ],
                "remarks": "OFF"
            },
            "wed": {
                "id": 10,
                "start": "2023-04-13T19:00:00",
                "end": "2023-04-15T07:00:00",
                "grounds": [],
                "remarks": ""
            },
            "thur": {
                "id": 11,
                "start": "2023-04-13T20:00:00",
                "end": "2023-04-16T08:00:00",
                "grounds": [],
                "remarks": ""
            },
            "fri": {
                "id": 12,
                "start": "2023-04-13T21:00:00",
                "end": "2023-04-17T09:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sat": {
                "id": 13,
                "start": "2023-04-13T22:00:00",
                "end": "2023-04-17T10:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sun": {
                "id": 14,
                "start": "2023-04-09T23:00:00",
                "end": "2023-04-17T11:00:00",
                "grounds": [],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 2,
            "admissionFees": 100000000000,
            "playerMonthlyExpense": 100000000000,
            "travelMonthlyExpense": 500000000000,
            "remarks": ""
        },
        "titles": [
            {
                "id": 206,
                "competition": "test-test",
                "yearAchieved": 1000
            }
        ],
        "competitions": [
            {
                "id": 65,
                "competition": "岐阜フェスティバル",
                "position": "PA_3RD"
            }
        ]
    },
    {
        "id": 191,
        "teamDetails": {
            "id": 191,
            "teamName": "test",
            "established": 2020,
            "ageGroup": "AG_U10",
            "staffSupervisor": -1,
            "staffCoaches": [
                -1
            ],
            "staffGkCoaches": [
                -1
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                -1
            ]
        },
        "playerAges": {
            "id": 191,
            "u18": 1,
            "u17": 1,
            "u16": 1,
            "u15": 1,
            "u14": 1,
            "u13": 1,
            "u12": 1,
            "u11": 1,
            "u10": 1,
            "u9": 1
        },
        "trainingSchedule": {
            "id": 191,
            "mon": {
                "id": 1331,
                "start": "2023-05-09T15:00:00",
                "end": "2023-05-10T03:00:00",
                "grounds": [],
                "remarks": ""
            },
            "tue": {
                "id": 1332,
                "start": "2023-05-09T16:00:00",
                "end": "2023-05-10T04:00:00",
                "grounds": [],
                "remarks": ""
            },
            "wed": {
                "id": 1333,
                "start": "2023-05-09T17:00:00",
                "end": "2023-05-10T05:00:00",
                "grounds": [],
                "remarks": ""
            },
            "thur": {
                "id": 1334,
                "start": "2023-05-09T18:00:00",
                "end": "2023-05-10T06:00:00",
                "grounds": [],
                "remarks": ""
            },
            "fri": {
                "id": 1335,
                "start": "2023-05-09T19:00:00",
                "end": "2023-05-10T07:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sat": {
                "id": 1336,
                "start": "2023-05-09T20:00:00",
                "end": "2023-05-10T08:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sun": {
                "id": 1337,
                "start": "2023-05-09T21:00:00",
                "end": "2023-05-10T09:00:00",
                "grounds": [],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 191,
            "admissionFees": 100,
            "playerMonthlyExpense": 1000,
            "travelMonthlyExpense": 100,
            "remarks": ""
        },
        "titles": [
            {
                "id": 358,
                "competition": "",
                "yearAchieved": 0
            }
        ],
        "competitions": [
            {
                "id": 657,
                "competition": "100",
                "position": "PA_2ND"
            }
        ]
    },
    {
        "id": 192,
        "teamDetails": {
            "id": 192,
            "teamName": "t",
            "established": 2020,
            "ageGroup": "AG_U10",
            "staffSupervisor": -1,
            "staffCoaches": [
                -1
            ],
            "staffGkCoaches": [
                -1
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                -1
            ]
        },
        "playerAges": {
            "id": 192,
            "u18": 1,
            "u17": 0,
            "u16": 0,
            "u15": 0,
            "u14": 0,
            "u13": 0,
            "u12": 0,
            "u11": 0,
            "u10": 0,
            "u9": 0
        },
        "trainingSchedule": {
            "id": 192,
            "mon": {
                "id": 1338,
                "start": "2023-05-09T15:00:00",
                "end": "2023-05-10T08:00:00",
                "grounds": [],
                "remarks": ""
            },
            "tue": {
                "id": 1339,
                "start": "1970-01-01T09:00:00",
                "end": "2023-05-11T09:00:00",
                "grounds": [],
                "remarks": ""
            },
            "wed": {
                "id": 1340,
                "start": "2023-05-09T15:00:00",
                "end": "2023-05-11T10:00:00",
                "grounds": [],
                "remarks": ""
            },
            "thur": {
                "id": 1341,
                "start": "1970-01-01T09:00:00",
                "end": "2023-05-11T11:00:00",
                "grounds": [],
                "remarks": ""
            },
            "fri": {
                "id": 1342,
                "start": "2023-05-09T15:00:00",
                "end": "2023-05-11T12:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sat": {
                "id": 1343,
                "start": "1970-01-01T09:00:00",
                "end": "2023-05-11T13:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sun": {
                "id": 1344,
                "start": "2023-05-09T15:00:00",
                "end": "2023-05-11T14:00:00",
                "grounds": [],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 192,
            "admissionFees": 100,
            "playerMonthlyExpense": 100,
            "travelMonthlyExpense": 100,
            "remarks": ""
        },
        "titles": [
            {
                "id": 359,
                "competition": "",
                "yearAchieved": 0
            }
        ],
        "competitions": [
            {
                "id": 658,
                "competition": "100",
                "position": "PA_2ND"
            }
        ]
    },
    {
        "id": 193,
        "teamDetails": {
            "id": 193,
            "teamName": "west",
            "established": 2020,
            "ageGroup": "AG_U9",
            "staffSupervisor": -1,
            "staffCoaches": [
                -1
            ],
            "staffGkCoaches": [
                -1
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                3
            ]
        },
        "playerAges": {
            "id": 193,
            "u18": 0,
            "u17": 0,
            "u16": 0,
            "u15": 0,
            "u14": 0,
            "u13": 0,
            "u12": 0,
            "u11": 0,
            "u10": 0,
            "u9": 0
        },
        "trainingSchedule": {
            "id": 193,
            "mon": {
                "id": 1345,
                "start": "2023-05-10T15:00:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "tue": {
                "id": 1346,
                "start": "1970-01-01T05:30:00",
                "end": "2023-05-10T17:00:00",
                "grounds": [],
                "remarks": ""
            },
            "wed": {
                "id": 1347,
                "start": "2023-05-10T15:00:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "thur": {
                "id": 1348,
                "start": "2023-05-10T15:00:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "fri": {
                "id": 1349,
                "start": "1970-01-01T05:30:00",
                "end": "2023-05-10T17:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sat": {
                "id": 1350,
                "start": "1970-01-01T05:30:00",
                "end": "2023-05-10T16:00:00",
                "grounds": [],
                "remarks": ""
            },
            "sun": {
                "id": 1351,
                "start": "2023-05-10T15:00:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 193,
            "admissionFees": 100,
            "playerMonthlyExpense": 100,
            "travelMonthlyExpense": 0,
            "remarks": ""
        },
        "titles": [
            {
                "id": 360,
                "competition": "",
                "yearAchieved": 0
            }
        ],
        "competitions": [
            {
                "id": 659,
                "competition": "test",
                "position": "PA_LAST_16"
            }
        ]
    },
    {
        "id": 195,
        "teamDetails": {
            "id": 195,
            "teamName": "RASA",
            "established": 1998,
            "ageGroup": "AG_U16",
            "staffSupervisor": -1,
            "staffCoaches": [
                -1
            ],
            "staffGkCoaches": [
                -1
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                -1
            ]
        },
        "playerAges": {
            "id": 195,
            "u18": 1,
            "u17": 1,
            "u16": 1,
            "u15": 1,
            "u14": 1,
            "u13": 1,
            "u12": 1,
            "u11": 1,
            "u10": 1,
            "u9": 1
        },
        "trainingSchedule": {
            "id": 195,
            "mon": {
                "id": 1359,
                "start": "2023-05-11T03:30:00",
                "end": "2023-05-11T04:30:00",
                "grounds": [],
                "remarks": ""
            },
            "tue": {
                "id": 1360,
                "start": "2023-05-11T06:30:00",
                "end": "2023-05-10T18:30:00",
                "grounds": [],
                "remarks": ""
            },
            "wed": {
                "id": 1361,
                "start": "1970-01-01T05:30:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "thur": {
                "id": 1362,
                "start": "1970-01-01T05:30:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "fri": {
                "id": 1363,
                "start": "1970-01-01T05:30:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "sat": {
                "id": 1364,
                "start": "1970-01-01T05:30:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            },
            "sun": {
                "id": 1365,
                "start": "1970-01-01T05:30:00",
                "end": "1970-01-01T05:30:00",
                "grounds": [],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 195,
            "admissionFees": 1,
            "playerMonthlyExpense": 1,
            "travelMonthlyExpense": 1,
            "remarks": "1"
        },
        "titles": [
            {
                "id": 362,
                "competition": "",
                "yearAchieved": 0
            }
        ],
        "competitions": [
            {
                "id": 661,
                "competition": "1",
                "position": "PA_16TH"
            }
        ]
    },
    {
        "id": 196,
        "teamDetails": {
            "id": 196,
            "teamName": "test",
            "established": 2020,
            "ageGroup": "AG_U18",
            "staffSupervisor": -1,
            "staffCoaches": [
                -1
            ],
            "staffGkCoaches": [
                -1
            ],
            "medicalStaff": [
                -1
            ],
            "practiceGrounds": [
                -1
            ]
        },
        "playerAges": {
            "id": 196,
            "u18": 0,
            "u17": 0,
            "u16": 0,
            "u15": 0,
            "u14": 0,
            "u13": 0,
            "u12": 0,
            "u11": 0,
            "u10": 0,
            "u9": 0
        },
        "trainingSchedule": {
            "id": 196,
            "mon": {
                "id": 1366,
                "start": "2023-05-10T18:30:00",
                "end": "2023-05-10T20:30:00",
                "grounds": [],
                "remarks": ""
            },
            "tue": {
                "id": 1367,
                "start": null,
                "end": null,
                "grounds": [],
                "remarks": ""
            },
            "wed": {
                "id": 1368,
                "start": null,
                "end": null,
                "grounds": [],
                "remarks": ""
            },
            "thur": {
                "id": 1369,
                "start": null,
                "end": null,
                "grounds": [],
                "remarks": ""
            },
            "fri": {
                "id": 1370,
                "start": null,
                "end": null,
                "grounds": [],
                "remarks": ""
            },
            "sat": {
                "id": 1371,
                "start": null,
                "end": null,
                "grounds": [],
                "remarks": ""
            },
            "sun": {
                "id": 1372,
                "start": null,
                "end": null,
                "grounds": [],
                "remarks": ""
            }
        },
        "teamFees": {
            "id": 196,
            "admissionFees": 100,
            "playerMonthlyExpense": 100,
            "travelMonthlyExpense": 100,
            "remarks": ""
        },
        "titles": [
            {
                "id": 363,
                "competition": "",
                "yearAchieved": 0
            }
        ],
        "competitions": [
            {
                "id": 662,
                "competition": "100",
                "position": "PA_3RD"
            }
        ]
    }
]

export const companyDetails = {
    "companyName": "Raven FC PLC",
    "executiveMember": "Jim Brown",
    "academyDirector": "Paula Smith"
}

export const contactDetails = {
    "name": "Jim Brown",
    "department": "CEO",
    "phone": "01234567891",
    "fax": "01234567892",
    "mobile": "01234567893",
    "email": "jim.brown@ravenfc.com",
    "workLocation": "Tokyo"
}

export const accountingProfitLossSummary = [
    [
        {categoryName: 'Income', amount: 0},
        {categoryName: 'Expense', amount: 0},
        {categoryName: 'Net', amount: 0},
    ],
    [
        {categoryName: 'Labuor Cost', amount: 0},
        {categoryName: 'Equipment/Fixtures', amount: 0},
        {categoryName: 'Facilities', amount: 0},
        {categoryName: 'Travel expenses', amount: 0},
    ],
    [
        {categoryName: 'Total club income (for the whole club, including academy)', amount: 0},
        {categoryName: 'Total club expense (for the whole club, including academy)', amount: 0},
    ],
    [
        {categoryName: 'Total club income (for the whole club, including academy)', amount: 0},
        {categoryName: 'Total club expense (for the whole club, including academy)', amount: 0},
    ]
]

export const accountingProfitLossSummaryHead = [
    [
        {
            key: '', displayName: 'total',
        },
        {
            key: '', displayName: '',
        },
    ],
    [
        {
            key: '', displayName: 'DC_3_CLUB_TOTAL_EXPENSE',
        },
        {
            key: '', displayName: '',
        },
      ],
      [
        {
            key: '', displayName: '2022 figure ¥',
        },
        {
            key: '', displayName: '',
        },
      ],
      [
        {
            key: '', displayName: '2023 budget ¥',
        },
        {
            key: '', displayName: '',
        },
      ]
]
