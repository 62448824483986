import { toast } from 'react-toastify';
// import { EvidenceLibraryItem, LanguageCode } from '../api/types';
import { triggerFile } from '../api/evidence';
import useFilePreview from './useFilePreview';
import { useTranslation } from 'react-i18next';
import { baseUrl } from '../api/config';

const allowedToTranslate = ['excel', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'pdf'];

interface Params {
  onSuccessTranslation: () => void;
}

const useFileTranslationProcess = ({ onSuccessTranslation }: Params) => {
  const user = { id: 123 };
  const { i18n, t } = useTranslation();
  const onFileClick = useFilePreview();

  const runFileTranslation = (src: string) => {
    triggerFile(src)
      .catch(() => {
        return;
      })
      .finally(onSuccessTranslation);
  };

  return (mode: 'preview' | 'download', item: any & { name?: string }, originalLang?: boolean) => {
    // const fileLang = item.languageCode.toLocaleLowerCase();
    const fileLang = 'en';
    const title = item.title || item.name;
    const extension = title?.split('.').pop();
    const isReadyToDownload = item.translated || i18n.language === fileLang;

    const fileSrc = `${baseUrl}/api/evidence/${item.id}/fileUid/${item.uid}?&displayInline=${
      mode === 'preview'
    }&userId=${user.id}&lang=${originalLang ? item.languageCode.toLocaleLowerCase() : i18n.language}`;

    localStorage.setItem('fileSrc', fileSrc);
    localStorage.setItem('fileExtension', extension);

    if (extension && allowedToTranslate.includes(extension) && !isReadyToDownload) {
      toast(
        // t(fileLang === LanguageCode.English ? 'FILE_TRANSLATED_FROM_EN_TO_JA' : 'FILE_TRANSLATED_FROM_JA_TO_EN'),
        t(fileLang === 'en' ? 'FILE_TRANSLATED_FROM_EN_TO_JA' : 'FILE_TRANSLATED_FROM_JA_TO_EN'),
        {
          hideProgressBar: true,
        }
      );
      return runFileTranslation(fileSrc);
    }

    mode === 'preview' ? onFileClick(title, fileSrc) : window.open(fileSrc);
  };
};

export default useFileTranslationProcess;
