import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import '../styles.css';
//internal and external components import
import { Table, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import StatusIndicatorSmall from '../../StatusIndicatorSmall';
import ProgressBar from '../../ProgressBar';
import { stableSort, descendingComparator, getComparator, sortData } from '../../../common/SortSupport';
import { EnhancedTableHead } from '../../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMaximize } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../CustomTooltip';
import { colors } from '../../../common/pallette/colors';
import TableSkeleton from '../../TableSkeleton';
import { resetOffSiteStandards, resetSelectedOffSiteCriteria, setSelectedOffSiteCriteria } from '../../../redux/reducers/evaluation';
import { genUID } from '../../../common/StaticExports';


export default function OffSiteCriteriaTable(props: { data: any; criteria: any; selectedCriteria: any }) {

  const { data, criteria, selectedCriteria } = props;

  const dispatch = useDispatch();

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('uid');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows:any = React.useMemo(
    () =>
      data?.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = [
    {
      key: 'uid', displayName: 'id',
    },
    {
      key: 'name', displayName: 'criteria',
    },
    // {
    //   key: 'interimReprot', displayName: 'interim report',
    // },
    // {
    //   key: 'finalReport', displayName: 'final report',
    // },
    {
      key: 'status', displayName: 'status',
    },
    {
      key: 'progress', displayName: 'evaluation progress',
    },
  ]

  const manageTableExpand = () => {
    dispatch(resetSelectedOffSiteCriteria());
    dispatch(resetOffSiteStandards());
  }

  const manageTableShrink = (d: any) => {
    dispatch(setSelectedOffSiteCriteria(d));  
    // dispatch(setSelectedStandards(standards[d.criteria]))
  }

  //table contents
    
  return (
    <React.Fragment>
      <Box sx={{ position: 'relative' }}>
        {!criteria && <FontAwesomeIcon className='icon tableExpandIcon' icon={faMaximize} onClick={() => manageTableExpand()} />}
        <TableContainer sx={{height: '100%', width: !criteria ? 'fit-content' : 'auto', overflow: criteria ? 'auto' : 'hidden'}}>
          <Table stickyHeader sx={{ width: criteria ? '100%' : 'auto', boxShadow: 'none' }} size="small" aria-label="a dense table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={criteria ? head : [head[0]]}
            />
            <TableBody>
              {
                (visibleRows?.length) ?
                visibleRows.map((d: any, i: number) => (
                  <TableRow
                    className='hoverBG'
                    key={`dynamic-table-row-${i}`}
                    onClick={() => manageTableShrink(d)}
                  >
                    <CustomTooltip title={d.name} className={!criteria ? 'capitalize' : 'displayNone'} arrow followCursor placement='top-start'>
                      <TableCell className={(!criteria && selectedCriteria.id === d.id) ? 'fw900 ' : ''}>{genUID(d.uid)}</TableCell>
                    </CustomTooltip>
                    {
                      criteria && <>
                      <TableCell className='capitalize' sx={{cursor: 'pointer'}}>
                        {/* <FontAwesomeIcon className='icon mr5' icon={faChevronRight} /> */}
                        {d.name}
                      </TableCell>
                      {/* <TableCell><StatusIndicatorSmall reportType={'interim'} status={d.interimReprot} /></TableCell>
                      <TableCell><StatusIndicatorSmall reportType={'final'} status={d.finalReport} /></TableCell> */}
                      <TableCell><StatusIndicatorSmall reportType={'siteReview'} status={d.status} /></TableCell>
                      <TableCell>
                        <ProgressBar percent={d.progress}/>
                      </TableCell></>
                    } 
                  </TableRow>
                ))
                :
                <TableSkeleton rowCount={13} cellCount={5} />
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}