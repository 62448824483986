import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import './styles.css';
import CheckBox from "../CheckBox/CheckBox";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import LoadingButton from "@mui/lab/LoadingButton";
import ThreeDotMenu from "../ThreeDotMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { setArchiveData, setCurrentData } from "../../redux/reducers/library";
import { useSelector, useDispatch } from "react-redux";
import { updateEvidence } from "../../api/evidence";
import InformationPopup from "../InformationPopup";

const MultiSelectActionBar = (props:any) => {

    const { manageClose, section } = props;
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [isBulkDelete, setIsBulkDelete] = useState(false);
    const [isBulkArchiveRestore, setIsBulkArchiveRestore] = useState(false);
    const [t] = useTranslation();
    const selectedFolder = useSelector((state: any) => state.library.selectedFolder);
    const selectedArchiveFolder = useSelector((state: any) => state.library.selectedArchiveFolder);
    const currentData = useSelector((state: any) => state.library.currentData);
    const archiveData = useSelector((state: any) => state.library.archiveData);
    const displayData = useSelector((state: any) => state.library.displayData);
    const displayArchiveData = useSelector((state: any) => state.library.displayArchiveData);
    const currentFolderList = useSelector((state: any) => state.library.currentFolderList);
    const archiveFolderList = useSelector((state: any) => state.library.archiveFolderList);
    const currentRootFolder = currentFolderList.find((x:any) => x.parentFolderId == null);
    const archiveRootFolder = archiveFolderList.find((x:any) => x.parentFolderId == null);
    const clubDesirePlan = useSelector((state: any) => state.clubData.clubDesirePlan);
    const dispatch = useDispatch();
    const mainData = section == 'archive' ? archiveData : currentData;
    let [retainedArr, setRetainedArr]:any = useState([]);
    let [doAction, setDoAction]:any = useState([]);
    let [actionListMessage, setActionListMessage]:any = useState('');
    let [retainListMessage, setRetainListMessage]:any = useState('');
    let menuOptions = section == 'archive' ? archiveFolderList : currentFolderList;
    menuOptions = menuOptions.map((d:any) => {
        if(d.parentFolderId == null) {
            return { ...d, name: t('ALL_DOCUMENTS') }
        }
        return d;
    });

    const Div = styled.div`
      display: flex;
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
    
      .label {
        margin-left: 12px;
        font-size: 14px;
        width: 120px;
      }
    `;

    useEffect(() => {
        if(section == 'current') {
            const tempData = displayData.filter((d:any) => d.isSelected);
            if(tempData?.length != displayData?.length || tempData?.length == 0) {
                setIsSelectAll(false);
            } else {
                setIsSelectAll(true);
            }
        } else {
            const tempData = displayArchiveData.filter((d:any) => d.isSelected);
            if(tempData?.length != displayArchiveData?.length || tempData?.length == 0) {
                setIsSelectAll(false);
            } else {
                setIsSelectAll(true);
            }
        }
    }, [selectedFolder, selectedArchiveFolder, displayData, displayArchiveData]);

    const manageAction = (action:any) => {
        let arr1:any = [];
        let arr2:any = [];
        mainData.map((d:any) => {
            if(d.isSelected) {
                if((d?.evaluationStandards?.length != 0 || d?.licenceStandards?.length != 0) && clubDesirePlan.data?.isReadyToReview) {
                    arr1.push(d);
                } else {
                    arr2.push(d);
                }
            }
        });
        setRetainedArr(arr1);
        setDoAction(arr2);
        switch(action) {
            case 'delete':
                setIsBulkDelete(true);
                if(arr1?.length > 0) {
                    setActionListMessage('EVIDENCES_TO_BE_DELETED');
                    setRetainListMessage('EVIDENCES_TO_BE_RETAINED_DELETED');
                } else {
                    handleAction('delete');
                }
                break;
            case 'archive':
                setIsBulkArchiveRestore(true);
                if(arr1?.length > 0) {
                    if(section == 'current') {
                        setActionListMessage('EVIDENCES_TO_BE_ARCHIVED');
                        setRetainListMessage('EVIDENCES_TO_BE_RETAINED_ARCHIVED');
                    } else {
                        setActionListMessage('EVIDENCES_TO_BE_RESTORED');
                        setRetainListMessage('EVIDENCES_TO_BE_RETAINED_RESTORED');
                    }
                } else {
                    handleAction('archiveRestore');
                }
                break;
        }
    }

    const manageAfterAction = (select:any) => {
        let tempData:any = [];
        
        if(section == 'current') {
            currentData.map((d:any) => {
                if((d.isSelected && (d?.evaluationStandards?.length != 0 || d?.licenceStandards?.length != 0) && clubDesirePlan.data?.isReadyToReview) || select == 'unselectall') {
                    tempData.push({...d, isSelected: false});
                } else if(!d.isSelected) {
                    tempData.push(d);
                }
            });
            dispatch(setCurrentData(tempData));
        } else {
            archiveData.map((d:any) => {
                if((d.isSelected && (d?.evaluationStandards?.length != 0 || d?.licenceStandards?.length != 0)) || select == 'unselectall') {
                    tempData.push({...d, isSelected: false});
                } else if(!d.isSelected) {
                    tempData.push(d);
                }
            });
            dispatch(setArchiveData(tempData));
        }
    }

    const manageSelectAllClick = (selected:any) => {
        setIsSelectAll(selected);
        let tempData:any = [];
        
        if(section == 'current') {
            tempData = currentData.map((d:any) => {
                if(selectedFolder == d.evidenceFolderId || selectedFolder == currentRootFolder.id) {
                    return {...d, isSelected: selected};
                } return d;
            });
            dispatch(setCurrentData(tempData));
        } else {
            tempData = archiveData.map((d:any) => {
                if(selectedArchiveFolder == d?.archivedEvidenceFolderId || selectedArchiveFolder == archiveRootFolder.id) {
                    return {...d, isSelected: selected};
                } return d;
            });
            dispatch(setArchiveData(tempData));
        }

        if(!selected) {
            setTimeout(() => {
                manageClose();
            }, 300);
        }
    };
    
    const manageBulkDelete = () => {

        const bulkDelete = async (ids:any) => {
            const response = await updateEvidence({
                evidenceId: ids,
                // @ts-ignore
                newFolderFolderId: null,
                isDelete: true
            });
            return 0;
        }
        let ids:any = [];
        mainData.map((d:any) => {
            if(d.isSelected && (d?.evaluationStandards?.length == 0 && d?.licenceStandards?.length == 0)) {
                ids.push(d.id);
            }
        });
        ids.length ? 
        bulkDelete(ids).then(() => {
            manageAfterAction('');
        }) : manageAfterAction('unselectall')
    }

    const manageBulkArchiveRestore = () => {

        const bulkArchive = async (ids:any) => {
            const response = await updateEvidence({
                evidenceId: ids,
                // @ts-ignore
                newFolderFolderId: null,
                isDelete: false
            });
        }
        let ids:any = [];
        mainData.map((d:any) => {
            if(d.isSelected && (d?.evaluationStandards?.length == 0 && d?.licenceStandards?.length == 0)) {
                ids.push(d.id);
            }
        });
        ids.length ? 
        bulkArchive(ids).then(() => {
            manageAfterAction('');
        }) : manageAfterAction('unselectall');
    }

    const manageBulkMoveTo = (actionFolder:any) => {

        const moveEvidencePath = async (ids:any) => {
            const response = await updateEvidence({
                evidenceId: [...ids],
                newFolderFolderId: actionFolder.id,
                isArchived: false,
                isDelete: false
            });
        }
        let ids:any = [];
        mainData.map((d:any) => {
            if(d.isSelected) {
                ids.push(d.id);
            }
        });
        moveEvidencePath(ids).then(() => {
            let tempData:any = [];
            
            if(section == 'current') {
                tempData = currentData.map((d:any) => {
                    if(d.isSelected) {
                        return {...d, evidenceFolderId: actionFolder.id, isSelected: false};
                    } return d;
                });
                dispatch(setCurrentData(tempData));
            } else {
                tempData = archiveData.map((d:any) => {
                    if(d.isSelected) {
                        return {...d, archivedEvidenceFolderId: actionFolder.id, isSelected: false};
                    } return d;
                });
                dispatch(setArchiveData(tempData));
            }

            setTimeout(() => {
                manageClose();
            }, 1000);
        })
    }

    const handleAction = (act:any) => {
        if(isBulkDelete || act == 'delete') {
            manageBulkDelete();
            setIsBulkDelete(false);
            setTimeout(() => {
                manageClose();
            }, 1000);
        } else if(isBulkArchiveRestore || act == 'archiveRestore') {
            manageBulkArchiveRestore();
            setIsBulkArchiveRestore(false);
            setTimeout(() => {
                manageClose();
            }, 1000);
        }
    }

    return(
        <>
            <InformationPopup isOpen={(isBulkDelete || isBulkArchiveRestore) && retainedArr?.length > 0} 
            closeModal={() => {
                handleAction('');
                setIsBulkDelete(false);
                setIsBulkArchiveRestore(false);
            }} 
            // makeAction={() => {handleAction()}} 
            message={'EVIDENCE_FOLDER_ACTION_WARNING_MESSAGE'}
            retainedList={retainedArr}
            actionList={doAction}
            retainMessage={retainListMessage}
            listMessage={actionListMessage}
            singleButtonLabel={'OK'}
             />
            <Box className={'actionBarWrapper'}>
                <Div onClick={() => {manageSelectAllClick(!isSelectAll)}} className="mr28">
                    <CheckBox
                        // @ts-ignore
                        onClick={() => {manageSelectAllClick(!isSelectAll)}}
                        isHidden={false}
                        isOn={isSelectAll}
                        isMain={true}
                    />
                    <div className="label">{isSelectAll ? t('UNSELECT_ALL') : t('SELECT_ALL')}</div>
                </Div>
                <LoadingButton variant='contained' 
                className='mtbAuto ELbuttons mr28' 
                onClick={() => {manageAction('delete')}} 
                sx={{ height: 28 }}
                loading={isBulkDelete}
                disableElevation>
                    {t('DELETE_SELECTED')}
                </LoadingButton>
                <LoadingButton variant='contained' 
                className='mtbAuto ELbuttons mr28' 
                onClick={() => {manageAction('archive')}} 
                sx={{ height: 28 }}
                loading={isBulkArchiveRestore}
                disableElevation>
                    {section == 'archive' ? t('RESTORE_SELECTED') : t('ARCHIVE_SELECTED')}
                </LoadingButton>
                <Box className={'mr28'}>
                    <ThreeDotMenu options={menuOptions} isButton={true} manageClick={(d:any) => {('id' in d) && manageBulkMoveTo(d)}}/>
                </Box>
                <Tooltip title={t('CLOSE')} arrow placement="bottom">
                    <FontAwesomeIcon css={{ margin: 'auto', fontSize: '18px' }} icon={faXmark} onClick={() => {manageClose()}} />
                </Tooltip>
            </Box>
        </>
    );
}

export default MultiSelectActionBar;