import React from 'react';
import styled, { css } from 'styled-components';
import tickIconSrc from '../../assets/icons/tick.svg';

interface DivProps {
  disabled?: boolean;
  isMain?: boolean;
  isOn?: boolean;
}

const Div = styled.div<DivProps>`
  border: 1px solid ${({ isMain }) => (isMain ? 'black' : '#ebebeb')};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  user-select: none;

  background-color: ${({ isOn }) => (isOn ? '#ebebeb' : 'white')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

interface CheckBoxProps extends DivProps {
  onClick: () => void;
  isHidden: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ onClick, disabled, isMain, isOn, isHidden }) => {
  if (isHidden) return null;
  return (
    <Div onClick={disabled ? undefined : onClick} disabled={disabled} isMain={isMain} isOn={isOn}>
      {isOn && <img src={tickIconSrc} alt="" />}
    </Div>
  );
};

export default CheckBox;
