import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Popper, Fade, Paper, Typography } from '@mui/material/';
import { stableSort, getComparator, sortData } from '../../../common/SortSupport';
import { EnhancedTableHead } from '../../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import CommentsDocsActionsIconWrapper from '../../CommentsDocsActionsIconWrapper';
import SelectBoxPrimary from '../../SelectBoxPrimary';
import StandardsDetail from '../../../containers/StandardsDetail/SAStandardsDetail';
import { colors } from '../../../common/pallette/colors';
import { getEvaluationCriteriaStandards, getEvaluationReportStandardDetails, getEvaluationReportStandards, getOffSiteStandardList } from '../../../api/evaluation';
import TableSkeleton from '../../TableSkeleton';
import { setEvalReportStandards, setOffSiteStandards } from '../../../redux/reducers/evaluation';
import { genUID } from '../../../common/StaticExports';

const EvalReportStandardsTable = () => {
  const dispatch = useDispatch();

  const currentCriteria = useSelector((state:any) => state.evaluation.selectedEvalReportCriteria);
  const currentClub = useSelector((state:any) => state.clubDetails);
  const standards = useSelector((state:any) => state.evaluation.evalReportStandards);

  //supporting contents
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('uid');
  const [detail, setDetail] = React.useState(Object);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    const fetchCriteriaStandards = async () => {
      const response = await getEvaluationReportStandards({clubId: currentClub.id, criteriaId: currentCriteria.id});
      return response;
    }
    currentClub?.id && currentCriteria?.id &&
    fetchCriteriaStandards().then((res) => {
      dispatch(setEvalReportStandards(res)); 
    });
  }, [currentClub, currentCriteria])
  
  let visibleRows = React.useMemo(
    () =>
      standards && standards.length > 0 &&
      sortData(standards, orderBy, order),
    [order, orderBy, standards],
  );

  const head = [
    {
      key: 'uid', displayName: 'id',
    },
    {
      key: 'name', displayName: 'standards',
    },
    {
      key: '', displayName: 'star',
    },
    {
      key: '', displayName: 'rating',
    },
    {
      key: '', displayName: '',
    },
  ]

  //table contents
    return(
        <React.Fragment>
            <Box sx={{ position: 'relative' }}>
              <StandardsDetail isOpen={isOpen} detail={detail} handleClose={() => setIsOpen(false)}/>
              <TableContainer sx={{ height: '40vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    {
                      visibleRows.length ?
                      <TableBody>
                        {
                          visibleRows.map((d: any, i: number) => (
                              <TableRow
                              className='hoverBG'
                              sx={{ cursor: 'pointer' }}
                              key={`standards-table-row-${i}`}
                              >
                              <TableCell className='fs12'>{genUID(d.uid)}</TableCell>
                              <TableCell className='fs12'
                              onClick={() => {
                                setDetail(d);
                                setIsOpen(!isOpen);
                              }} 
                              >{d.name}</TableCell>
                              <TableCell className='fs12'>
                                  {
                                      Array.from({length: d.starRating}, () => <FontAwesomeIcon icon={faStar} style={{color: colors.yellow.bright,}} />)
                                  }
                              </TableCell>
                              <TableCell className='fs12'>
                                  <SelectBoxPrimary label={''} report={'siteReview'} options={[0, 1, 2, 3]} isStatus={true} selected={d.status} />
                              </TableCell>
                              <TableCell className='fs12'><CommentsDocsActionsIconWrapper actions={d.noOfActions} comments={d.noOfComments} docs={d.noOfDocuments} /></TableCell>
                              </TableRow>
                          ))
                        }
                      </TableBody>
                      :
                      <TableBody>
                        <TableSkeleton rowCount={8} cellCount={5} />
                      </TableBody>
                    }
                </Table>
              </TableContainer>
            </Box>
        </React.Fragment>
    )
}

export default EvalReportStandardsTable;