import axios from "axios";
import { baseUrl } from "./config";
import { encodeBody, encodeQueryParams } from "./client";

export const getSelfAssessmentCriteria: any = (params: any) => {
    const path = `${baseUrl}/api/criteria/clubs/${params.clubId}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getSelfAssessmentCriteria', err)
    })
}

export const getOffSiteCriteria: any = (params: any) => {
    const path = `${baseUrl}/api/offSiteReviews/clubs/${params.clubId}/criteria`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getOffSiteCriteria', err)
    })
}

export const getOffSiteEvaluation: any = (params:any) => {
  const path = `${baseUrl}/api/offSiteReviews/clubs/${params.clubId}/standards/${params.standardId}/evaluationByCriteriaId${encodeQueryParams(params)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data);
};

export const getOnSiteCriteria: any = (params: any) => {
    const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/sessions`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getOnSiteCriteria', err)
    })
}

export const getClubReviewsInfo: any = (params: any) => {
  const path = `${baseUrl}/api/clubs/${params.clubId}/reviews`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch(err => {
    console.error('error while fetching data in getClubReviewsInfo', err)
});
};

export const getEvaluationReportCriteria: any = (params: any) => {
    const path = `${baseUrl}/api/evaluationReport/clubs/${params.clubId}/criteria`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data).catch(err => {
        console.error('error while fetching data in getEvaluationReportCriteria', err)
    });
};

export const getEvaluationCriteriaStandards: any = (params: any) => {
    const path = `${baseUrl}/api/standards/criteria/${params.criteriaId}/clubs/${params.clubId}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getEvaluationCriteriaStandards', err)
    })
}

export const getStandrdsActions: any = (params: any) => {    
    const path = `${baseUrl}/api/standards/${params.standardId}/clubs/${params.clubId}/actions${encodeQueryParams(params)}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getStandrdsActions', err)
    })
}

export const getStandardsEvidences: any = (params: any) => {
    const path = `${baseUrl}/api/evidence/standards/${params.standardId}/clubs/${params.clubId}${encodeQueryParams(params)}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getStandardsEvidences', err)
    })
}

export const getStandardsSelfAssessmentComment: any = (params: any) => {
    const path = `${baseUrl}/api/offSiteReviews/clubs/${params.clubId}/standards/${params.standardId}/selfAssessmentComment`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getStandardsSelfAssessmentComment', err)
    })
}

export const getCriteriasSelfAssessmentComments: any = (params: any) => {
    const path = `${baseUrl}/api/offSiteReviews/clubs/${params.clubId}/standards/${params.standardId}/selfAssessmentCommentByCriteria${encodeQueryParams(params)}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data). catch(err => {
        console.error('error while fetching data in getCriteriasSelfAssessmentComments', err)
    })
}

export const saveOffSiteEvaluation: any = (params:any) => {
  const path = `${baseUrl}/api/offSiteReviews/clubs/${params.clubId}/standards/${params.standardId}/evaluation`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.evaluation),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};
export const getCriteriaGroups: any = (params:any) => {
  const path = `${baseUrl}/api/criteria/clubs/${params.clubId}/groups`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data). catch(err => {
    console.error('error while fetching data in getCriteriaGroups', err)
})
};

export const getOffSiteStandardList: any = (params:any) => {
    const path = `${baseUrl}/api/offSiteReviews/clubs/${params.clubId}/criteria/${params.criteriaId}/standards`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };

export const getEvaluationReportStandards: any = (params:any) => {
  const path = `${baseUrl}/api/evaluationReport/clubs/${params.clubId}/criteria/${params.criteriaId}/standards`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};
  
export const getOnSiteCriteriaStandards: any = (params:any) => {
    const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/sessions/${params.sessionId}/criteria`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getEvaluationReportStandardDetails: any = (params:any) => {
  const path = `${baseUrl}/api/evaluationReport/clubs/${params.clubId}/standards/${params.standardId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getEvaluationCommentsByCriteria: any = (params:any) => {
  const path = `${baseUrl}/api/evaluationReport/clubs/${params.clubId}/criteria/${params.criteriaId}/evaluationComments`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data);
};

export const saveEvaluationReportStandardDetails: any = (params:any) => {
  const path = `${baseUrl}/api/evaluationReport`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getStandardAndClubMetadata: any = (params:any) => {
  const path = `${baseUrl}/api/standards/${params.standardId}/actionStandard`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getOnSiteQuestions: any = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/sessions/${params.sessionId}/criteria/${params.criteriaId}/question`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const syncQuestions: any = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/sessions/${params.sessionId}/question`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.questions),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getOnSiteRoles = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/interviewees/roles`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const saveSessionDate = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/sessions/${params.sessionId}/date`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.sessionDate),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const updateSessionStartTime = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/sessions/${params.sessionId}/updateStartTime${encodeQueryParams({hours: params.hours, minutes: params.minutes})}`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const updateSessionDuration = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/sessions/${params.sessionId}/updateDuration`;
  return axios({
    method: 'POST',
    url: path,
    data: params.duration,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const syncInterviewees = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/sessions/${params.sessionId}/interviewees`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.interviewees),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getInterviewers = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/interviewers`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getInterviewTeamUsers = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/users${encodeQueryParams(params)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const syncInterviewers = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/${params.onSiteReviewId}/interviewers`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.user),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const deleteInterviewer = (id:number) => {
  const path = `${baseUrl}/api/onSiteReviews/interviewers/${id}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getOnSiteCriteriaComments = (params:any) => {
  const path = `${baseUrl}/api/criteria/clubs/${params.clubId}/onSiteCriteriaComments/${params.criteriaId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const updateOnSiteCriteriaComments = (params:any) => {
  const path = `${baseUrl}/api/criteria/updateOnSiteCriteriaComments`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const getCriteriaComments: any = (params:any) => {
  const path = `${baseUrl}/api/criteria/clubs/${params.clubId}/criteriaComments/${params.criteriaId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const saveCriteriaComments: any = (params:any) => {
  const path = `${baseUrl}/api/criteria/updateCriteriaComments/${params.id}`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export const fetchCriteriaSesions: any = (params:any) => {
  const path = `${baseUrl}/api/onSiteReviews/clubs/${params.clubId}/criteria/${params.criteriaId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data);
};