import { useSelector } from "react-redux";

const environments = {
    DEV: 'https://dev.test.jamp.football',
    TEST: 'https://test.jamp.football',
    STAGING: 'https://staging.jamp.football',
    PROD: 'https://jamp.football',
    OLD_TEST: 'https://test.circlesquare.dev',
    OLD_STAGING: 'https://jl.corvus.london',
    OLD_PROD: 'https://jleague.circlesquare.dev',
    DEPLOYMENT_ENV: 'https://localhost:5001',
}

export const baseUrl = environments.TEST;
