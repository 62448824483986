import axios, { AxiosResponse } from 'axios';
import { baseUrl } from './config';
import { encodeBody, encodeQueryParams } from './client';
import { QueryKey } from 'react-query';
import {
  AxiosArgs,
  ThenArgs,
  Club,
  ClubEvaluation,
  DesirePlan,
  PerformancePlan,
  ClubUser,
  ClubProject,
  Department,
  StarRating,
  Strategy,
  Identifier,
  PastStatusPeriod,
  SiteReviewStatuses,
} from './types';

// Get clubs endpoint
export type getClubsResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<Club[]>>>>;

export type getClubsParameters = {
  leagueId?: Identifier;
  starRating?: number;
  targetStarRating?: number;
};

export type getClubsType = (key: QueryKey, parameters: getClubsParameters) => getClubsResponse;

export const getClubs: getClubsType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs${encodeQueryParams(parameters)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// get Club's Desire Plan
export type getClubsDesirePlanResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<DesirePlan>>>>;

export type getClubsDesirePlanParameters = {
  clubId: Identifier;
};

export type getClubsDesirePlanType = (
  key: QueryKey,
  parameters: getClubsDesirePlanParameters,
) => getClubsDesirePlanResponse;

export const getClubsDesirePlan: getClubsDesirePlanType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/${parameters.clubId}/cycles/current`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// Get users by club

export type getUsersByClubResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<ClubUser[]>>>>;

export type getUsersByClubParameters = {
  id: number;
};

export type getUsersByClubType = (key: QueryKey, parameters: getUsersByClubParameters) => getUsersByClubResponse;

export const getUsersByClub = (params:any) => {
  const path = `${baseUrl}/api/clubs/${params.clubId}/users`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// get projects by club

export const getProjectsByClub: any = (params:any) => {
  const path = `${baseUrl}/api/clubs/projects${encodeQueryParams({
    clubId: params.clubId,
  })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data).catch((err) => console.error('Error in getProjectsByClub:', err));
};

// save Club's Desire Plan

export type saveClubsDesirePlanResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<DesirePlan>>>>;

export type saveClubsDesirePlanParameters = {
  starRating: StarRating;
};

export type saveClubsDesirePlanType = (parameters: saveClubsDesirePlanParameters) => saveClubsDesirePlanResponse;

export const saveClubsDesirePlan: saveClubsDesirePlanType = parameters => {
  const path = `${baseUrl}/api/clubs/cycles/current`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody({ starRating: parameters.starRating }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// Get club's performance plan summary

export type getClubsPerformancePlanResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<PerformancePlan>>>>;

export type getClubsPerformancePlanParameters = {
  clubId?: Identifier;
  departmentId?: Identifier;
  pastStatusPeriod?: PastStatusPeriod;
};

export type getClubsPerformancePlanType = (
  key: QueryKey,
  parameters: getClubsPerformancePlanParameters,
) => getClubsPerformancePlanResponse;

export const getClubsPerformancePlan: getClubsPerformancePlanType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/performance${encodeQueryParams(parameters)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// Get club with metrics by id

export type getClubResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<Club>>>>;

export type getClubParameters = {
  id: number;
};

export type getClubType = (key: QueryKey, parameters: getClubParameters) => getClubResponse;

export const getClub: getClubType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/${parameters.id}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// Get club evaluation info

export type getClubEvaluationResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<ClubEvaluation>>>>;

export type getClubEvaluationParameters = {
  clubId: Identifier;
};

export type getClubEvaluationType = (
  key: QueryKey,
  parameters: getClubEvaluationParameters,
) => getClubEvaluationResponse;

export const getClubEvaluationInfo: getClubEvaluationType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/${parameters.clubId}/evaluation`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// get club's departments

export const getClubDepartments = (params:any) => {
  const path = `${baseUrl}/api/clubs/departments${encodeQueryParams({ clubId: params.clubId })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// Get site review for club (On/Of-site)

export type getClubReviewsInfoResponse = Promise<
  AxiosArgs<
    ThenArgs<
      AxiosResponse<{
        onSiteReviewId: Identifier;
        onSite: SiteReviewStatuses;
        offSite: SiteReviewStatuses;
        evaluationReport: SiteReviewStatuses;
        futureProgress: number;
        offSiteReadonly: boolean;
        onSiteReadonly: boolean;
      }>
    >
  >
>;

// get club deadlines

export type getClubDeadlinesResponse = Promise<
  AxiosArgs<
    ThenArgs<
      AxiosResponse<{
        selfAssessment: string;
        evaluationReport: string;
        projectDueDates: string[];
        daysToSetTarget: number;
        daysToSelfAssessment: number;
        daysToEvaluationReport: number;
      }>
    >
  >
>;

export type getClubDeadlinesParameters = {
  clubId: Identifier;
};

export type getClubDeadlinesType = (
  key: QueryKey,
  parameters: getClubDeadlinesParameters,
) => getClubDeadlinesResponse;

export const getClubDeadlines: getClubDeadlinesType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/${parameters.clubId}/deadlines`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// get strategy overview

export type getStrategyOverviewResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<Strategy>>>>;

export type getStrategyOverviewParameters = {
  clubId?: Identifier;
  departmentId?: Identifier;
  pastStatusPeriod?: PastStatusPeriod;
};

export type getStrategyOverviewType = (
  key: QueryKey,
  parameters: getStrategyOverviewParameters,
) => getStrategyOverviewResponse;

export const getStrategyOverview: getStrategyOverviewType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/strategy${encodeQueryParams(parameters)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};