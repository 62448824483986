const extensionsAvailableForPreview = ['excel', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'pdf'];

const useFilePreview = () => {
  const onClick = (name:any, url:any) => {
    const extension = name.split('.').pop();
    if (extensionsAvailableForPreview.includes(extension)) {
      window.open(`/preview?src=${url}&extension=${extension}`);
    } else {
      window.open(url);
    }
  };

  return onClick;
};

export default useFilePreview;
