import * as React from 'react';
import { useDispatch } from "react-redux";
import '../styles.css';
import { TextField, InputAdornment, ThemeProvider, createTheme, Popper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { colors } from '../../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0px 14px !important'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '12px'
        }
      }
    },
  }
});

const CustomPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: '14px',
  },
});

export default function ActionSelectBox(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('');
  const { placeholder, selected, optionList, variant, key, manageActionSelect } = props;

  const manageChange = (event: any, value: any) => {
    if(value?.name && value.name.startsWith("create '")) {
      value.name = value.name.slice(8, -1);
    }
    setValue(value?.name || '');
    manageActionSelect(value);
  };

  React.useEffect(() => {
    if (selected !== '' && selected !== undefined) {
      setValue(selected);
    }
  }, [selected]);

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={1} sx={{ width: 300 }}>
        <Autocomplete
          freeSolo
          className='fs12'
          onChange={manageChange}
          options={optionList}
          getOptionLabel={(option) => {
            // Handle the string type and custom object type
            if (typeof option === 'string') {
              return option;
            }
            if (option && option.name) {
              return option.name;
            }
            return '';
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter((option: any) =>
              option.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );

            // If input value doesn't match any option, add the create option
            if (params.inputValue !== '') {
              filtered.push({
                name: `create '${params.inputValue}'`,
              });
            }

            return filtered;
          }}
          sx={{ bgcolor: colors.grey.light, border: 0 }}
          PopperComponent={CustomPopper}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ border: 0 }}
              label={''}
              value={value}
              placeholder={placeholder ? placeholder : "Search"}
              className='fs12'
              variant={variant ? variant : 'outlined'}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </Stack>
    </ThemeProvider>
  );
}
