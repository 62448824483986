import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import OnSiteCriteriaTable from "../../components/CriteriaTable/OnSiteCriteriaTable";
import DetailedCriteria from "../DetailedCriteria";
import { useDispatch, useSelector } from "react-redux";
import ContentOverview from "../../components/ContentOverview";
import { setOnSiteDateGroups } from "../../redux/reducers/evaluation";
import { detectConflicts, formatDate } from "../../common/StaticExports";

const OnSiteWrapper = (props:any) => {
    const { isLoading } = props;
    const onSiteCriteria = useSelector((state:any) => state.evaluation.onSiteCriteria);
    const selectedOnSiteSession = useSelector((state:any) => state.evaluation.selectedOnSiteSession);
    const onSiteDateGroups = useSelector((state:any) => state.evaluation.onSiteDateGroups);
    const dispatch = useDispatch();

    useEffect(() => {
        var interviewDateGroups:any = []
        onSiteCriteria.forEach((interview:any) => {
           if(interview.date != undefined && interview.date != null && !interviewDateGroups.includes(formatDate(interview.date))) {
            interviewDateGroups.push(formatDate(interview.date));
           } 
        });
        const sortedDatesAsc = interviewDateGroups.sort((a:any, b:any) => new Date(a).getTime() - new Date(b).getTime());
        if (onSiteCriteria.some((d:any) => (d.date == null || d.date == undefined))) {
            sortedDatesAsc.push('Not Scheduled');
        }
        dispatch(setOnSiteDateGroups(sortedDatesAsc));
    }, [onSiteCriteria]);

    return(
        <Box sx={{ height: '100%', overflowY: Object.keys(selectedOnSiteSession).length ?  'hidden' : 'auto' }}>
            {
                Object.keys(selectedOnSiteSession).length ?
                <ContentOverview data={(selectedOnSiteSession.date != null && selectedOnSiteSession.date != undefined) ? formatDate(selectedOnSiteSession.date) : 'Not Scheduled'} 
                from={'ON_SITE'} criterias={onSiteCriteria.filter((x:any) => x.criteriaGroupId == selectedOnSiteSession.criteriaGroupId)}/> : <></>
            }
            {
                !isLoading && onSiteCriteria?.length && onSiteDateGroups?.length ?
                onSiteDateGroups.map((group:any, i:any) => {
                    var filteredData = onSiteCriteria.filter((x:any) => (group != 'Not Scheduled') ? formatDate(x.date) == formatDate(group) : (x.date == null || x.date == undefined));
                    const conflictIndices = detectConflicts(filteredData);
                    var calcHeight = () => {
                        // var pxCalc = 27/criteriaGroups.length;
                        // return `calc(${(filteredData.length/clubCriterias.length)*100}% - 53px)`;
                        //divide the height accordingly
                        // return `calc(${(100/criteriaGroups.length)}% - 27px)`;
                        return 'fit-content'
                    }
                    return (
                        <>
                            {
                                (Object.keys(selectedOnSiteSession).length > 0 && i < 1) ||
                                (Object.keys(selectedOnSiteSession).length == 0) &&
                                <ContentOverview data={group} from={'ON_SITE'} criterias={filteredData}/>
                            }
                            <Box sx={Object.keys(selectedOnSiteSession).length ? 
                                    {display: i<1 ? "flex" : "none", mt: 1, height: 'calc(100% - 27px)', mb: '20px'} : 
                                    { mt: 1, height: calcHeight(), mb: '20px' }}>
                                        <OnSiteCriteriaTable
                                        data={
                                            (Object.keys(selectedOnSiteSession).length) > 0 ?
                                            onSiteCriteria :
                                            filteredData.map((item:any, idx:any) => ({
                                                ...item,
                                                isInConflict: conflictIndices.includes(idx), // Flag each row if it is in conflict
                                                }))}
                                        criteria={Object.keys(selectedOnSiteSession).length === 0}
                                        isLoading={isLoading}
                                        selectedCriteria={selectedOnSiteSession}/>
                                        {(Object.keys(selectedOnSiteSession).length > 0) && <DetailedCriteria criteria={selectedOnSiteSession} module={"ON_SITE"}/>}
                            </Box>
                        </>
                    )
                }) :
                <Box sx={{ mt: 1, height: 'calc(100% - 27px)', mb: '20px' }}>
                    <OnSiteCriteriaTable data={[]}
                    criteria={Object.keys(selectedOnSiteSession).length === 0}
                    isLoading={isLoading}
                    selectedCriteria={selectedOnSiteSession}/>
                </Box>
            }
        </Box>
    );
}

export default OnSiteWrapper;