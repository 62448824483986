import React, { useState } from "react";
import TableSingleSelectBox from "../SearchSelectBox/TableSingleSelectBox";
import { useSelector } from "react-redux";

const OnSiteRolesSelectBox = (props:any) => {
    const { value, manageSelect } = props;
    const userRoles = useSelector((state:any) => state.common.userRoles);
    const [selectedOption, setSelectedOption] = React.useState(value || null);
    const [inputValue, setInputValue] = React.useState(value ? (value?.role || '') : '');
    const [optionList, setOptionList] = useState(userRoles || []);

    const manageHandleChange = (_event: any, newValue: any) => {
      let tempObj:any = Object;
      
      if (newValue == null || newValue == undefined) {
        tempObj = {...selectedOption, role: null};
      } else {
        tempObj = {...selectedOption, role: newValue.value};
      }

      setSelectedOption(tempObj);
      manageSelect(tempObj);
    };
  
    const manageHandleInputChange = (_event: any, newInputValue: string) => {
      (newInputValue != undefined || newInputValue != 'undefined') &&
      setInputValue(newInputValue);
      setOptionList(userRoles);
    };

    return (
        <TableSingleSelectBox
        options={optionList.map((item:any) => {return {...item, role: item.value}})}
        keyLabel={'role'}
        handleChange={manageHandleChange}
        variant={'standard'}
        selectedValue={selectedOption}
        handleInputChange={manageHandleInputChange}
        inputValue={inputValue}
        />
    )
}

export default OnSiteRolesSelectBox