import React, { useState } from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import { AvatarAndName } from '../AvatarAndName';
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import logo from '../../assets/icons/JLogo.svg';
import ThreeDotMenu from '../ThreeDotMenu';
import SelectBoxPrimary from '../SelectBoxPrimary';
import SelectBox from '../SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faLinkSlash, faEyeSlash, faBicycle, faTrashCan, faTrash } from '@fortawesome/free-solid-svg-icons';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import DateSelector from '../DateSelector';
import TableSkeleton from '../TableSkeleton';
import { SwitchButton } from '../SwitchButton';
import { format } from 'date-fns';
import { DATE_FORMAT, formatDate, genUID } from '../../common/StaticExports';
import { useDispatch, useSelector } from 'react-redux';
import ActionSelectBox from '../SearchSelectBox/ActionSelectBox';
import StaffSelectBox from '../SearchSelectBox/StaffSelectBox';
import { Description } from '@mui/icons-material';
import { deleteAction, updateActions } from '../../api/action';
import { setActionsList, setSaActions } from '../../redux/reducers/evaluation';
import NestedThreeDotMenu from '../NestedThreeDotMenu';
import { icon } from '@fortawesome/fontawesome-svg-core';
import AutoComplete from '../AutoComplete';
import { unassignAction } from '../../api/standards';
import { baseUrl } from '../../api/config';
import { updateActionsLicence } from '../../api/licence';
import { setLicenceStandardsActions } from '../../redux/reducers/licence';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 16px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const ActionsTable = (props: any) => {
    const { data, head, isAction, addAction, handleClick, isLoading, isAddLoading, currentStandard, bg, module } = props;

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [openActionDetail, setOpenActionDetail] = React.useState(false);
  const [detail, setDetail] = React.useState(Object);
  const [isOpen, setIsOpen] = React.useState(false);
  const actionList = useSelector((state:any) => state.evaluation.actionsList);
  const staffList = useSelector((state:any) => state.evaluation.staffList);
  const projectList = useSelector((state:any) => state.evaluation.projectList);
  const departmentList = useSelector((state:any) => state.evaluation.departmentList);
  const standardsList = useSelector((state:any) => state.evaluation.saStandards);
  // const newAction = useSelector((state:any) => state.evaluation.newSaAction);
  const licenceStandardsActions = useSelector((state: any) => state.licence.standardsActions);
  const isAdmin = useSelector((state:any) => state.common.isAdmin);
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const initialAction = {
    name: '',
    description: '',
    dueDate: null,
    ragb: 4,
    ownerName: '',
    ownerUserId: null,
    isCreatedByJL: false,
    isArchived: false,
    isAlreadyComplete: false,
    projectName: '',
    projectId: null,
    departmentName: '',
    departmentId: null,
    standardHistory: [],
  };
  const [newAction, setNewAction]:any = useState(initialAction);
  const [newlyAddedAction, setNewlyAddedAction]:any = useState(initialAction);
  // const [selectedStandards]
  const dispatch = useDispatch();
  const threeDotMenuOptions = isAction ? 
  [
    {label: 'Archive Action', icon: faEyeSlash, isDisabled: false},
    {label: 'Delete Action', icon: faTrash, isDisabled: false},
  ] :
  [
    {label: 'Unlink Action', icon: faLinkSlash, isDisabled: false},
    {label: 'Archive Action', icon: faEyeSlash, isDisabled: false},
    {label: 'Delete Action', icon: faTrash, isDisabled: false},
  ];
  const [actionData, setActionData] = useState([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows:any = React.useMemo(
    () => 
      data?.length > 0 ?
      stableSort(data, getComparator(order, orderBy)) : [],
    [order, orderBy, data],
  );

  const manageCreateClick = () => {
    // if(selectedStandards.length > 0) {
    //   setNewAction({...newAction, standardHistory: selectedStandards});
    // }
    
    const createAction = async (data:any) => {
      const response = await updateActions(data);
      return response;
    }

    if(newAction.name != '' && newAction.name != undefined && clubDetails?.id && (currentStandard?.id || isAction)) {
      if(isAction) {
        if(!(newAction && Object.keys(newAction).includes('id'))) {
          var actionArr:any = [];
          var p:any = {};
          switch(module) {
            case 'LICENCE':
              const tempNewAction = {
                ...newAction,
                isCreatedByJL: true,
                licenceJLActionStatus: false
              }
              actionArr = [...licenceStandardsActions, tempNewAction];
              p = {
                clubId: clubDetails.id,
                actions: actionArr,
                standardId: currentStandard.id,
              }
              const response = updateActionsLicence(p);
              break;
            default:
              actionArr = data.filter((a:any) => {return a.standardHistory.some((sh:any) => sh.standardId == newAction.standardHistory[0].id)});
              actionArr = [...actionArr, newAction];
              p = {
                clubId: clubDetails.id,
                standardId: newAction.standardHistory[0].id,
                actions: actionArr
              };
              createAction(p).then((res) => {
                newAction.standardHistory.forEach((na:any, index:number) => {
                  if(index > 0) {
                    var arr01 = data.filter((a:any) => {return a.standardHistory.some((sh:any) => sh.standardId == na.id)});
                    arr01 = [...arr01, res[0]];
                    createAction({...p, standardId: na.id, actions: arr01}).then((ress) => {
                      if(index == newAction.standardHistory.length - 1) {
                        handleClick();
                      }
                    })
                  }
                })
                if(newAction.standardHistory.length == 1) {
                  handleClick();
                }
              });
          }
        } else {
          newAction.standardHistory.forEach((std:any, index:number) => {
            var tempArr = data.filter((a:any) => {return a.standardHistory.some((sh:any) => sh.standardId == std.id)});
            tempArr = [...tempArr, newAction];
            const parameters = {
              clubId: clubDetails.id,
              standardId: std.id,
              actions: tempArr
            }
            createAction(parameters).then((res) => {
              index == newAction.standardHistory.length-1 &&
              handleClick();
            });
          }) 
        }
      } else {
        var actionArr:any = [];
        var parameters:any = {};
        switch(module) {
          case 'LICENCE':
            const tempNewAction = {
              ...newAction,
              isCreatedByJL: true,
              licenceJLActionStatus: false
            }
            actionArr = [...licenceStandardsActions, tempNewAction];
            parameters = {
              clubId: clubDetails.id,
              actions: actionArr,
              standardId: currentStandard.id,
            }
            const response = updateActionsLicence(parameters);
            dispatch(setLicenceStandardsActions(response));
            handleClick();
            break;
          default:
            actionArr = [...data, newAction];
            parameters = {
              clubId: clubDetails.id,
              standardId: currentStandard.id,
              actions: actionArr
            }
            createAction(parameters).then((res) => {
              dispatch(setSaActions(res));
              handleClick();
            });
        }
      }
    }
  }

  const manageSwitchChange = (row:any) => {
    let tempArr = data.map((d:any) => {
      if(d.id == row.id) {
        return {...d, isAlreadyComplete: !row.isAlreadyComplete};
      } return d;
    });
    dispatch(setSaActions(tempArr));
  }

  const manageStandardSelect = (stand:any) => {
    const index = newAction.standardHistory.findIndex((obj:any) => obj.id == stand.id);
    let tempArr:any = [...newAction.standardHistory];
    if (index !== -1) {
        tempArr.splice(index, 1);
    } else {
      tempArr.push(stand);
    }
    setNewAction({...newAction, standardHistory: tempArr});
  }

  const manageLicenceActionDelete = (action:any) => {
    const index = licenceStandardsActions.findIndex((obj:any) => (obj.id == action.id || !("id" in action)));
    let tempArr:any = licenceStandardsActions;
    if (index !== -1) {
      tempArr = licenceStandardsActions.filter((d:any, i:any) => i != index);
    }
    const parameters = {
      clubId: clubDetails.id,
      actions: tempArr,
      standardId: currentStandard.id,
    }
    const response = updateActionsLicence(parameters);
    dispatch(setLicenceStandardsActions(response));
  }

  const handleThreedotMenuClick = (selctedAction:any, action:any) => {
    switch(selctedAction) {
      case 'Delete Action': 
        switch(module) {
          case 'LICENCE':
            manageLicenceActionDelete(action);
            break;
          default:
            deleteAction({actionId: action.id});
        }
        handleClick();
        break;
      case 'Archive Action': 
        deleteAction({actionId: action.id});
        handleClick();
        break;
      case 'Unlink Action':
        unassignAction({actionId: action.id, standardId: currentStandard.id});
        handleClick();
        break;
    }
  }
  
  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <ActionDetailPopper isOpen={openActionDetail} handleIsOpen={() => {setOpenActionDetail(false)}} data={actionData} />
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    bg={bg}
                    />
                    <TableBody>
                      {
                        !isAddLoading && addAction ? 
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`actions-table-row-create`}
                        >
                            <TableCell className='fs12'>
                                {
                                    isAdmin && <img className='smallLogoForTables' style={{ marginTop: '0px' }} src={logo} alt="JL Logo" />
                                }
                            </TableCell>
                            <TableCell className='fs12'>
                                {/* <StatusIndicatorSmall reportType={''} status={d.status} /> */}
                                <SelectBoxPrimary label={''} options={[0, 1, 2, 3, 4]} isStatus={true} selected={newAction.ragb ? newAction.ragb : 0} report={'rabg'} />
                            </TableCell>
                            <TableCell className='fs12'>
                              <ActionSelectBox optionList={actionList} variant={'standard'} selected={newAction.name} manageActionSelect={(data:any) => {
                                if (data && Object.keys(data).includes('id')) {
                                  setNewAction({...data, standardHistory: []});
                                } else {
                                  setNewAction({...newAction, ...data, standardHistory: []});
                                }
                                }}/>
                            </TableCell>
                            <TableCell className='fs12'>
                              <TextField variant='standard' value={newAction.description} onChange={(e) => {setNewAction({...newAction, description: e.target.value, standardHistory: []})}} />
                            </TableCell>
                            {isAction && <TableCell className='fs12' width={'220px'}>
                                          <AutoComplete 
                                          options={standardsList}
                                          selectedValues={newAction.standardHistory}
                                          key={'uid'}
                                          handleMenuClick={(d:any) => {manageStandardSelect(d)}} 
                                          clickReset={() => {setNewAction({...newAction, standardHistory: []})}}
                                          isMultiple={true}
                                          variant={'standard'}
                                          />
                                        </TableCell>}
                            <TableCell className='fs12'>
                              <Box sx={{ width: '120px' }}>
                                <DateSelector variant='standard' dateValue={newAction.dueDate ? formatDate(newAction.dueDate) : null} 
                                manageChange={(e:any) => {setNewAction({...newAction, dueDate: e ? e : null, standardHistory: []})}}/>
                              </Box>
                            </TableCell>
                            <TableCell className='fs12'>
                              <Box sx={{ width: '120px' }}>
                                <StaffSelectBox options={staffList} variant={'standard'} selectedValue={newAction.ownerUserId}
                                manageSelect={(data:any) => {
                                  setNewAction({...newAction, ownerName: data.displayName, ownerUserId: data.id, standardHistory: []})
                                }}/>
                              </Box>
                            </TableCell>
                            {isAction && <TableCell className='fs12'>
                              <StaffSelectBox options={departmentList} variant={'standard'} selectedValue={newAction.projectId}
                                  manageSelect={(e:any) => {setActionData({...newAction, departmentName: e.name, departmentId: e.id, standardHistory: []})}} />
                              </TableCell>}
                            {isAction && <TableCell className='fs12'>
                              <StaffSelectBox options={projectList} variant={'standard'} selectedValue={newAction.projectId}
                                  manageSelect={(e:any) => {setActionData({...newAction, projectName: e.displayName, projectId: e.id, standardHistory: []})}} />
                            </TableCell>}
                            <TableCell className='fs12'>
                                {/* <Checkbox sx={{ padding: '0px' }} checked={true}/> */}
                                <SwitchButton label={''} isOn={newAction.isAlreadyComplete}
                                 handleOnClick={() => {setNewAction({...newAction, isAlreadyComplete: !newAction.isAlreadyComplete, standardHistory: []})}} />
                            </TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Delete' arrow placement='top'><FontAwesomeIcon onClick={() => {setNewAction(initialAction); handleClick();}} className={'icon mr10'} icon={faXmark}/></Tooltip>
                                <Tooltip title='Create' arrow placement='top'><FontAwesomeIcon onClick={() => {manageCreateClick()}} className={'icon mr10'} icon={faCheck} /></Tooltip>
                              </Box>
                            </TableCell>
                        </TableRow>:
                        (
                          isAddLoading &&
                          <TableSkeleton rowCount={1} cellCount={9} />
                        )
                      }
                    {
                      !isLoading && visibleRows?.length ?
                      visibleRows.map((d: any, i: number) => 
                        (
                          <TableRow
                          sx={{ cursor: 'pointer' }}
                          key={`actions-table-row-${i}`}
                          >
                              <TableCell className='fs12'>
                                  {
                                      d.isCreatedByJL && <img className='smallLogoForTables' style={{ marginTop: '0px' }} src={logo} alt="JL Logo" />
                                  }
                              </TableCell>
                              <TableCell className='fs12'>
                                  <StatusIndicatorSmall reportType={'rabg'} status={d.ragb} />
                              </TableCell>
                              <TableCell className='fs12' onClick={() => {setActionData(d); setOpenActionDetail(true);}}>{d.name?.length > 40 ? d.name.slice(0, 40) + '...' : d.name}</TableCell>
                              <TableCell className='fs12'>
                                {
                                  d.description?.length > 60 ? d.description.slice(0, 60) + '...' : 
                                  (d.description != undefined && d.description != null) ? d.description : ''
                                }
                                </TableCell>
                              {isAction && <TableCell className='fs12'>
                                {
                                  d.standardHistory?.length > 0 ?
                                  (d.standardHistory?.length == 1 ?
                                  <Chip className='chipStyle' label={genUID(d.standardHistory[0].uid)} color="primary" /> : 
                                  <Box>
                                    <Chip className='chipStyle' label={genUID(d.standardHistory[0].uid)} color="primary" />
                                    <Tooltip title={d.standardHistory.map((obj:any) => genUID(obj.uid)).join(', ')} arrow placement='top'>
                                      <Chip className='chipStyle' sx={{ ml: '0px !important' }} label={'+' + (d.standardHistory.length - 1)} color="primary" />
                                    </Tooltip>
                                  </Box>
                                  ) : ''
                                }
                                </TableCell>}
                              <TableCell className='fs12'>{d.dueDate ? formatDate(d.dueDate) : '-'}</TableCell>
                              <TableCell className='fs12'>
                                  <AvatarAndName avatarName={d.ownerName} avatarImage={d.ownerAvatarPath ? `${baseUrl}/Content${d.ownerAvatarPath}` : ''}/>
                              </TableCell>
                              {isAction && <TableCell className='fs12'>{d.departmentName}</TableCell>}
                              {isAction && <TableCell className='fs12'>{d.projectName}</TableCell>}
                              <TableCell className='fs12'>
                                  {/* <Checkbox sx={{ padding: '0px' }} checked={d.isAlreadyComplete}/> */}
                                  <SwitchButton label={''} isOn={d.isAlreadyComplete} handleOnClick={() => {manageSwitchChange(d)}} />
                              </TableCell>
                              <TableCell sx={{ width: '1px' }}>
                                {
                                  !isAdmin &&
                                <NestedThreeDotMenu options={threeDotMenuOptions} manageClick={(e:any) => {handleThreedotMenuClick(e, d)}} />
                                }
                              </TableCell>
                          </TableRow>
                      )) :
                      (
                        !isLoading && (visibleRows?.length == 0 || visibleRows == undefined) ?
                        <TableRow>
                          <TableCell colSpan={isAction ? 10 : 8} className='fs12'>No Action Found</TableCell>
                        </TableRow>:
                        <TableSkeleton rowCount={isAction ? 6 : 3} cellCount={isAction ? 10 : 8} />
                      )
                  }
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default ActionsTable;