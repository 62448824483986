import { Avatar, Box, createTheme, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import './styles.css';
import theme from "../../theme";

const ChatDataContainer = (props:any) => {
    const { data } = props;
    const theme = createTheme({
        components: {
            MuiTypography: {
              styleOverrides: {
                root: {
                    fontSize: '13px',
                }
              }
            },
        }
    })
    const myId = 10;
    return (
        <ThemeProvider theme={theme}>
            <Box className={ data.userId == myId ? 'chatDataContainer chatDataContainerOwner' : 'chatDataContainer'}>
                <Box className={data.userId == myId ? 'chatDataHeader chatDataHeaderOwner' : 'chatDataHeader'}>
                    <Avatar></Avatar>
                    <Box className={data.userId == myId ? 'chatNameDetail chatNameDetailOwner' : 'chatNameDetail'}>
                        <Typography sx={{ fontWeight: 800 }}>{data.username}</Typography>
                        <Typography>{data.time? 'now' : 'Yesterday 16:12'}</Typography>
                    </Box>
                </Box>
                <Box className={data.userId == myId ? 'chatTextContainer chatTextContainerOwner' : 'chatTextContainer'}>
                    <Typography>{data.message}</Typography>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default ChatDataContainer;