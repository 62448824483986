import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import './styles.css';
import { TextField, InputAdornment, ThemeProvider, createTheme, Popper, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { colors } from '../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0px 14px !important'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          width: '100% !important'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '12px'
        }
      }
    },
  }
});

const CustomPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: '14px',
  },
});

export default function SearchSelectBox(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { placeholder, selectedValue, options, variant, key, manageSelect, label } = props;
  const [optionList, setOptionList] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState(null);

  React.useEffect(() => {
    if (options !== undefined) {
      setOptionList(options);
    }
  }, [options]);

  React.useEffect(() => {
    if(options != undefined) {
      const selectedObj = options.find((d: any) => d.id === selectedValue);
      if (selectedObj === undefined) {
        setSelectedOption(null);
      } else {
        setSelectedOption(selectedObj);
        setInputValue(selectedObj.name);
      }
    }
  }, [selectedValue, options]);

  const handleChange = (_event: any, newValue: any) => {
    setSelectedOption(newValue);
    setInputValue(newValue ? newValue.name : '');
    manageSelect(newValue);
  };

  const handleInputChange = (_event: any, newInputValue: any) => {
    setInputValue(newInputValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={1} sx={{ '& > :not(style) ~ :not(style)': { marginTop: '0px' } }}>
        { label && label != '' && <InputLabel className='fs12' sx={{ textAlign: 'left' }}>{label}</InputLabel>}
        <Autocomplete
          freeSolo
          className='fs12'
          value={selectedOption}
          inputValue={inputValue}
          onChange={handleChange}
          onKeyDown={(e:any) => {console.log(e.key)}}
          onInputChange={handleInputChange}
          options={optionList}
          getOptionLabel={(option: any) => option.name || ''}
          sx={{ bgcolor: colors.grey.light, border: 0 }}
          PopperComponent={CustomPopper}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ border: 0 }}
              placeholder={placeholder ? placeholder : "Search"}
              className='fs12'
              variant={variant ? variant : 'outlined'}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </Stack>
    </ThemeProvider>
  );
}
