import axios from "axios";
import { baseUrl } from "./config";
import { encodeBody, encodeQueryParams } from "./client";

export const getClubLicence: any = (params:any) => {
    const path = `/api/clubs/${params.id}/licence-cycles/current`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },
    })
    .then(response => response.data.data)
    .catch(err => {
        console.error('error while fetching data in getClubLicence', err);
    })
};

export const getLicenceCriteria: any = (params: any) => {
    const path = `${baseUrl}/api/licenceReviews/clubs/${params.clubId}/criteria`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceCriteria', err)
    })
}

export const getLicenceCriteriaStandards: any = (params: any) => {
    const path = `${baseUrl}/api/licenceReviews/clubs/${params.clubId}/criteria/${params.criteriaId}/standards`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceCriteriaStandards', err)
    })
}

export const getLicenceStandardsMetadata: any = (params: any) => {
    const path = `${baseUrl}/api/licence/standards/${params.standardsId}/actionStandard`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceStandardsMetadata', err)
    })
}

export const getLicenceStandardsAction: any = (params: any) => {
    const path = `${baseUrl}/api/licence/standards/${params.standardId}/clubs/${params.clubId}/actions${encodeQueryParams(params)}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceStandardsAction', err)
    })
}

export const getLicenceStandardsEvidences: any = (params: any) => {
    const path = `${baseUrl}/api/evidence/licence/standards/${params.standardId}/clubs/${params.clubId}${encodeQueryParams(params)}`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceStandardsEvidences', err)
    })
}

export const getLicenceStandardsSAComments: any = (params: any) => {
    const path = `${baseUrl}/api/licenceReviews/clubs/${params.clubId}/standards/${params.standardsId}/selfAssessmentComment`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceStandardsSAComments', err)
    })
}

export const getLicenceStandardsEvaluation: any = (params: any) => {
    const path = `${baseUrl}/api/licenceReviews/clubs/${params.clubId}/standards/${params.standardId}/evaluation`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getLicenceStandardsEvaluation', err)
    })
}

export const getStandardListByClubLicence: any = (params:any) => {
    const path = `${baseUrl}/api/licence/standards/clubs/${params.clubId}${encodeQueryParams({
      criterionId: params.criterionId,
    })}`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data).catch(err => {
        console.error('error while fetching data in getStandardListByClubLicence', err)
    });
  };
  
export const getLicenceCriterias: any = (params:any) => {
    return axios({
      method: 'GET',
      url: `${baseUrl}/api/licence/criteria/clubs/${params.clubId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };

export const updateActionsLicence: any = (params:any) => {
  const path = `${baseUrl}/api/actions/clubs/${params.clubId}/licence/standards/${params.standardId}`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params.actions),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};