import React, { useState } from 'react';
import './styles.css';
import { Box, InputLabel, TextField, ThemeProvider, createTheme, Button, Typography } from '@mui/material';
import CustomTextField from '../../components/CustomTextField';
import YenTextField from '../../components/YenTextField';
import CustomCurrencyTextField from '../../components/CustomCurrencyTextField';
import DateSelector from '../../components/DateSelector';
import SelectBox from '../../components/SelectBox';
import MultiSelectPrimary from '../../components/MultiSelectPrimary';
import { colors } from '../../common/pallette/colors';
import HistoryTable from '../../components/HistoryTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

const theme = createTheme({
    components: {
        MuiStack: {
            styleOverrides: {
                root: {
                    paddingTop: '0px !important'
                }
            }
        }
    }
})

const competitionHead = [
    {
      key: '', displayName: 'Club Name',
    },
    {
        key: '', displayName: 'From year',
    },
    {
        key: '', displayName: 'To year',
    },
    {
        key: '', displayName: '',
    },
  ]

const coachingHead = [
{
    key: '', displayName: 'Club Name',
},
{
    key: '', displayName: 'From year',
},
{
    key: '', displayName: 'To year',
},
{
    key: '', displayName: '',
},
]

const AddStaffForm = (props: any) => {
    const {onCancelClick} = props;

    const [addCompetionCareer, setAddCompetitionCareer] = useState(false);
    const [addCoachingCareer, setAddCoachingCareer] = useState(false);

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box className='mb20'>
                    <Typography className='standardsHeading uppercase'>New Staff Details</Typography>
                    <Box className={'addStaffForm'}>
                        <CustomTextField label={'Kanji Name'} />
                        <CustomTextField label={'Katakana Name'} />
                        <CustomTextField label={'English Name'} />
                        <DateSelector label={'Date of Birth'}/>
                        <CustomTextField label={'JFA ID'} />
                        <CustomTextField label={'Instructor Registration Number'} />
                        <SelectBox label={'Employment Status'} options={['Full-Time', 'Professional contract', 'Part time', 'Student', 'Volunteer']} border={'solid'} />
                        <MultiSelectPrimary label={'Department'}/>
                        <DateSelector label={'Licence Start Date'}/>
                        <DateSelector label={'Licence End Date'}/>
                        <MultiSelectPrimary label={'Registered Position'}/>
                        <CustomTextField label={'Job Title'} />
                        <MultiSelectPrimary label={'Leadership Role(Optional)'}/>
                        <CustomCurrencyTextField label={'Annual Salary(¥)'}/>
                        <MultiSelectPrimary label={'Qualifications'}/>
                    </Box>
                    <Box className='mb20'>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography className='standardsHeading uppercase'>Competition Career</Typography>
                            <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addCompetionCareer} variant='contained' size='small' onClick={() => {setAddCompetitionCareer(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                                {'Add another club'}
                            </Button>
                        </Box>
                        <Box>
                            <HistoryTable head={competitionHead} addData={addCompetionCareer} handleClick={() => setAddCompetitionCareer(false)}/>
                        </Box>
                    </Box>
                    <Box className='mb20'>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography className='standardsHeading uppercase'>Coaching Career</Typography>
                            <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addCoachingCareer} variant='contained' size='small' onClick={() => {setAddCoachingCareer(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                                {'Add another organization'}
                            </Button>
                        </Box>
                        <Box>
                            <HistoryTable head={coachingHead} addData={addCoachingCareer} handleClick={() => setAddCoachingCareer(false)}/>
                        </Box>
                    </Box>
                    <Box className='mb20'>
                        <JoyCssVarsProvider>
                            <Textarea sx={{ borderRadius: '6px', mt: 0, width: '100%' }} onChange={(e) => {}} placeholder='Main duties at the club are...' minRows={2} maxRows={2} value={''}/>
                        </JoyCssVarsProvider>
                    </Box>
                    <Box sx={{ mt: 'auto', display: 'flex', ml: 'auto' }}>
                        <Button className='smallButton closeCancelButtonStyles' variant='contained' sx={{ mr: 2 }} disableElevation disableRipple onClick={() => onCancelClick()}>Cancel</Button>
                        <Button className='smallButton' variant='contained' disableElevation disableRipple>Add</Button>
                    </Box>
                </Box>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default AddStaffForm;