import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from '../../../api/auth';
import { toast } from 'react-toastify';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Alert = styled.div<{ isSuccess: boolean }>`
  padding: 10px;
  width: 400px;
  border-radius: 6px;
  margin-bottom: 20px;
  background-color: ${({ isSuccess }) => (isSuccess ? '#a5d7a7' : '#fad1df')};
  color: ${({ isSuccess }) => (isSuccess ? '#2d6930' : '#a31545')};
  border: 1px solid ${({ isSuccess }) => (isSuccess ? '#2d6930' : '#a31545')};
`;

const PasswordResetPage = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [qEmail, setQEmail]:any = useState('email');
  const [qToken, setQToken]:any = useState('token');
  const [qLang, setQLang]:any = useState('lang');
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  // const [reset] = useMutation(resetPassword, {
  //   onSuccess: () => {
  //     setSuccess(true);
  //   },
  //   onError: () => {
  //     setError(true);
  //   },
  // });

  const reset = async (params:any) => {
    try {
      const response = await resetPassword(params);
      return response;
    } catch (error) {
      setError(true);
      console.error('Error in password reset');
    }
  }

  useEffect(() => {
    setQEmail(undefined);
    setQToken(undefined);
    setQLang(undefined);

    if ((qEmail && qToken)) {
      reset({
        email: qEmail,
        token: qToken,
      }).then(() => {
        setSuccess(true);
        toast.success('Reset Success')
      });
    }

    // if (qLang) {
    //   i18n.changeLanguage(qLang);
    // }
  }, []);

  return (
    <Div>
      {(success || error) && (
        <>
          <Alert isSuccess={success}>{success ? t('PASSWORD_HAS_BEEN_CHANGED') : t('SOMETHING_WENT_WRONG')}</Alert>
          <LoadingButton onClick={() => navigate('/login')}>{t('BACK_TO_LOG_IN')}</LoadingButton>
        </>
      )}
    </Div>
  );
};

export default PasswordResetPage;

