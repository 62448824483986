import axios from "axios";
import { encodeQueryParams } from "./client";
import { baseUrl } from "./config";

export const getCompanyDetails = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/1/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getContactDetails = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/2/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getStaffDetails = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/5/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getFacilitiesDetails = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/6/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getTeamDetails = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/7/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getProfitandLoss = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/3/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };

  
export const getSupportFund = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/4/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };
  
export const getPlayersDetails = (params:any) => {
    return axios({
      url: `${baseUrl}/api/datacapture/8/club/${params.clubId}${encodeQueryParams({
        isPast: params.isPast,
      })}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data);
  };