import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import '../styles.css';
//internal and external components import
import { ClickAwayListener, Collapse, IconButton, Table, Tooltip, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import StatusIndicatorSmall from '../../StatusIndicatorSmall';
import ProgressBar from '../../ProgressBar';
import { stableSort, descendingComparator, getComparator, sortData } from '../../../common/SortSupport';
import { EnhancedTableHead } from '../../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faCircleInfo, faMaximize, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../CustomTooltip';
import { colors } from '../../../common/pallette/colors';
import TableSkeleton from '../../TableSkeleton';
import { resetInterviewDetails, resetOnSiteStandards, resetSelectedOffSiteCriteria, resetSelectedOnSiteCriteria, resetSelectedOnSiteSession, setOnSiteCriteria, setSelectedOffSiteCriteria, setSelectedOnSiteCriteria, setSelectedOnSiteSession } from '../../../redux/reducers/evaluation';
import ContentOverview from '../../ContentOverview';
import DateSelector from '../../DateSelector';
import CustomTimePicker from '../../CustomTimePicker';
import StaffSelectBox from '../../SearchSelectBox/StaffSelectBox';
import TableDateSelector from '../../TableDateSelector';
import TableTimePicker from '../../TableTimePicker';
import TableSelectBox from '../../SearchSelectBox/TableSelectBox';
import { detectConflicts, formatDate, formatHHmmToDateTime, formatMMToDateTime } from '../../../common/StaticExports';
import { getUsersByClub } from '../../../api/club';
import { setClubUsers, setUserRoles } from '../../../redux/reducers/common';
import { getClubReviewsInfo, getOnSiteRoles, saveSessionDate, syncInterviewees, updateSessionDuration, updateSessionStartTime } from '../../../api/evaluation';
import TableMultiSelectCheckBox from '../../TableMultiSelectCheckBox';
import { useTranslation } from 'react-i18next';
import TableSingleSelectBox from '../../SearchSelectBox/TableSingleSelectBox';
import OnSiteIntervieweeSelectBox from '../../OnSiteIntervieweeSelectBox';
import OnSiteRolesSelectBox from '../../OnSiteRolesSelectBox';


export default function OnSiteCriteriaTable(props: any) {

  const { data, criteria, selectedCriteria, isLoading } = props;
  const dispatch = useDispatch();
  const [idToEdit, setIdToEdit] = React.useState(0);
  const onSiteCriteria = useSelector((state:any) => state.evaluation.onSiteCriteria);
  const currentClub = useSelector((state:any) => state.clubDetails);
  const [t] = useTranslation();
  const [isUpdate, setIsUpdate] = React.useState(false);

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows:any = React.useMemo(
    () =>
      data?.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  const head = [
    {
      key: '', displayName: 'id',
    },
    {
      key: '', displayName: 'interview',
    },
    {
      key: '', displayName: 'date',
    },
    {
      key: '', displayName: 'start time',
    },
    {
      key: '', displayName: 'duration (mins)',
    },
    {
      key: '', displayName: 'interviewee',
    },
    {
      key: '', displayName: 'role',
    },
    {
      key: '', displayName: 'progress',
    },
    {
      key: '', displayName: '',
    },
  ]

  const manageTableExpand = () => {
    dispatch(resetSelectedOnSiteSession());
    dispatch(resetInterviewDetails());
    dispatch(resetOnSiteStandards());
  }

  const manageTime = (timeProps:any) => {
    const { hours, minutes } = timeProps;
    return hours * 60 + minutes;
  }

  const manageEditOnClick = async (dataId:any) => {
    setIdToEdit(dataId);
    const response = await getUsersByClub({clubId: currentClub.id});
    dispatch(setClubUsers(response));
    const reviewData = await getClubReviewsInfo({clubId: currentClub?.id});
    const roles = await getOnSiteRoles({onSiteReviewId: reviewData?.onSiteReviewId});
    dispatch(setUserRoles(roles));
  }

  //update session start time api
  const manageStartTime = async (value:any, session:any, keyLabel:any) => {
    const [hours, minutes] = value.split(':');
    const response = await updateSessionStartTime({sessionId: session?.id, hours: hours, minutes: minutes});
    updateState(response, session, keyLabel);
  }

  //update session duration api
  const manageDuration = async (value:any, session:any, keyLabel:any) => {
    const response = await updateSessionDuration({sessionId: session?.id, duration: value});
    updateState(response, session, keyLabel);
  }

  //update session date api
  const manageDate = async (value:any, session:any, keyLabel:any) => {
    const date = formatDate(value);
    const reviewData = await getClubReviewsInfo({clubId: currentClub?.id});
    const response = await saveSessionDate({onSiteReviewId: reviewData?.onSiteReviewId, sessionId: session?.id, sessionDate: date});
    updateState(response, session, keyLabel);
  }

  //update interviewee api
  const manageInterviewees = async (value:any, session:any, keyLabel:any) => {
    const reviewData = await getClubReviewsInfo({clubId: currentClub?.id});
    setIsUpdate(true);
    const response = await syncInterviewees({onSiteReviewId: reviewData?.onSiteReviewId, sessionId: session.id, interviewees: value});
    updateState(response, session, keyLabel);
  }

  const updateState = async (updatedValue:any, obj:any, keyLabel:any) => {
    let conflicts:any = [];
    let changedObj = obj;
    if (keyLabel == 'startTime' && false) {
      const [hours, minutes] = updatedValue.split(':').map(Number);
      changedObj = {...changedObj, [keyLabel]: {...changedObj[keyLabel], hours: hours, minutes: minutes} };
    } else {
      changedObj = {...changedObj, [keyLabel]: updatedValue};
    }
    let tempArr = onSiteCriteria;
    tempArr = tempArr.map((da:any, i:any) => {
      if (obj.id == da.id) {
        return changedObj;
      } return da;
    });
    dispatch(setOnSiteCriteria(tempArr));
    setIsUpdate(false);
  }

  return (
    <React.Fragment>
      <Box sx={{ position: 'relative' }}>
        {!criteria && <FontAwesomeIcon className='icon tableExpandIcon' icon={faMaximize} onClick={() => manageTableExpand()} />}
          <TableContainer sx={{height: '100%', width: !criteria ? 'fit-content' : 'auto', overflow: criteria ? 'auto' : 'hidden'}}>
            <ClickAwayListener onClickAway={() => {setIdToEdit(999)}}>
              <Table stickyHeader sx={{ width: isLoading ? '100%' : (criteria ? 'fit-content' : 'auto'), boxShadow: 'none' }} size="small" aria-label="a dense table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  tableHeads={criteria ? head : [head[0]]}
                />
                <TableBody>
                  {
                    (visibleRows?.length) ?
                    visibleRows.map((d: any, i: number) => (
                      <>
                      <TableRow
                        className={`${d.isInConflict ? 'conflictRow' : 'hoverBG' } ${idToEdit != d.id && 'cursorPointer'}`}
                        key={`dynamic-table-row-${i}`}
                        onClick={() => {
                          idToEdit != d.id &&
                          dispatch(setSelectedOnSiteSession(d));
                        }}
                      >
                        <CustomTooltip title={d.name} className={!criteria ? 'capitalize' : 'displayNone'} arrow followCursor placement='top-start'>
                          <TableCell className='capitalize' sx={{cursor: 'pointer'}} 
                          onClick={() => {
                            dispatch(setSelectedOnSiteSession(d));
                          }}>
                          {i+1}
                          </TableCell>
                        </CustomTooltip>
                        {
                          criteria &&
                          <>
                          <TableCell className='capitalize' sx={{ width: '400px' }}>
                            {d.name}
                          </TableCell>
                          <TableCell sx={{ width: '120px' }}>
                            {
                              idToEdit != d.id ?
                              <Typography className='fs13'>{(d.date != undefined) ? formatDate(d.date) : '-'}</Typography> :
                              <TableDateSelector
                              variant={'standard'}
                              manageChange={(e:any) => {manageDate(new Date(e), d, 'date')}}
                              dateValue={d.date ? formatDate(d.date) : null} />
                            }
                          </TableCell>
                          <TableCell sx={{ width: '120px' }}>
                            {
                              idToEdit != d.id ?
                              <Typography className='fs13'>{(d?.startTimeStr && d?.startTimeStr != 'NaN:NaN') ? d.startTimeStr : '-'}</Typography> :
                              <TableTimePicker 
                              format={'HH:mm'}
                              views={['hours', 'minutes']}
                              manageChange={(e:any) => {manageStartTime(e, d, 'startTimeStr')}}
                              value={d?.startTimeStr ? formatHHmmToDateTime(d.startTimeStr) : ''} />
                            }
                          </TableCell>
                          <TableCell sx={{ width: '120px' }}>
                            {
                              idToEdit != d.id ?
                              <Typography className='fs13'>{d?.duration ? d?.duration : 0}</Typography> :
                              <TableTimePicker 
                              format={'mm'}
                              views={['minutes']}
                              manageChange={(e:any) => {manageDuration(e, d, 'duration')}}
                              value={formatMMToDateTime(d.duration)} />
                            }
                          </TableCell>
                          <TableCell sx={{ width: '180px' }}>
                            {
                              idToEdit != d.id ?
                              d.interviewees && d.interviewees.length ?
                              d.interviewees.map((interviewee:any) => 
                                <Typography className='fs13'>{interviewee?.name ? interviewee?.name : '-'}</Typography>
                              ) :
                              <Typography className='fs13'>{'-'}</Typography>
                              :
                              d?.interviewees?.length && 
                              d?.interviewees.map((interviewee:any, j:any) => {
                                return(
                                  <OnSiteIntervieweeSelectBox
                                  value={interviewee}
                                  manageSelect={(e:any) => {
                                    let tempArr:any = [];
                                    if (e.role == null && e.name == null && e.userId == null) {
                                      tempArr= d.interviewees.filter((inter:any, index:any) => j!=index );
                                    } else {
                                      tempArr= d.interviewees.map((inter:any, index:any) => {return j==index ? e : inter});
                                    }
                                    updateState(tempArr, d, 'interviewees');
                                    manageInterviewees(tempArr, d, 'interviewees');
                                  }}
                                  />
                                )
                              })
                            }
                            {idToEdit == d.id && <Typography className='fs13' onClick={() => {
                              const newObj = {
                                id: null,
                                userId: null,
                                name: null,
                                departmentId: null,
                                role: null
                              };
                              const tempObj = [...d?.interviewees, newObj];
                              updateState(tempObj, d, 'interviewees');
                              }}>{'+ Add new interviewee'}</Typography>}
                          </TableCell>
                          <TableCell sx={{ width: '180px' }}>
                            {
                              idToEdit != d.id ?
                              d.interviewees && d.interviewees.length &&
                              d.interviewees.map((interviewee:any) => 
                                <Typography className='fs13'>{interviewee?.role ? interviewee?.role : '-'}</Typography>
                              )
                              :
                              d?.interviewees?.length && 
                              d?.interviewees.map((interviewee:any, j: any) => {
                                return(
                                  <OnSiteRolesSelectBox
                                  value={interviewee}
                                  manageSelect={(e:any) => {
                                    let tempArr:any = [];
                                    if (e.role == null && e.name == null && e.userId == null) {
                                      tempArr= d.interviewees.filter((inter:any, index:any) => j!=index );
                                    } else {
                                      tempArr= d.interviewees.map((inter:any, index:any) => {return j==index ? e : inter});
                                    }
                                    updateState(tempArr, d, 'interviewees');
                                    manageInterviewees(tempArr, d, 'interviewees');
                                  }}
                                  />
                                )
                              })
                            }
                            {idToEdit == d.id && <Typography className='fs13' sx={{ visibility: 'hidden' }}>{'+ Add new interviewee'}</Typography>}
                          </TableCell>
                          <TableCell>
                            {
                              d?.progress == 100 ?
                              <StatusIndicatorSmall reportType={'siteReview'} status={3}/> :
                              <ProgressBar percent={d.progress} width={'180px'}/>
                            }
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box className={idToEdit == d.id ? 'cursorNotAllowed' : 'cursorPointer'} onClick={(e) => {e.stopPropagation(); idToEdit != d.id && manageEditOnClick(d.id)}}>
                                <FontAwesomeIcon className={'icon'} icon={faPencil} />
                              </Box>
                              {
                                d.isInConflict &&
                                <Box className={'infoIcon'}>
                                  <Tooltip title={'This interview schedule conflicts with an existing interview. Please adjust the date, time, or duration to avoid overlap.'} arrow placement='top'>
                                    <FontAwesomeIcon icon={faCircleInfo} color={colors.yellow.bright} fontSize='16px'/>
                                  </Tooltip>
                                </Box>
                              }
                            </Box>
                          </TableCell>
                          </>
                        }
                      </TableRow>
                      {/* DISABLED THE Row to show addtional roles and interviewer */}
                      {
                        false && criteria && d.interviewees && (d.interviewees.slice(1)).length > 0 && 
                        d.interviewees.slice(1).map((interviee:any, j:any) => (
                          <TableRow
                            className='hoverBG'
                            key={`additional-dynamic-table-row-${j}`}
                            // onClick={() => manageTableShrink(d)}
                          >
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell sx={{ width: '120px' }}>
                            </TableCell>
                            <TableCell sx={{ width: '120px' }}>
                            </TableCell>
                            <TableCell sx={{ width: '120px' }}>
                            </TableCell>
                            <TableCell sx={{ width: '180px' }}>
                              <Typography className='fs13'>{interviee.name}</Typography>
                            </TableCell>
                            <TableCell sx={{ width: '180px' }}>
                              <Typography className='fs13'>{interviee.role}</Typography>
                            </TableCell>
                            <TableCell sx={{ width: '180px' }}>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                      </>
                    ))
                    :
                    isLoading ?
                    <TableSkeleton rowCount={13} cellCount={8} /> :
                    <TableRow>
                      <TableCell colSpan={9} sx={{ textAlign: 'center'}}>
                        <Typography className='fs13'>{t('NO_RECORDS_FOUND')}</Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </ClickAwayListener>
          </TableContainer>
      </Box>
    </React.Fragment>
  );
}