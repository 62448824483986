import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import './styles.css';
//internal and external components import
import { Table, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import ProgressBar from '../ProgressBar';
import { stableSort, descendingComparator, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMaximize } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { CustomTooltip } from '../CustomTooltip';
import { colors } from '../../common/pallette/colors';
import { setSelectedLicenceCriteria, setInitialSelectedLicenceCriteria } from '../../redux/reducers/licence';
import { standards } from '../../assets/staticData';
import TableSkeleton from '../TableSkeleton';
import { genUID } from '../../common/StaticExports';


export default function LicenceCriteriaTable(props: { data: any; licenceCriteria: any; selectedLicenceCriteria: any }) {

  const { data, licenceCriteria, selectedLicenceCriteria } = props;

  const dispatch = useDispatch();

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(data, getComparator(order, orderBy)),
  //   [order, orderBy],
  // );

  const head = [
    {
      key: 'uid', displayName: 'id',
    },
    {
      key: 'name', displayName: 'criteria',
    },
    {
      key: 'status', displayName: 'status',
    },
    {
      key: 'evaluationProgress', displayName: 'Evaluation Progress',
    },
    {
      key: 'progress', displayName: 'Club Progress',
    },
  ]

  const manageTableExpand = () => {
    dispatch(setInitialSelectedLicenceCriteria());
  }

  const manageTableShrink = (d: any) => {    
    dispatch(setSelectedLicenceCriteria(d));
  }



  //table contents
    
  return (
    <React.Fragment>
      <Box sx={{ position: 'relative' }}>
        {!licenceCriteria && <FontAwesomeIcon className='icon tableExpandIcon' icon={faMaximize} onClick={() => manageTableExpand()} />}
        <TableContainer sx={{maxHeight: 640, width: !licenceCriteria ? 'fit-content' : 'auto', overflow: licenceCriteria ? 'auto' : 'hidden'}}>
          <Table stickyHeader sx={{ width: licenceCriteria ? '100%' : 'auto', boxShadow: 'none' }} size="small" aria-label="a dense table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeads={licenceCriteria ? head : [head[0]]}
            />
            <TableBody>
              {
                data?.length ?
                data.map((d: any, i: number) => (
                  <TableRow
                    className='hoverBG'
                    key={`dynamic-table-row-${i}`}
                    onClick={() => manageTableShrink(d)}
                  >
                    <CustomTooltip title={d.name} className={!licenceCriteria ? 'capitalize' : 'displayNone'} arrow followCursor placement='top-start'>
                      <TableCell className={(!licenceCriteria && selectedLicenceCriteria.uid === d.uid) ? 'fw900 ' : ''}>{genUID(d.uid)}</TableCell>
                    </CustomTooltip>
                    {
                      licenceCriteria && <>
                      <TableCell className='capitalize' sx={{cursor: 'pointer'}}>
                        {/* <FontAwesomeIcon className='icon mr5' icon={faChevronRight} /> */}
                        {d.name}
                      </TableCell>
                      <TableCell><StatusIndicatorSmall reportType={'siteReview'} status={d.status} /></TableCell>
                      <TableCell>
                        <ProgressBar percent={d.evaluationProgress}/>
                      </TableCell>
                      <TableCell>
                        <ProgressBar percent={d.progress}/>
                      </TableCell></>
                    } 
                  </TableRow>
                )) : 
                <TableSkeleton rowCount={16} cellCount={5}/>
            }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}