import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ReactModal, { Props } from 'react-modal';

const GlobalStyle = createGlobalStyle`
  .modalOverlay {
    z-index: 100;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    /* backdrop-filter: blur(10px); */
  }
`;

const Container = styled(ReactModal).attrs<{ overlayClassName?: string }>(p => {
  return { overlayClassName: `modalOverlay`, shouldCloseOnOverlayClick: true, shouldCloseOnEsc: false, ...p };
})`
  box-shadow: 0 5px 10px 0 rgba(54, 54, 72, 0.1);
  position: absolute;
  background-color: #ffffff;
  z-index: 100;
  outline: none;
  border-radius: 12px;
`;
// ReactModal.setAppElement('#modal');

export interface ModalProps extends Props {}

const Modal: React.FC<ModalProps> = ({ children, ...other }) => {
  return (
    <>
      <GlobalStyle />
      {/* @ts-ignore */}
      <Container {...other}>{children}</Container>
    </>
  );
};

export default Modal;
