import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TeamsTable from "../../components/TeamsTable";
import { useDispatch, useSelector } from "react-redux";
import { getTeamDetails } from "../../api/dataCapture";
import { setTeamDetails } from "../../redux/reducers/dataCaptures";

const TeamsDetails = () => {
    const [addTeam, setAddTeam] = React.useState(false);
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    useEffect(() => {
        const fetchTeamDetails = async () => {
            const response = await getTeamDetails({clubId: clubDetails.id, isPast: false});
            dispatch(setTeamDetails(response));
        }
        fetchTeamDetails();
    }, []);
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography className='standardsHeading uppercase'>teams</Typography>
                <Button className='smallButton' 
                sx={{ float: 'right' }} 
                disableElevation disabled={addTeam} 
                variant='contained' size='small' 
                onClick={() => {setAddTeam(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                    {'Add team'}
                </Button>
            </Box>
            <TeamsTable addData={addTeam} handleClick={() => setAddTeam(false)} />
        </Box>
    )
}

export default TeamsDetails;