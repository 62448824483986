import { DesktopTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TextField, ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '8px !important'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderRadius: 0,
                    borderTop: 0,
                    borderRight: 0,
                    borderLeft: 0
                }
            }
        }
    }
})

const CustomTimePicker = (props:any) => {
    const { format, views } = props;
    return(
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker format={format} views={views} />
            </LocalizationProvider>
        </ThemeProvider>
        
    )
}

export default CustomTimePicker;