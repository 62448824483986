import React from 'react';
import './styles.css';
import { Box, Typography, Avatar, ThemeProvider, createTheme } from '@mui/material';
import ThreeDotMenu from '../../components/ThreeDotMenu';
import { useSelector, useDispatch } from "react-redux";
import ChangeClubPopup from '../ChangeClubPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import SearchBox from '../../components/SearchBox';
import SearchDropBox from '../../components/SearchDropBox';
import logo from '../../assets/icons/JLogoLight.svg'

const theme = createTheme({
    components: {
    }
})

const CurrentClubIndicator = () => {
    
    const [isOpen, setIsOpen] = React.useState(false);
    const [rotation, setRotation] = React.useState(0);
    const currentClub = useSelector((state: any) => state.clubDetails);
    const isAdmin = useSelector((state:any) => state.common.isAdmin);

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Box className={'currentClubContainerWrapper'} bgcolor={ currentClub.color ? currentClub.color : '#000' }>
                    <Box className={'currentClubDetail'} bgcolor={ currentClub.color ? currentClub.color : '#000' }>
                        <Box className={isAdmin ? 'changeIconContainer' : ''} onClick={() => {setIsOpen(!isOpen); setRotation( rotation ? 0 : -180);}}>
                            {
                                isAdmin && 
                                <FontAwesomeIcon 
                                className='changeIcon' 
                                style={{ transform: `rotate(${rotation}deg)`, transition: 'transform 0.5s ease' }}
                                icon={faArrowsRotate} />
                            }
                        </Box>
                        <Box className={'clubNameContainer'}>
                            <Typography className='clubIndicatorTitle'>{currentClub.name}</Typography>
                            <Box className='logoContainer'>
                                <img className='clubIndicatorLogo' src={
                                    currentClub.id == 0 ?
                                    logo:
                                    `https://jamp.football${currentClub.logo}`} alt={'CLUB LOGO'} />
                            </Box>
                        </Box>
                    </Box>
                    <Box 
                    className='slideableSearhBarWrapper'
                    style={{ transform: isOpen ? 'translateX(-110%)' : 'translateX(0)', transition: 'transform 0.5s ease' }}>
                        <SearchDropBox placeholder={'Search the club name...'} selectedValue={() => setIsOpen(!isOpen)}/>
                    </Box>
                </Box>
                {/* <ChangeClubPopup isOpen={isOpen} handleIsOpen={() => {setIsOpen(!isOpen)}}/> */}
            </ThemeProvider>
        </React.Fragment>
    )
}

export default CurrentClubIndicator;