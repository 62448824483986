import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import './styles.css'
import { Box, Typography, Button } from '@mui/material';
import StatusIndicatorSmall from '../../components/StatusIndicatorSmall';
import SelectBox from '../../components/SelectBox';
import C7Accordion from '../C7Accordion';
import { colors } from '../../common/pallette/colors';
import { genUID } from '../../common/StaticExports';
import PhasesStatusOverview from '../../components/PhasesStatusOverview';
import ProgressBar from '../../components/ProgressBar';
import { useTranslation } from 'react-i18next';

const DetailedCriteria = (props: { criteria: any; module: string }) => {
    const { criteria, module } = props;
    const [t] = useTranslation();

    return(
        <React.Fragment>
            <Box sx={{ width: '100%', padding: 2, ml: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden'}}>
                <Box>
                    <Box className='detailedCriteriaHeading' sx={{ bgcolor: colors.grey.bright }}>
                        <Typography className='detailedCriteriaTitle'>{genUID(criteria?.uid)} {criteria?.name}</Typography>    
                        {
                            (module == t('EVALUATION')) ?
                            <Box sx={{display: 'flex'}}>
                                <Box sx={{mr: 2, my: 'auto'}}>
                                    <Typography className='detailedCriteriaReports'>Interim Report</Typography>
                                    <Box sx={{fontSize: 12, float: 'right'}}>
                                        <StatusIndicatorSmall reportType={'interim'} status={criteria.interimReprot} />
                                    </Box>
                                </Box>
                                <Box sx={{mr: 2, my: 'auto'}}>
                                    <Typography className='detailedCriteriaReports'>Final Report</Typography>
                                    <Box sx={{fontSize: 12, float: 'right'}}>
                                        <StatusIndicatorSmall reportType={'final'} status={criteria.finalReport}/>
                                    </Box>
                                </Box>
                                {/* <PhasesStatusOverview data={criteria} criterias={[criteria]}/> */}
                                <SelectBox label={'Status'} options={['Ready for review', 'Completed']} border={'dotted'} />
                            </Box>:
                            (module == t('LICENCE')) &&
                            <Box sx={{display: 'flex'}}>
                                <Box sx={{mr: 4, my: 'auto'}}>
                                    <Typography className='detailedCriteriaReports'>Evluation Status</Typography>
                                    <Box sx={{fontSize: 12, float: 'right'}}>
                                        <StatusIndicatorSmall reportType={'siteReview'} status={criteria.status} minW={0} />
                                    </Box>
                                </Box>
                                <Box sx={{mr: 2, my: 'auto', textAlign: 'end'}}>
                                    <Typography className='detailedCriteriaReports'>Club Progress</Typography>
                                    <ProgressBar percent={criteria.evaluationProgress}/>
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box className='detailedcriteriaContent'>
                        <C7Accordion data={criteria} module = {module}/>
                    </Box>
                </Box>
                {/* <Box className='detailedcriteriaFooter' sx={{ bgcolor: colors.grey.bright }}>
                    <Button className='detailedCriteriaButton' size='small' variant='outlined'>Cancel</Button>
                    <Box sx={{ display: 'flex' }}>
                        <SelectBox label={'Status'} options={['Ready for review', 'Completed']} />
                        <Button className='detailedCriteriaButton' size='small' variant='contained'>Save</Button>
                    </Box>
                </Box> */}
            </Box>
        </React.Fragment>
    )
}

export default DetailedCriteria;