import React, { useEffect, useRef, useState } from "react";
import './styles.css';
//import internal / external components and containers
import { Box } from "@mui/material";
import MyClubsContainer from "../MyClubsContainer";
import EvaluationUpcoming from "../EvaluationUpcoming";
import EvaluationProjects from "../EvaluationProjects";
import { colors } from "../../common/pallette/colors";
import { useSelector } from "react-redux";

const JLHomeContainers = () => {
    const [expandedContainer, setExpandedContainer] = React.useState({
        upcoming: false,
        projects: false,
});
const displayUpcomingData = useSelector((state:any) => state.common.displayUpcoming);
const displayProjectsData = useSelector((state:any) => state.common.displayProjects);
const upcomingElemnt:any = useRef(null);
const projectsElement:any = useRef(null);
const [upcomingHeight, setUpcomingHeight] = useState(0);
const [projectsHeight, setProjectsHeight] = useState(0);

useEffect(() => {
    if(upcomingElemnt.current) {
        setUpcomingHeight(upcomingElemnt?.current?.clientHeight);
    }
    if(projectsElement.current) {
        setProjectsHeight(projectsElement?.current?.clientHeight);   
    }
}, []);
const generateHeight = (root: string) => {    
    // switch(root) {
    //     case 'upcoming':
    //         if ((displayUpcomingData.length <= 4 && displayProjectsData.length <= 4) ||
    //              (!expandedContainer.upcoming && displayUpcomingData.length > 4 &&
    //                 !expandedContainer.projects && displayProjectsData.length > 4)){
    //             return '49.25%';
    //         } else if ((expandedContainer.upcoming && displayUpcomingData.length > 4)) {
    //             return '79.25%';
    //         } else if (displayUpcomingData.length > 4 && displayProjectsData.length <= 4) {
    //             return `calc(100% - ${projectsHeight}px)` 
    //         } else if (displayUpcomingData.length <= 4 && displayProjectsData.length > 4) {
    //             return 'auto';
    //         } else {
    //             return '49.25%';
    //         } break;
    //     case 'projects':
    //         if ((displayUpcomingData.length <= 4 && displayProjectsData.length <= 4) ||
    //         (!expandedContainer.upcoming && displayUpcomingData.length > 4 &&
    //            !expandedContainer.projects && displayProjectsData.length > 4)){
    //             return '49.25%';
    //         } else if (expandedContainer.projects && displayProjectsData.length > 4) {
    //             return '79.25%';
    //         } else if (displayUpcomingData.length <= 4 && displayProjectsData.length > 4) {
    //             return `calc(100% - ${upcomingHeight}px)` 
    //         } else if (displayUpcomingData.length > 4 && displayProjectsData.length <= 4) {
    //             return 'auto';
    //         } else {
    //             return '49.25%';
    //         } break;
    // }
    if(displayUpcomingData.length <=4 && displayProjectsData.length <=4) {
        return '49.25'
    } else if (displayUpcomingData.length <=4 || displayProjectsData.length <=4){
        if ((displayUpcomingData.length <=4 && root == 'upcoming') || (displayProjectsData.length <=4 && root == 'projects')) {
            return '19.25%'
        } else if ((displayUpcomingData.length > 4 && root == 'upcoming') || (displayProjectsData.length > 4 && root == 'projects')) {
            return '79.25%'
        } 
    } else {
        if(!expandedContainer.upcoming && !expandedContainer.projects) {
            return '49.25%'
        } else if((expandedContainer.upcoming && !expandedContainer.projects && root == 'upcoming') || (!expandedContainer.upcoming && expandedContainer.projects && root == 'projects')) {
            return '79.25%'
        } else {
            return '19.25%'
        }
    }
}

const generateMaxHeight = (root:string) => {
    if(!expandedContainer.upcoming && !expandedContainer.projects) {
        return '49.25%';
    } else if((expandedContainer.upcoming && !expandedContainer.projects && root == 'upcoming') || (!expandedContainer.upcoming && expandedContainer.projects && root == 'projects')) {
        return '79.25%';
    }
}
    return(
        <React.Fragment>
            <Box className='homeContainersWrapper'>
                <Box className='myClubsWrapper'>
                    <MyClubsContainer />
                </Box>
                <Box className='homeRightWrapper'>
                    <Box sx={{ height: generateHeight('upcoming'), mb: 3.5}} ref={upcomingElemnt}>
                        <EvaluationUpcoming isExpanded={expandedContainer.upcoming} 
                        disableExpand={displayUpcomingData.length <= 4 || displayProjectsData.length <= 4}
                        setIsExpanded={() => {
                            !expandedContainer.upcoming ?
                            setExpandedContainer({upcoming: true, projects: false}):
                            setExpandedContainer({...expandedContainer, upcoming: false})}} />
                    </Box>
                    <Box sx={{ height: generateHeight('projects') }} ref={projectsElement}>
                        <EvaluationProjects isExpanded={expandedContainer.projects} 
                        disableExpand={displayUpcomingData.length <= 4 || displayProjectsData.length <= 4}
                        setIsExpanded={() => {
                            !expandedContainer.projects ?
                            setExpandedContainer({upcoming: false, projects: true}):
                            setExpandedContainer({...expandedContainer, projects: false})
                        }} />
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default JLHomeContainers;