import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddStaffForm from "../AddStaffForm";
import StaffListTable from "../../components/StaffListTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setStaffDetails } from "../../redux/reducers/dataCaptures";
import { getStaffDetails } from "../../api/dataCapture";
import StaffRoleTable from "../../components/StaffRoleTable";

const StafffDetails = () => {
    const [addNewStaff, setAddNewStaff] = React.useState(false);
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    useEffect(() => {
        const fetchStaffDetails = async () => {
            const response = await getStaffDetails({clubId: clubDetails.id, isPast: false});
            dispatch(setStaffDetails(response));
        }
        fetchStaffDetails();
    }, []);
    return (
        <Box className='liceDataSubContainer'>
            {addNewStaff && <AddStaffForm onCancelClick={() => setAddNewStaff(false)}/>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography className='standardsHeading uppercase'>Staff list</Typography>
                <Button className='smallButton' 
                sx={{ float: 'right' }} 
                disableElevation 
                disabled={addNewStaff} 
                variant='contained' 
                size='small' 
                onClick={() => {setAddNewStaff(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                    {'Add staff member'}
                </Button>
            </Box>
            <StaffListTable />
            <Box className='liceDataSubContainer'>
                <Typography className='standardsHeading uppercase'>{'Staff Roles'}</Typography>
                <Box>
                    <StaffRoleTable />
                </Box>
            </Box>
        </Box>
    )
}

export default StafffDetails;