import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import './styles.css';
import { TextField, InputAdornment, ThemeProvider, createTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { colors } from '../../common/pallette/colors';
import { useNavigate } from 'react-router-dom';
import { setCurrentClub } from '../../redux/reducers/clubDetails';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0px 14px !important'
        }
      }
    }
  }
})

export default function SearchDropBox(props: { placeholder?: any, selectedValue?: any }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { placeholder, selectedValue } = props;
    const clubDetails = useSelector((state: any) => state.common.myClubs);

    const manageSelect = (name: any) => {
      if (name !== undefined && name !== null && name !== '') {
        const club = clubDetails.find((x: any) => x.name == name);
        let d: any = {id: club.id, name: club.name, color: club.color, logo: club.imageUrl, currentEvaluationPhase: club.currentEvaluationPhase};
        selectedValue();
        dispatch(setCurrentClub(d));
        navigate('/selfassessment')
      }
    }
  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={1} sx={{ width: 300 }}>
        <Autocomplete
          freeSolo
          onChange={(e, value) => {manageSelect(value)}}
          options={clubDetails ? clubDetails.map((option: any) => option.name) : []}
          sx={{ bgcolor: colors.grey.light, border: 0 }}
          renderInput={(params) => <TextField {...params} sx={{ border: 0 }} label={''}
                                      placeholder={placeholder ? placeholder : "Search"}
                                      InputProps={{
                                          ...params.InputProps,
                                          type: 'search',
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <SearchIcon style={{ width: 20, color: colors.grey.dark }} />
                                              </InputAdornment>
                                          )
                                      }}
                                      />}
        />
      </Stack>
    </ThemeProvider>
  );
}
