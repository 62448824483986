export const colors = {
    grey: {
        light: '#E9ECF0',
        dark: '#7D8793',
        bright: '#F5F5F5',
        cancelButton: '#cccccc6e'
    },
    white: {
        bright: '#FFFFFF',
        light: '#FFFFFF40'
    },
    yellow: {
        bright: '#F2C94B',
    },
    purple: {
        bright: '#7956BE'
    },
    green: {
        bright: '#24AA57',
    },
    statusColors: {
        notEvaluated: '#D9D9D9',
        met: '#27AE60',
        partial: '#EA8B19',
        notMet: '#EF3946'
    },
    black: {
        dark: '#181820'
    },
    blue: {
        navy: '#4C65C0'
    },
    projectsStatus: {
        notStarted: '#CBCBCB',
        missed: '#F03A47',
        atRisk: '#ED8A19',
        onTrack: '#23C406',
        complete: '#FFE70A'
    },
    lightishRed: '#f03a47',
    mediumBlue: '#3551b8',
    dark: '#181820',
    darkTwo: '#13131a',
    azure: '#01b0f0',
    windowsBlue: '#3956c3',
    tangerine: '#ed8a19',
    shamrockGreen: '#04bf5c',
    veryLightPink: '#ebebeb',
    middleGrey: '#cbcbcb',
    paleGrey: '#f3f6fb',
    iris: '#4c65c0',
    battleshipGrey: '#737376',
    brightYellow: '#ffff00',
    vibrantGreen: '#23c406',
    rosy: '#ea3df5',
    lightishGrey: '#f7f7f7',
    gold: '#FFE70A',
    darkGrey: '#565656',
    darkLightGrey: '#A0A0A0',
    notEvalGrey: '#D9D9D9',
    red: '#FF0000',
}