import { Box, Typography, Button } from "@mui/material";
import './styles.css';
import React, { useState } from "react";
import CustomTextField from "../../components/CustomTextField";
import MultiSelectPrimary from "../../components/MultiSelectPrimary";
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import GroundDetailsTable from "../../components/GroundDetailsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddFacilitiesForm = (props:any) => {
    const { onCancelClick } = props;
    const [addGroundDetails, setAddGroundDetails] = useState(false);
    return(
        <React.Fragment>
            <Box className='facilitiesFormStyles mb20'>
                <CustomTextField label='Facility Name'/>
                <CustomTextField label='Short Name'/>
                <CustomTextField label='Address'/>
                <CustomTextField label='Owner'/>
                <MultiSelectPrimary label='Facility Status'/>
                <MultiSelectPrimary label='Capablities'/>
                <CustomTextField label='No. of Changing Rooms'/>
                <CustomTextField label='Dormintory Capacity'/>
                <JoyCssVarsProvider>
                    <Textarea sx={{ borderRadius: '6px', mt: 0, width: '100%' }} onChange={(e) => {}} placeholder='Dormintory Remarks...' minRows={2} maxRows={2} value={''}/>
                </JoyCssVarsProvider>
            </Box>
            <Box className={'mb20'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography className='standardsHeading uppercase'>Ground Details</Typography>
                    <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addGroundDetails} variant='contained' size='small' onClick={() => {setAddGroundDetails(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                        {'Add ground details'}
                    </Button>
                </Box>
                <Box>
                    <GroundDetailsTable addData={addGroundDetails} handleClick={() => setAddGroundDetails(false)}/>
                </Box>
            </Box>
            <Box className={'mb40'} sx={{ display: 'flex', ml: 'auto', float: 'right' }}>
                <Button className='smallButton closeCancelButtonStyles' variant='contained' sx={{ mr: 2 }} disableElevation disableRipple onClick={() => onCancelClick()}>Cancel</Button>
                <Button className='smallButton' variant='contained' disableElevation disableRipple>Add</Button>
            </Box>
        </React.Fragment>
    )
}

export default AddFacilitiesForm;