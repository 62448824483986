import React from 'react'
import {Box, Skeleton, TableRow, TableCell} from '@mui/material';


const TableSkeleton = (props: {rowCount:number, cellCount:number, width?: number}) => {
    const {rowCount, cellCount, width} = props

    const TableSkeletonCell = () => {
        return(
            <TableCell sx={width ? { width: width } : { }}>
                <Skeleton />
            </TableCell>
        )
    }
    
    return(
        <>
        {
            Array.from({length: rowCount},() => {                       
                return(
                    <TableRow>
                        {
                            Array.from({length: cellCount}, () => {
                                return <TableSkeletonCell />
                            })
                        }
                    </TableRow>
                )
            })
        }
        </>
    )
}

export default TableSkeleton;