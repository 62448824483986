import React from 'react';
import './styles.css';
//internal and external components/containers import
import { Avatar, Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

const UpcomingCards = (props: {statusColor?: string; cardMedia?: any; cardHeading:any; cardComment: any; index: any; }) => {
    const { statusColor, cardMedia, cardHeading, cardComment, index } = props;
    return(
        <React.Fragment>
            <Card sx={{width: '100%', height: 'auto', marginBottom: 1, textAlign: 'left'}} elevation={0}>
                <Box className="upcomingCards" sx={{m: 1}}>
                    {
                        (cardMedia && statusColor == undefined) ?
                        <CardMedia
                        component={'img'}
                        sx={{ width: 40, margin: '0' }}
                        image={cardMedia}
                        alt={"Upcoming clubs logo"}
                        className='upcomingLogo'
                        /> :
                        <Avatar sx={{bgcolor: statusColor, marginRight: 1, width: '20px', height: '20px'}}>{' '}</Avatar>
                    }
                    <Box className="upcomingEventWrapper">
                        <Typography className='upcomingDateandTime'>{cardHeading}</Typography>
                        <Typography className='upcomingEvents'>{cardComment}</Typography>
                    </Box>
                </Box>
            </Card>
        </React.Fragment>
    )
}

export default UpcomingCards;