import React from 'react';
import { Box, InputLabel, TextField, createTheme, ThemeProvider, InputAdornment } from '@mui/material';

const theme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 12
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '8px !important'
                }
            }
        }
    }
})

const CustomCurrencyTextField = (props: any) => {
    const { label, value } = props;
    return(
        <ThemeProvider theme={theme}>
            <Box>
                <InputLabel>{label}</InputLabel>
                <TextField
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            ¥
                        </InputAdornment>
                    )
                }}
                 label='' value={value} />
            </Box>
        </ThemeProvider>
    )
}

export default CustomCurrencyTextField;