import React, { useState } from "react";
import { Box, ClickAwayListener, InputLabel, ThemeProvider, createTheme } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";


const TableDateSelector = (props:any) => {
    const { variant, label, dateValue, manageChange, textColor } = props;
    const [value, setValue] = useState(dateValue);
    
const theme = createTheme({
    components:{
        MuiInput: {
            styleOverrides: {
                input: {
                    height: 'auto',
                    fontSize: '13px',
                    color: textColor,
                },
                root: {
                    '&::before': {
                        borderBottom: 'none'
                    },
                    '&::after': {
                        borderBottom: 'none'
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error)::before': {
                        borderBottom: 'none'
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    '&:hover:not(.Mui-disabled, .Mui-error)::before': {
                        borderBottom: 'none'
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    paddingTop: '0px !important'
                }
            }
        }
    }
})
    return(
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateField']} sx={{ overflow: 'hidden' }}>
                    <Box sx={{ width: '100%' }} onBlur={() => {manageChange(value)}} >
                    {(label && label !== '')  && <InputLabel className='selectBoxLabel'>{label}</InputLabel>}
                    <DatePicker label=""
                    onChange={(v) => {setValue(v)}}
                    value={value ? dayjs(value) : null}
                    // onClose={() => {manageChange(value)}}'
                    format="YYYY-MM-DD"
                    slotProps={{
                    textField: {
                        variant: variant ? variant : 'outlined',
                        size: 'small',
                        fullWidth: true,
                        InputProps: {
                        endAdornment: null, // Removes the calendar icon
                        },
                    }
                    }}
                    />
                    </Box>
                </DemoContainer>
            </LocalizationProvider>
        </ThemeProvider>
    )
}

export default TableDateSelector;