import React from 'react';
import { MenuItem } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface SingleOption {
  id: number;
  name: string;
}

interface MultiSelectDropdownProps {
  title?: string;
  options: SingleOption[] | any;
  value: SingleOption | SingleOption[] | undefined | any; // Depending on whether it's multi-select or not
  getId?: (option: SingleOption) => string;
  getLabel?: (option: SingleOption) => string;
  onChange?: (newValue: SingleOption | SingleOption[] | undefined) => void;
  isMulti?: boolean;
  width?: string;
  rootCss?: React.CSSProperties;
  addNone?: boolean;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  title = 'Select',
  options,
  value,
  // getId = (option: SingleOption) => option.id,
  // getLabel = (option: SingleOption) => option.name,
  onChange,
  isMulti,
  width = '200px',
  rootCss,
  addNone,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = (event.target.value as unknown) as SingleOption | SingleOption[];

    onChange?.(newValue);
  };

  return (
    <div style={rootCss}>
      <FormControl variant="standard" sx={{ m: 1, width: width, display: 'flex', flexDirection: 'row' }} size="small">
        <InputLabel id="" sx={{ display: 'contents', fontWeight: 700 }}>{title}</InputLabel>
        <Select
          sx={{ mt: '0px !important', ml: 2, minWidth: '32px' }}
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple={isMulti}
          defaultValue={value}
          value={value}
          onChange={(e:any) => {handleChange(e)}}
          MenuProps={MenuProps}
        >
          {addNone ? <MenuItem value="">None</MenuItem> : <></>}
          {options.map((option: any) => (
            <MenuItem key={(option)} value={option}>
              {(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelectDropdown;
