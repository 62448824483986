import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import { getImageSrc } from "../EvidenceTable/comps/FileType";
import { Tooltip } from "@mui/material";
import { stableSort, getComparator } from "../../../common/SortSupport";
import useFileTranslationProcess from "../../../hooks/useFileLangTranslation";
// @ts-ignore
import { queryCache, useMutation } from 'react-query';
import NestedThreeDotMenu from "../../../components/NestedThreeDotMenu";
import { useTranslation } from "react-i18next";
import { deleteFile, updateEvidence } from "../../../api/evidence";
import { FileDeleteStatus } from "../../../api/types";
import { toast } from "react-toastify";
import { setArchiveData, setLibraryData, updateIsOpenActionBar } from "../../../redux/reducers/library";
import CheckBox from "../../../components/CheckBox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { faDownload, faEye, faFileExport, faTrash, faTrashCanArrowUp } from "@fortawesome/free-solid-svg-icons";

const Row = (props: {row:any, i:number, subData:any}) => {
    const { row, i, subData } = props;
    const [open, setOpen] = React.useState(false);
    //supporting contents
    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');
    // const isOldCycle = clubDesirePlanId !== desirePlan.data?.id;
    const isOldCycle = false;
    const [t, i18n] = useTranslation();
    const [previousUploadFile, setPreviousUploadFile] = useState<any>([]);
    const archiveData = useSelector((state:any) => state.library.archiveData);
    const libraryData = useSelector((state:any) => state.library.libraryData);
    const archiveFolderStructure = useSelector((state:any) => state.library.archiveFolderStructure);
    const selectedArchiveFolder = useSelector((state:any) => state.library.selectedArchiveFolder);
    const archiveFolderList = useSelector((state: any) => state.library.archiveFolderList);
    const isOpenActionBar = useSelector((state: any) => state.library.isOpenActionBar);
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const isAdmin = useSelector((state:any) => state.common.isAdmin);


    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: any,
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const visibleRows = React.useMemo(
      () =>
        stableSort(subData, getComparator(order, orderBy)),
      [order, orderBy, row],
    );
  
    const head = [
      {
        key: 'type', displayName: t('TYPE'),
      },
      {
        key: 'criteriaUid', displayName: t('CRITERIA_ID'),
      },
      {
        key: 'criteriaName', displayName: t('CRITERIA'),
      },
      {
        key: 'uid', displayName: t('STANDARD_ID'),
      },
      {
        key: 'name', displayName: t('STANDARD'),
      },
      {
        key: '', displayName: '',
      },
    ]
    // const onOpenFile = () => {
    //   useFileTranslationProcess({
    //     onSuccessTranslation: () => queryCache.invalidateQueries(['evidence_library', { clubId: clubDetails?.id }]),
    //   });
    // }

    
    let [menuOptions, setMenuOptions]:any = useState([
      {
        label: t('TDM_FILE_VIEW'),
        subMenu: [],
        isDisabled: false,
        icon: faEye
      },
      {
        label: t('TDM_FILE_DOWNLOAD'),
        subMenu: [],
        isDisabled: false,
        icon: faDownload
      },
      {
        label: t('TDM_FILE_DELETE'),
        subMenu: [],
        isDisabled: (isAdmin || subData?.length > 0 ),
        icon: faTrash
      },
      {
        label: t('TDM_FILE_RESTORE'),
        subMenu: [],
        isDisabled: (subData?.length > 0 || isAdmin),
        icon: faTrashCanArrowUp
      },
    ]);

    const handleThreeDotEvents = (selectedAction:any) => {      
      switch(selectedAction) {
        case t('TDM_FILE_VIEW'): 
          // onOpenFile('preview', row);
          break;
        case t('TDM_FILE_DOWNLOAD'): 
          // onOpenFile('download', row);
          break;
        case t('TDM_FILE_DELETE'):
          // deleteFileTdm();
          break;
        case t('TDM_FILE_RESTORE'):
          manageRestoreEvidence();
          break;
        case t('TDM_FILE_MOVE_TO'): 
          console.log('clicked on Move to');
          break;
        default:
          manageMovetoClick(selectedAction);
          break;
      }
    }
    
    // @ts-ignore
    // const [delFile] = useMutation(deleteFile, {
    //   onSuccess: data => {
    //     if (data === 0) {
    //       toast.success(t('FILE_DETACHED'));
    //     } else {
    //       toast.success(t('FILE_DELETED'));
    //       // @ts-ignore
    //       const tempArchiveData = archiveData.filter(d => d.id != row.id);
    //       dispatch(setArchiveData(tempArchiveData));
    //     }
    //     queryCache.invalidateQueries(['evidence_library', { clubId: clubDetails?.id }]);
    //   },
    // });
    // const deleteFileTdm = () => {
    //   // @ts-ignore
    //   setPreviousUploadFile(previousUploadFile.filter(el => el !== row.title));
    //   // return delFile({ fileId: row.id });
    //   return 0;
    // };
    
    const manageRestoreEvidence = async () => {
      const response = await updateEvidence({
        evidenceId: [row.id],
        // @ts-ignore
        newFolderFolderId: null,
        isDelete: false
      }).then(() => {
        let currentRow = {...row, isArchived: false};
        const updatedArchiveData = archiveData.filter((obj:any) => {
          return obj.id != currentRow.id
        }) 
        dispatch(setArchiveData(updatedArchiveData));
              
        const updatedLibraryData = libraryData.filter((obj:any) => {
          return obj.id != currentRow.id
        })     
        dispatch(setLibraryData(updatedLibraryData));
      })
    }

    const renderMoveTo = (currentData:any) => {
      let movetoList:any = [];
      if(currentData == undefined) {
        archiveFolderList.map((d:any) => {
          movetoList.push({ label: d.parentFolderId == null ? t('ALL_DOCUMENTS') : d.name, pathId: d.id, isDisabled: row.archivedEvidenceFolderId == d.id, subMenu: [] });
        })
      } else{
        archiveFolderList.map((d:any) => {
          movetoList.push({ label: d.parentFolderId == null ? t('ALL_DOCUMENTS') : d.name, pathId: d.id, isDisabled: currentData.archivedEvidenceFolderId == d.id, subMenu: [] });
        })
      }
      const tempMenuOptions = menuOptions.filter((x:any) => x.label != t('TDM_FILE_MOVE_TO'));
      setMenuOptions([...tempMenuOptions, {label: t('TDM_FILE_MOVE_TO'), isDisabled: isAdmin, icon: faFileExport, subMenu:[...movetoList]}])
    }
    useEffect(() => {
      renderMoveTo(undefined);
    }, [archiveFolderStructure, selectedArchiveFolder, archiveFolderList]);
    
    const manageMovetoClick = (newDirectory:any) => {
      let currentEvidence = row.id;
      let newEvidenceFolder = newDirectory?.pathId;

      if(newEvidenceFolder) {
        const moveEvidencePath = async () => {
          const response = await updateEvidence({
            evidenceId: [currentEvidence],
            newFolderFolderId: newEvidenceFolder,
            isArchived: true,
            isDelete: false
          })
        }
        moveEvidencePath().then(() => {
          let currentRow = {...row, archivedEvidenceFolderId: newEvidenceFolder};
          const updatedArchivedData = archiveData.map((obj:any) => {
            if(obj.id == currentRow.id) {
              return currentRow;
            } return obj;
          }) 
          dispatch(setArchiveData(updatedArchivedData));
          renderMoveTo(currentRow);
        })
      }
    }

    const manageSelectClick = () => { 
      let tempData:any = archiveData.map((d:any) => {
        if(d.id == row.id) {
          return {...row, isSelected: !row.isSelected};
        } return d;
      });
      // @ts-ignore
      dispatch(setArchiveData([...tempData]));

      const tempList = tempData.filter((d:any) => d.isSelected);

      if(tempList.length && !isOpenActionBar) {
        // @ts-ignore
        dispatch(updateIsOpenActionBar(true));
      } else if(tempList.length == 0 && isOpenActionBar) {
        // @ts-ignore
        dispatch(updateIsOpenActionBar(false));
      }
    }

    const title:any = decodeURIComponent(row.title);
    return (
        <>
        <TableRow
          className='hoverBG'
          key={`collapsable-table-row-${i}`}
          sx={{ '& > *': { borderBottom: 'unset' } }}
        >
        <TableCell>
          <Box>
           {/* @ts-ignore */}
            <CheckBox
              // @ts-ignore
              onClick={manageSelectClick}
              isHidden={isAdmin}
              isOn={row?.isSelected}
              isMain={true}
            />
          </Box>
        </TableCell>
          <TableCell>
            {
              (row?.evaluationStandards?.length || row?.licenceStandards?.length) ?
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton> : ''
            }
          </TableCell>
          <TableCell>
            <Tooltip title={title} arrow placement='top'>
              {/* {row.title.length > 15 ? row.title.slice(0,14) + '...' : row.title} */}
              {title}
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title={title.split('.')[title.split('.').length-1]} arrow placement='top'>
              {<img src={getImageSrc(title.split('.')[title.split('.').length-1])} alt="" />}
            </Tooltip>
          </TableCell>
          {/* <TableCell>26kb</TableCell> */}
          <TableCell>{new Date(row.archivedDate).toLocaleDateString()}</TableCell>
          <TableCell>{row?.evaluationStandards?.length}</TableCell>
          <TableCell>{row?.licenceStandards?.length}</TableCell>
          <TableCell>
            <NestedThreeDotMenu options={menuOptions} manageClick={(e:any) => {handleThreeDotEvents(e)}} pathId={row.pathId}/>
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeads={head}
                />
                <TableBody>
                  {
                    visibleRows?.length > 0 ?
                    visibleRows.map((d:any) => {
                        return(
                            <TableRow className='hoverBG'>
                              <TableCell component="th" scope="row">{t(d.type)}</TableCell>
                              <TableCell>{d.criteriaUid}</TableCell>
                              <TableCell>{d.criteriaName}</TableCell>
                              <TableCell>{d.uid}</TableCell>
                              <TableCell>{d.name}</TableCell>
                              <TableCell>
                                {/* {(
                                    !isAdmin &&
                                    !isOldCycle &&
                                    (!desirePlan.data?.isReadyToReview || !row.evaluationCriteria.length)
                                  ) &&
                                  <Tooltip title={'Unlink'} arrow placement='top'>
                                    <span onClick={() => unlinkTdm(d.id, (d.type == 'Evaluation' ? 0 : 1))}>
                                      <FontAwesomeIcon icon={faLinkSlash}/>
                                    </span>
                                  </Tooltip>
                                } */}
                              </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow>
                      <TableCell colSpan={7} align='center'>
                        No Records Found
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>
    );
}

export default Row;