import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FacilitiesTable from "../../components/FacilitiesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getFacilitiesDetails } from "../../api/dataCapture";
import { setFacilitiesDetails } from "../../redux/reducers/dataCaptures";

const FacilityDetails = () => {
    const [addFactilities, setAddFactilities] = React.useState(false);
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    useEffect(() => {
        const fetchFacilitiesDetails = async () => {
            const response = await getFacilitiesDetails({clubId: clubDetails.id, isPast: false});
            dispatch(setFacilitiesDetails(response));
        }
        fetchFacilitiesDetails();
    }, []);
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography className='standardsHeading uppercase'>facilities</Typography>
                <Button className='smallButton' sx={{ float: 'right' }} 
                disableElevation 
                disabled={addFactilities} 
                variant='contained' size='small' 
                onClick={() => {setAddFactilities(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                    {'Add facilities'}
                </Button>
            </Box>
            <FacilitiesTable addData={addFactilities} handleClick={() => setAddFactilities(false)} />
        </Box>
    )
}

export default FacilityDetails;