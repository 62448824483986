import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material/";
import SelectBoxPrimary from "../../components/SelectBoxPrimary";
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { getLicenceStandardsEvaluation } from "../../api/licence";

const EvaluationContents = () => {
    const [evaluation, setEvaluation]:any = useState({});
    useEffect(() => {
        const fetchEvaluation = async () => {
            try {
                const response = await getLicenceStandardsEvaluation();
                setEvaluation(response);
            } catch {

            }
        }
        fetchEvaluation();
    }, [])
    return(
        <>
            <Box sx={{ display: 'flex', justifyContent: "end", mb:1 }}>
                {/* <Typography className='standardsHeading uppercase'>Evaluation</Typography> */}
                <SelectBoxPrimary label={''} options={[1,3]} isStatus={true} selected={evaluation.status}/>
            </Box>
            <JoyCssVarsProvider>
                <Textarea sx={{ borderRadius: '6px', mt: 0, width: '100%' }} onChange={(e) => {}} placeholder='Add comment' minRows={4} maxRows={4} value={evaluation.comment}/>
            </JoyCssVarsProvider>
        </>
    )
}

export default EvaluationContents;