import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContactDetails } from "../../api/dataCapture";
import { setContactDetails } from "../../redux/reducers/dataCaptures";

const ContactDetails = () => {
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const data = useSelector((state:any) => state.dataCaptures.contactDetails);
    useEffect(() => {
        const fetchContactDetails = async () => {
            const response = await getContactDetails({clubId: clubDetails.id, isPast: false});
            dispatch(setContactDetails(response));
        }
        fetchContactDetails();
    }, []);
    return (
        <Box className='liceDataSubContainer'>
            <Typography className='standardsHeading uppercase'>contact person details</Typography>
            <Box  className={'licenceDataCDWrapper'}>
                <Box className={'licenceDataCDs'}>
                    <Typography>Name</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.name}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Department/Position</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.department}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Telephone Number</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.phone}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Fax</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.fax}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Mobile Phone Number</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.mobile}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Email Address</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.email}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Work Location</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.workLocation}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ContactDetails;