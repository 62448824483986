import { standards } from "../../assets/staticData";
import { createSlice } from "@reduxjs/toolkit";
import logo from '../../assets/icons/JLogoLight.svg'

let initialData: any = {
    clubDesirePlan: {},
    clubLicence: {},
};

export const clubData = createSlice({
    name: 'clubData',
    initialState: initialData,
    reducers: {
        setClubDesirePlan: (state: any, action: any) => {
            state.clubDesirePlan =  action.payload;
            return state;
        },
        setClubLicence: (state: any, action: any) => {
            state.fetchClubLicence =  action.payload;
            return state;
        },
    }
})

export const { setClubDesirePlan, setClubLicence } = clubData.actions;
export default clubData.reducer;