import React from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePowerpoint, faFileExcel } from '@fortawesome/free-solid-svg-icons';

export const EvidenceWrapper = (props: {name: string}) => {
    const { name } = props;
    const fileType = name.split('.');
    return(
        <React.Fragment>
            <Box sx={{ display: "flex", my: 1 }}>
                <FontAwesomeIcon className={'smallFileIcon'} icon={(fileType[fileType.length - 1] === 'xlsx') ? faFileExcel : faFilePowerpoint} />
                <Typography className='fs13'>{name}</Typography>
            </Box>
        </React.Fragment>
    )
}
