import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import TableSingleSelectBox from "../SearchSelectBox/TableSingleSelectBox";
import { useDispatch, useSelector } from "react-redux";
import { deleteInterviewer, getClubReviewsInfo, syncInterviewers } from "../../api/evaluation";
import { setInterviewers } from "../../redux/reducers/evaluation";
import InterviewTeamSelectBox from "../InterviewTeamSelectBox";

const DataPreview = (props:any) => {
    const { label, data } = props;
    const [isAdd, setIsAdd] = useState(false);
    const interviewTeams = useSelector((state:any) => state.evaluation.interviewTeams);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const dispatch = useDispatch();

    const updateInterviewUsers = async (updatedUser:any, currentData:any) => {
      if (!updatedUser) {
        const response = await deleteInterviewer(currentData?.id);
        const tempArr = data.filter((item:any) => currentData.id != item.id);
        dispatch(setInterviewers(tempArr));
      } else {
        const reviewData = await getClubReviewsInfo({clubId: clubDetails?.id});
        const response = await syncInterviewers({ onSiteReviewId: reviewData?.onSiteReviewId, user: updatedUser });
        const updatedInterviewer = (response && response.userId) ? interviewTeams.find((item:any) => response.userId == item.id) : (response && response);
        const tempArr:any = [...data, updatedInterviewer];
        dispatch(setInterviewers(tempArr));
      }
      setIsAdd(false);
    }
    return (
        <Box sx={{ display: "flex" }} onBlur={() => {setIsAdd(false)}}>
          <Typography className="mtbAuto mr5 fs13 fw800">{label}</Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gridTemplateRows: 'auto auto' }}>
            {
                data && data.map((d:any) => {
                  if(isAdd) {
                    return(
                      <Box sx={{ width: '120px' }}>
                      <InterviewTeamSelectBox
                      value={d}
                      manageSelect={(e:any) => {updateInterviewUsers(e, d)}}
                      />
                      </Box>
                    )
                  } else {
                    return <Typography className="mtbAuto fs13 ml10">{`${d?.displayName}, ${d?.roleName}`}</Typography>
                  }
                })
            }
            {
              isAdd &&
              <Box sx={{ width: '120px' }}>
              <InterviewTeamSelectBox
              value={null}
              manageSelect={(e:any) => {updateInterviewUsers(e, null)}}
              />
              </Box>
            }
          </Box>
          <Tooltip title={'Add Interview Team'} arrow placement="top">
            <FontAwesomeIcon className={'icon mtbAuto ml10'} onClick={() => {setIsAdd(true)}} icon={faPlus} />
          </Tooltip>
        </Box>
    )
}

export default DataPreview;