import axios from 'axios';
import qs from 'qs';
// import { makeLogout, getToken } from 'src/utils/auth';

const stringifyConfig = {
  arrayFormat: 'brackets' as 'brackets',
};

export const stringify = (data: object | any) => {
  const dataset = Object.keys(data).forEach(key => {
    if (!data[key] || data[key] === null) {
      // eslint-disable-next-line no-param-reassign
      delete data[key];
    }
  });
  return qs.stringify(dataset);
};

export const encodeQueryParams = (queryParameters:any) => {
  const queryParams =
    queryParameters && Object.keys(queryParameters).length ? qs.stringify(queryParameters, stringifyConfig) : null;
  return queryParams ? `?${queryParams}` : '';
};

export const encodeBody = (body:any) => {
  if (body && !(body instanceof Array) && !Object.keys(body).length) {
    return undefined;
  }
  return JSON.stringify(body);
};

// axios.defaults.baseURL = window.config.BASE_URL || 'https://jleague.circlesquare.dev';

export const getAuthHeaders = () => {
  const headers: any = {};
  const token = localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

axios.interceptors.request.use(request => {
  request.headers = {
    ...getAuthHeaders(),
    ...request.headers,
  };
  request.withCredentials = true;
  return request;
});

// axios.interceptors.response.use(
//   response => response,
//   err => {
//     if (err.response) {
//       if (err.response.status === 401) {
//         makeLogout();
//       }
//       if (err.response.status === 404) {
//         // window.location.href = '/404';
//       }
//     }

//     return Promise.reject(err.response?.data);
//   },
// );
