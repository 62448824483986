import React from 'react';
import styled from 'styled-components';
import extDefault from '../../../../assets/icons/extDefault.svg';
import extVideo from '../../../../assets/icons/extVideo.svg';
import extPdf from '../../../../assets/icons/extPdf.svg';
import extImage from '../../../../assets/icons/extImage.svg';
import extSpread from '../../../../assets/icons/extSpread.svg';
import extWord from '../../../../assets/icons/extWord.svg';
import extPpt from '../../../../assets/icons/extPpt.svg';

const Div = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 15px;
    max-height: 15px;
  }
`;

export const getImageSrc = (ext: string) => {
  switch (ext) {
    case 'pdf':
      return extPdf;

    case 'mp4':
      return extVideo;
    case 'mpg':
      return extVideo;
    case 'm4v':
      return extVideo;

    case 'png':
      return extImage;
    case 'svg':
      return extImage;
    case 'jpg':
      return extImage;
    case 'jpeg':
      return extImage;
    case 'webp':
      return extImage;

    case 'csv':
      return extSpread;
    case 'xlsx':
      return extSpread;
    case 'xls':
      return extSpread;

    case 'docx':
      return extWord;

    case 'pptx':
      return extPpt;
    case 'ppt':
      return extPpt;

    default:
      return extDefault;
  }
};

interface FileTypeProps {
  ext: string;
}

export const FileType: React.FC<FileTypeProps> = ({ ext }) => {
  return (
    <Div>
      <img src={getImageSrc(ext)} alt="" />
    </Div>
  );
};
