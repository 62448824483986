import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StatusIndicatorSmall from "../StatusIndicatorSmall";
import './styles.css';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { siteStatusCalculator } from "../../common/logics";
import { formatDate } from "../../common/StaticExports";

const ContentOverview = (props:any) => {
    const { data, from, criterias} = props;
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const [t] = useTranslation();
    const [offSiteStatuses, setOffSiteStatuses]:any = useState([]);
    const [onSiteStatuses, setOnSiteStatuses]:any = useState([]);
    const [interimReportStatus, setInterimReportStatus]:any = useState([]);
    const [finalReportStatus, setFinalReportStatus]:any = useState([]);
    useEffect(() => {
        var tempOff:any =[];
        var tempOn:any  =[];
        var tempInterim:any =[];
        var tempFinal:any = [];
        if(criterias && criterias.length) {
            criterias.forEach((element:any) => {
                tempOff.push(element.offSiteStatus);
                tempOn.push(element.onSiteStatus);
                tempInterim.push(element.interimReportStatus);
                tempFinal.push(element.finalReportStatus);
            });
            setOffSiteStatuses(tempOff);
            setOnSiteStatuses(tempOn);
            setInterimReportStatus(tempInterim);
            setFinalReportStatus(tempFinal);
        }
    }, [criterias]);
    return (
        <Box className={'contentOverviewWrapper'}>
            <Typography sx={{ fontWeight: 800, fontSize: '14px' }} className="uppercase">
                {from == 'ON_SITE' ? (data == 'Not Scheduled' ? 'Not Scheduled' : formatDate(data)) : data.name}
            </Typography>
            {
                from == 'SA' &&
                <Box className={'statusWrapperContainer'}>
                    <StatusIndicatorSmall reportType={'siteReview'} status={data.offSite != undefined ? data.offSite : siteStatusCalculator(offSiteStatuses)} from={'overview'} label={t("OFF_SITE")}/>
                    {
                        (clubDetails.currentEvaluationPhase == "ON_SITE" || clubDetails.currentEvaluationPhase == "INTERIM_REPORT" ||
                             clubDetails.currentEvaluationPhase == "FINAL_REPORT") ?
                        <StatusIndicatorSmall reportType={'siteReview'} status={data.onSite != undefined ? data.onSite : siteStatusCalculator(onSiteStatuses)} from={'overview'} label={t("ON_SITE")}/> : <Typography>-</Typography>
                    }
                    {
                        (clubDetails.currentEvaluationPhase == "INTERIM_REPORT" || clubDetails.currentEvaluationPhase == "FINAL_REPORT") ?
                            <StatusIndicatorSmall reportType={'siteReview'} status={data.interimReport != undefined ? data.interimReport : siteStatusCalculator(interimReportStatus)} from={'overview'} label={t("INTERIM_REPORT")}/> : <Typography>-</Typography>
                    }
                    {
                        (clubDetails.currentEvaluationPhase == "FINAL_REPORT") ?
                        <StatusIndicatorSmall reportType={'siteReview'} status={data.finalReport != undefined ? data.finalReport : siteStatusCalculator(finalReportStatus)} from={'overview'} label={t("FINAL_REPORT")}/> : <Typography>-</Typography>
                    }
                </Box>
            }
        </Box>
    );
}

export default ContentOverview;