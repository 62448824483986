import React from 'react';
//internal or external components and containers
import { Box, Typography, Avatar } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { colors } from '../../common/pallette/colors';
import { useTranslation } from 'react-i18next';
import { report } from 'process';

const commonStatus = [
    {statusName: 'Not Evaluated', color: colors.statusColors.notEvaluated},
    {statusName: 'Not Met', color: colors.statusColors.notMet},
    {statusName: 'Partial', color: colors.statusColors.partial},
    {statusName: 'Met', color: colors.statusColors.met}
];

const interimReportStatus = [
    {statusName: 'Not Met', color: colors.statusColors.notMet},
    {statusName: 'Partial', color: colors.statusColors.partial},
    {statusName: 'Met', color: colors.statusColors.met}
];

const finalReportStatus = [
    {statusName: 'Not Started', color: '#FFF'},
    {statusName: 'Not Met', color: colors.statusColors.notMet},
    {statusName: 'Partial', color: colors.statusColors.partial},
    {statusName: 'Met', color: colors.statusColors.met}
];

const rabgStatus = [
    {statusName: 'MISSED', color: colors.lightishRed},
    {statusName: 'AT_RISK', color: colors.tangerine},
    {statusName: 'ON_TRACK', color: colors.vibrantGreen},
    {statusName: 'COMPLETE', color: colors.gold},
    {statusName: 'NOT_STARTED', color: colors.middleGrey}
];

const siteReviewStatus = [
    {statusName: 'NOT_EVALUATED', color: colors.notEvalGrey},
    {statusName: 'NOT_MET', color: colors.lightishRed},
    {statusName: 'PARTIAL', color: colors.tangerine},
    {statusName: 'MET', color: colors.shamrockGreen},
];

const liecenceHome = [
    {statusName: 'NOT_MET', color: colors.lightishRed},
    {statusName: 'PARTIAL', color: colors.tangerine},
    {statusName: 'MET', color: colors.vibrantGreen},
    {statusName: '_', color: colors.gold},
    {statusName: 'NOT_EVALUATED', color: colors.middleGrey},
];

const liecenceAdmin = [
    {statusName: 'DRAFT', color: colors.middleGrey},
    {statusName: 'ISSUED', color: colors.vibrantGreen},
]

const displayStatus = (key: String, status: number) => {
    switch (key) {
        case 'interim':
            return interimReportStatus[status];

        case 'final':
            return finalReportStatus[status];
            
        case 'rabg':
            return rabgStatus[status];
        
        case 'siteReview':
            return siteReviewStatus[status];

        case 'liecenceHome':
            return liecenceHome[status];
            
        case 'liecenceAdminAction':
            return liecenceAdmin[status];
    
        default:
            return commonStatus[status]
    }
}

const StatusIndicatorSmall = (props: any) => {
    const { reportType, status, from, label, minW } = props;
    const s: number = +status;
    const [t] = useTranslation();
    return(
        <React.Fragment>
            <Box sx={{display: 'flex', minWidth: (minW != undefined) ? minW : 90}}>
                {(label != '' && label != undefined) && 
                <Typography sx={{ fontSize: '12px', m: 'auto', mr: '10px' }}
                    className={from == "overview" ? "uppercase" : ""}>{
                        label == 'generate' ? t(displayStatus(reportType, s).statusName) : label
                        }</Typography>}
                <Avatar sx={{
                    width: 10,
                    height: 10,
                    mr: 1,
                    mt: 'auto',
                    mb: 'auto',
                    bgcolor: displayStatus(reportType, s)?.color,
                    border: displayStatus(reportType, s)?.color == '#FFF' ?
                    '1px solid black' : 
                    `1px solid ${displayStatus(reportType, s)?.color}`}}>
                        {' '}
                </Avatar>
                {(label == '' || label == undefined) && t(displayStatus(reportType, s)?.statusName)}
            </Box>
        </React.Fragment>
    )
}

export default StatusIndicatorSmall;