import React, { useEffect, useState } from 'react';
//internal and external components and containers import
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SwitchButton } from '../../components/SwitchButton';
import ActionsTable from '../../components/ActionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getStandrdsActions } from '../../api/evaluation';
import { setActionsList, setCriteriaActions, setDepartmentList, setProjectList, setStaffList } from '../../redux/reducers/evaluation';
import { getAllActions } from '../../api/action';
import { getStaff } from '../../api/user';
import { getClubDepartments, getProjectsByClub } from '../../api/club';
import { setLicenceCriteriaActions } from '../../redux/reducers/licence';
import { getLicenceStandardsAction } from '../../api/licence';

export const Actions = (props:any) => {
    const { isOpen, module } = props;
    const [showAllctions, setShowAllActions] = React.useState(false);
    const [addAction, setAddAction] = React.useState(false);
    const [isFetchingActions, setIsFetchingActions] = React.useState(false);
    const [isAddAcionLoad, setIsAddAcionLoad] = useState(false);
    const currentEvalCriteria = useSelector((state:any) => state.evaluation.selectedSaCriteria);
    const selectedLicenceCriteria = useSelector((state: any) => state.licence.selectedLicenceCriteria);
    const currentClub = useSelector((state:any) => state.clubDetails);
    const criteriaActions = useSelector((state:any) => state.evaluation.saCriteriaActions);
    const licenceCriteriaActions = useSelector((state:any) => state.licence.criteriaActions);
    const dispatch = useDispatch();
    const [actionList, setActionList] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState({});
    const tableHead = [
        {
            key: '', displayName: '',
        },
        {
            key: 'status', displayName: 'status',
        },
        {
            key: 'actionComment', displayName: 'action',
        },
        {
            key: 'description', displayName: 'description',
        },
        {
            key: 'standardsId', displayName: 'standard',
        },
        {
            key: 'dueDate', displayName: 'due date',
        },
        {
            key: 'owner', displayName: 'owner',
        },
        {
            key: 'department', displayName: 'department',
        },
        {
            key: 'project', displayName: 'project',
        },
        {
            key: 'isComplete', displayName: 'complete',
        },
        {
            key: '', displayName: '',
        },
      ];

      useEffect(() => {
        const fetchActions = async () => {
            setIsFetchingActions(true);
            switch(module) {
                case 'evaluation':
                    const evalRes = await getStandrdsActions({standardId: 0, clubId: currentClub.id, criteriaId: currentEvalCriteria.id});
                    dispatch(setCriteriaActions(evalRes));
                    setActionList(evalRes);
                    break;
                case 'licence':
                    const licenceRes = await getLicenceStandardsAction({standardId: 0, clubId: currentClub.id, criteriaId: selectedLicenceCriteria.id});
                    dispatch(setLicenceCriteriaActions(licenceRes));
                    setActionList(licenceRes);
                    break;
            }
            setIsFetchingActions(false);
        }
        isOpen && !addAction &&
        fetchActions();

        switch(module) {
            case 'evaluation':
                setSelectedCriteria(currentEvalCriteria);
                setActionList(criteriaActions);
                break;
            case 'licece':
                setSelectedCriteria(selectedLicenceCriteria);
                setActionList(licenceCriteriaActions);
                break;
        }
      }, [currentEvalCriteria, selectedLicenceCriteria, isOpen, addAction, module]);

      const manageAddAction = () => {
        const fetchActionList = async () => {
            setIsAddAcionLoad(true);
            const response = await getAllActions({ clubId: currentClub.id, isSelfAssessment: true });
            return response;
        }
        const fetchStaffList = async () => {
            const response = await getStaff({clubId: currentClub.id});
            return response;
        }
        const fetchClubProject = async () => {
            const response = await getProjectsByClub({clubId: currentClub.id});
            return response;
        }
        const fetchDepartments = async () => {
            const response = await getClubDepartments({clubId: currentClub.id});
            return response;
        }

        if(currentClub?.id) {
            fetchActionList().then((res) => {
                dispatch(setActionsList(res));
            });
            fetchStaffList().then((res) => {
                dispatch(setStaffList(res));
            });
            fetchClubProject().then((res) => {
                dispatch(setProjectList(res));
                setIsAddAcionLoad(false);
            });
            currentClub.id &&
            fetchDepartments().then((res:any) => {
                dispatch(setDepartmentList(res));
            })
        }
        setAddAction(true);
      }

    return(
        <React.Fragment>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <SwitchButton label='Show all actions' isOn={showAllctions} handleOnClick={() => setShowAllActions(!showAllctions)}/>
                    <Button disableElevation variant='contained' size='small' startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={() => manageAddAction()} disabled={addAction}>
                        {'Add Action'}
                    </Button>
                </Box>
                <ActionsTable data={actionList} 
                head={tableHead} 
                isAction={true} 
                addAction={addAction} 
                isLoading={isFetchingActions}
                isAddLoading={isAddAcionLoad}
                handleClick={() => setAddAction(false)}/>
            </Box>
        </React.Fragment>
    )
} 