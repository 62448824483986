import React from "react";
//internal and external components/containers
import { Box, styled, Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    display: 'inline-block',
    marginRight: 10,
    marginTop: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

const ProgressBar = (props: { percent: number; width?: string }) => {
    const { percent, width } = props;
    return(
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <BorderLinearProgress sx={{ width: !width ? 100 : width }} variant="determinate" value={percent} />
                {`${percent}%`}
            </Box>
        </React.Fragment>
    )
}

export default ProgressBar;