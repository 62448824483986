import { DesktopTimePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Box, TextField, ThemeProvider, createTheme } from "@mui/material";
import dayjs from "dayjs";
import { formatDateTimeToHHmm, formatDateTimeTomm } from "../../common/StaticExports";

const TableTimePicker = (props:any) => {
    const { format, views, textColor, value, manageChange } = props;
    const theme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        border: 0
                    },
                    input: {
                        padding: '0px !important'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: 0,
                        }
                    },
                    notchedOutline: {
                        borderRadius: 0,
                        borderTop: 0,
                        borderRight: 0,
                        borderLeft: 0,
                        borderWidth: 0
                    },
                    input: {
                        color: textColor,
                        fontSize: '12px'
                    }
                }
            },
        }
    })
    const [time, setTime] = useState(value);
    const manageUpdate = () => {
        switch(views.length) {
            case 1:
                const mm = formatDateTimeTomm(time);
                manageChange(mm);
                break;
            case 2:
                const hhmm = formatDateTimeToHHmm(time);
                manageChange(hhmm);
                break;
        }
    }

    return(
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box onBlur={() => {manageUpdate()}}>
                <DesktopTimePicker 
                format={format} 
                views={views}
                value={time ? dayjs(time) : null}
                onChange={(e) => {setTime(e)}}
                ampm={false}
                slotProps={{
                    textField:{
                        InputProps: {
                          endAdornment: null, // Removes the calendar icon
                        },
                    }
                }}/>
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
        
    )
}

export default TableTimePicker;