import React, { useState } from "react";
import { useEffect } from "react";
import {Typography, Box} from "@mui/material";
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { colors } from "../../common/pallette/colors";
import { useSelector } from "react-redux";
import { getLicenceStandardsSAComments } from "../../api/licence";
import { AvatarAndName } from "../../components/AvatarAndName";

const CommentsContents = (props: {standardsId?: any}) => {
    const { standardsId } = props;
    const currentClub = useSelector((state:any) => state.clubDetails);
    const [comments, setComments] = useState('');
    useEffect(() => {
        const fetchEvidences = async () => {
            try {
                const response = await getLicenceStandardsSAComments({standardsId: standardsId, clubId: currentClub.id});
                setComments(response);
            } catch {

            }
        }
        (standardsId !== undefined && standardsId) && fetchEvidences();
    }, [])
    return(
        <>
        {/* <Typography className='standardsHeading uppercase'>Comments</Typography> */}
        <Box className={'sdCommentsWrapper'}>
            <Box className={'standardsDetailsCommentsWrapper mr10'} sx={{ bgcolor: colors.grey.bright }}>
                <Typography className='fs13 SDcomments'>{comments}</Typography>
                <AvatarAndName avatarName={'Anonymous'}/>
            </Box>
            <JoyCssVarsProvider>
                <Textarea sx={{ borderRadius: '6px', mt: 0, width: '50%' }} onChange={(e) => {}} placeholder='Add report comment text' minRows={4} maxRows={4} value={''}/>
            </JoyCssVarsProvider>
        </Box>
        </>
    )
}

export default CommentsContents;