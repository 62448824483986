import React, { useEffect, useRef, useState } from 'react';
import '../styles.css';
//internal and external componentscontainers
import { Typography, Paper, Box, Fade, Popper, ClickAwayListener, Button, ThemeProvider, createTheme, Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faXmark,faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import SelectBoxPrimary from '../../../components/SelectBoxPrimary';
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import ActionsTable from '../../../components/ActionsTable';
import { AvatarAndName } from '../../../components/AvatarAndName';
import { EvidenceWrapper } from '../../../components/EvidenceWrapper';
import { colors } from '../../../common/pallette/colors';
import { getStandardsEvidences, getStandardsSelfAssessmentComment, getStandrdsActions } from '../../../api/evaluation';
import { useDispatch, useSelector } from 'react-redux';
import { setActionsList, setProjectList, setSaActions, setSaComments, setSaEvidences, setStaffList } from '../../../redux/reducers/evaluation';
import EvidenceList from '../../../components/EvidenceList';
import { getAllActions } from '../../../api/action';
import { getStaff } from '../../../api/user';
import { getProjectsByClub } from '../../../api/club';
import { evidenceToStandard, uploadFile } from '../../../api/evidence';
import { formatDateTime, genUID } from '../../../common/StaticExports';
import EvidencesTable from '../../../components/EvidencesTable';
import SplitButton from '../../../components/SplitButton';
import EvidencePopup from '../../../components/EvidencePopup';
import { baseUrl } from '../../../api/config';

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 50
                },
            }
        },
    }
})


const SAStandardsDetail = (props: any) => {
    const { detail, isOpen, handleClose } = props;
    const currentClub = useSelector((state:any) => state.clubDetails);
    const [addAction, setAddAction] = React.useState(false);
    const [addEvidence, setAddEvidence] = React.useState(false);
    const [isOpenLibModal, setIsOpenLibModal] = React.useState(false);
    const actions = useSelector((state:any) => state.evaluation.saActions);
    const evidences = useSelector((state:any) => state.evaluation.saEvidences);
    const selfAssessmentComment = useSelector((state:any) => state.evaluation.saComments);
    const [isLoadingActions, setIsLoadingActions] = useState(false);
    const [isLoadingEvidences, setIsLoadingEvidences] = useState(false);
    const [isAddAcionLoad, setIsAddAcionLoad] = useState(false);
    const isAdmin = useSelector((state:any) => state.common.isAdmin);
    const dispatch = useDispatch();
    const fileInputRef:any = useRef(null);

    const tableHead = [
        {
            key: '', displayName: '',
        },
        {
            key: 'status', displayName: 'status',
        },
        {
            key: 'actionComment', displayName: 'action',
        },
        {
            key: 'description', displayName: 'description',
        },
        {
            key: 'dueDate', displayName: 'due date',
        },
        {
            key: 'owner', displayName: 'owner',
        },
        {
            key: 'isComplete', displayName: 'complete',
        },
        {
            key: '', displayName: '',
        },
      ];

      React.useEffect(() => {
        if(isOpen && detail?.id && currentClub?.id) {
            const fetchActions = async () => {
                setIsLoadingActions(true);
                const response = await getStandrdsActions({standardId: detail.id, clubId: currentClub.id});
                return response;
            }
    
            const fetchEvidences = async () => {
                setIsLoadingEvidences(true);
                const response = await getStandardsEvidences({standardId: detail.id, clubId: currentClub.id});
                return response;
            }
    
            const fetchSelfAssessmentComments = async () => {
                const response = await getStandardsSelfAssessmentComment({standardId: detail.id, clubId: currentClub.id});
                return response;
            }
    
            fetchActions().then((res) => {
                dispatch(setSaActions(res));
                setIsLoadingActions(false);
            });
            fetchEvidences().then((res) => {
                dispatch(setSaEvidences(res));
                setIsLoadingEvidences(false);
            });
            fetchSelfAssessmentComments().then((res) => {
                dispatch(setSaComments(res));
            });
        }
      }, [isOpen, detail, currentClub]);

      const manageAddAction = () => {
        const fetchActionList = async () => {
            setIsAddAcionLoad(true);
            const response = await getAllActions({ clubId: currentClub.id, isSelfAssessment: true });
            return response;
        }
        const fetchStaffList = async () => {
            const response = await getStaff({clubId: currentClub.id});
            return response;
        }
        const fetchClubProject = async () => {
            const response = await getProjectsByClub({clubId: currentClub.id});
            return response;
        }

        if(currentClub?.id) {
            fetchActionList().then((res) => {
                dispatch(setActionsList(res));
            });
            fetchStaffList().then((res) => {
                dispatch(setStaffList(res));
            });
            fetchClubProject().then((res) => {
                dispatch(setProjectList(res));
                setIsAddAcionLoad(false);
            });
        }
        setAddAction(true);
      }

      const handleFileChange = (e:any) => {
        console.log(e.target.files[0]);
        
        const params:any = {
            clubId: currentClub.id,
            standardId: detail.id,
            file: e.target.files[0],
            standardKind: 0,
            currentFolderId: 0
        }

        const fileUpload = async () => {
            const response = await uploadFile(params);
            return response;
        }

        e.target.files[0] && currentClub.id && detail.id &&
        fileUpload().then((res) => {
            const tempArr:any = [...evidences, res];
            dispatch(setSaEvidences(tempArr));
            setAddEvidence(false);
        })
      }

      const manageEvidenceClick = (label:any) => {
        switch(label) {
            case 'Add Evidence':
                fileInputRef.current.click();
                setAddEvidence(true);
                break;
            case 'Add Evidence From Library':
                setAddEvidence(true);
                setIsOpenLibModal(true);
                break;
        }
      }

      const linkLibraryFile = (fileData:any) => {
        const linkToStandard = async () => {
            const response = await evidenceToStandard({fileId: fileData.id, standardId: detail.id});
            return response;
        }
        linkToStandard().then((res) => {
            const tempArr:any = [...evidences, res];
            dispatch(setSaEvidences(tempArr));
            setAddEvidence(false);
            setIsOpenLibModal(false);
        })
      }
      
      const handleClickInsidePopper = (event: MouseEvent) => {
          event.stopPropagation();
          // handleIsOpen()
      }

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popper className='popperStyle' open={isOpen} transition>
                    {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={() => {handleClose()}}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ p: 2, width: '1600px', mt: '70px', height: '700px' }} onClick={() => handleClickInsidePopper}>
                            <Box className={'detailsWrapper'} sx={{ mt: 0 }}>
                                <Typography className='fs13 fw700'>{ genUID(detail.uid) + ' ' + detail.name}</Typography>
                                <Typography className='fs13'>{detail.description}</Typography>
                            </Box>
                            <Box className={'standardDetailsContainer'}>
                                <Box className={'detailsWrapper'}>
                                    <Typography className='standardsHeading uppercase'>Actions</Typography>
                                    <Button className={!isAdmin ? 'smallButton' : 'displayNone'} sx={{ float: 'right' }} disableElevation onClick={() => {manageAddAction()}} variant='contained' size='small' startIcon={<FontAwesomeIcon icon={faPlus} />} disabled={addAction}>
                                        {'Add Action'}
                                    </Button>
                                    <Box sx={{ mt: !isAdmin ? 6 : 0 }}>
                                        <ActionsTable data={actions}
                                            head={tableHead}
                                            currentStandard={detail}
                                            isAction={false} 
                                            addAction={addAction} 
                                            isLoading={isLoadingActions} 
                                            isAddLoading={isAddAcionLoad}
                                            bg={'#FAFAFA'}
                                            handleClick={() => setAddAction(false)}/>
                                    </Box>
                                </Box>
                                <Box className={'detailsWrapper'}>
                                    <Typography className='standardsHeading uppercase'>Evidence</Typography>
                                    <EvidencePopup isOpen={isOpenLibModal} 
                                    manageFileSelect={(d:any) => {linkLibraryFile(d)}}
                                    manageClickAway={() => {setIsOpenLibModal(false)}}/>
                                    {
                                        !isAdmin &&
                                        <>
                                            <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            />
                                            <Box sx={{ float: 'right' }}>
                                                <SplitButton 
                                                options={['Add Evidence', 'Add Evidence From Library']}
                                                handleClick={(e:any) => {manageEvidenceClick(e)}}/>
                                            </Box>
                                            {/* <Button className='smallButton' sx={{ float: 'right' }} disableElevation 
                                            onClick={() => {fileInputRef.current.click(); setAddEvidence(true);}} variant='contained' size='small' startIcon={<FontAwesomeIcon icon={faPlus} />} disabled={addEvidence}>
                                                {'Add Evidence'}
                                            </Button> */}
                                            {/* <Button className='smallButton mr10' sx={{ float: 'right' }} 
                                            disableElevation 
                                            onClick={() => setAddEvidence(true)} 
                                            variant='contained' size='small' 
                                            startIcon={<FontAwesomeIcon icon={faPlus} />} disabled={addEvidence}>
                                                {'Add Evidence From Library'}
                                            </Button> */}
                                        </>
                                    }
                                        <Box sx={{ mt:!isAdmin ? 6 : 0 }}>
                                            {/* <EvidenceList data={evidences} isLoading={isLoadingEvidences} /> */}
                                            <EvidencesTable data={evidences} isLoading={isLoadingEvidences} bg={'#FAFAFA'}/>
                                        </Box>
                                </Box>
                                <Box className={'detailsWrapper'}>
                                    <Typography className='standardsHeading uppercase'>Comments</Typography>
                                    <Box className={'sdCommentsWrapper'}>
                                        <Box className={'standardsDetailsCommentsWrapper mr10'} sx={{ bgcolor: colors.grey.bright }}>
                                            <Typography className='fs13 SDcomments'>{selfAssessmentComment?.comment}</Typography>
                                            <Box className={'SDuserDetailsWrapper'}>
                                                <AvatarAndName avatarName={selfAssessmentComment?.firstName ? `${selfAssessmentComment.firstName} ${selfAssessmentComment.lastName}` : 'Undefined'}
                                                avatarImage={selfAssessmentComment.avatarPath ? `${baseUrl}/Content${selfAssessmentComment.avatarPath}` : ''} />
                                                <Typography className='fs12' sx={{ textAlign: 'end' }}>{formatDateTime(selfAssessmentComment.updatedDate)}</Typography>
                                            </Box>
                                        </Box>
                                        <JoyCssVarsProvider>
                                            <Textarea sx={{ borderRadius: '6px', mt: 0, width: '50%' }} onChange={(e) => {}} placeholder='Add comment' minRows={4} maxRows={4} value={''}/>
                                        </JoyCssVarsProvider>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                    </ClickAwayListener>
                    )}
                </Popper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default SAStandardsDetail;