import React from 'react';
import { Box } from '@mui/material';

const Dragger = (props:any) => {
    const { onMouseDown } = props;
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Box
        onMouseDown={onMouseDown}
        sx={{
          height: '4px',
          width: '20px',
          margin: 'auto',
          backgroundColor: '#000',
          cursor: 'row-resize',
        }}
      />
      <Box
        onMouseDown={onMouseDown}
        sx={{
          height: '4px',
          width: '20px',
          margin: 'auto',
          marginTop: '2px',
          backgroundColor: '#000',
          cursor: 'row-resize',
        }}
      />
    </Box>
  );
};

export default Dragger;
