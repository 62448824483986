import { Avatar, Box, Button, createTheme, TextField, ThemeProvider, Typography } from "@mui/material";
import React, { useState } from "react";
import ChatDataContainer from "../../components/ChatDataContainer";
import { useSelector } from "react-redux";

const ChatBox = () => {
    const selectedStandardsChat = useSelector((state:any) => state.evaluation.selectedStandardsChat);
    const chatContent = {
        chatId: 1,
        criteriaId: 1,
        chats: [
            {
                id: 1,
                userId: 10,
                username: 'Vijay',
                message: 'Hi'
            },
            {
                id: 2,
                userId: 11,
                username: 'Preethi',
                message: 'Hello'
            },
            {
                id: 3,
                userId: 12,
                username: 'Adam',
                message: 'Hi team'
            },
            {
                id: 4,
                userId: 13,
                username: 'Oliver',
                message: 'Hello everyone'
            },
            {
                id: 2,
                userId: 11,
                username: 'Preethi',
                message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'
            },
            {
                id: 1,
                userId: 10,
                username: 'Vijay',
                message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industryLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'
            }
        ]
    }
    const [chatMessage, setChatMessage]:any = useState(chatContent);
    const [newChat, setNewChat] = useState('');

    const manageSendClick = () => {
        const tempNewChat = {
            id: 1,
            userId: 10,
            username: 'Vijay',
            time: true,
            message: newChat
        }
        const tempChat = {
            ...chatMessage,
            chats: [tempNewChat, ...chatMessage.chats]
        };
        setChatMessage(tempChat);
        setNewChat('');
    }

    const theme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        padding: '8px !important'
                    }
                }
            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', height: '100%' }}>
                {
                    Object.keys(selectedStandardsChat).length ?
                    <>
                        <Box sx={{ width: '100%', height: '92%', overflowY: 'auto', display: 'flex', flexDirection: "column-reverse" }}>
                            {
                                chatMessage.chats.map((data:any) => {
                                    return(
                                        <ChatDataContainer data={data}/>
                                    )
                                })
                            }
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <TextField placeholder="Message.." 
                            sx={{ width: '93%' }} 
                            value={newChat} 
                            onKeyDown={(e) => {
                                if(e.key == 'Enter'){
                                    manageSendClick();
                                }
                            }}
                            onChange={(e) => {setNewChat(e.target.value)}}/>
                            <Button disableElevation variant='contained' size='small' sx={{ ml: 'auto' }} onClick={() => {manageSendClick()}}>
                                {'Send'}
                            </Button>
                        </Box>
                    </> :
                    <Typography className="fs14 fw800 mtbAuto">Select any chat</Typography>
                }
            </Box>
        </ThemeProvider>
    );
}

export default ChatBox;