export default {
  lightishRed: '#f03a47',
  mediumBlue: '#3551b8',
  dark: '#181820',
  darkTwo: '#13131a',
  azure: '#01b0f0',
  windowsBlue: '#3956c3',
  tangerine: '#ed8a19',
  shamrockGreen: '#04bf5c',
  veryLightPink: '#ebebeb',
  middleGrey: '#cbcbcb',
  paleGrey: '#f3f6fb',
  iris: '#4c65c0',
  battleshipGrey: '#737376',
  brightYellow: '#ffff00',
  vibrantGreen: '#23c406',
  rosy: '#ea3df5',
  grey: '#d9d9d9',
  lightishGrey: '#f7f7f7',
  gold: '#FFE70A',
  white: '#ffffff',
  darkGrey: '#565656',
  darkLightGrey: '#A0A0A0',
};
