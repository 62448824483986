import { statusConstants } from "../../containers/EvaluationProjects";

export const filterTheData = (data:any, filters:any) => {
    let result = data.filter((d:any) => {
        return filters.some((filter:any) => {
            const { filterBy, operator, value, condition } = filter;
            let propertyValue;

            if(filter?.value != '' || (filter?.operator == 'is empty' || filter?.operator == 'is not empty')) {
                switch (filterBy) {
                    case 'Club name':
                        propertyValue = d.name.toLowerCase();
                        break;
                    case 'League':
                        propertyValue = d.leagueName.toLowerCase();
                        break;
                    case 'Star rating':
                        if(d.currentRating != null) {
                            propertyValue = d.currentRating == 1 ? '1 Star'.toLowerCase() : d.currentRating + ' Stars'.toLowerCase();
                        } else {
                            propertyValue = '';
                        }
                        break;
                    case 'Target star rating':
                        if(d.targetRating != null) {
                            propertyValue = d.targetRating == 1 ? '1 Star'.toLowerCase() : d.targetRating + ' Stars'.toLowerCase();
                        } else {
                            propertyValue = '';
                        }
                        break;
                    case 'Project status':
                        if(d.ragb != null) {
                            propertyValue = statusConstants[d.ragb].status.toLowerCase();
                        } else {
                            propertyValue = '';
                        }
                        break;
                    default:
                        return true; // Default to true for unknown filterBy
                }

                // Apply filtering based on filter conditions
                switch (operator) {
                    case 'contains':
                        return propertyValue.includes(value.toLowerCase());
                    case 'equals':
                        return propertyValue === value.toLowerCase();
                    case 'starts with':
                        return propertyValue.startsWith(value.toLowerCase());
                    case 'ends with':
                        return propertyValue.endsWith(value.toLowerCase());
                    case 'is empty':
                        return propertyValue === '';
                    case 'is not empty':
                        return propertyValue !== '';
                    default:
                        return true; // Default to true for unknown operators
                }
            }
        });
    });

    return result;
}

export const siteStatusCalculator = (statuses:any) => {
    
    const SiteReviewStatus = {
        NotEvaluated: 0,
        NotMet: 1,
        Partial: 2,
        Met: 3,
    }
    var result = SiteReviewStatus.NotEvaluated;

    if (statuses && statuses.length)
    {
        if (statuses.every((x:any) => x == SiteReviewStatus.NotMet))
        {
            result = SiteReviewStatus.NotMet;
        }

        if (statuses.some((x:any) => x == SiteReviewStatus.Met))
        {
            result = SiteReviewStatus.Partial;
        }

        if (statuses.every((x:any) => x == SiteReviewStatus.Met))
        {
            result = SiteReviewStatus.Met;
        }

        if (statuses.some((x:any) => x == SiteReviewStatus.NotEvaluated))
        {
            result = SiteReviewStatus.NotEvaluated;
        }
        else if (result == SiteReviewStatus.NotEvaluated && statuses.some((x:any) => x == SiteReviewStatus.Partial))
        {
            result = SiteReviewStatus.Partial;
        }
    }

    return result;
}