import React, { useEffect, useState } from "react";
import './styles.css';
//internal and external components/containers import
import UpcomingCards from "../../components/UpcomingCards";
import { Box, Tooltip, Typography } from "@mui/material";
// import { projects } from "../../assets/staticData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortBox from "../../components/SortBox";
import { colors } from "../../common/pallette/colors";
import { faDownLeftAndUpRightToCenter, faMaximize, faCalendarDays, faList } from "@fortawesome/free-solid-svg-icons";
import { stableSort, getComparator, sortData } from "../../common/SortSupport";
import Calendar from "../../components/Calendar";
import UpcomingCardsSkeleton from "../../components/UpcomingCardsSkeleton";
import { getprojects } from "../../api/common";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayProjects, setProjects } from "../../redux/reducers/common";
import { filterTheData } from "../../common/logics";
import { formatDate } from "../../common/StaticExports";

export const statusConstants = [
    {status: 'Not Started', color: colors.projectsStatus.notStarted},
    {status: 'Missed', color: colors.projectsStatus.missed},
    {status: 'At Risk', color: colors.projectsStatus.atRisk},
    {status: 'On Track', color: colors.projectsStatus.onTrack},
    {status: 'Completed', color: colors.projectsStatus.complete},
]

const commentGenerator = (status: number, due: string) => {
    if(status == 4) {
        return `${statusConstants[status].status}`;
    } return `${statusConstants[status].status}, due to complete on ${due}`;
}

const EvaluationProjects = (props: any) => {
    const { isExpanded, setIsExpanded, disableExpand } = props;

    const [showBy, setShowBy] = React.useState('list');
    // type projectObject = {
    //     name: string,
    //     dueDate: string,
    //     ragb: number
    // }
    // const [projects, setProjects] = React.useState<projectObject[]>([]);
    const searchString = useSelector((state:any) => state.common.adminPageSearchString);
    const projectsData = useSelector((state:any) => state.common.projects);
    const displayProjectsData = useSelector((state:any) => state.common.displayProjects);
    const globalFilters = useSelector((state:any) => state.common.globalFilters);
    const clubNameFilter = useSelector((state:any) => state.common.clubNameFilter);
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(false);


    //sort functionalities
    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');

    const optionData = [
        {property: 'ragb', name: 'Status desc'},
        {property: 'ragb', name: 'Status asc'},
        {property: 'name', name: 'Project name asc'},
        {property: 'name', name: 'Project name desc'},
        {property: 'dueDate', name: 'Date asc'},
        {property: 'dueDate', name: 'Date desc'},
    ]

    const handleRequestSort = (
        value: any,
        ) => {
            let property: any = optionData.find((d:any) => d.name == value);
            setOrder(value.includes('asc') ? 'asc' : 'desc');
            setOrderBy(property?.property);
    };

    const updatedData = React.useMemo(
        () =>
            displayProjectsData?.length > 0 &&
            sortData(displayProjectsData, orderBy, order),
        [order, orderBy, displayProjectsData],
    );

    useEffect(() => {
        let filteredProjects = projectsData;
        if(searchString !== '') {
            filteredProjects = projectsData.filter((d:any) => 
                                                    d.name.toLowerCase().includes(searchString.toLowerCase()) ||
                                                    statusConstants[d.ragb].status.toLowerCase().includes(searchString.toLowerCase()) ||
                                                    (d.dueDate !== null && d.dueDate.toString().includes(searchString))
                                                );
        }

        // const count = globalFilters.some((d:any) => d.value != '' || (d.operator == 'is empty' || d.operator == 'is not empty'))
        if (globalFilters && globalFilters.length > 0) {
            // filteredProjects = filterTheData(filteredProjects, globalFilters);
            // filteredProjects = filteredProjects.filter((d:any) => clubNameFilter.some((obj:any) => d.id == obj.id));
        }
        
        dispatch(setDisplayProjects(filteredProjects));
    }, [searchString, projectsData, clubNameFilter]);

    useEffect(() => {
        const fetechProjects = async () => {
            setIsFetching(true);
            const response = await getprojects();
            return (response);
        }
        fetechProjects().then((d:any) => {
            dispatch(setProjects(d));
            setIsFetching(false);
        });
    }, [])

    return(
        <React.Fragment>
            <Box className={'projectsWrapper'} sx={{ bgcolor: colors.grey.bright }}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <span className="collapseHeading">PROJECTS</span>
                    <Box sx={{marginLeft: 'auto', display: "flex"}}>
                        {showBy == 'list' && <SortBox label={'Sort By'} options={optionData} handleClick={handleRequestSort}/>}
                        <Box sx={{ml: 1, my: 'auto'}}>
                            <Tooltip  title='Calendar View' arrow placement='top'>
                                <FontAwesomeIcon className={showBy !== 'list' ? 'selectedIconColor upcomingIcons cursorPointer' : 'icon upcomingIcons cursorPointer'} icon={faCalendarDays} onClick={() => {setShowBy('calendar')}}/>
                            </Tooltip>
                            <Tooltip  title='List View' arrow placement='top'>
                                <FontAwesomeIcon className={showBy == 'list' ? 'selectedIconColor upcomingIcons cursorPointer' : 'icon upcomingIcons cursorPointer'} icon={faList} onClick={() => {setShowBy('list')}}/>
                            </Tooltip>
                            <FontAwesomeIcon className={disableExpand ? "icon cursorNotAllowed" : "icon cursorPointer" }
                            icon={isExpanded ? faDownLeftAndUpRightToCenter : faMaximize}
                            onClick={() => !disableExpand && setIsExpanded({...isExpanded, upcoming: !isExpanded.upcoming})}/>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{marginTop: 1, height: 'calc(100% - 42px)', overflowY: "auto"}}>
                    {
                        showBy == 'list' ?
                        <Box>
                            {
                                !isFetching && updatedData && updatedData?.length ? 
                                updatedData.map((project:any, index:number) => {
                                    return <UpcomingCards statusColor={statusConstants[project.ragb]?.color} cardHeading={project.name} cardComment={commentGenerator(project.ragb, formatDate(project.dueDate))} index={index}/>
                                })
                                :
                                (
                                    isFetching ?
                                    Array.from({length: 8}, () => {
                                        return <UpcomingCardsSkeleton />
                                    }):
                                    <Typography>No Records Found</Typography> 
                                )
                            }
                        </Box>
                        :
                        <Calendar data={[]}/>
                    }
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default EvaluationProjects;