import React from 'react';
// import './styles.css';
//internal and external componentscontainers
import { Typography, Paper, Box, Fade, Popper, ClickAwayListener, Button, ThemeProvider, createTheme, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faXmark,faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../common/pallette/colors';
import { actions, contactDetails, companyDetails } from '../../assets/staticData';
import ActionContents from '../ActionContents';
import EvidenceContents from '../EvidenceContents';
import CommentsContents from '../CommentsContents';
import EvaluationContents from '../EvaluationContents';
import { Accordion, AccordionSummary } from '../../common/AccordionsCustomized';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CompanyDetails from '../CompanyDetails';
import ContactDetails from '../ContactDetails';
import { genUID } from '../../common/StaticExports';
import { useSelector } from 'react-redux';
import StafffDetails from '../StaffDetails';
import FacilityDetails from '../FacilityDetails';
import TeamsDetails from '../TeamsDetails';
import AccountingDetails from '../AccountingDetails';
import PlayersDetails from '../PlayersDetails';
import AdminActionContents from '../AdminActionContents';


const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '7%'
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 13
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: 13
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    marginTop: 0
                }
            }
        }
    }
})


const LicenceStandardsDetail = (props: {detail: any, isOpen: boolean, handleClose: any}) => {
    const { detail, isOpen, handleClose } = props;
    type MyStateType = {
        actionsPanel: boolean;
        evidencePanel: boolean;
        commentsPanel: boolean;
        licenceDataPanel: boolean;
        evaluationPanel: boolean;
      };
    const [expanded, setExpanded] = React.useState<MyStateType>({
        actionsPanel: false,
        evidencePanel: false,
        commentsPanel: false,
        licenceDataPanel: false,
        evaluationPanel: false
    });
    // const handleChange =
    // (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    //   setExpanded(newExpanded ? panel : false);
    // };

    const manageLicenceData = (standardNumber:any) => {
        switch(standardNumber) {
            case 1:
                return (
                    <>
                    <CompanyDetails />
                    <ContactDetails />
                    </>
                );
            case 2:
                return (
                    <>
                    <StafffDetails />
                    </>
                );
            case 3:
                return (
                    <>
                    <FacilityDetails />
                    </>
                );
            case 4:
                return (
                    <>
                    <TeamsDetails />
                    </>
                );
            case 5:
                return (
                    <>
                    <AccountingDetails />
                    </>
                );
            case 8:
                return (
                    <>
                    <PlayersDetails />
                    </>
                );
            default:
                return(
                    <>
                    NO RECORDS FOUND
                    </>
                )
        }
    }

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
            <Popper className='popperStyle' open={isOpen} transition>
                    {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ p: 2, width: '1600px', mt: '70px', height: '700px' }}>
                                <Box className={'detailsWrapper'}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className='standardsHeading uppercase'>{genUID(detail.uid)} {detail.name}</Typography>
                                        <Typography className='fs13'>{detail.description}</Typography>
                                    </Box>
                                    {/* <Typography className='fs13'>{detail.standardsOverview}</Typography> */}
                                </Box>
                                <Box className={'standardDetailsContainer'}>
                                    {/* Actions Data */}
                                    <Box className={'detailsWrapper'}>
                                        <Typography className='licenceStandardsHeading uppercase'>Actions</Typography>
                                        <ActionContents standard={detail} />
                                    </Box>
                                    {/* evidence wrapper */}
                                    <Box className={'detailsWrapper'}>
                                        <Typography className='licenceStandardsHeading uppercase'>Evidence</Typography>
                                        <EvidenceContents standardId={detail.id} />
                                    </Box>
                                    {/* commentsWrapper */}
                                    <Box className={'detailsWrapper'}>
                                        <Typography className='licenceStandardsHeading uppercase'>Comments</Typography>
                                        <CommentsContents />
                                    </Box>
                                    {/* licence Data */}
                                    <Box className={'detailsWrapper'}>
                                        <Typography className='licenceStandardsHeading uppercase'>Licence Data</Typography>
                                        {
                                            manageLicenceData(detail.id)
                                        }
                                    </Box>
                                    {/* evaluation */}
                                    <Box className={'detailsWrapper'}>
                                        <Typography className='licenceStandardsHeading uppercase'>Evaluation</Typography>
                                        <EvaluationContents />
                                    </Box>
                                    {/* Admin Actions Data */}
                                    <Box className={'detailsWrapper'}>
                                        <Typography className='licenceStandardsHeading uppercase'>Admin Actions</Typography>
                                        <AdminActionContents standard={detail} />
                                    </Box>
                                </Box>
                                {/* <Box className={'standardsDetailFooter detailsWrapper'} sx={{ bgcolor: colors.grey.light }}>
                                        <Button className='closeButtonSFD' disableElevation 
                                                variant='contained' size='small' 
                                                onClick={handleClose}
                                                startIcon={<FontAwesomeIcon icon={faXmark} />}>
                                            {'Close'}
                                        </Button>
                                    </Box> */}
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                    )}
                </Popper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default LicenceStandardsDetail;