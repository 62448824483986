import React, { useState } from "react"
import { Box, Typography, Button } from "@mui/material"
import ActionsTable from "../../components/ActionsTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { getLicenceStandardsAction } from "../../api/licence"
import { useDispatch, useSelector } from "react-redux"
import { setLicenceStandardsActions } from "../../redux/reducers/licence"
import AdminActionsTable from "../../components/AdminActionsTable"

const AdminActionContents = (props: any) => {

    const { standard } = props;
    const currentClub = useSelector((state: any) => state.clubDetails);
    const standardsActions = useSelector((state: any) => state.licence.standardsActions);
    const dispatch = useDispatch();
    const [fetchActions, setFetchActions] = React.useState(false);
    const [addAction, setAddAction] = React.useState(false);
    const tableHead = [
        {
            key: '', displayName: '',
        },
        {
            key: 'status', displayName: 'status',
        },
        {
            key: 'actionComment', displayName: 'action',
        },
        {
            key: 'description', displayName: 'description',
        },
        {
            key: 'isComplete', displayName: 'complete',
        },
        {
            key: '', displayName: '',
        },
      ];

    //   React.useEffect(() => {
    //     const fetchLicenceCriteriaStandards = async () => {
    //       try{
    //         setFetchActions(true);
    //         const response = await getLicenceStandardsAction({clubId: currentClub.id, standardId: standard.id});
    //         dispatch(setLicenceStandardsActions(response));
    //         setFetchActions(false);
    //       } catch {
    
    //       }
    //     }
    //     fetchLicenceCriteriaStandards();
    //   }, [standard]);

    return(
        <>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button className='smallButton' sx={{ float: 'right' }} disableElevation onClick={() => setAddAction(true)} variant='contained' size='small' startIcon={<FontAwesomeIcon icon={faPlus} />} disabled={addAction}>
                {'Add Admin Action'}
            </Button>
        </Box>
        <Box sx={{ mt: 1 }}>
            <AdminActionsTable
            //re-work on this filter to display jl actions in the table
            data={standardsActions?.filter((d:any) => d.isCreatedByJL) || []}
            // data={standardsActions}
            head={tableHead} 
            addAction={addAction}
            currentStandard={standard}
            isLoading={fetchActions}
            bg={'#FAFAFA'}
            handleClick={() => setAddAction(false)}/>
        </Box>
        </>
    )
}

export default AdminActionContents;