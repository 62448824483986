import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { getCompanyDetails } from "../../api/dataCapture";
import { isPast } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/reducers/dataCaptures";

const CompanyDetails = () => {
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const data = useSelector((state:any) => state.dataCaptures.companyDetails);
    useEffect(() => {
        const fetchCompanyDetails = async () => {
            const response = await getCompanyDetails({clubId: clubDetails.id, isPast: false});
            dispatch(setCompanyDetails(response));
        }
        fetchCompanyDetails();
    }, []);
    return (
        <Box className='liceDataSubContainer'>
            <Typography className='standardsHeading uppercase'>company details</Typography>
            <Box className={'licenceDataCDWrapper'}>
                <Box className={'licenceDataCDs'}>
                    <Typography>Company Trading Name</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.companyName}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Name of the Executive Committee Member</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.executiveMember}/>
                </Box>
                <Box className={'licenceDataCDs'}>
                    <Typography>Academy Director</Typography>
                    <TextField className={'licenceDataCDsTextField'} variant='standard' value={data.academyDirector}/>
                </Box>
            </Box>
        </Box>
    )
}

export default CompanyDetails;