import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ListItemText,ThemeProvider, createTheme, Typography, Box } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important'
                }
            }
        },
        MuiFormLabel: {
          styleOverrides: {
              root: {
                  fontSize: 12
              }
          }
      },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 13
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    paddingRight: 5
                }
            }
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              padding: 8
            }
          }
        }
    }
})

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

export default function MultiSelectPrimary(props:any) {
  const { label, variant } = props;
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <InputLabel id="">{label}</InputLabel>
        <Select
          fullWidth
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          renderValue={(selected) => selected.length + ' Selcted'}
          variant={variant ? variant : 'outlined'}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {/* <Typography>{personName.join(', ')}</Typography> */}
      </Box>
    </ThemeProvider>
  );
}