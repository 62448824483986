import axios from "axios";
import { baseUrl } from "./config";
import { encodeQueryParams } from "./client";

export const getClubs: any = () => {
    const path = `${baseUrl}/api/clubs`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getClubs', err)
    })
}

export const getprojects: any = () => {
    const path = `${baseUrl}/api/projects?archivedStatus=1&pastStatusPeriod=1&status=&unionType=`;
    return axios({
        method: 'GET',
        url: path,
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },

    }).then(res => res.data.data). catch(err => {
        console.error('error while fetching data in getprojects', err)
    })
}

export const getLeagues: any = () => {
    const path = `${baseUrl}/api/leagues`;
    return axios({
      method: 'GET',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data). catch(err => {
        console.error('error while fetching data in getLeagues', err)
    });
};

export const managePinnedClubs: any = (parameters:any) => {
    const path = `${baseUrl}/api/clubs/pinnedclubs/${parameters.clubId}`;
    return axios({
      method: 'POST',
      url: path,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.data.data). catch(err => {
        console.error('error in managePinnedClubs', err)
    });
};