import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountingProfitLossTable from "../../components/AccountingProfitLossTable";
import AccountingProfitLossSummaryTable from "../../components/AccountingProfitLossSummaryTable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { accountingProfitLossSummary, accountingProfitLossSummaryHead } from '../../assets/staticData';
import { useDispatch, useSelector } from "react-redux";
import { getProfitandLoss, getSupportFund } from "../../api/dataCapture";
import { setProfitAndLossDetails, setSupportFundDetails } from "../../redux/reducers/dataCaptures";
import AccountingSupportFundTable from "../../components/AccountingSupportFundTable";

const AccountingDetails = () => {
    const [isAPTrow, setAPTrow] = useState(false);
    const [isASFrow, setASFrow] = useState(false);
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const profitAndLossDetails = useSelector((state:any) => state.dataCaptures.profitAndLossDetails);
    useEffect(() => {
        const fetchAccountingDetails = async () => {
            const response = await getProfitandLoss({clubId: clubDetails.id, isPast: false});
            dispatch(setProfitAndLossDetails(response));
            const res = await getSupportFund({clubId: clubDetails.id, isPast: false});
            dispatch(setSupportFundDetails(res));
        }
        fetchAccountingDetails();
    }, []);
    return (
        <>
        <Box className='liceDataSubContainer'>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='standardsHeading uppercase'>Accounting - Profit & Loss</Typography>
                <Button className='smallButton' 
                sx={{ float: 'right' }} 
                disableElevation disabled={isAPTrow} 
                variant='contained' size='small' 
                onClick={() => setAPTrow(true)} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                    {'Add other expense'}
                </Button>
            </Box>
            <Box>
                <AccountingProfitLossTable addRow={isAPTrow} handleClick={() => setAPTrow(false)} />
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                        profitAndLossDetails &&
                        profitAndLossDetails.map((d:any, i:any) => 
                            <AccountingProfitLossSummaryTable data={d} head={accountingProfitLossSummaryHead[i]}/>
                        )
                    }
                </Box> */}
            </Box>
        </Box>
        <Box className='liceDataSubContainer'>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className='standardsHeading uppercase'>{'Accounting (Support Fund)'}</Typography>
                <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={isASFrow} variant='contained' size='small' onClick={() => setASFrow(true)} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                    {'Add other expense'}
                </Button>
            </Box>
            <Box>
                <AccountingSupportFundTable addRow={isASFrow} handleClick={() => setASFrow(false)} />
            </Box>
        </Box>
        </>
    )
}

export default AccountingDetails;