import React from 'react';
//internal and external components/containers import
import SearchFilterContainer from '../SearchFilterContainer';
import JLEvaluation from '../JLEvaluation';
import JLHomeContainers from '../JLHomeContainers';
import { Box } from '@mui/material';

const JLHome = () => {
    return(
        <React.Fragment>
            <Box className={'contentSpacing'}>
                <SearchFilterContainer parent={'adminHome'} />
                {/* <JLEvaluation /> */}
                <JLHomeContainers />
            </Box>
        </React.Fragment>
    )
}

export default JLHome;