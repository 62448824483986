import React, { useState } from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import { accountingSupportFund } from '../../assets/staticData';
import YenTextField from '../YenTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const AccountingSupportFundTable = (props?: any) => {
    const { addRow, handleClick } = props;
    const [total, setTotal] = useState(0);

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [openActionDetail, setOpenActionDetail] = React.useState(false);
  const [detail, setDetail] = React.useState(Object);
  const [isOpen, setIsOpen] = React.useState(false);
  const data = useSelector((state:any) => state.dataCaptures.supportFundDetails);
  let types:any = [];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      data?.length > 0 &&
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const head = [
    {
        key: 'description', displayName: 'description',
    },
    {
        key: 'amount', displayName: 'amount',
    },
    {
        key: 'supportFundContribution', displayName: 'supportFundContribution',
    },
    {
        key: '', displayName: '',
    },
  ]

  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <ActionDetailPopper isOpen={openActionDetail} handleIsOpen={() => {setOpenActionDetail(false)}} />
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                      {
                        addRow &&
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`accountingpl-table-add-row`}
                        >
                            <TableCell className='fs12' width={520}>
                                <TextField variant='standard' sx={{ width: '100%' }} value={''}/>
                            </TableCell>
                            <TableCell className='fs12'>
                                <YenTextField v={''}/>
                            </TableCell>
                            <TableCell className='fs12'>
                                <Checkbox sx={{ padding: '0px' }} checked={false} />
                            </TableCell>
                            <TableCell>
                              <Tooltip title='Delete' arrow placement='top'><FontAwesomeIcon onClick={() => handleClick()} className={'icon mr10'} icon={faXmark}/></Tooltip>
                              <Tooltip title='Create' arrow placement='top'><FontAwesomeIcon className={'icon mr10'} icon={faCheck} /></Tooltip>
                            </TableCell>
                        </TableRow>
                      }
                    {
                    visibleRows && visibleRows?.length &&
                    visibleRows?.map((d: any, i: number) => (
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`accountingpl-table-row-${i}`}
                        >
                            <TableCell className='fs12' width={520}>
                                <TextField variant='standard' sx={{ width: '100%' }} value={d.description}/>
                            </TableCell>
                            <TableCell className='fs12'>
                                <YenTextField v={d.amount}/>
                            </TableCell>
                            <TableCell className='fs12'>
                                <Checkbox sx={{ padding: '0px' }} checked={d.supportFundContribution} />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                    ))}
                    </TableBody>
                </Table>
              </TableContainer>
              <Typography className='fs13'>
                {/* Total: {visibleRows?.reduce((accum:any, d:any) => {return accum + d.amount}, 0)} ¥ */}
                Total: -Rework logic-
              </Typography>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default AccountingSupportFundTable;