import React from 'react';
import './styles.css';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography,Button  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import { players } from '../../assets/staticData';
import YenTextField from '../YenTextField';
import CustomTextField from '../CustomTextField';
import DateSelector from '../DateSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PeriodTable from '../PeriodTable';
import ClubCareerTable from '../ClubCareerTable';
import InternationalClubTable from '../InternationalClubTable';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          // display: 'inline',
          // width: '90px',
          paddingTop: '0px !important'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const PlayersTable = (props?: any) => {
    const { addData, handleClick } = props;

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const data = useSelector((state:any) => state.dataCaptures.playerDetails);
  const [addNewPeriod, setAddNewPeriod] = React.useState(false);
  const [addClubCareer, setAddClubCareer] = React.useState(false);
  const [addInternationalCareer, setAddInternationalCareer] = React.useState(false);
  let types:any = [];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      data?.length > 0 &&
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const head = [
    {
        key: '', displayName: 'name',
    },
    {
        key: '', displayName: 'date of birth',
    },
    {
        key: '', displayName: 'current club',
    },
    {
        key: '', displayName: 'international',
    },
  ]
 

  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
            {addData && 
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography className='standardsHeading uppercase'>Academy Details</Typography>
                  <Box className='academyDetailsContainer'>
                    <CustomTextField label={'Name'} />
                    <DateSelector label={'Date of Birth'}/>
                    <CustomTextField label={'Id'} />
                  </Box>
                </Box>
                <Box className='mb20 periodAtAcademyContainer'>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography className='standardsHeading uppercase'>Period at academy</Typography>
                      <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addNewPeriod} variant='contained' size='small' onClick={() => {setAddNewPeriod(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                          {'Add another time period'}
                      </Button>
                  </Box>
                  <Box>
                    <PeriodTable addData={addNewPeriod} handleClick={() => setAddNewPeriod(false)} />
                  </Box>
                </Box>
              </Box>
            
              <Box className='mb20'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography className='standardsHeading uppercase'>CLUB CAREER (PROFESSIONAL)</Typography>
                    <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addClubCareer} variant='contained' size='small' onClick={() => {setAddClubCareer(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                        {'Add another time period'}
                    </Button>
                </Box>
                <Box>
                  <ClubCareerTable addData={addClubCareer} handleClick={() => setAddClubCareer(false)}/>
                </Box>
              </Box>
              <Box className='mb20'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography className='standardsHeading uppercase'>INTERNATIONAL ACADEMY CLUB CAREER (COMPETITIVE)</Typography>
                    <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addInternationalCareer} variant='contained' size='small' onClick={() => {setAddInternationalCareer(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                        {'Add another time period'}
                    </Button>
                </Box>
                <Box>
                  <InternationalClubTable addData={addInternationalCareer} handleClick={() => setAddInternationalCareer(false)}/>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', float: 'right', mb: 2 }}>
                  <Button className='smallButton closeCancelButtonStyles' variant='contained' sx={{ mr: 2 }} disableElevation disableRipple onClick={() => handleClick()}>Cancel</Button>
                  <Button className='smallButton' variant='contained' disableElevation disableRipple>Add</Button>
              </Box>
          </Box>
            }

              {/* table content is here */}
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                    {
                        visibleRows && visibleRows?.length &&
                        visibleRows?.map((d: any, i: number) => (
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`accountingpl-table-row-${i}`}
                        >
                            <TableCell className='fs12'>
                                {d.name}
                            </TableCell>
                            <TableCell className='fs12'>
                                {new Date(d.dateOfBirth).toLocaleDateString()}
                            </TableCell>
                            <TableCell className='fs12'>
                                {/* {
                                  d.clubCareers.map((cc:any, i:number) => {
                                    if(i>0) {
                                      return ', ' + cc.club
                                    } return cc.club
                                  })
                                } */}
                                {
                                  d.clubCareers.length ?
                                  d.clubCareers[d.clubCareers.length - 1].club : '-'
                                }
                            </TableCell>
                            <TableCell className='fs12'>
                                {
                                  d.internationalCareers.length ?
                                  d.internationalCareers[d.internationalCareers.length - 1].country : '-'
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default PlayersTable;