import React, { useEffect, useState, useTransition } from 'react';
//internal and external components import
import { Avatar, Box, Typography, CardMedia, Card, CardContent, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCopy, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { colors } from '../../common/pallette/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedChatPhase, setSelectedStandardsChat } from '../../redux/reducers/evaluation';
import { formatDateTime, genUID } from '../../common/StaticExports';
import { useTranslation } from 'react-i18next';
import { baseUrl } from '../../api/config';

const CardContentCustom = styled(CardContent)(`
&:last-child {
    padding-bottom: 10px;
  }
padding: 10px;
height: 80%;
//flex-direction: row;
// border: 2px solid ${colors.grey.dark};
// border-radius: 4px;
`)

const ChatsContactCard = (props: any) => {
    const { data, handleClick, from } = props;
    const [isOpen, setIsOpen] = useState(false);
    const selectedChatPhase = useSelector((state:any) => state.evaluation.selectedChatPhase);
    const selectedCommentContent = useSelector((state:any) => state.evaluation.selectedStandardsChat);
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const manageOnClick = () => {
        dispatch(setSelectedChatPhase(from));
        dispatch(setSelectedStandardsChat(data));
        handleClick(data);
    }
    const firstName = data.firstName ? data.firstName : '';
    const lastName = data.lastName ? data.lastName : '';

    const messageBy = ['You', 'Preethi', 'Oliver', 'Adam', 'Thush'];
    const message = ['Test Message', 'Passed', 'upload action', 'upload evidences'];

    return(
        <React.Fragment>
            <Card sx={(selectedChatPhase == from && selectedCommentContent?.id == data.id) ?
            { width: '98%', marginBottom: '10px', border: `2px solid ${colors.grey.dark}`, borderRadius: '4px' } :
            { width: '98%', marginBottom: '10px' }} 
            elevation={0} 
            onClick={() => {manageOnClick()}}>
                <CardContentCustom>
                <Box sx={{ maxHeight: 140, overflow: 'auto' }}>
                    <Typography sx={{ fontSize: 12, textAlign: 'left', fontWeight: 600}} onClick={() => setIsOpen(!isOpen)}>
                        {`${genUID(data.uid)} ${data.name}`}
                    </Typography>
                    <Typography sx={{ fontSize: 12, textAlign: 'left'}} onClick={() => setIsOpen(!isOpen)}>
                        {/* {`${messageBy[Math.floor(Math.random()*messageBy.length)]}: ${message[Math.floor(Math.random()*message.length)]}`} */}
                        {`${messageBy[0]}: ${message[0]}`}
                    </Typography>
                </Box>
                </CardContentCustom>
            </Card>
        </React.Fragment>
    )
}

export default ChatsContactCard;