import React, { useEffect, useState } from "react";
import './styles.css';
//internal and external components/containers import
import UpcomingCards from "../../components/UpcomingCards";
import { Box, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faCalendarDays, faDownLeftAndUpRightToCenter, faMaximize } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../common/pallette/colors";
import Calendar from "../../components/Calendar";
import SortBox from "../../components/SortBox";
import { stableSort, getComparator, sortData } from "../../common/SortSupport";
import DemoApp from "../../components/DemoCalendar";
import UpcomingCardsSkeleton from "../../components/UpcomingCardsSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayUpcoming } from "../../redux/reducers/common";
import { formatDate } from "../../common/StaticExports";

const EvaluationUpcoming = (props: any) => {
    const { isExpanded, setIsExpanded, disableExpand } = props;
    const [showBy, setShowBy] = React.useState('list');
    const searchString = useSelector((state:any) => state.common.adminPageSearchString);
    const upcomingData = useSelector((state:any) => state.common.upcoming);
    const displayUpcomingData = useSelector((state:any) => state.common.displayUpcoming);
    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(false);

    const optionData = [
        {property: 'upcomingDateTime', name: 'Date asc'},
        {property: 'upcomingDateTime', name: 'Date desc'},
        {property: 'clubName', name: 'Club asc'},
        {property: 'clubName', name: 'Club desc'},
    ]

    const handleRequestSort = (
        value: any,
        ) => {
            let property: any = optionData.find((d:any) => d.name == value);
            setOrder(value.includes('asc') ? 'asc' : 'desc');
            setOrderBy(property?.property);
    };
    
    const updatedData = React.useMemo(
        () =>
            displayUpcomingData?.length > 0 &&
            sortData(displayUpcomingData, orderBy, order),
        [order, orderBy, displayUpcomingData],
    );

    useEffect(() => {
        setIsFetching(true);
        if(searchString !== '') {
            const tempArr = upcomingData.filter((d:any) => 
                                                    d.clubName.toLowerCase().includes(searchString.toLowerCase()) ||
                                                    d.upcomingEvent.toLowerCase().includes(searchString.toLowerCase()) ||
                                                    d.upcomingDateTime.toString().includes(searchString)
                                                );
            dispatch(setDisplayUpcoming(tempArr));
        } else {
            dispatch(setDisplayUpcoming(upcomingData));
        }
        setIsFetching(false);
    }, [searchString, upcomingData])

    return(
        <React.Fragment>
            <Box sx={{ bgcolor: colors.grey.bright }} className='upcomingWrapper'>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <span className="collapseHeading">UPCOMING</span>
                    <Box sx={{ display: 'flex' }}>
                        {showBy == 'list' && <SortBox label={'Sort By'} options={optionData} handleClick={handleRequestSort}/>}
                        <Box sx={{ml: 1, my: 'auto'}}>
                            <Tooltip  title='Calendar View' arrow placement='top'>
                                <FontAwesomeIcon className={showBy !== 'list' ? 'selectedIconColor upcomingIcons cursorPointer' : 'icon upcomingIcons cursorPointer'} icon={faCalendarDays} onClick={() => {setShowBy('calendar')}}/>
                            </Tooltip>
                            <Tooltip  title='List View' arrow placement='top'>
                                <FontAwesomeIcon className={showBy == 'list' ? 'selectedIconColor upcomingIcons cursorPointer' : 'icon upcomingIcons cursorPointer'} icon={faList} onClick={() => {setShowBy('list')}}/>
                            </Tooltip>
                            <FontAwesomeIcon className={disableExpand ? "upcomingIcons icon cursorNotAllowed" : "upcomingIcons icon cursorPointer" }
                            icon={isExpanded ? faDownLeftAndUpRightToCenter : faMaximize}
                            onClick={() => !disableExpand && setIsExpanded({...isExpanded, upcoming: !isExpanded.upcoming})}/>
                        </Box>
                    </Box>
                </Box>
                {
                    showBy == 'list' ?
                    <Box sx={{marginTop: 1}} className={'upComingCardsWrapper'}>
                    {
                        !isFetching && updatedData && updatedData.length > 0 ?
                        updatedData?.map((up:any, index:any) => {
                            return <UpcomingCards cardMedia={up.clubLogo} cardHeading={formatDate(up.upcomingDateTime)} cardComment={up.upcomingEvent} index={index}/>
                        })
                        : 
                        (
                            isFetching ?
                            Array.from({length: 8}, () => {
                                return <UpcomingCardsSkeleton />
                            }):
                            <Typography sx={{ textAlign: 'center' }}>No Records Found</Typography>
                        )
                    }
                </Box>
                :
                <Box sx={{marginTop: 1}} className={'upComingCardsWrapper'}>
                    <Calendar data={displayUpcomingData} />
                    {/* <DemoApp /> */}
                </Box>
                }
            </Box>
        </React.Fragment>
    )
}

export default EvaluationUpcoming;