import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CriteriaTable from "../../components/CriteriaTable/SACriteriaTable";
// import { clubCriteria } from "../../assets/staticData";
import { Box } from "@mui/material";
import DetailedCriteria from "../DetailedCriteria";
import { getSelfAssessmentCriteria, getOffSiteCriteria, getCriteriaGroups, getOnSiteCriteria, getClubReviewsInfo, getEvaluationReportCriteria } from "../../api/evaluation";
import { getClubsDesirePlan } from "../../api/club";
import { setClubDesirePlan } from "../../redux/reducers/clubData";
import { setCriteriaGroups, setEvalReportCriteria, setOffSiteCriteria, setOnSiteCriteria, setOnSiteDateGroups, setSaCriteria } from "../../redux/reducers/evaluation";
import OffSiteCriteriaTable from "../../components/CriteriaTable/OffSiteCriteriaTable";
import OnSiteCriteriaTable from "../../components/CriteriaTable/OnSiteCriteriaTable";
import EvalReportCriteriaTable from "../../components/CriteriaTable/EvalReportCriteriaTable";
import ContentOverview from "../../components/ContentOverview";
import { sortData } from "../../common/SortSupport";
import OnSiteWrapper from "../OnSiteWrapper";
import Calendar from "../../components/Calendar";
import { useTranslation } from "react-i18next";

const ClubCriteria = () => {
    const clubDetails = useSelector((state:any) => state.clubDetails);
    // const [selectedCriteria, setSelectedCriteria] = useState(useSelector((state: any) => state.selectedCriteria));
    const selectedCriteria = useSelector((state: any) => state.evaluation.selectedSaCriteria);
    const criterias = useSelector((state: any) => state.evaluation.saCriteria);
    const [clubCriterias, setClubCriterias] = useState([]);
    const searchString = useSelector((state: any) => state.evaluation.searchString);
    const currentSection = useSelector((state:any) => state.evaluation.currentEvaluationTab);
    const offSiteCriteria = useSelector((state:any) => state.evaluation.offSiteCriteria);
    const selectedOffSiteCriteria = useSelector((state:any) => state.evaluation.selectedOffSiteCriteria);
    const evalReportCriteria = useSelector((state:any) => state.evaluation.evalReportCriteria);
    const selectedEvalReportCriteria = useSelector((state:any) => state.evaluation.selectedEvalReportCriteria);
    const criteriaGroups = useSelector((state:any) => state.evaluation.criteriaGroups);
    const interviewView = useSelector((state:any) => state.evaluation.interviewView);
    const onSiteCriteria = useSelector((state:any) => state.evaluation.onSiteCriteria);
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(false);
    const auth:any = localStorage.getItem('_jauth_');
    const parsedAuth = JSON.parse(auth);
    const idClub = clubDetails?.id || parsedAuth.clubId;
    const [t] = useTranslation();
    
    useEffect(() => {
        const fetchDesirePlan = async () => {
        try {
            const response = await getClubsDesirePlan('desirePlan', {clubId: idClub})
            return response;
        } catch (error) {
            console.error('Error while fetching getClubsDesirePlan');
        }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (idClub) && 
        fetchDesirePlan()?.then((res:any) => {
        dispatch(setClubDesirePlan(res));
        });
  }, [clubDetails, dispatch]);

  //fetch SA Criteria
  const fetchCriteria = async () => {
    setIsFetching(true);
    const response = await getSelfAssessmentCriteria({clubId: idClub});
    const groups = await getCriteriaGroups({clubId: idClub});
    dispatch(setSaCriteria(response));
    dispatch(setCriteriaGroups(groups));
    var tempArr = response;
    if(tempArr.length > 0) {
        tempArr = sortData(response, 'uid', 'asc')
    }
    setClubCriterias(tempArr);
    setIsFetching(false);
}   

    //fetch Off-Site Criteria
    const fetchOffSiteCriteria = async () => {
        const response = await getOffSiteCriteria({clubId: idClub});
        dispatch(setOffSiteCriteria(response));
    }

    //fetch On-Site Criteria
    const fetchOnSiteSessions = async () => {
        setIsFetching(true);
        const reviewData = await getClubReviewsInfo({clubId: idClub});
        const response = await getOnSiteCriteria({onSiteReviewId: reviewData.onSiteReviewId});
        dispatch(setOnSiteCriteria(response));
        setIsFetching(false);
    }

    //fetch evaluation report criteria
    const fetchEvalCriteria = async () => {
        const response = await getEvaluationReportCriteria({clubId: idClub});
        dispatch(setEvalReportCriteria(response));
    }

    useEffect(() => {
        if(idClub) {
            switch(currentSection) {
                case t('EVALUATION'): 
                    fetchCriteria();
                    break;
                case t('OFF_SITE'): 
                    fetchOffSiteCriteria();
                    break;
                case t('ON_SITE'): 
                    fetchOnSiteSessions();
                    break;
                case t('EVALUATION_REPORT'):
                    fetchEvalCriteria();
                    break;
            }
        }
    }, [clubDetails, currentSection])

    useEffect(() => {
        if (searchString !== '' && criterias.length > 0) {
            let filteredCriteria:any = criterias;

            filteredCriteria = filteredCriteria.filter((criteria:any) =>
                criteria.name.toLowerCase().includes(searchString.toLowerCase()) ||
                criteria.uid.toLowerCase().includes(searchString.toLowerCase())
            );

            setClubCriterias(filteredCriteria);
        }

    }, [searchString, criterias])

    const overviewGroups = [
        {label: 'FOOTBALL', offSite: 1, onSite: 1, interimReport: 0, finalReport: 0},
        {label: 'OPERATIONS', offSite: 1, onSite: 1, interimReport: 0, finalReport: 0},
    ]
    
    return(
        <React.Fragment>
            <Box sx={{ height: '73vh' }}>
                    {
                        Object.keys(selectedCriteria).length ?
                        <ContentOverview data={criteriaGroups.find((x:any) => x.id == selectedCriteria.criteriaGroupId)} 
                        from={'SA'} criterias={clubCriterias.filter((x:any) => x.criteriaGroupId == selectedCriteria.criteriaGroupId)}/> : <></>
                    }
                    {
                        currentSection == t('EVALUATION') &&
                        // height: '100%', overflow: 'auto'
                        <Box sx={{ height: '100%', overflowY: Object.keys(selectedCriteria).length ?  'hidden' : 'auto' }}>
                            {
                                !isFetching ?
                                criteriaGroups.map((group:any, i:any) => {
                                    var filteredData = clubCriterias.filter((x:any) => x.criteriaGroupId == group.id);
                                    var calcHeight = () => {
                                        // var pxCalc = 27/criteriaGroups.length;
                                        // return `calc(${(filteredData.length/clubCriterias.length)*100}% - 53px)`;
                                        //divide the height accordingly
                                        // return `calc(${(100/criteriaGroups.length)}% - 27px)`;
                                        return 'fit-content'
                                    }
                                    return (
                                        <>
                                            {
                                                (Object.keys(selectedCriteria).length > 0 && i < 1) ||
                                                (Object.keys(selectedCriteria).length == 0) &&
                                                <ContentOverview data={group} from={'SA'} criterias={filteredData}/>
                                            }
                                            <Box sx={Object.keys(selectedCriteria).length ? 
                                                {display: i<1 ? "flex" : "none", mt: 1, height: 'calc(100% - 27px)', mb: '20px'} : 
                                                { mt: 1, height: calcHeight(), mb: '20px' }}>
                                                    <CriteriaTable data={(Object.keys(selectedCriteria).length) > 0 ? clubCriterias : filteredData}
                                                    criteria={Object.keys(selectedCriteria).length === 0}
                                                    selectedCriteria={selectedCriteria}
                                                    isLoading={isFetching}/>
                                                    {(Object.keys(selectedCriteria).length > 0) && i < 1 && <DetailedCriteria criteria={selectedCriteria} module={t('EVALUATION')}/>}
                                            </Box>
                                        </>
                                    )
                                }) :
                                <Box sx={{ mt: 1, height: 'calc(100% - 27px)', mb: '20px' }}>
                                    <CriteriaTable data={[]}
                                    criteria={Object.keys(selectedCriteria).length === 0}
                                    selectedCriteria={selectedCriteria}
                                    isLoading={isFetching}/>
                                </Box>
                            }
                        </Box>
                    }
                    {
                        currentSection == t('OFF_SITE') && 
                        <Box sx={Object.keys(selectedOffSiteCriteria).length ? {display: "flex", mt: 1, height: '100%'} : { mt: 1, height: '100%' }}>
                            <OffSiteCriteriaTable data={offSiteCriteria}
                            criteria={Object.keys(selectedOffSiteCriteria).length === 0}
                            selectedCriteria={selectedOffSiteCriteria}/>
                            {(Object.keys(selectedOffSiteCriteria).length > 0) && <DetailedCriteria criteria={selectedOffSiteCriteria} module={t('EVALUATION')}/>}
                        </Box>
                    }
                    {
                        currentSection == t('ON_SITE') &&
                        (
                            interviewView == 'CALENDAR' ?
                            <Box sx={{ height: '80vh' }}>
                                <Calendar data={onSiteCriteria.map((d:any) => {return {...d, title: d.name, allDay: true}})}/>
                            </Box> :
                            <OnSiteWrapper isLoading={isFetching} />
                        )
                    }
                    {
                        currentSection == t('EVALUATION_REPORT') && 
                        <Box sx={Object.keys(selectedEvalReportCriteria).length ? {display: "flex", mt: 1, height: '100%'} : { mt: 1, height: '100%' }}>
                            <EvalReportCriteriaTable data={evalReportCriteria}
                            criteria={Object.keys(selectedEvalReportCriteria).length === 0}
                            selectedCriteria={selectedEvalReportCriteria}/>
                            {(Object.keys(selectedEvalReportCriteria).length > 0) && <DetailedCriteria criteria={selectedEvalReportCriteria} module={t('EVALUATION')}/>}
                        </Box>
                    }
            </Box>
        </React.Fragment>
    )
}

export default ClubCriteria;