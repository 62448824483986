import React from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, ThemeProvider, createTheme } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePowerpoint, faMagnifyingGlass, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../common/StaticExports';
import TableSkeleton from '../TableSkeleton';
import NestedThreeDotMenu from '../NestedThreeDotMenu';
import { useSelector } from 'react-redux';
import useFileTranslationProcess from '../../hooks/useFileLangTranslation';
import { useQueryClient, QueryClientProvider } from 'react-query';

const theme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          border: 'none',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
          border: 0
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const EvidencesTable = (props?: any) => {
    const { data, isLoading, bg } = props;
    const queryClient = useQueryClient();
    const clubDetails = useSelector((state:any) => state.clubDetails);

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows:any = React.useMemo(
    () =>
      data?.length > 0 &&
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const head = [
    {key: 'name', displayName: 'Title'},
    {key: 'uploaded', displayName: 'Upload Date'},
    {key: '', displayName: ''},
  ]

  const onOpenFile = useFileTranslationProcess({
    onSuccessTranslation: () => {
      queryClient.invalidateQueries(['evidence_library', { clubId: clubDetails.id }]);  // Invalidate the query
    },
  });
  

  const threeDotMenuOptions = 
  [
    {label: 'View File', icon: faMagnifyingGlass, isDisabled: false},
    {label: 'Download File', icon: faDownload, isDisabled: false},
  ];
  const handleThreedotMenuClick = (selctedAction:any, row:any) => {
    switch(selctedAction) {
      case 'View File':
        onOpenFile('preview', row);
        break;
      case 'Download File':
        onOpenFile('download', row);
        break;
    }
  }

  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    bg={bg}
                    />
                    <TableBody>
                    {
                      !isLoading && visibleRows && visibleRows?.length ?
                      visibleRows?.map((d: any, i: number) => (
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`history-table-row-${i}`}
                        >
                            <TableCell className='fs12'sx={{ width: '300px' }}>
                                <FontAwesomeIcon className={'smallFileIcon'} icon={(d.type === '.xlsx') ? faFileExcel : faFilePowerpoint} />
                                {d.name}
                            </TableCell>
                            <TableCell className='fs12'>
                              {formatDate(d.uploaded)}
                            </TableCell>
                            <TableCell className='fs12' align={'right'}>
                              <NestedThreeDotMenu options={threeDotMenuOptions} manageClick={(e:any) => {handleThreedotMenuClick(e, d)}} />
                            </TableCell>
                        </TableRow>
                    )) :
                    (
                      !isLoading && (data?.length == 0 || data == undefined) ?
                      <TableRow>
                        <TableCell colSpan={4} className='fs12'>No Records Found</TableCell>
                      </TableRow>:
                      <TableSkeleton rowCount={6} cellCount={3} />
                    )
                  }
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default EvidencesTable;