import { Box, Typography, Button } from "@mui/material";
import './styles.css';
import React, { useState } from "react";
import CustomTextField from "../../components/CustomTextField";
import MultiSelectPrimary from "../../components/MultiSelectPrimary";
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import GroundDetailsTable from "../../components/GroundDetailsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import YenTextField from "../../components/YenTextField";
import CustomCurrencyTextField from "../../components/CustomCurrencyTextField";
import TrainingScheduleTable from "../../components/TrainingScheduleTable";
import CompetionsTable from "../../components/CompetionsTable";
import TitlesTable from "../../components/TitlesTable";

const AddTeamDetailsForm = (props:any) => {
    const { onCancelClick } = props;
    const [addCompetitions, setAddCompetitions] = useState(false);
    const [addTitles, setAddTitles] = useState(false);
    return(
        <React.Fragment>
            <Box  className='mb20'>
                <Typography className='standardsHeading uppercase'>Team Details</Typography>
                <Box className='teamDetailsStyles'>
                    <CustomTextField label='Name'/>
                    <CustomTextField label='Established'/>
                    <MultiSelectPrimary label='Age Group'/>
                    <MultiSelectPrimary label='Staff Supervisor'/>
                    <MultiSelectPrimary label='Staff Coaches'/>
                    <MultiSelectPrimary label='Staff GK Coaches'/>
                    <MultiSelectPrimary label='Medical Staffs'/>
                    <MultiSelectPrimary label='Practice Grounds'/>
                </Box>
            </Box>
            <Box  className='mb20'>
                <Typography className='standardsHeading uppercase'>Number of Players</Typography>
                <Box className='noPlayersStyles'>
                    <CustomTextField label='U-18'/>
                    <CustomTextField label='U-17'/>
                    <CustomTextField label='U-16'/>
                    <CustomTextField label='U-15'/>
                    <CustomTextField label='U-14'/>
                    <CustomTextField label='U-13'/>
                    <CustomTextField label='U-12'/>
                    <CustomTextField label='U-11'/>
                    <CustomTextField label='U-10'/>
                    <CustomTextField label='U-9'/>
                </Box>
            </Box>
            <Box  className='mb20'>
                <Typography className='standardsHeading uppercase'>Training Schedule</Typography>
                <Box>
                    <TrainingScheduleTable />
                </Box>
            </Box>
            <Box  className='mb20'>
                <Typography className='standardsHeading uppercase'>Team Fees</Typography>
                <Box className='teamFeeStyles'>
                    <CustomCurrencyTextField label={'ADMISSION FEES (¥)'} variant='outlined'/>
                    <CustomCurrencyTextField label={'PLAYER MONTHLY EXPENSES (¥)'} variant='outlined'/>
                    <CustomCurrencyTextField label={'TRAVEL MONTHLY EXPENSES (¥)'} variant='outlined'/>
                    <CustomTextField label='Remarks'/>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box  className='mb20' sx={{ width: '45%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography className='standardsHeading uppercase'>Last Year Competitions</Typography>
                        <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addCompetitions} variant='contained' size='small' onClick={() => {setAddCompetitions(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                            {'Add competition'}
                        </Button>
                    </Box>
                    <Box>
                        <CompetionsTable addData={addCompetitions} handleClick={() => setAddCompetitions(false)} />
                    </Box>
                </Box>
                <Box  className='mb20' sx={{ width: '45%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography className='standardsHeading uppercase'>Titles</Typography>
                        <Button className='smallButton' sx={{ float: 'right' }} disableElevation disabled={addTitles} variant='contained' size='small' onClick={() => {setAddTitles(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                            {'Add title'}
                        </Button>
                    </Box>
                    <Box>
                        <TitlesTable addData={addTitles} handleClick={() => setAddTitles(false)}/>
                    </Box>
                </Box>
            </Box>
            <Box className={'mb20'} sx={{ display: 'flex', ml: 'auto', float: 'right' }}>
                <Button className='smallButton closeCancelButtonStyles' variant='contained' sx={{ mr: 2 }} disableElevation disableRipple onClick={() => onCancelClick()}>Cancel</Button>
                <Button className='smallButton' variant='contained' disableElevation disableRipple>Add</Button>
            </Box>
        </React.Fragment>
    )
}

export default AddTeamDetailsForm;