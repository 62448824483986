import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector, useDispatch } from 'react-redux';
// import { getLeagueOptions, getLeagueValue } from '../licenseAnalyticsStore/getLicenseAnalyticsActions';
// import { actions } from '../licenseAnalyticsStore/licenseAnalyticsSlice';
import MultiSelectDropdown from '../../components/MultiSelectComponent/MultiSelectPrimary/selectDropDownComponent';

interface SingleOption {
  id: number;
  name: string;
}

const LicenceYearFilter = (props:any) => {
  const { data, onChange, selected } = props;
  const [t] = useTranslation();
  // const dispatch = useDispatch();
  // const leagueOptions: SingleOption[] = useSelector(getLeagueOptions);
  // const leagueValue: SingleOption | SingleOption[] | undefined = useSelector(getLeagueValue);

  // const onChange = (newValue: SingleOption | SingleOption[] | undefined): void => {
  //   dispatch(actions.setLeagueValue({ newValue }));
  // };

  return (
    <MultiSelectDropdown
      title={t('LICENCE_YEAR')}
      options={[...data]}
      value={selected}
      // getId={(obj: SingleOption): string => obj.id}
      // getLabel={(obj: SingleOption): string => obj.name}
      onChange={onChange}
      isMulti={false}
      width="100%"
      rootCss={{ width: '100%' }}
    />
  );
};

export default LicenceYearFilter;
