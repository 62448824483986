import React, { useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../../theme/colors';
// import { Redirect } from 'react-router-dom';
// import { useAuth } from 'src/context/auth.context';
import { ReactComponent as JLogo } from '../../../assets/icons/JLogo.svg';
import { ReactComponent as Logo } from '../../../assets/icons/Logo.svg'
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCurrentClub } from '../../../redux/reducers/clubDetails';
// import { getIsSignedIn } from 'src/utils/auth';
// import { Spacer } from 'src/common/comps';

const Div = styled.div`
  height: 100%;
  padding: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  #welcome-container {
    display: flex;
    flex-direction: column;
    max-width: 45%;

    #logo-box {
      display: flex;
    }

    #welcome-text {
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
    }

    #company-name {
      font-size: 40px;
      font-weight: bold;
      color: #3551b8;
    }

    #support-text {
      font-size: 16px;
      font-weight: 500;
      margin-top: 121px;
    }
  }
`;

const LoginPage = () => {
  // const { baseRoute } = useAuth();
  // const isSignedIn = getIsSignedIn();
  const {i18n, t} = useTranslation();

  // if (isSignedIn) return <Redirect to={baseRoute} />;

  return (
    <Div>
      <div id="welcome-container">
        <div id="logo-box">
          <Logo width="67px" height="67px" />
          {/* <Spacer xs={22} horizontal /> */}
          <JLogo width="50px" height="65px" />
        </div>
        {/* <Spacer xs={50} /> */}
        <span id="welcome-text">{t('WELCOME_TO')}</span>
        {/* <Spacer xs={32} /> */}
        <span id="company-name">{`${t('PERFORMANCE_MANAGEMENT_SYSTEM')}`}</span>
        <div id="support-text">
          {`${t('SUPPORT_TEXT')} `}
          <a
            href="/support"
            css={`
              color: ${colors.mediumBlue};
            `}
          >
            {t('WEB_FORM')}
          </a>
          .
        </div>
      </div>
      <LoginForm />
    </Div>
  );
};

export default LoginPage;
