import React, { useEffect } from 'react';
import '../styles.css';
//internal and external componentscontainers
import { Typography, Paper, Box, Fade, Popper, ClickAwayListener, Button, ThemeProvider, createTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faXmark,faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import SelectBoxPrimary from '../../../components/SelectBoxPrimary';
import Textarea from '@mui/joy/Textarea';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import ActionsTable from '../../../components/ActionsTable';
import { AvatarAndName } from '../../../components/AvatarAndName';
import { EvidenceWrapper } from '../../../components/EvidenceWrapper';
import { colors } from '../../../common/pallette/colors';
import { getStandardAndClubMetadata, getStandardsEvidences, getStandardsSelfAssessmentComment, getStandrdsActions } from '../../../api/evaluation';
import { useSelector } from 'react-redux';
import EvidenceList from '../../../components/EvidenceList';
import { genUID } from '../../../common/StaticExports';

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 50
                },
            }
        },
    }
})


const OffSiteStandardsDetail = (props:any) => {
    const { detail, isOpen, handleClose } = props;
    const currentClub = useSelector((state:any) => state.clubDetails);
    const [metaData, setMetaData]:any = React.useState({});
    const [evidences, setEvidences] = React.useState([]);
    const [selfAssessmentComments, setSelfAssessmentComments] = React.useState('');

    const tableHead = [
        {
            key: '', displayName: '',
        },
        {
            key: 'status', displayName: 'status',
        },
        {
            key: 'actionComment', displayName: 'action',
        },
        {
            key: 'description', displayName: 'description',
        },
        {
            key: 'dueDate', displayName: 'due date',
        },
        {
            key: 'owner', displayName: 'owner',
        },
        {
            key: 'isComplete', displayName: 'complete',
        },
        {
            key: '', displayName: '',
        },
      ];

      React.useEffect(() => {
        if(isOpen) {
            // const fetchActions = async () => {
            //     try {
            //         const response = await getStandrdsActions({standardId: detail.id, clubId: currentClub.id});
            //         setActions(response);
            //     } catch {
    
            //     }
            // }
    
            const fetchEvidences = async () => {
                try {
                    const response = await getStandardsEvidences({standardId: detail.id, clubId: currentClub.id});
                    setEvidences(response);
                } catch {
    
                }
            }
    
            const fetchSelfAssessmentComments = async () => {
                try {
                    const response = await getStandardsSelfAssessmentComment({standardId: detail.id, clubId: currentClub.id});
                    setSelfAssessmentComments(response);
                } catch {
    
                }
            }

            const fetchStandardMetaData = async () => {
                try {
                    const response = await getStandardAndClubMetadata({standardId: detail.id});
                    setMetaData(response);
                } catch {
    
                }
            }
    
            fetchEvidences();
            fetchSelfAssessmentComments();
            fetchStandardMetaData();
        }
      }, [isOpen]);

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
            <Popper className='popperStyle' open={isOpen} transition>
                    {({ TransitionProps }) => (
            // <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ width: 'fit-content' }}>
                            <Box width={1100} minHeight={600} className={'standardardsDetailWrapper'}>
                                <Box className={'detailsWrapper'}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <Typography className='standardsHeading uppercase'>{genUID(detail.uid)} {detail.name}</Typography>
                                        <Box>
                                            {
                                                Array.from({length: detail.starRating}, () => <FontAwesomeIcon icon={faStar} style={{color: colors.yellow.bright,}} />)
                                            }
                                        </Box>
                                    </Box>
                                    <Typography className='fs13'>{metaData.description}</Typography>
                                </Box>
                                <Box className={'detailsWrapper'}>
                                    <Typography className='standardsHeading uppercase'>Evidence</Typography>
                                    <EvidenceList data={evidences} isLoading={false} />
                                </Box>
                                <Box className={'detailsWrapper'}>
                                    <Typography className='standardsHeading uppercase'>Comments</Typography>
                                    <Box className={'sdCommentsWrapper'}>
                                        <Box className={'standardsDetailsCommentsWrapper mr10'} sx={{ bgcolor: colors.grey.bright }}>
                                            <Typography className='fs13 SDcomments'>{selfAssessmentComments}</Typography>
                                            {/* <Box className={'SDavatarWrapper'}> */}
                                            <AvatarAndName avatarName={'Anonymous'}/>
                                            {/* </Box> */}
                                        </Box>
                                        <JoyCssVarsProvider>
                                            <Textarea sx={{ borderRadius: '6px', mt: 0, width: '50%' }} onChange={(e) => {}} placeholder='Add comment' minRows={4} maxRows={4} value={''}/>
                                        </JoyCssVarsProvider>
                                    </Box>
                                </Box>
                                <Box className={'standardsDetailFooter detailsWrapper'} sx={{ bgcolor: colors.grey.light }}>
                                    <Button className='closeButtonSFD' disableElevation 
                                            variant='contained' size='small' 
                                            onClick={handleClose}
                                            startIcon={<FontAwesomeIcon icon={faXmark} />}>
                                        {'Close'}
                                    </Button>
                                    <Box className={'footerSelectWrapper'}>
                                        <Typography className='selectLabelSDF'>Rating</Typography>
                                        <SelectBoxPrimary label={''} report={'siteReview'} options={[1,2,3]} isStatus={true} selected={detail.status}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                    // </ClickAwayListener>
                    )}
                </Popper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default OffSiteStandardsDetail;