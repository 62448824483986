// Get metadata about standard and club files. Returns empty array when accessing the api first time

import axios, { AxiosResponse } from 'axios';
import { QueryKey } from 'react-query';
import { AxiosArgs, ThenArgs, FileMeta, EvidenceLibraryItem, FileDeleteStatus, Identifier } from './types';
import { encodeBody, encodeQueryParams } from './client';
import { baseUrl } from './config';

export type getFilesMetadataResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta[]>>>>;

export type getFilesMetadataParameters = {
  clubId: Identifier;
  standardId: Identifier;
};

export type getFilesMetadataType = (
  key: QueryKey,
  parameters: getFilesMetadataParameters,
) => getFilesMetadataResponse;

export const getFilesMetadata: getFilesMetadataType = (key, parameters) => {
  const path = `${baseUrl}/api/evidence/standards/${parameters.standardId}/clubs/${parameters.clubId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// delete file
type DeleteFile = (parameters: {
  fileId: Identifier;
  standardId?: Identifier;
  standardKind?: 0 | 1;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<FileDeleteStatus>>>>;

export const deleteFile: DeleteFile = parameters => {
  const path = `${baseUrl}/api/evidence/${parameters.fileId}${encodeQueryParams({
    standardId: parameters.standardId,
    standardKind: parameters.standardKind || 0,
  })}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// upload file

export type uploadFileResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta>>>>;

export type uploadFileParameters = {
  standardId?: Identifier;
  clubId: Identifier;
  existingEvidenceId?: Identifier;
  file: File;
  onUploadProgress?: (progressEvent: any) => void;
  standardKind?: 0 | 1;
  currentFolderId: Identifier
};

export type uploadFileType = (parameters: uploadFileParameters) => uploadFileResponse;

export const uploadFile: uploadFileType = parameters => {
  const path = `${baseUrl}/api/evidence/clubs/${parameters.clubId}${encodeQueryParams({
    standardId: parameters.standardId,
    existingEvidenceId: parameters.existingEvidenceId,
    standardKind: parameters.standardKind || 0,
    currentFolderId: parameters.currentFolderId || 0
  })}`;
  const data = new FormData();
  data.append('file', parameters.file);
  return axios({
    method: 'POST',
    url: path,
    data,
    onUploadProgress: parameters.onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => response.data.data);
};

//Bulk update files comments

export type updateFileCommentsResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta[]>>>>;

export type updateFileCommentsParameters = {
  comments: Array<{ id: number; clubComment: string }>;
  standardId: Identifier;
};

export type updateFileCommentsType = (parameters: updateFileCommentsParameters) => updateFileCommentsResponse;

export const updateFileComments: updateFileCommentsType = parameters => {
  const path = `${baseUrl}/api/evidence/standards/${parameters.standardId}/comments`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(parameters.comments),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

//check is file exist

export type checkFileExistResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta[]>>>>;

export type checkFileExistParameters = {
  clubId: number;
  fileName: string;
};

export type checkFileExistType = (parameters: checkFileExistParameters) => checkFileExistResponse;

export const checkFileExist: checkFileExistType = parameters => {
  const path = `${baseUrl}/api/evidence/clubs/${parameters.clubId}/checkEvidenceName?evidenceName=${parameters.fileName}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// get all files of club

export type GetEvidenceLibrary = (
  a: QueryKey,
  b: {
    clubId: Identifier;
    evaluationCriteriaId?: Identifier;
    evaluationStandardId?: Identifier;
    licenceCriteriaId?: Identifier;
    licenceStandardId?: Identifier;
    evaluationCycleDataId?: Identifier;
    licenceCycleDataId?: Identifier;
    searchString?: string;
    isArchived: boolean;
  },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<EvidenceLibraryItem[]>>>>;

export const getEvidenceLibrary: GetEvidenceLibrary = (key, parameters) => {
  const path = `${baseUrl}/api/evidence/clubs/${parameters.clubId}/library${encodeQueryParams({
    evaluationCriteriaId: parameters.evaluationCriteriaId,
    evaluationStandardId: parameters.evaluationStandardId,
    licenceCriteriaId: parameters.licenceCriteriaId,
    licenceStandardId: parameters.licenceStandardId,
    evaluationCycleDataId: parameters.evaluationCycleDataId,
    licenceCycleDataId: parameters.licenceCycleDataId,
    searchString: parameters.searchString,
    isArchived: parameters.isArchived,
  })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

export type evidenceToStandardResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta>>>>;

export type evidenceToStandardParameters = {
  fileId: Identifier;
  standardId: Identifier;
};

export type evidenceToStandardType = (parameters: evidenceToStandardParameters) => evidenceToStandardResponse;

export const evidenceToStandard: evidenceToStandardType = parameters => {
  const path = `${baseUrl}/api/evidence/standards`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

// get all desire plans for club

export type getLibraryDesirePlansResponse = Promise<
  AxiosArgs<
    ThenArgs<
      AxiosResponse<
        Array<{}>
      >
    >
  >
>;

export type getLibraryDesirePlansParameters = {
  clubId: Identifier;
};

export type getLibraryDesirePlansType = (
  key: QueryKey,
  parameters: getLibraryDesirePlansParameters,
) => getLibraryDesirePlansResponse;

export const getLibraryDesirePlans: getLibraryDesirePlansType = (key, parameters) => {
  const path = `${baseUrl}/api/clubs/${parameters.clubId}/cycles/evidence`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

//evidence folders

export type getEvidenceFoldersParameters = {
  clubId: Identifier;
  isArchived: Boolean;
};

export type getEvidenceFoldersTypes = (
  parameters: getEvidenceFoldersParameters,
) => getLibraryDesirePlansResponse;

export const getEvidenceFolders: getEvidenceFoldersTypes = (parameters) => {
  const path = `${baseUrl}/api/evidence/clubs/${parameters.clubId}/evidenceFolders/${parameters.isArchived}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

//updatingEvidenceFolders
export type updateEvidenceFoldersParameters = {
  clubId: Identifier;
  evidenceFolderId: Identifier;
  parentFolderId: Identifier;
  folderName: string;
  isArchived: Boolean | undefined;
};

export type updatetEvidenceFoldersTypes = (
  parameters: updateEvidenceFoldersParameters,
) => getLibraryDesirePlansResponse;

export const updateEvidenceFolders: updatetEvidenceFoldersTypes = parameters => {
  const path = `${baseUrl}/api/evidence/clubs/updateEvidenceFolder${encodeQueryParams({
    clubId: parameters.clubId,
    evidenceFolderId: parameters.evidenceFolderId,
    parentFolderId: parameters.parentFolderId,
    folderName: parameters.folderName,
    isArchived: parameters.isArchived,
  })}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};


// delete Evidence Folder
type DeleteEvidenceFolderType = (parameters: {
  clubId: Identifier;
  evidenceFolderIds?: Array<Identifier>;
  evidencesToDeleteIds?: Array<Identifier>;
  evidencesToMoveIds?: Array<Identifier>;
  isArchived: Boolean;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<FileDeleteStatus>>>>;
export const deleteEvidenceFolder: DeleteEvidenceFolderType = parameters => {
  let payloadBody:any = {
    clubId: parameters.clubId,
    isArchived: parameters.isArchived
  };

  parameters?.evidenceFolderIds?.forEach((id, index) => {
    payloadBody[`evidenceFolderIds[${index}]`] = id;
  });
  parameters?.evidencesToDeleteIds?.forEach((id, index) => {
    payloadBody[`evidencesToDeleteIds[${index}]`] = id;
  });
  parameters?.evidencesToMoveIds?.forEach((id, index) => {
    payloadBody[`evidencesToMoveIds[${index}]`] = id;
  });

  const path = `${baseUrl}/api/evidence/clubs/removeEvidenceFolder${encodeQueryParams(payloadBody)}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};


//update evidences

export type updateEvidenceResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta>>>>;

export type updateEvidenceParameters = {
  evidenceId: Array<Identifier>;
  newFolderFolderId: Identifier;
  isArchived: Boolean;
  isDelete: Boolean;
};

export type updateEvidenceType = (parameters: updateEvidenceParameters) => updateEvidenceResponse;

export const updateEvidence: updateEvidenceType = parameters => {
  let payloadBody:any = {
    evidenceId: parameters.evidenceId,
    newFolderFolderId: parameters.newFolderFolderId,
    isArchived: parameters.isArchived,
    isDelete: parameters.isDelete
  };
  parameters?.evidenceId?.forEach((id, index) => {
    payloadBody[`evidenceId[${index}]`] = id;
  });

  const path = `${baseUrl}/api/evidence/clubs/updateEvidence${encodeQueryParams(payloadBody)}`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};


//resubmit evidences

export type resubmitEvidenceResponse = Promise<AxiosArgs<ThenArgs<AxiosResponse<FileMeta>>>>;

export type resubmitEvidenceParameters = {
  fileId: Identifier;
  fileUid: string;
};

export type resubmitEvidenceType = (parameters: resubmitEvidenceParameters) => resubmitEvidenceResponse;

export const resubmitEvidence: resubmitEvidenceType = parameters => {
  const path = `${baseUrl}/api/evidence/${parameters.fileId}/fileUid/${parameters.fileUid}/resubmit`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.data.data);
};

type getFileSizeResponse = Promise<number>;

type getFileSizeTypeParameters = {
  url: string;
};

type getFileSizeType = (parameters: getFileSizeTypeParameters) => getFileSizeResponse;

export const getFileSize: getFileSizeType = async ({ url }) => {
  const { token: cancelToken, cancel: cancelDownload } = axios.CancelToken.source();

  let total:number | undefined = 0;
  try {
    await axios({
      method: 'GET',
      url,
      onDownloadProgress: progress => {
        total = progress.total;
        cancelDownload();
      },
      cancelToken,
    });
  } catch (error) {
    return total;
  }

  return total;
};

export const triggerFile = (url: string) => {
  const { token: cancelToken, cancel: cancelDownload } = axios.CancelToken.source();

  return axios({
    method: 'GET',
    url,
    onDownloadProgress: () => {
      cancelDownload();
    },
    cancelToken,
  });
};
