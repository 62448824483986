import React from "react";
//internal and external componentscontainers
import { Typography, Paper, Box, Fade, Popper, Button, ThemeProvider, createTheme, ClickAwayListener } from '@mui/material';
import { CommentsOverviewBox } from "../CommentsOverviewBox";
import { executiveSummary } from "../../assets/staticData";
import { ReportComment } from "../ReportComment";

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 0
                },
            }
        },
    }
})

const ExecutiveSummaryPopper = (props: { isOpen: boolean, handleIsOpen: any }) => {
    const { isOpen, handleIsOpen } = props;
    const [comment, setComment] = React.useState('');
    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
        // handleIsOpen()
    }
    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popper className='popperStyle' open={isOpen} transition>
                    {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleIsOpen}>
            <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ p: 2, width: 'fit-content', mt: '70px' }} onClick={() => handleClickInsidePopper}>
                            <Typography className="fw700 uppercase">Executive Summary</Typography>
                            <Box sx={{ display: "flex", mt: 2, mb: 2, height: 'auto' }}>
                                <CommentsOverviewBox data={executiveSummary} parentComponent={'executiveSummary'} />
                                <ReportComment />
                            </Box>
                            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Button variant="outlined" disableElevation onClick={handleIsOpen}>Cancel</Button>
                                <Button variant="contained" disableElevation>Save</Button>
                            </Box> */}
                        </Paper>
                    </Fade>
                    </ClickAwayListener>
                    )}
                </Popper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default ExecutiveSummaryPopper;