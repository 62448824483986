import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { EnhancedTableHead } from "../../../components/EnhancedTableHead";
import { getImageSrc } from "../EvidenceTable/comps/FileType";
import { getComparator } from "../../../common/SortSupport";
import useFileTranslationProcess from "../../../hooks/useFileLangTranslation";
// @ts-ignore
import { queryCache, useMutation } from 'react-query';
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faDownload, faEye, faEyeSlash, faFileExport, faLinkSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import NestedThreeDotMenu from "../../../components/NestedThreeDotMenu";
import { useDispatch, useSelector } from "react-redux";
import { setArchiveData, setCurrentData, updateIsOpenActionBar, setExistingEvidenceId } from "../../../redux/reducers/library";
// import usePreservedHistory from "../../../hooks/usePreservedHistory";
import { deleteFile, updateEvidence } from "../../../api/evidence";
import { FileDeleteStatus } from "../../../api/types";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import CheckBox from "../../../components/CheckBox/CheckBox";
import { stableSort } from "../../../common/SortSupport";

const Row = (props: {row:any, i:number, standards:any}) => {
  const [t, i18n] = useTranslation();
  const { row, i, standards } = props;
  const [open, setOpen] = React.useState(false);
  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const dispatch = useDispatch();
  const folderStructure = useSelector((state: any) => state.library.folderStructure);
  const currentData = useSelector((state:any) => state.library.currentData);
  const selectedFolder = useSelector((state:any) => state.library.selectedFolder);
  const archiveData = useSelector((state: any) => state.library.archiveData);
  const currentFolderList = useSelector((state: any) => state.library.currentFolderList);
  const currentRootFolder = currentFolderList.find((x:any) => x.parentFolderId == null);
  const isOpenActionBar = useSelector((state: any) => state.library.isOpenActionBar);
  // const { history } = usePreservedHistory();
  const [previousUploadFile, setPreviousUploadFile] = useState<any>([]);
  const clubDetails = useSelector((state:any) => state.clubDetails);
  const clubDesirePlan = useSelector((state: any) => state.clubData.clubDesirePlan);
  const inputRef = useSelector((state: any) => state.common.inputRef);
  // const isOldCycle = clubDesirePlanId !== clubDesirePlan.data?.id;
  const isOldCycle = false;
  const isAdmin = useSelector((state:any) => state.common.isAdmin);


    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: any,
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const visibleRows = React.useMemo(
      () =>
        stableSort(standards, getComparator(order, orderBy)),
      [order, orderBy, row],
    );
  
    const head = [
      {
        key: 'type', displayName: t('TYPE'),
      },
      {
        key: 'criteriaUid', displayName: t('CRITERIA_ID'),
      },
      {
        key: 'criteriaName', displayName: t('CRITERIA'),
      },
      {
        key: 'uid', displayName: t('STANDARD_ID'),
      },
      {
        key: 'name', displayName: t('STANDARD'),
      },
      {
        key: '', displayName: '',
      },
    ]

    // @ts-ignore
    // const [delFile] = useMutation(deleteFile, {
    //   onSuccess: data => {
    //     if (data === 0) {
    //       toast.success(t('FILE_DETACHED'));
    //     } else {
    //       toast.success(t('FILE_DELETED'));
    //       // @ts-ignore
    //       const tempCurrentData = currentData.filter(d => d.id != row.id);
    //       dispatch(setCurrentData(tempCurrentData));
    //     }
    //     queryCache.invalidateQueries(['evidence_library', { clubId: clubDetails?.id }]);
    //   },
    // });

    // const onOpenFile = () => {
    //   useFileTranslationProcess({
    //     onSuccessTranslation: () => queryCache.invalidateQueries(['evidence_library', { clubId: clubDetails?.id }]),
    //   });
    // }

    let evaluationStandardsCount = row?.evaluationStandards?.length;
    let licenceStandardsCount = row?.licenceStandards?.length;

    const getCriteriaPath = (type:any, criteriaId:number) => {
      switch(type) {
        case 'EVALUATION':
          return `/evaluation/criteria/${criteriaId}/standards`; break;
        case 'LICENCE_EL':
          return `/licence/${criteriaId}`;break;
      }
    };
    
    const getStandardsPath = (type:any, criteriaId:number, stadId:number) => {
      switch(type) {
        case 'EVALUATION':
          return `/evaluation/criteria/${criteriaId}/standards/${stadId}/edit`; break;
        case 'LICENCE_EL':
          return `/licence/${criteriaId}/standards/${stadId}/edit`;break;
      }
    };

    
    const manageMovetoClick = (newDirectory:any) => {
      let currentEvidence = row.id;
      let newEvidenceFolder = newDirectory?.pathId;

      if(newEvidenceFolder) {
        const moveEvidencePath = async () => {
          const response = await updateEvidence({
            evidenceId: [currentEvidence],
            newFolderFolderId: newEvidenceFolder,
            isArchived: false,
            isDelete: false
          })
        }
        moveEvidencePath().then(() => {
          let currentRow = {...row, evidenceFolderId: newEvidenceFolder};
          const updatedCurrentData = currentData.map((obj:any) => {
            if(obj.id == currentRow.id) {
              return currentRow;
            } return obj;
          }) 
          dispatch(setCurrentData(updatedCurrentData));
          renderMoveTo(currentRow);
        })
      }
    }

    const manageArchiveEvidence = async () => {
      const nowDate = new Date();
      const response = await updateEvidence({
        evidenceId: [row.id],
        // @ts-ignore
        newFolderFolderId: null,
        isDelete: false
      }).then(() => {
        let currentRow:any = {...row, isArchived: true, archivedDate: nowDate};
        const updatedCurrentData = currentData.filter((obj:any) => {
          return obj.id != currentRow.id
        }) 
        dispatch(setCurrentData(updatedCurrentData));
        
        const tempArchiveData:any = [...archiveData, currentRow];
        dispatch(setArchiveData(tempArchiveData));
      })
    }

    const handleThreeDotEvents = (selectedAction:any) => {      
      switch(selectedAction) {
        case t('TDM_FILE_VIEW'): 
          // onOpenFile('preview', row);
          break;
        case t('TDM_FILE_DOWNLOAD'): 
          // onOpenFile('download', row);
          break;
        case t('TDM_FILE_REPLACE'): 
          replaceTdm();
          break;
        case t('TDM_FILE_DELETE'):
          // deleteFileTdm();
          break;
        case t('TDM_FILE_ARCHIVE'):
          manageArchiveEvidence();
          break;
        case t('TDM_FILE_MOVE_TO'): 
          console.log('clicked on Move to');
          break;
        default:
          manageMovetoClick(selectedAction);
          break;
      }
    }

    const manageDragging = (event:any, data:any) => {
      event.dataTransfer.setData('text/plain', data);
    }

    let [menuOptions, setMenuOptions]:any = useState([
      {
        label: t('TDM_FILE_VIEW'),
        subMenu: [],
        isDisabled: false,
        icon: faEye
      },
      {
        label: t('TDM_FILE_DOWNLOAD'),
        subMenu: [],
        isDisabled: false,
        icon: faDownload
      },
      {
        label: t('TDM_FILE_REPLACE'),
        subMenu: [],
        isDisabled: !(
          !isAdmin &&
          !isOldCycle &&
          (!clubDesirePlan.data?.isReadyToReview || !evaluationStandardsCount) &&
          !licenceStandardsCount
        ),
        icon: faArrowsRotate
      },
      {
        label: t('TDM_FILE_DELETE'),
        subMenu: [],
        isDisabled: !(
          (!isAdmin && evaluationStandardsCount == 0 && licenceStandardsCount == 0) ||
          (!isAdmin &&
          !isOldCycle &&
          (!clubDesirePlan.data?.isReadyToReview || !evaluationStandardsCount) &&
          !licenceStandardsCount)
        ),
        icon: faTrash
      },
      {
        label: t('TDM_FILE_ARCHIVE'),
        subMenu: [],
        isDisabled: !(evaluationStandardsCount == 0 && licenceStandardsCount == 0 &&
          !isAdmin),
        icon: faEyeSlash
      },
    ]);

    const renderMoveTo = (currentData:any) => {
      let movetoList:any = [];
      if(currentData == undefined) {
        currentFolderList.map((d:any) => {
          movetoList.push({ label: d.parentFolderId == null ? t('ALL_DOCUMENTS') : d.name, pathId: d.id, isDisabled: row.evidenceFolderId == d.id, subMenu: [] });
        })
      } else{
        currentFolderList.map((d:any) => {
          movetoList.push({ label: d.parentFolderId == null ? t('ALL_DOCUMENTS') : d.name, pathId: d.id, isDisabled: currentData.evidenceFolderId == d.id, subMenu: [] });
        })
      }
      const tempMenuOptions = menuOptions.filter((x:any) => x.label != t('TDM_FILE_MOVE_TO'));
      setMenuOptions([...tempMenuOptions, {label: t('TDM_FILE_MOVE_TO'), isDisabled: isAdmin, icon: faFileExport, subMenu:[...movetoList]}])
    }
    useEffect(() => {
      renderMoveTo(undefined);
    }, [folderStructure, selectedFolder, currentFolderList])

    //unlink the standard
    const unlinkTdm = (standardId:number, standardKind:any) => {
      return deleteFile({ fileId: row.id, standardId, standardKind }).then(() => {
        queryCache.invalidateQueries(['evidence_library', { clubId: clubDetails?.id }]);
        // setIsOpen(false);
        const tempCurrentData = currentData.map((d:any) => {
          if(d.id == row.id) {
            if (standardKind) {
              const updatedStandards = d.licenceStandards.filter((c:any) => c.id != standardId);
              return { ...d, licenceStandards: updatedStandards };
            } else {
              const updatedStandards = d.evaluationStandards.filter((c:any) => c.id != standardId);
              return { ...d, evaluationStandards: updatedStandards };
            }
          } else {
            return d;
          }
        })
        dispatch(setCurrentData(tempCurrentData))
      });
    };

    
    const replaceTdm = () => {
      dispatch(setExistingEvidenceId(row.id));
      inputRef.current?.click();
      // manageAddFile();
    };

    const deleteFileTdm = () => {
      setPreviousUploadFile(previousUploadFile.filter((el:any) => el !== row.title));
      // return delFile({ fileId: row.id });
      return 0;
    };

    const manageSelectClick = () => {
      let tempData:any = currentData.map((d:any) => {
        if(d.id == row.id) {
          return {...row, isSelected: !row.isSelected};
        } return d;
      });
      // @ts-ignore
      dispatch(setCurrentData([...tempData]));

      const tempList = tempData.filter((d:any) => d.isSelected);

      if(tempList.length && !isOpenActionBar) {
        // @ts-ignore
        dispatch(updateIsOpenActionBar(true));
      } else if(tempList.length == 0 && isOpenActionBar) {
        // @ts-ignore
        dispatch(updateIsOpenActionBar(false));
      }
    }


    const title:any = decodeURIComponent(row.title);
    return (
        <>
        <TableRow
          className='hoverBG'
          key={`collapsable-table-row-${i}`}
          sx={{ '& > *': { borderBottom: 'unset' } }}
        >
          <TableCell>
            <Box>
             {/* @ts-ignore */}
              <CheckBox
                // @ts-ignore
                onClick={manageSelectClick}
                isHidden={isAdmin}
                isOn={row?.isSelected}
                isMain={true}
              />
            </Box>
          </TableCell>
          <TableCell>
            {
              (row.evaluationStandards.length || row.licenceStandards.length) ?
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton> : ''
            }
          </TableCell>
          <TableCell onDragStart={(e:any) => manageDragging(e, row)}>
            <Tooltip title={title} arrow placement='top'>
              {title}
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title={title.split('.')[title.split('.').length-1]} arrow placement='top'>
              {<img src={getImageSrc(title.split('.')[title.split('.').length-1])} alt="" />}
            </Tooltip>
          </TableCell>
          {/* <TableCell>26kb</TableCell> */}
          <TableCell>{new Date(row.uploaded).toLocaleDateString()}</TableCell>
          <TableCell align="center">{evaluationStandardsCount}</TableCell>
          <TableCell align="center">{licenceStandardsCount}</TableCell>
          <TableCell>
            <NestedThreeDotMenu options={menuOptions} manageClick={(e:any) => {handleThreeDotEvents(e)}} pathId={row.pathId}/>
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open && visibleRows?.length > 0} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeads={head}
                />
                <TableBody>
                  {
                    visibleRows?.length > 0 ?
                    visibleRows.map((d:any) => {
                        return(
                            <TableRow className='hoverBG'>
                              <TableCell component="th" scope="row">{t(d.type)}</TableCell>
                              <TableCell>{d.criteriaUid}</TableCell>
                              <TableCell className="pointer" onClick={() => {
                                // history.pushWithState(getCriteriaPath(d.type, d.criteriaId))
                                }}>{d.criteriaName}</TableCell>
                              <TableCell>{d.uid}</TableCell>
                              <TableCell className="pointer" onClick={() =>{ 
                                // history.pushWithState(getStandardsPath(d.type,d.criteriaId ,d.id))
                                }}>{d.name}</TableCell>
                              <TableCell>
                                {(
                                    !isAdmin &&
                                    !isOldCycle &&
                                    (!clubDesirePlan.data?.isReadyToReview || !row.evaluationCriteria?.length)
                                  ) &&
                                  <Tooltip title={'Unlink'} arrow placement='top'>
                                    <span onClick={() => unlinkTdm(d.id, (d.type == 'EVALUATION' ? 0 : 1))}>
                                      <FontAwesomeIcon icon={faLinkSlash}/>
                                    </span>
                                  </Tooltip>
                                }
                              </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow>
                      <TableCell colSpan={7} align='center'>
                        No Records Found
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>
    );
}

export default Row;