import { faCaretRight, faCaretDown, faFolder, faCheck, faXmark, faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField, Typography, Tooltip, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import './styles.css';
import { ThemeProvider, createTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedArchiveFolder, setDisplayData, setSelectedFolder, updateArchiveFolderStructure, updateFolderStructure, setDisplayArchiveData, updateArchiveFolderList, updateCurrentFolderList, setCurrentData, setArchiveData } from "../../redux/reducers/library";
import { deleteEvidenceFolder, getEvidenceFolders, getEvidenceLibrary, updateEvidenceFolders } from "../../api/evidence";
import NestedThreeDotMenu from "../NestedThreeDotMenu";
import { isAdmin } from "../../tempGlobal";
import FolderStructureSkeleton from "../FolderStructureSkeleton";
import { useTranslation } from "react-i18next";
import InformationPopup from "../InformationPopup";


const theme = createTheme({
    components: {
        MuiInputBase: {
          styleOverrides: {
            input: {
                padding: '0px'
            }
          }
        },
    }
})

const Directory = (props:any) => {
    const { data, isRootFolder, isOpen, manageIsOpen, section } = props;
    const [renameLabel, setRenameLabel] = useState('');
    
    const [isRename, setIsRename] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();
    const folderStructure = useSelector((state: any) => state.library.folderStructure);
    const archiveFolderStructure = useSelector((state: any) => state.library.archiveFolderStructure);
    const currentData = useSelector((state: any) => state.library.currentData);
    const archiveData = useSelector((state: any) => state.library.archiveData);
    const evidenceData = section == 'archive' ? archiveData : currentData;
    const selectedFolder = useSelector((state:any) => state.library.selectedFolder);
    const selectedArchiveFolder = useSelector((state:any) => state.library.selectedArchiveFolder);
    const displayData = useSelector((state:any) => state.library.displayData);
    const displayArchiveData = useSelector((state:any) => state.library.displayArchiveData);
    const folders = section == 'archive' ? archiveFolderStructure : folderStructure;
    const selected = section == 'archive' ? selectedArchiveFolder : selectedFolder;
    const currentFolderList = useSelector((state: any) => state.library.currentFolderList);
    const archiveFolderList = useSelector((state: any) => state.library.archiveFolderList);
    const folderList = section == 'archive' ? archiveFolderList : currentFolderList;
    const [isProcessing, setIsProcessing] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [renameWarning, setRenameWarning] = useState(false);
    const [retainFiles, setRetainFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [retainFilesIds, setRetainFilesIds] = useState([]);
    const [deleteFilesIds, setFilesToDeleteIds] = useState([]);
    const [foldersToDelete, setFoldersToDelete] = useState([]);
    const currentRootFolder = currentFolderList.find((x:any) => x.parentFolderId == null);
    const archiveRootFolder = archiveFolderList.find((x:any) => x.parentFolderId == null);
    const clubDesirePlan = useSelector((state: any) => state.clubData.clubDesirePlan);
    const clubLicence = useSelector((state: any) => state.clubData.clubLicence);
    const clubDetails = useSelector((state:any) => state.clubDetails);
    

    const manageOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
        let draggedData = event.dataTransfer.getData('text/plain'); 
    }

    const handleThreeDotEvents = (selectedAction: any) => {
      switch(selectedAction) {
        case 'Delete Folder': 
            let sortedFiles:any = [];
            let deleteFolders: any = getChildIds(data?.pathId);
            setFoldersToDelete(deleteFolders);
            deleteFolders.map((d:any)=> {
                if(section == 'current') {
                    const selectedFiles = currentData.filter((obj:any) => obj.evidenceFolderId == d);
                    selectedFiles.forEach((d:any)=> {
                        sortedFiles.push(d);
                    });
                } else {
                    const selectedFiles = archiveData.filter((obj:any) => obj.archivedEvidenceFolderId == d);
                    selectedFiles.forEach((d:any)=> {
                        sortedFiles.push(d);
                    });
                }
            })
            let arr1:any = [];
            let arr2:any = [];
            let arr1Ids:any = [];
            let deleteIds:any = [];
            sortedFiles.map((d:any) => {
                if(((d?.evaluationStandards?.length != 0 || d?.licenceStandards?.length != 0) && clubDesirePlan.data?.isReadyToReview) ||
                ((d?.evaluationStandards?.length != 0 || d?.licenceStandards?.length != 0) && section == 'archive')) {
                    arr1.push(d);
                    arr1Ids.push(d.id);
                } else {
                    arr2.push(d);
                    deleteIds.push(d.id);
                }
            })
            setRetainFiles(arr1);
            setFilesToDelete(arr2);
            setRetainFilesIds(arr1Ids);
            setFilesToDeleteIds(deleteIds);
            if(arr1.length > 0 || arr2.length > 0 || deleteFolders.length > 1) {
                setOpenDeletePopup(true);
            } else {
                manageDeleteFolder();
            }
            break;
        case 'Rename Folder':
            setIsRename(true);
            break;
      }
    }

    const handleDelete = () => {
      let params:any = {};
      if(section == 'current') {
        params = 
        {
          clubId: clubDetails?.id as number,
          evaluationCriteriaId: null,
          evaluationStandardId: null,
          licenceCriteriaId: null,
          licenceStandardId: null,
          evaluationCycleDataId: clubDesirePlan.data.id,
          licenceCycleDataId: clubLicence?.id,
          searchString: null,
          isArchived: false as boolean
        };
      } else {
        params = 
        {
          clubId: clubDetails?.id as number,
          evaluationCriteriaId: null,
          evaluationStandardId: null,
          licenceCriteriaId: null,
          licenceStandardId: null,
          evaluationCycleDataId: clubDesirePlan.data.id,
          licenceCycleDataId: clubLicence?.id,
          searchString: null,
          isArchived: true as boolean
        };
      }
      getEvidenceLibrary('evidence_library', params).then((data:any) => {
        if(section == 'current') {
          dispatch(setCurrentData(data));
        //   dispatch(setDisplayData(data));
        } else {
          dispatch(setArchiveData(data));
        //   dispatch(setDisplayArchiveData(data));
        }
      });
    }

    useEffect(() => {
        if(!open && selected == data.pathId && !isRootFolder) {
            setOpen(true);
        }
    }, [data])
    
    function getChildIds(parentId:any) {
        const childIds:any = [];
      
        function findChildren(id:any) {
          const children = folderList.filter((obj:any) => obj.parentFolderId === id);
          children.forEach((child:any) => {
            childIds.push(child.id);
            findChildren(child.id);
          });
        }
      
        findChildren(parentId);
        return [parentId, ...childIds];
    }

    const manageDeleteFolder = async () => {
        setIsProcessing(true);
        const response = await deleteEvidenceFolder({
            clubId: clubDetails?.id,
            evidenceFolderIds: foldersToDelete?.length > 0 ? foldersToDelete : [data?.pathId],
            evidencesToDeleteIds: deleteFilesIds,
            evidencesToMoveIds: retainFilesIds,
            isArchived: section == 'archive'
        }).then(() => {
            // @ts-ignores
            let updatedList:any = folderList.filter((d:any) =>  (foldersToDelete?.length > 0 ? foldersToDelete.indexOf(d.id) == -1 : data?.pathId !== d.id));
            if(section == 'archive') {
                if(selected == data.pathId) {
                    onSelectFolder('makeRoot');
                    dispatch(setSelectedArchiveFolder(archiveRootFolder.id));
                }
                dispatch(updateArchiveFolderList(updatedList));
                handleDelete();
            } else {
                if(selected == data.pathId) {
                    onSelectFolder('makeRoot');
                    dispatch(setSelectedFolder(currentRootFolder.id));
                }
                dispatch(updateCurrentFolderList(updatedList));
                handleDelete();
            }
            setFoldersToDelete([]);
            setRetainFiles([]);
            setFilesToDelete([]);
            setRetainFilesIds([]);
            setFilesToDeleteIds([]);
            setIsProcessing(false);
        })
    }
    
    const manageRenameClick = (d: any, e: string) => {
        const isNameExist = folderList.some((d:any) => d?.name == e);
        if(!isNameExist && e != '' && e != undefined) {
            let updatedList:any = folderList.map((obj:any) => {
                if(obj.id == d.pathId) {
                    return {
                        ...obj,
                        name: e,
                    }
                }
                return obj;
            })
            
            const renameFolder = async () => {
                const response = await updateEvidenceFolders({
                    clubId: clubDetails?.id,
                    evidenceFolderId: d.pathId,
                    folderName: e,
                    parentFolderId: "",
                    isArchived: undefined
                })
            }
            if(e != '' && e != undefined) {
                setIsProcessing(true);
                renameFolder().then(() => {
                    if(section == 'archive') {
                        dispatch(updateArchiveFolderList(updatedList));
                    } else {
                        dispatch(updateCurrentFolderList(updatedList));
                    }
                    setIsProcessing(false);
                    setRenameLabel('');
                    setIsRename(false);
                })
            }
        } else {
            setRenameWarning(true);
            setRenameLabel('');
        }
    }

    const menuOptions = [
        {label: 'Rename Folder', isDisabled: isAdmin, icon: faPenToSquare},
        {label: 'Delete Folder', isDisabled: isAdmin, icon: faTrash}
    ]

    const onSelectFolder = (sel:any) => {
        if(section == 'archive') {
            let tempData = archiveData.filter((d:any) => {
            return (d.archivedEvidenceFolderId == data.pathId || archiveRootFolder.id == data.pathId || sel == 'makeRoot');
            })
            dispatch(setDisplayArchiveData(tempData));
        } else {
            let tempData = currentData.filter((d:any) => {
            return (d.evidenceFolderId == data.pathId || currentRootFolder.id == data.pathId || sel == 'makeRoot');
            })
            dispatch(setDisplayData(tempData));
        }
    }
    
    return(
        <ThemeProvider theme={theme}>
            <InformationPopup isOpen={(openDeletePopup && (foldersToDelete.length > 1 || deleteFilesIds.length > 0 || retainFilesIds.length > 0)) || renameWarning}
            closeModal={() => {
                if(openDeletePopup) {
                    setOpenDeletePopup(false);
                    manageDeleteFolder();
                } else {
                    setRenameWarning(false);
                }
            }}
            message={openDeletePopup ? 'EVIDENCE_FOLDER_DELETE_WARNING_MESSAGE' : 'FOLDER_NAME_ALREADY_EXIST_MESSAGE'}
            retainedList={retainFiles}
            actionList={filesToDelete}
            singleButtonLabel={'OK'}
            retainMessage={openDeletePopup && 'EVIDENCES_TO_BE_MOVED_TO_ROOTFOLDERS'}
            listMessage={openDeletePopup && 'EVIDENCES_TO_BE_DELETED'}/>
            <Box className={`directoryWrapper ${data.pathId == selected && 'currentFolder'}`}>
                <Box className={`directoryContainer`} onClick={() => {
                    // manageIsOpen();
                    if(section == 'archive') {
                        if(data.pathId != selected && !isRename) {
                            dispatch(setSelectedArchiveFolder(data.pathId));
                            onSelectFolder('');
                        }
                    } else {
                        if(data.pathId != selected && !isRename) {
                            dispatch(setSelectedFolder(data.pathId));
                            onSelectFolder('');
                        }
                    }
                    }}
                    onDrop={(e:any) => manageOnDrop(e)}
                    draggable>
                        <Box className={"carrotIconsContainer"}
                        onClick={(e:any) => {
                            e.stopPropagation();
                            setOpen(!open);
                        }}>
                            {
                                !isRootFolder && data?.children?.length && open &&
                                <FontAwesomeIcon className="directoryIcons" icon={faCaretDown} />
                            }
                            {
                                !isRootFolder && data?.children?.length && !open &&
                                <FontAwesomeIcon className="directoryIcons" icon={faCaretRight} />
                            }
                        </Box>
                    <FontAwesomeIcon className="directoryIcons" icon={faFolder} />
                    {
                        isRename ? 
                            <TextField variant="standard" 
                            className="textfieldStyle" 
                            value={renameLabel}  
                            onKeyDown={(e:any) => {
                                if(e.key == 'Enter') {
                                    e.stopPropagation();
                                    manageRenameClick(data, renameLabel);
                                }
                            }}
                            onChange={(e:any) => {setRenameLabel(e.target.value)}} />
                        : 
                            <Typography>{!isRootFolder ? data.name : t('ALL_DOCUMENTS') + ' (' + evidenceData.length + ')'}</Typography>
                    }
                </Box>
                <Box>
                    {
                        !isRootFolder && 
                        <Box>
                            {
                                isRename ?
                                <>
                                    {
                                        !isProcessing ?
                                        <Box className={'renameOptionsWrapper'}>
                                            <Tooltip title='Cancel' arrow placement='top'>
                                                <span onClick={() => setIsRename(false)}>
                                                    <FontAwesomeIcon className={'iconStyles'} icon={faXmark}/>
                                                </span>
                                            </Tooltip>
                                            <Tooltip title='Rename' arrow placement='top'>
                                                <span onClick={(e:any) => {
                                                    e.stopPropagation();
                                                    manageRenameClick(data, renameLabel);
                                                }}>
                                                    <FontAwesomeIcon className={'iconStyles'} icon={faCheck} />
                                                </span>
                                            </Tooltip>
                                        </Box> :
                                        <CircularProgress sx={{ width: '20px !important', height: '20px !important', mr: '10px' }} />
                                    }
                                </>
                                :
                                <>
                                    {
                                        isProcessing ?
                                        <CircularProgress sx={{ width: '20px !important', height: '20px !important', mr: '10px' }} />
                                        :
                                        <NestedThreeDotMenu options={menuOptions} manageClick={(e:any) => {handleThreeDotEvents(e)}}/>
                                    }
                                </>
                            }
                        </Box>
                    }
                </Box>
            </Box>
            <Box sx={{ ml: 3 }}>
                {
                    ((open && data?.children?.length) || isRootFolder) && data?.children?.map((d:any) => 
                        <Directory 
                        data={d}
                        isRootFolder={false}
                        isOpen={isOpen}
                        manageIsOpen={() => {setOpen(isOpen)}}
                        section={section}/>
                    )
                }
            </Box>
        </ThemeProvider>
    )
}

const FolderStructure = (props:any) => {
    const { section } = props;
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const folderStructure = useSelector((state: any) => state.library.folderStructure);
    const selectedFolder = useSelector((state: any) => state.library.selectedFolder);
    const archiveFolderStructure = useSelector((state: any) => state.library.archiveFolderStructure);
    const currentFolderList = useSelector((state: any) => state.library.currentFolderList);
    const archiveFolderList = useSelector((state: any) => state.library.archiveFolderList);
    const selectedArchiveFolder = useSelector((state: any) => state.library.selectedArchiveFolder);
    const folderList = section == 'archive' ? archiveFolderList : currentFolderList;
    const folders = section == 'archive' ? archiveFolderStructure : folderStructure;
    const selected = section == 'archive' ? selectedArchiveFolder : selectedFolder;
    const [ clubId, setClubId ] = useState(0);
    const [isFetchingFolders, setIsFetchingFolders] = useState(false);
    const [dataFolders, setDataFolders] = useState([]);
    const clubDetails = useSelector((state:any) => state.clubDetails);

    function buildFolderStructure(folders:any, parentId:number | null) {
        const result:any = [];
        
        folders.forEach((folder:any) => {
          if (folder.parentFolderId === parentId) {
            const children = buildFolderStructure(folders, folder.id);
            if (children.length) {
                result.push({
                    ...folder,
                    pathId: folder.id,
                    children: children
                });
            } else {
                result.push({...folder, pathId: folder.id});
            }
          }
        });
      
        return result;
    }

    useEffect(() => {
        
        const rootFolder = folderList.find((d:any) => d?.parentFolderId == null); 
        const folderStruct:any = buildFolderStructure(folderList, null);
        setDataFolders(folderStruct);

        if(section == 'archive' && archiveFolderList.length) {
            dispatch(updateArchiveFolderStructure(folderStruct));
            !selectedArchiveFolder && dispatch(setSelectedArchiveFolder(rootFolder?.id));
        } else if (section == 'current' && currentFolderList.length) {
            dispatch(updateFolderStructure(folderStruct));
            !selectedFolder && dispatch(setSelectedFolder(rootFolder?.id));
        }   
    }, [currentFolderList, archiveFolderList])

    useEffect(() => {
        const evidenceFolders = async () => {
            try {
                let response:any = await getEvidenceFolders({ clubId: clubDetails?.id, isArchived: section == 'archive' });

                //update folder list
                if (response.length) {
                    // response = responseData;


                    if(section == 'archive') {
                        dispatch(updateArchiveFolderList(response));
                    } else {
                        dispatch(updateCurrentFolderList(response));
                    }

                }
                
                setIsFetchingFolders(false);
            } catch (error) {
                console.error('Error fetching evidence folders data:', error);
            }
            setClubId( clubDetails?.id ? clubDetails?.id : 0);
        }

        if((folderStructure.length == 0 && section == 'current') || (archiveFolderStructure.length == 0 && section == 'archive')
        || (clubDetails?.id != undefined && clubDetails?.id != 0 && clubId != clubDetails?.id
        || ((folderList?.some((folder:any) => folder.id == 0) && section == 'current') 
        || (archiveFolderList?.some((folder:any) => folder.id == 0) && section == 'archive')))){
            setIsFetchingFolders(true);
            clubDetails?.id != undefined && evidenceFolders();
        }
    }, [clubDetails, folderStructure, archiveFolderStructure])
    
    return(
        <>
        {
            (isFetchingFolders && dataFolders?.length == 0) ?
            <FolderStructureSkeleton childFolders={10} />
            :
            <Box>
                {
                    dataFolders?.length && dataFolders.map((d:any) => {
                        return(
                            <Directory 
                            data={d}
                            isRootFolder={d?.parentFolderId == null}
                            isOpen={isOpen}
                            manageIsOpen={() => {setIsOpen(!isOpen)}}
                            section={section}/>
                        )
                    })
                }
            </Box>
        // </Box>
        }
        </>
    );
}

export default FolderStructure;