import React, { useEffect } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import ClubsHomePage from './pages/ClubsHomePage';
import { ThemeProvider, createTheme } from "@mui/material";
import JLHome from './containers/JLHome';
import AppDrawer from './components/AppDrawer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faList, faHouse, faCircleNodes, faFile, faBell } from '@fortawesome/free-solid-svg-icons';
import CurrentClubIndicator from './containers/CurrentClubIndicator';
import LicenceHome from './containers/LicenceHome';
import EvidenceLibraryPageeWithState from './pages/EvidenceLibraryPage';
import LoginPage from './pages/auth/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import PasswordResetPage from './pages/auth/PasswordResetPage';
import { setCurrentRoute, setIsAdmin, setIsClubChecker, setIsClubMaker, setToken } from './redux/reducers/common';
import { QueryClient, QueryClientProvider } from 'react-query';
import FilePreviewPage from './common/FilePreviewPage';
import DraggableNotes from './containers/DraggableNotes';
import './i18n';
import ProtectedRoute from './components/ProtectedRoute';
import { routes } from './routes';

library.add(fab, faHouse)

const theme = createTheme({
  components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans',
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans',
            fontSize: '12px',
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans',
            fontSize: '13px',
            padding: '6px 18px',
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans'
          },
          animated: {
            fontFamily: 'Noto Sans'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'Noto Sans'
          },
          gutters: {
            fontFamily: 'Noto Sans'
          }
        }
      }
  }
});

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();
  let token:any = localStorage.getItem('_jauth_');
  let auth:any = localStorage.getItem('_jauth_');
  let parsedAuth = JSON.parse(auth);
  const jlUser = [
    {title: 'Evaluation', icon: faList},
    {title: 'Licence', icon: faCircleNodes},
    {title: 'Evidence Library', icon: faFile},
    {title: 'Notification', icon: faBell}
  ]

  useEffect(() => {
    const url:any = window.location.href;    
    dispatch(setCurrentRoute(url));
    if(parsedAuth && Object.keys(parsedAuth).length && Object.keys(token).length == 0) {
      const isAdminToken = parsedAuth.roles.includes('JLeagueAdmin');
      const isClubMakerToken = parsedAuth.roles.includes('ClubMaker');
      const isClubCheckerToken = parsedAuth.roles.includes('ClubChecker');
      dispatch(setIsAdmin(isAdminToken));
      dispatch(setIsClubMaker(isClubMakerToken));
      dispatch(setIsClubChecker(isClubCheckerToken));
      dispatch(setToken(parsedAuth));
    }
  }, [window.location.href, dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <div className='appWrapper'>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/resetpassword" element={<PasswordResetPage />} />
                {Object.keys(routes).map((key, index) => {
                  const { component: Component, clubRequired } = routes[key];
                  return (
                    <Route path={key} element={<ProtectedRoute element={<Component />}/>} />
                  )
                })}
            </Routes>
            <Footer />
          </div>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;