import React, { useEffect, useState } from 'react';
import useQueryString from '../../hooks/useQueryString';
import { toast } from 'react-toastify';
import { getFileSize } from '../../api/evidence';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { json } from 'stream/consumers';
// import WebViewer from '@pdftron/webviewer';
// https://test.circlesquare.dev/api/evidence/140/fileUid/cc5c6c85-22c2-44ab-9592-e7c6dbd49b62

const PREVIEW_SIZE_LIMIT = 41943040; // 40 mb
const EXEL_FILE_LIMIT = 5210000; // approx 4.96 mb

const FilePreviewPage = () => {
  // const [src] = useQueryString('src');
  // const [lang] = useQueryString('lang');
  // const [extension] = useQueryString('extension');
  const src = localStorage.getItem('fileSrc');
  const lang = 'en';
  const extension = localStorage.getItem('fileExtension');
  const [preview, setPreview] = useState(false);
  const [t] = useTranslation();
  // const user = useSelector((state:any) => state.common.user);
  var user:any = localStorage.getItem('user');
  user = JSON.parse(user);

  const fileSrc = `${src}&userId=${user?.id}&extension=${extension}&lang=${lang}`;
  // const viewer = useRef();
  // useEffect(() => {
  //   WebViewer(
  //     {
  //       path: '/webviewer/lib',
  //       initialDoc: src,
  //       extension,
  //     },
  //     viewer.current,
  //   ).then(instance => {
  //     const { docViewer, disableFeatures, Feature } = instance;
  //     instance.disableFeatures([Feature.Download, Feature.Print, Feature.Annotations]);
  //     docViewer.on('documentLoaded', () => {
  //       disableFeatures([Feature.Download, Feature.Print]);
  //     });
  //     // you can now call WebViewer APIs here...
  //   });
  // }, [src]);
  // return <div className="webviewer" ref={viewer} style={{ height: '100vh', width: '100%' }}></div>;

  const shouldFileBeViewed = () => {
    getFileSize({ url: fileSrc }).then(totalSize => {
      const isExel = extension && ['excel', 'xls', 'xlsx'].includes(extension);
      if (isExel && totalSize < EXEL_FILE_LIMIT) {
        setPreview(true);
        return;
      }
      if (totalSize < PREVIEW_SIZE_LIMIT && !isExel) {
        setPreview(true);
        return;
      }

      toast.info(t('LARGE_FILE_CANNOT_BE_VIEWED'), { autoClose: false });
      window.location.href = fileSrc + '&displayInline=false';
    });
  };

  useEffect(() => {
    if (!user && user.id != undefined) return;
    shouldFileBeViewed();
  }, [user?.id]);

  if (!user) return null;

  if (extension === 'pdf' && preview) {
    return (
      <div css="height: 100%;">
        <embed src={`${fileSrc}#toolbar=0`} css="width: 100%; height: calc(100vh - 160px);" />
      </div>
    );
  }

  console.log('fileSrc', fileSrc);

  return (
    <div className='openFileWrapper'>
      {preview && (
        <iframe
          className='openFileIframe'
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileSrc)}`}
          frameBorder="0"
        />
      )}
    </div>
  );
};

export default FilePreviewPage;
