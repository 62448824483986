import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PlayersTable from "../../components/PlayersTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getPlayersDetails } from "../../api/dataCapture";
import { setPlayerDetails } from "../../redux/reducers/dataCaptures";

const PlayersDetails = () => {
    const [addPlayer, setAddPlayer] = React.useState(false);
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const data = useSelector((state:any) => state.dataCaptures.playerDetails);
    useEffect(() => {
        const fetchPlayersDetails = async () => {
            const response = await getPlayersDetails({clubId: clubDetails.id, isPast: false});
            dispatch(setPlayerDetails(response));
        }
        fetchPlayersDetails();
    }, []);
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography className='standardsHeading uppercase'>Players table</Typography>
                <Button className='smallButton' 
                sx={{ float: 'right' }} disableElevation 
                disabled={addPlayer} 
                variant='contained' 
                size='small' 
                onClick={() => {setAddPlayer(true)}} startIcon={<FontAwesomeIcon icon={faPlus} />}>
                    {'Add new player'}
                </Button>
            </Box>
            <PlayersTable addData={addPlayer} handleClick={() => setAddPlayer(false)}/>
        </Box>
    )
}

export default PlayersDetails;