import { ClickAwayListener, Fade, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getEvidenceLibrary } from "../../api/evidence";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentData } from "../../redux/reducers/library";
import { EnhancedTableHead } from "../EnhancedTableHead";
import { getComparator, stableSort } from "../../common/SortSupport";
import TableSkeleton from "../TableSkeleton";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../common/StaticExports";

const EvidencePopup = (props:any) => {
    const { isOpen, manageClickAway, manageFileSelect } = props;
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const dispatch = useDispatch();
    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);
    const currentData = useSelector((state: any) => state.library.currentData);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');
    const [t] = useTranslation();

    const handleClickInsidePopper = (event: MouseEvent) => {
        event.stopPropagation();
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
      ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };
    const visibleRows:any = React.useMemo(
    () =>
        currentData?.length > 0 &&
        stableSort(currentData, getComparator(order, orderBy)),
    [order, orderBy, currentData],
    );

    useEffect(() => {
      const fetchCurrentLibrary = () => {
        try {
          setIsLoadingCurrent(true);
          const response = getEvidenceLibrary(
            'evidence_library',
            {
              clubId: clubDetails?.id as number,
              isArchived: false as boolean,
            });
          return response;
        } catch (error) {
          console.error('Error while fetching fetchCurrentLibrary data');
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isOpen && clubDetails && clubDetails?.id &&
      fetchCurrentLibrary()?.then((res:any) => {
        dispatch(setCurrentData(res));
        setIsLoadingCurrent(false);
      });
    }, [isOpen]);

    const head = [
        {
          key: 'name', displayName: t('FILE_NAME'),
        },
        {
          key: 'upload', displayName: t('UPLOAD_DATE'),
        },
    ]
    return (
        <>
        <Popper className='popperStyle' open={isOpen} transition>
            {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={() => {manageClickAway()}}>
            <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ p: 2, width: 'fit-content', mt: '70px', maxHeight: 600, overflowY: 'auto' }} onClick={() => handleClickInsidePopper}>
                <TableContainer sx={{ width: '100%', maxHeight: '100%', overflowY: 'auto' }}>
                    <Table stickyHeader size="small" aria-label="a dense table">
                        <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        tableHeads={head}
                        />
                        <TableBody>
                        {
                        !isLoadingCurrent && visibleRows?.length ?
                        visibleRows.map((d: any, i: number) => (
                            <TableRow
                            sx={{ cursor: 'pointer' }}
                            key={`actions-table-row-${i}`}
                            onClick={() => {manageFileSelect(d)}}
                            >
                                <TableCell className='fs12'>
                                    { d.title }
                                </TableCell>
                                <TableCell className='fs12'>
                                    { formatDate(d.uploaded) }
                                </TableCell>
                            </TableRow>
                        )) :
                        (
                            !isLoadingCurrent && (visibleRows?.length == 0 || visibleRows == undefined) ?
                            <TableRow>
                            <TableCell colSpan={2} className='fs12'>No Records Found</TableCell>
                            </TableRow>:
                            <TableSkeleton rowCount={7} cellCount={2} />
                        )
                    }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
            </Fade>
            </ClickAwayListener>
            )}
        </Popper>
        </>
    );
}

export default EvidencePopup;