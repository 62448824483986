import React from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip, Typography,InputAdornment  } from '@mui/material';
import { stableSort, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import { staffList } from '../../assets/staticData';
import YenTextField from '../YenTextField';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const StaffListTable = () => {

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [openActionDetail, setOpenActionDetail] = React.useState(false);
  const data = useSelector((state:any) => state.dataCaptures.staffDetails);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      data?.length > 0 &&
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const head = [
    {
        key: '', displayName: 'name',
    },
    {
        key: '', displayName: 'job title',
    },
    {
        key: '', displayName: 'department',
    },
    {
        key: '', displayName: 'contract type',
    },
  ]

  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <ActionDetailPopper isOpen={openActionDetail} handleIsOpen={() => {setOpenActionDetail(false)}} />
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                    {
                        visibleRows && visibleRows?.length &&
                        visibleRows?.map((d: any, i: number) => (
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`accountingpl-table-row-${i}`}
                        >
                            <TableCell className='fs12'>
                                {d.kanjiName}
                            </TableCell>
                            <TableCell className='fs12'>
                                {d.jobTitle}
                            </TableCell>
                            <TableCell className='fs12'>
                              Football
                            </TableCell>
                            <TableCell className='fs12'>
                              Professional contract
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default StaffListTable;