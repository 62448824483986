import React, { useState } from 'react';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faListUl } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../common/pallette/colors';

const StatusButton = (props: { isTodo?: boolean, status: string }) => {
    const { isTodo, status } = props;

    const [statusDetails, setStatusDetails] = useState([
        {icon: faListUl, text: 'to do', color: colors.purple.bright},
        {icon: faClock, text: 'in progress', color: colors.green.bright},
        {icon: faCheck, text: 'done', color: colors.yellow.bright}
    ])

    const statusContainer = (key: string) => {
        switch(key) {
            case 'todo': return 0; break;
            case 'progress': return 1;break;
            case 'done': return 2; break;
            default: return 0;
        }
    }

    const theme = createTheme({
        components: {
            MuiButton:{
                styleOverrides: {
                    root: {
                        borderRadius: 22,
                        '&:hover': {
                            backgroundColor: statusDetails[statusContainer(status)].color,
                        }
                    }
                }
            }
        }
    })

    return(
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Button size='small'
                        sx={{ fontSize: 12, height: 22, margin: 'auto 0', bgcolor: statusDetails[statusContainer(status)].color, cursor: 'default' }}
                        variant='contained' disableElevation
                        disableRipple
                        startIcon={<FontAwesomeIcon width={12} icon={statusDetails[statusContainer(status)].icon} />}>
                    {statusDetails[statusContainer(status)].text}
                </Button>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default StatusButton;