import React from "react";
import Modal from "../Modal";
import styled from 'styled-components';
// import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Props } from 'react-modal';

interface MessageModalInterface extends Props {
  message: string;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
`;

const MessageModal: React.FC<MessageModalInterface> = ({ message, onClose, ...props }) => {
  const [t] = useTranslation();

  return (
    <StyledModal
      {...props}
      onRequestClose={onClose}
    >
      <div css="font-size: 18px;text-align: center;">{message}</div>
      <div css="margin-top: 40px;">
        {/* <Button css="margin-right: 25px;" onClick={onClose}>
          {t('BACK')}
        </Button> */}
      </div>
    </StyledModal>
  );
};

export default MessageModal;
