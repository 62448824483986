import React from 'react';
//internal and external components/containers import
import { Avatar, Box, Card, CardContent, CardMedia, Skeleton, Typography } from "@mui/material";

const UpcomingCardsSkeleton = () => {
    return(
        <React.Fragment>
            <Card sx={{width: '100%', height: 'auto', marginBottom: 1, textAlign: 'left'}} elevation={0}>
                <Box sx={{m: 1, display: 'flex', justifyContent: 'space-between'}}>
                    <Skeleton variant='circular' height={40} width={40} />
                    <Box sx={{ width: '80%' }}>
                        <Skeleton />
                        <Skeleton />
                    </Box>
                </Box>
            </Card>
        </React.Fragment>
    )
}

export default UpcomingCardsSkeleton;