import * as React from 'react';
// import { useSelector, useDispatch } from "react-redux";
import './styles.css';
//internal and external components import
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import { stableSort, descendingComparator, getComparator } from '../../common/SortSupport';
import { EnhancedTableHead } from '../../../components/EnhancedTableHead';
import { Box } from '@mui/material';
import Row from './Row';
import { stableSort, getComparator } from '../../../common/SortSupport';
import TableSkeleton from '../../../components/TableSkeleton';
import { useTranslation } from 'react-i18next';
import MultiSelectActionBar from '../../../components/MultiSelectActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsOpenActionBar } from '../../../redux/reducers/library';
import { useEffect } from 'react';

function CurrentTable(props:any) {

  const { data, isLoading } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isOpenActionBar = useSelector((state: any) => state.library.isOpenActionBar);
  const selectedFolder = useSelector((state: any) => state.library.selectedFolder);
  const displayData = useSelector((state: any) => state.library.displayData);
  

  // const dispatch = useDispatch();

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  );

  const head = [
    {
      key: '', displayName: '',
    },
    {
      key: '', displayName: '',
    },
    {
      key: 'title', displayName: t('FILE_NAME'),
    },
    {
      key: '', displayName: t('FILE_TYPE'),
    },
    // {
    //   key: '', displayName: t('SIZE'),
    // },
    {
      key: 'uploaded', displayName: t('UPLOAD_DATE'),
    },
    {
      key: 'evaluationStandards', displayName: t('EVALUATION_STANDARDS'),
    },
    {
      key: 'licenceStandards', displayName: t('LICENCE_STANDARDS'),
    },
    {
      key: '', displayName: '',
    },
  ]

  useEffect(() => {
    const update = displayData.some((d:any) => d.isSelected);
    if(update) {
      // @ts-ignore
      dispatch(updateIsOpenActionBar(true));
    } else {
      // @ts-ignore
      dispatch(updateIsOpenActionBar(false));
    }
      
  }, [selectedFolder]);

  //table contents
    
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
    {/* @ts-ignore */}
      {isOpenActionBar && <MultiSelectActionBar manageClose={() => {dispatch(updateIsOpenActionBar(false))}} section={'current'} />}
      <TableContainer sx={{maxHeight: '100%', width: 'auto', overflow: 'auto'}}>
        <Table stickyHeader sx={{ width: '100%', boxShadow: 'none' }} size="small" aria-label="a dense table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            tableHeads={head}
          />
          <TableBody>
            {
              !isLoading &&
              visibleRows?.length > 0 ?
              visibleRows.map((d: any, i: number) => 
              {
                return <Row row={d} i={i} standards={[...d?.evaluationStandards, ...d?.licenceStandards]}/>
              })
              :
              (
                !isLoading &&
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    No Records Found
                  </TableCell>
                </TableRow>
              )
            }
            {
              isLoading &&
              <TableSkeleton rowCount={10} cellCount={7}/>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CurrentTable;