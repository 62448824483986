import React, { useEffect, useState } from 'react';
//internal and external components import
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow, Checkbox, Chip, TextField, ThemeProvider, createTheme, Tooltip  } from '@mui/material';
import { stableSort, getComparator, sortData } from '../../common/SortSupport';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { Box } from '@mui/material';
import { AvatarAndName } from '../AvatarAndName';
import StatusIndicatorSmall from '../StatusIndicatorSmall';
import logo from '../../assets/icons/JLogo.svg';
import ThreeDotMenu from '../ThreeDotMenu';
import SelectBoxPrimary from '../SelectBoxPrimary';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SelectBox from '../SelectBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLinkSlash, faStar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../common/pallette/colors';
import ActionDetailPopper from '../../containers/ActionDetailPopper';
import DateSelector from '../DateSelector';
import { useSelector } from 'react-redux';
import { getStandardListByClub } from '../../api/standards';
import { getSelfAssessmentCriteria } from '../../api/evaluation';
import SearchSelectBox from '../SearchSelectBox';
import { getLicenceCriterias, getStandardListByClubLicence } from '../../api/licence';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '5px'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          display: 'inline',
          width: '90px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '90px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 18,
          height: 18
        }
      }
    }
  },
})

const ActionsStandardTable = (props: any) => {
    const { data, head, addStandards, handleClick, manageDone } = props;

  //supporting contents
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [detail, setDetail] = React.useState(Object);
  const [isOpen, setIsOpen] = React.useState(false);
  const currentClub = useSelector((state:any) => state.clubDetails);
  const [standardsList, setStandardsList] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [criteria, setCriteria]:any = useState({id: null, name: 'Criteria'});
  const [standard, setStandard]:any = useState({});

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      data?.length > 0 &&
      sortData(data, orderBy, order),
    [order, orderBy, data],
  );

  useEffect(() => {

    const fetchStandards = async () => {
        const response = await getStandardListByClub({clubId: currentClub.id});
        return response;
    }
    const fetchCriteria = async () => {
        const response = await getSelfAssessmentCriteria({clubId: currentClub.id});
        return response;
    }

    if(addStandards) {
      currentClub.id &&
      fetchStandards().then((res:any) => {
          setStandardsList(res);
      })
      currentClub.id &&
      fetchCriteria().then((res:any) => {
          setCriteriaList(res);
      })
    }
  }, [addStandards]);


  //table contents
    return(
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative' }}>
              <TableContainer sx={{ maxHeight: '34vh', overflowY: 'auto' }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    tableHeads={head}
                    />
                    <TableBody>
                      {
                        addStandards && 
                        <TableRow
                        sx={{ cursor: 'pointer' }}
                        key={`actions-standards-table-row-add`}
                        >
                            <TableCell className='fs12'>
                              <SearchSelectBox
                              options={standardsList}
                              variant={'standard'}
                              manageSelect={(e:any) => {
                                if(e != null && e != undefined) {
                                  var selectedCriteria = criteriaList.find((d:any) => d.id == e.criteriaId);
                                  setCriteria(selectedCriteria);
                                } else {
                                  setCriteria({id: null, name: 'Criteria'});
                                }
                                setStandard(e);
                              }}
                              />
                            </TableCell>
                            <TableCell className='fs12'>
                              <TextField variant='standard' placeholder='Criteria' disabled value={criteria.name}/>
                            </TableCell>
                            <TableCell className='fs12'>
                              
                            </TableCell>
                            <TableCell className='fs12'>
                              <Tooltip title={'Delete'} placement='top' arrow>
                                <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faXmark} onClick={() => handleClick()}/>
                              </Tooltip>
                              <Tooltip title={'Done'} placement='top' arrow>
                                <FontAwesomeIcon icon={faCheck} onClick={() => {
                                  manageDone({standardId: standard.id, standardName: standard.name, criteriaName: criteria.name, criteriaId: criteria.id});
                                }}/>
                              </Tooltip>
                            </TableCell>
                        </TableRow>
                      }
                    {
                        visibleRows && visibleRows?.length ?
                        visibleRows.map((d: any, i: number) => (
                        <TableRow
                        className='hoverBG'
                        sx={{ cursor: 'pointer' }}
                        key={`actions-standards-table-row-${i}`}
                        >
                            <TableCell className='fs12'>{d.criteriaName}</TableCell>
                            <TableCell className='fs12'>{d.criteriaName}</TableCell>
                            <TableCell className='fs12'>
                              {
                                Array.from({length: d.starRating},() => {
                                    i++;                        
                                    return <FontAwesomeIcon className="mtbAuto" icon={faStar} style={{color: colors.yellow.bright}} />
                                })
                              }
                            </TableCell>
                            <TableCell className='fs12'>
                              <Tooltip title={'Unlink'} placement='right' arrow>
                                <FontAwesomeIcon icon={faLinkSlash}/>
                              </Tooltip>
                            </TableCell>
                        </TableRow>
                        ))
                        :
                        <TableRow>{'No Records Found'}</TableRow>
                      }
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ThemeProvider>
        </React.Fragment>
    )
}

export default ActionsStandardTable;