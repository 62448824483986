import { createSlice } from "@reduxjs/toolkit";

type initialLibraryDataType = {
    libraryData: any,
    currentData: any,
    archiveData: any,
    currentFolderList: any,
    archiveFolderList: any,
    folderStructure: any,
    archiveFolderStructure: any,
    selectedFolder: number | null,
    selectedArchiveFolder: string,
    displayData: any,
    displayArchiveData: any,
    isOpenActionBar: boolean,
    existingEvidenceId: number | undefined,
}

let initialLibraryData: initialLibraryDataType = {
    libraryData: [],
    currentData: [],
    archiveData: [],
    currentFolderList: [],
    archiveFolderList: [],
    folderStructure: [],
    archiveFolderStructure: [],
    selectedFolder: 0,
    selectedArchiveFolder: '',
    displayData: [],
    displayArchiveData: [],
    isOpenActionBar: false,
    existingEvidenceId: undefined,
};

export const library = createSlice({
    name: 'evidenceLibrary',
    initialState: initialLibraryData,
    reducers: {
        updateFolderStructure: (state: any, action: any) => {
            state.folderStructure =  action.payload;
            return state;
        },
        updateArchiveFolderStructure: (state: any, action: any) => {
            state.archiveFolderStructure =  action.payload;
            return state;
        },
        updateCurrentFolderList: (state: any, action: any) => {
            state.currentFolderList =  action.payload;
            return state;
        },
        updateArchiveFolderList: (state: any, action: any) => {
            state.archiveFolderList =  action.payload;
            return state;
        },
        setLibraryData: (state: any, action: any) => {
            state.libraryData =  action.payload;
            return state;
        },
        setCurrentData: (state: any, action: any) => {
            state.currentData =  action.payload;
            return state;
        },
        setArchiveData: (state: any, action: any) => {
            state.archiveData =  action.payload;
            return state;
        },
        setSelectedFolder: (state: any, action: any) => {
            state.selectedFolder =  action.payload;
            return state;
        },
        setSelectedArchiveFolder: (state: any, action: any) => {
            state.selectedArchiveFolder =  action.payload;
            return state;
        },
        setDisplayData: (state: any, action: any) => {
            state.displayData =  action.payload;
            return state;
        },
        setDisplayArchiveData: (state: any, action: any) => {
            state.displayArchiveData =  action.payload;
            return state;
        },
        updateIsOpenActionBar: (state: any, action: any) => {
            state.isOpenActionBar =  action.payload;
            return state;
        },
        setExistingEvidenceId: (state: any, action: any) => {
            state.existingEvidenceId =  action.payload;
            return state;
        },
    }
})

export const { updateFolderStructure, setCurrentData, setArchiveData, 
    setSelectedFolder, setDisplayData, setLibraryData, setDisplayArchiveData,
    setSelectedArchiveFolder, updateArchiveFolderStructure, updateCurrentFolderList,
    updateArchiveFolderList, updateIsOpenActionBar,setExistingEvidenceId } = library.actions;
export default library.reducer;