import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import StandardsTable from "../../components/StandardsTable/SAStandardsTable";
import OffSiteStandardsTable from "../../components/StandardsTable/OffSiteStandardsTable";
import EvalReportStandardsTable from "../../components/StandardsTable/EvalReportStandardsTable";
import InterviewStandards from "../InterviewStandards";
import { useTranslation } from "react-i18next";

const ClubStandards = () => {
    const currentSection = useSelector((state:any) => state.evaluation.currentSection);
    const [t] = useTranslation();

    return(
        <>
            {
                currentSection == t('EVALUATION') &&
                <StandardsTable />
            }
            {
                currentSection == t('OFF_SITE') &&
                <OffSiteStandardsTable />
            }
            {
                currentSection == t('ON_SITE') &&
                <InterviewStandards />
            }
            {
                currentSection == t('EVALUATION_REPORT') &&
                <EvalReportStandardsTable />
            }
        </>
    )
};

export default ClubStandards;