import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box } from '@mui/material';
import LicenceCriteriaTable from '../../components/LicenceCriteriaTable';
import DetailedCriteria from '../DetailedCriteria';
import { getLicenceCriteria } from '../../api/licence';
import { setLicenceCriteria } from '../../redux/reducers/licence';
import FinalReportOverview from '../FinalReportOverview';

const LicenceHome = () => {
    const dispatch = useDispatch();
    const clubDetails = useSelector((state:any) => state.clubDetails);
    const selectedLicenceCriteria = useSelector((state: any) => state.licence.selectedLicenceCriteria);
    const licenceCriteria = useSelector((state: any) => state.licence.licenceCriteria);  

    React.useEffect(() => {
        const fetchLicenceCriteria = async () => {
            try {
                const response = await getLicenceCriteria({clubId: clubDetails.id});
                dispatch(setLicenceCriteria(response));
                
            } catch {
                
            }
        };
        fetchLicenceCriteria();
    }, [])
    
    return(
        <React.Fragment>
            <Box className={'contentSpacing'} sx={{ mt: 6 }}>
                <FinalReportOverview module={'LICENCE'} />
                <Box
                sx={Object.keys(selectedLicenceCriteria)?.length ? {display: "flex", height: '100%'} : { height: '100%' }}>
                    <LicenceCriteriaTable data={licenceCriteria}
                    licenceCriteria={Object.keys(selectedLicenceCriteria).length === 0}
                    selectedLicenceCriteria={selectedLicenceCriteria}/>
                    {(Object.keys(selectedLicenceCriteria).length > 0) && <DetailedCriteria criteria={selectedLicenceCriteria} module={'licence'}/>}
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default LicenceHome;