import React from 'react';
import './styles.css';
import { Box, InputLabel, TextField, createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 12
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '8px !important'
                }
            }
        }
    }
})

const CustomTextField = (props: any) => {
    const { label, value, manageChange, width, isDisabled } = props;
    return(
        <ThemeProvider theme={theme}>
            <Box>
                <InputLabel className='labelStyle'>{label}</InputLabel>
                <TextField disabled={isDisabled} sx={{ width: width ? width : 'max-content' }} label='' value={value} onChange={(e) => {manageChange(e.target.value)}}/>
            </Box>
        </ThemeProvider>
    )
}

export default CustomTextField;